export const API = "https://novafoodsbackend.herokuapp.com";
// export const API = "http://192.168.1.90:3011";
export const cloudfronturl = process.env.REACT_APP_CLOUDFRONT;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const KHALTI_KEY = process.env.REACT_APP_API_KHALTI;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_API_GOOGLE_CLIENT_ID;
export const KEY_AES = process.env.REACT_APP_API_KEY_AES;
export const FB_CON_ACCESS_TOKEN =
  process.env.REACT_APP_API_CONVERSION_ACCESS_TOKEN;
export const FB_PIXEL_ID = process.env.REACT_APP_API_CONVERSION_PIXEL_ID;
