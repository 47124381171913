import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  IconButton,
  Modal,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import { ArrowLeft, CaretDown, ShoppingBag, X } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GetAPI, POSTAPI } from "../../Auth/Index";
import {
  AlertError,
  formatDateTime,
  formatTime,
  NotSupported,
} from "../../Components/Common";
import "../../css/KDS/history.css";
import "../../css/KDS/home.css";
import { HideOnScroll } from "../Market/ScrollTest";
import { Item } from "./KDS_Home";

const theme = createTheme({
  components: {
    // Name of the component
    MuiAppBar: {
      styleOverrides: {
        root: {
          left: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#8943EF",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#818091",
          textTransform: "none",
          "&.Mui-selected": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#2E2E2E",
            textTransform: "none",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0",
          minHeight: "0px",
          "&.Mui-expanded": {
            minHeight: "0px",
          },
        },
        content: {
          margin: "0px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
        expandIconWrapper: {
          color: "#2E2E2E",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#F9F9F9",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
          color: "#818091",
        },
      },
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "670px",
  backgroundColor: "#FFFFFF",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflowX: "hidden",
  overflowY: "auto",
  scrollbarWidth: "thin",
};

export const KDSOrder = (props) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    first: true,
    orders: [],
    errorPosition: "",
    errorText: "",
    open: false,
    modalItem: {},
    modalItem_index: null,
    loading: false,
    loading_index: null,
  });
  const {
    first,
    orders,
    errorPosition,
    errorText,
    open,
    modalItem,
    modalItem_index,
    loading,
    loading_index,
  } = values;
  const outletContext = useOutletContext();
  const setErrorPositionFn = outletContext ? outletContext[0] : null;

  useEffect(() => {
    if (first) {
      GetAPI("/order/kds/history").then((res) => {
        if (res.success) {
          setValues((x) => ({ ...x, orders: res.orderHistory, first: false }));
        }
      });
    }
  }, [first]);

  const openModal = (each, i) => {
    setValues((x) => ({
      ...x,
      modalItem: each,
      open: !open,
      modalItem_index: i,
    }));
  };

  const recallFn = (thisOrder, i) => {
    setValues((prev) => ({
      ...prev,
      loading: true,
      loading_index: i,
    }));
    var send_data = {
      orderId: thisOrder.orderId,
      newStatus: "Queue",
    };
    POSTAPI("/order/status/change", send_data).then((res) => {
      if (res.success) {
        // setValues(prev => ({
        //     ...prev, orders: res.orders,
        //     errorPosition: 'success', errorText: 'Recalled ticket.',
        //     loading: false, loading_index: null, open: false
        // }))
        setErrorPositionFn((x) => ({
          ...x,
          errorPosition: "success",
          errorText: "Recalled ticket.",
        }));
        navigate("/kds/home");
      }
    });
  };

  const rightSide = (each) => (
    <>
      {/* item name */}

      {each.comboId && (
        <div className="d-flex mb-2 justify-content-between">
          <Accordion>
            <AccordionSummary
              expandIcon={<CaretDown weight="bold" className="ml-2" />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              {each.name}
            </AccordionSummary>
            <AccordionDetails>
              {/* {
    each
} */}
            </AccordionDetails>
          </Accordion>
          <span>x{each.quantity}</span>
        </div>
      )}

      {each.itemId && (
        <div className={"tile_each_btn_title"}>
          <span>{each.name}</span>
          <span>x{each.quantity}</span>
        </div>
      )}

      {/* modifiers */}
      <div
        className={
          each.modifiers && each.modifiers.length > 0
            ? "p-2 d-flex flex-column align-items-start w-100 ml-3"
            : "align-items-start w-100 ml-3"
        }
      >
        {each.modifiers &&
          each.modifiers.length > 0 &&
          each.modifiers.map((mod, ind) => {
            return (
              <p key={ind} className={"tile_mod_text"}>
                {mod.name}
              </p>
            );
          })}
        {each.spiceLevel && (
          <div className={"tile_mod_spice_div mb-3"}>
            <span className="tile_mod_text">Spice Level</span>
            <span className="tile_mod_text">{each.spiceLevel}</span>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className="testOk">
      <div className="kds_layout">
        <div className="kds_layout_main">
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <HideOnScroll {...props}>
              <AppBar className="bg-white market_appbar">
                <Toolbar className="bg-white kds_width">
                  <div
                    className="ml_top w-100"
                    onClick={() => navigate("/kds/home")}
                  >
                    <IconButton>
                      <ArrowLeft />
                    </IconButton>
                    <span className="history_title">Order History</span>
                  </div>
                </Toolbar>
              </AppBar>
            </HideOnScroll>
          </ThemeProvider>

          <Toolbar />

          <Container style={{ maxWidth: "100vw" }}>
            <Box sx={{ my: 2 }}>
              <div className="kds_width_main ml-4 mr-4">
                <div className="kds_width pt-4">
                  <Box sx={{ flexGrow: 1 }} className="ad_dash_top_kds w-100">
                    <Grid container spacing={3} className="w-100">
                      {orders &&
                        orders.length > 0 &&
                        orders.map((each, i) => (
                          <Grid
                            key={i}
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={3}
                          >
                            <Item className="d-flex">
                              <div className="kds_order_each">
                                <div className="kds_oe_title_div">
                                  <span className="c3 w3 s2">
                                    # {each.trackingNumber}
                                  </span>
                                  <span className="c7 s2">
                                    {formatDateTime(each.createdAt)}
                                  </span>
                                </div>

                                <div className="elipsis_text mt-2">
                                  <ShoppingBag
                                    className="oh_icon"
                                    color="#8943EF"
                                    size={20}
                                  />
                                  <span className="oh_text">
                                    {each.foodNames && each.foodNames.join(",")}
                                  </span>
                                </div>

                                <div className="d-flex justify-content-between mt-3">
                                  <Button
                                    className="oh_btn"
                                    onClick={() => openModal(each, i)}
                                  >
                                    View Details
                                  </Button>
                                  <Button
                                    className="oh_btn_track"
                                    onClick={() => recallFn(each, i)}
                                  >
                                    Recall Ticket
                                  </Button>
                                </div>
                              </div>
                            </Item>
                          </Grid>
                        ))}
                      {orders && orders.length === 0 && "No orders yet."}
                    </Grid>
                  </Box>
                </div>
              </div>
            </Box>

            <ThemeProvider theme={theme}>
              <Modal
                open={open}
                onClose={() =>
                  setValues((x) => ({
                    ...x,
                    open: false,
                    modalItem: {},
                    modalItem_index: null,
                  }))
                }
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  {modalItem && modalItem.orderId && (
                    <>
                      <div className="ko_modal_top">
                        <span className="ko_mt_track">
                          # {modalItem.orderId}
                        </span>
                        <IconButton
                          onClick={() =>
                            setValues((x) => ({
                              ...x,
                              open: false,
                              modalItem: {},
                              modalItem_index: null,
                            }))
                          }
                        >
                          <X />
                        </IconButton>
                      </div>
                      <div className="d-flex mt-3">
                        <div className="col-6">
                          <p className="ko_modal_left_title">Ordered at</p>
                          <p>{formatTime(modalItem.createdAt)}</p>
                          <p className="ko_modal_left_title">Created at</p>
                          <p>{formatTime(modalItem.cookingCompletedAt)}</p>
                          {modalItem.note && (
                            <p className="ko_modal_left_title">Note</p>
                          )}
                          <p>{modalItem.note}</p>
                        </div>
                        <div className="col-6 p-3">
                          {modalItem.foods &&
                            modalItem.foods.length > 0 &&
                            modalItem.foods.map((each, i) => {
                              return <div key={i}>{rightSide(each)}</div>;
                            })}
                        </div>
                      </div>
                      <Button
                        className="half_right_btn_comp"
                        onClick={() => recallFn(modalItem, modalItem_index)}
                      >
                        {loading && loading_index === modalItem_index ? (
                          <CircularProgress className="full_btn_comp_loading" />
                        ) : (
                          "Recall Ticket"
                        )}
                      </Button>
                    </>
                  )}
                </Box>
              </Modal>
            </ThemeProvider>
          </Container>
        </div>
        <div className="kds_layout_nope">{NotSupported()}</div>
        {AlertError(errorPosition, errorText, setValues)}
      </div>
    </div>
  );
};
