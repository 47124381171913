import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
} from "@mui/material";
import {
  ArrowLeft,
  CaretDown,
  SlidersHorizontal,
  X,
} from "@phosphor-icons/react";
import { Checkbox, Dropdown, Menu } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AlertError } from "../../Components/Common";
import { isTextEmpty } from "../../Components/Validate_Admin";
import "../../css/Market/filter.css";
import { useFilterMutation } from "../../slices/apiSlice";
import { AddToCartModal, OpenAddToCartModal } from "./AddToCartFn";
import { Item } from "./ReactSlick";
import { getS3Image } from "../../Components/s3";
const CheckboxGroup = Checkbox.Group;

export const FilterPage = () => {
  var location = useLocation();
  var navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [values, setValues] = useState({
    loadRight: true,
    singleCatNameShow: searchParams.get('name') ?? location.state?.name ?? '',
    first: true,
    loading: true,
    catId: searchParams.get('cid') ?? location.state?.catId ?? '',
    offerId: searchParams.get('oid') ?? location.state?.offerId ?? '',
    allCardsData: [],
    minPrice: "",
    maxPrice: "",
    sortValue: "Default",
    // lists
    allCats: [],
    allBrands: [],
    allOffers: [],
    allCatsOptions: [],
    // cat
    checkedList_Cat: [],
    checkAll_Cat: false,
    indeterminate_Cat: false,
    //  brands
    allBrandsOptions: [],
    checkedList_Brand: [],
    checkAll_Brand: false,
    indeterminate_Brand: false,
    // options
    allOffersOptions: [],
    checkedList_Offer: [],
    checkAll_Offer: false,
    indeterminate_Offer: false,
    // modals
    modifiersSel: [],
    modalItem: null,
    itemModalVisible: false,
  });
  var {
    loadRight,
    singleCatNameShow,
    first,
    //  loading,
    catId,
    offerId,
    allCardsData,
    minPrice,
    maxPrice,
    sortValue,
    // lists
    allCats,
    allBrands,
    allOffers,
    allCatsOptions,
    checkedList_Cat,
    checkAll_Cat,
    indeterminate_Cat,
    allBrandsOptions,
    checkedList_Brand,
    checkAll_Brand,
    indeterminate_Brand,
    allOffersOptions,
    checkedList_Offer,
    checkAll_Offer,
    indeterminate_Offer,
    modalItem,
    itemModalVisible,
    modifiersSel,
    errorPosition,
    errorText,
  } = values;
  const [filter] = useFilterMutation();

  useEffect(()=>{
    setValues(x=>({...x, first: true, singleCatNameShow: searchParams.get('name') ?? ''}))
  },[searchParams])
  useEffect(() => {
    (async () => {
      if (first) {
        var categoryIds = searchParams.get('cid') ? searchParams.get('cid').split(',') : Array(1).fill(location.state?.catId);
        var brandIds = searchParams.get('bid') ? searchParams.get('bid').split(',') : Array(1).fill(location.state?.brandId);
        var offerIds = Array(1).fill(
          searchParams.get('oid') ?? location.state?.offerId,
        );
        var send_data = {
          categoryIds: categoryIds[0] ? categoryIds : [],
          brandIds: brandIds[0] ? brandIds : [],
          offerIds: offerIds[0] ? offerIds : [],
        };
        const res = await filter(send_data).unwrap();
        let checkedList_CatData = searchParams.get('cid') ? 
          res.categories?.filter(
            (x) => searchParams.get('cid')?.split(',').includes(x._id),
          )?.map(x=>x.name)
        :
        Array(1).fill(
          res.categories?.filter(
            (x) => (x._id === location.state?.catId),
          )?.[0]?.name,
        )
        let checkedList_BrandData = searchParams.get('bid') ? 
          res.brands?.filter(
            (x) => searchParams.get('bid')?.split(',').includes(x._id),
          )?.map(x=>x.name)
        :
        Array(1).fill(
          res.brands?.filter(
            (x) => (x._id === location.state?.brandId),
          )?.[0]?.name,
        )
        let checkedList_OfferData = searchParams.get('oid') ? 
          res.offers?.filter(
            (x) => searchParams.get('oid')?.split(',').includes(x._id),
          )?.map(x=>x.name)
        :
        Array(1).fill(
          res.categories?.filter(
            (x) => (x._id === location.state?.offerId),
          )?.[0]?.name,
        )
        setValues((prev) => ({
          ...prev,
          allCardsData: res.items,
          first: false,
          loadRight: false,
          allCats: res.categories,
          allBrands: res.brands,
          allOffers: res.offers,
          allCatsOptions: res?.categories.map((x) => x.name),
          allBrandsOptions: res?.brands?.map((x) => x.name),
          checkedList_Cat: checkedList_CatData,
          checkedList_Brand: checkedList_BrandData,
          allOffersOptions: res.offers.map((x) => x.name),
          checkedList_Offer: checkedList_OfferData
        }));
      }
    })();
  }, [first, navigate, catId, offerId, location.state, filter, searchParams]);

  const Fm_left = () => (
    <div className="d-flex flex-column w-100 p-2 p-md-0">
      <div className="d-flex align-items-center justify-content-between">
        <b className="text-capitalize text_stb">FILTER</b>
        <Button
          className="clear_btn ml-auto ml-md-0"
          onClick={() => clearFilter()}
        >
          Clear filter
        </Button>
        <IconButton
          className="d-md-none"
          onClick={() => setOpenFilterDrawer(false)}
        >
          <X color="#2E2E2E" size={30} />
        </IconButton>
      </div>

      {/* cat */}
      <div className="mb-3">
        <Checkbox
          indeterminate={indeterminate_Cat}
          onChange={(e) =>
            onCheckAllChange(
              e,
              "checkedList_Cat",
              allCatsOptions,
              "checkAll_Cat",
              indeterminate_Cat,
            )
          }
          checked={checkAll_Cat}
        >
          <span className="cb_main">Categories</span>
        </Checkbox>
        <Divider />
        <CheckboxGroup
          options={allCatsOptions}
          value={checkedList_Cat}
          onChange={(list) =>
            onChange(
              list,
              "checkedList_Cat",
              allCatsOptions,
              "checkAll_Cat",
              indeterminate_Cat,
            )
          }
        />
      </div>

      {/* brands */}
      <div className="mb-3">
        <Checkbox
          indeterminate={indeterminate_Brand}
          onChange={(e) =>
            onCheckAllChange(
              e,
              "checkedList_Brand",
              allBrandsOptions,
              "checkAll_Brand",
              indeterminate_Brand,
            )
          }
          checked={checkAll_Brand}
        >
          <span className="cb_main">Brands</span>
        </Checkbox>
        <Divider />
        <CheckboxGroup
          options={allBrandsOptions}
          value={checkedList_Brand}
          onChange={(list) =>
            onChange(
              list,
              "checkedList_Brand",
              allBrandsOptions,
              "checkAll_Brand",
              indeterminate_Brand,
            )
          }
        />
      </div>
      {/* offer */}
      {allOffersOptions && allOffersOptions.length > 0 && (
        <div className="mb-3">
          <Checkbox
            indeterminate={indeterminate_Offer}
            onChange={(e) =>
              onCheckAllChange(
                e,
                "checkedList_Offer",
                allOffersOptions,
                "checkAll_Offer",
                indeterminate_Offer,
              )
            }
            checked={checkAll_Offer}
          >
            <span className="cb_main">Offers</span>
          </Checkbox>
          <Divider />
          <CheckboxGroup
            options={allOffersOptions}
            value={checkedList_Offer}
            onChange={(list) =>
              onChange(
                list,
                "checkedList_Offer",
                allOffersOptions,
                "checkAll_Offer",
                indeterminate_Offer,
              )
            }
          />
        </div>
      )}

      {/* price */}
      <span className="cb_main">Price (Rs.)</span>
      <div className="d-flex justify-content-between mt-2">
        <input
          type="text"
          className="fm_left_price"
          value={minPrice}
          name="minPrice"
          onChange={(e) => priceChanged(e.target.value, maxPrice)}
        />
        <span>_</span>
        <input
          type="text"
          className="fm_left_price"
          value={maxPrice}
          name="maxPrice"
          onChange={(e) => priceChanged(minPrice, e.target.value)}
        />
      </div>
    </div>
  );

  const sort_menu = (
    <Menu
      style={{ zIndex: "9999" }}
      items={[
        {
          label: (
            <Button
              className="acc_dropdownBtn"
              onClick={() => sortChanged("Default")}
            >
              Default
            </Button>
          ),
          key: "0",
        },
        {
          label: (
            <Button
              className="acc_dropdownBtn"
              onClick={() => sortChanged("High to Low")}
            >
              High to Low
            </Button>
          ),
          key: "1",
        },
        {
          label: (
            <Button
              className="acc_dropdownBtn"
              onClick={() => sortChanged("Low to High")}
            >
              Low to High
            </Button>
          ),
          key: "2",
        },
        {
          label: (
            <Button
              className="acc_dropdownBtn"
              onClick={() => sortChanged("Popularity")}
            >
              Popularity
            </Button>
          ),
          key: "3",
        },
        // {
        //     label: <Button className='acc_dropdownBtn'
        //         onClick={() => sortChanged('Fastest Delivery')}>
        //         Fastest Delivery
        //     </Button>,
        //     key: '4',
        // },
      ]}
    />
  );

  const sortChanged = async (val) => {
    setValues((x) => ({ ...x, sortValue: val }));
    var send_data = {
      categoryIds: checkedList_Cat,
      brandIds: checkedList_Brand,
      offerIds: checkedList_Offer,
      minPrice: minPrice === "" ? null : parseInt(minPrice),
      maxPrice: maxPrice === "" ? null : parseInt(maxPrice),
      requestSortQuery: val,
    };
    var send = await getIdsFromNameArray(send_data);
    const res = await filter(send).unwrap();
    setValues((prev) => ({ ...prev, allCardsData: res.items }));
  };

  // price in filter
  const priceChanged = async (min, max) => {
    if ((isNaN(min) && min !== "") || (isNaN(max) && max !== "")) {
      return;
    }
    setValues((x) => ({ ...x, minPrice: min, maxPrice: max }));
    var send_data = {
      categoryIds: checkedList_Cat,
      brandIds: checkedList_Brand,
      offerIds: checkedList_Offer,
      minPrice: min === "" ? null : parseInt(min),
      maxPrice: max === "" ? null : parseInt(max),
    };
    var send = await getIdsFromNameArray(send_data);
    const res = await filter(send).unwrap();
    setValues((prev) => ({ ...prev, allCardsData: res.items }));
  };
  // cat, brand, offer
  const onChange = async (list, type, options, all, indeterminate_val) => {
    if (loadRight) return;
    setValues((x) => ({
      ...x,
      loadRight: true,
      [type]: list,
      [indeterminate_val]: !!list.length && list.length < options.length,
      [all]: list.length === options.length,
    }));
    var send_data = {
      categoryIds: type === "checkedList_Cat" ? list : checkedList_Cat,
      brandIds: type === "checkedList_Brand" ? list : checkedList_Brand,
      offerIds: type === "checkedList_Offer" ? list : checkedList_Offer,
      minPrice: minPrice === "" ? null : parseInt(minPrice),
      maxPrice: maxPrice === "" ? null : parseInt(maxPrice),
    };
    var send = await getIdsFromNameArray(send_data);
    if (send.categoryIds.length === 1) {
      var toFind = send.categoryIds[0];
      var found = allCats.find((x) => x._id === toFind);
      found &&
        setValues((prev) => ({ ...prev, singleCatNameShow: found.name }));
    } else {
      setValues((prev) => ({ ...prev, singleCatNameShow: "" }));
    }

    const res = await filter(send_data).unwrap();
    setValues((prev) => ({
      ...prev,
      allCardsData: res.items,
      loadRight: false,
    }));
    changeUrl(send_data)
  };
  const getIdsFromNameArray = (send_data) => {
    var catArr = [];
    var brandArr = [];
    var offerArr = [];
    send_data.categoryIds.forEach((element) => {
      var found = allCats.find((x) => x.name === element);
      if (found) {
        catArr.push(found._id);
      }
    });
    send_data.categoryIds = catArr;
    send_data.brandIds.forEach((element) => {
      var found = allBrands?.find((x) => x.name === element);
      if (found) {
        brandArr.push(found._id);
      }
    });
    send_data.brandIds = brandArr;
    send_data.offerIds.forEach((element) => {
      var found = allOffers.find((x) => x.name === element);
      if (found) {
        offerArr.push(found._id);
      }
    });
    send_data.offerIds = offerArr;
    return send_data;
  };

  // all categories, brands, offers selected
  const onCheckAllChange = async (e, type, options, all, indeterminate_val) => {
    if (loadRight) return;
    setValues((x) => ({ ...x, loadRight: true }));
    if (e.target.checked) {
      //all checked
      setValues((x) => ({
        ...x,
        [type]: e.target.checked ? options : [],
        [indeterminate_val]: false,
        [all]: e.target.checked,
        singleCatNameShow: "",
      }));
      var send_data = {
        categoryIds: type === "checkedList_Cat" ? options : checkedList_Cat,
        brandIds: type === "checkedList_Brand" ? options : checkedList_Brand,
        offerIds: type === "checkedList_Offer" ? options : checkedList_Offer,
        minPrice: minPrice === "" ? null : parseInt(minPrice),
        maxPrice: maxPrice === "" ? null : parseInt(maxPrice),
      };
      var send = await getIdsFromNameArray(send_data);
      const res = await filter(send).unwrap();
      setValues((prev) => ({
        ...prev,
        allCardsData: res.items,
        loadRight: false,
      }));

      return;
    } else {
      setValues((x) => ({
        ...x,
        [type]: e.target.checked ? options : [],
        [indeterminate_val]: false,
        [all]: e.target.checked,
      }));
      var send_datas = {
        categoryIds: type === "checkedList_Cat" ? [] : checkedList_Cat,
        brandIds: type === "checkedList_Brand" ? [] : checkedList_Brand,
        offerIds: type === "checkedList_Offer" ? [] : checkedList_Offer,
        minPrice: minPrice === "" ? null : parseInt(minPrice),
        maxPrice: maxPrice === "" ? null : parseInt(maxPrice),
      };
      var sends = await getIdsFromNameArray(send_datas);
      const res = await filter(sends).unwrap();
      setValues((prev) => ({
        ...prev,
        allCardsData: res.items,
        loadRight: false,
      }));
    }
  };

  const changeUrl = (send_data) => {
    let send_name = ''
    let query='?'
    if(send_data.categoryIds?.length===1) { 
      send_name = allCats?.find(x=>x._id===send_data.categoryIds[0])?.name ?? ''
      query = query.concat(`name=${send_name}&`)
    }
    if(send_data.categoryIds?.length > 0)
      query = query.concat(`cid=${send_data.categoryIds.join(',')}&`)
    if(send_data.brandIds?.length > 0)
      query = query.concat(`bid=${send_data.brandIds.join(',')}`)
    window.history.pushState({}, '', `/filter${query}`);
  }

  const clearFilter = async () => {
    if (loadRight) return;
    setValues((x) => ({ ...x, loadRight: true }));
    var send_data = {
      categoryIds: [],
      brandIds: [],
      offerIds: [],
      minPrice: null,
      maxPrice: null,
      requestSortQuery: sortValue,
    };
    const res = await filter(send_data).unwrap();
    setValues((x) => ({
      ...x,
      allCardsData: res.items,
      checkedList_Cat: [],
      checkedList_Brand: [],
      checkedList_Offer: [],
      indeterminate_Cat: false,
      indeterminate_Offer: false,
      indeterminate_Brand: false,
      checkAll_Cat: false,
      checkAll_Offer: false,
      checkAll_Brand: false,
      minPrice: "",
      maxPrice: "",
      loadRight: false,
    }));
    changeUrl(send_data)
  };

  const FilterDrawer = () => (
    <Drawer
      anchor={"bottom"}
      open={openFilterDrawer}
      onClose={() => setOpenFilterDrawer(false)}
    >
      <Box className="d-flex justify-content-between p-3 pb-0">{Fm_left()}</Box>
    </Drawer>
  );

  return (
    <>
      {
        <div className="filter_main">
          <div className="fm_left">{Fm_left()}</div>

          <div className="fm_right">
            <div className="d-flex fm_right_sort_div ml-auto">
              <span>Sort by: </span>
              <Dropdown overlay={sort_menu} trigger={["click"]}>
                <div onClick={(e) => e.preventDefault()}>
                  <Button className="fm_right_sort_btn">
                    <span>{sortValue}</span>
                    <CaretDown className="ml-auto" />
                  </Button>
                </div>
              </Dropdown>
              <IconButton
                className="ml_mobile_last_filter"
                onClick={() => setOpenFilterDrawer(!openFilterDrawer)}
              >
                <SlidersHorizontal size={25} color="#2E2E2E" />
              </IconButton>
            </div>
            {!isTextEmpty(singleCatNameShow) && (
              <Button
                className="align_btn_items_l c3 s5 mb-2 w-fit-content d-md-none"
                onClick={() => navigate("/")}
              >
                <ArrowLeft />
                <span className="ml-2">{singleCatNameShow}</span>
              </Button>
            )}
            <Grid
              container
              mb={"30px"}
              rowSpacing={3}
              columnSpacing={{ xs: 0, sm: 2, md: 3 }}
            >
              {loadRight ? (
                <div className="ml-3 d-flex justify-content-center w-100 mt-3">
                  <CircularProgress sx={{ color: "#8943EF" }} />
                </div>
              ) : (
                <>
                  {allCardsData &&
                    allCardsData.map((card, ind) => (
                      <Grid key={ind} item xs={6} sm={6} md={6} lg={4}>
                        <Item
                          style={{ height: "100%" }}
                          onClick={(e) =>
                            OpenAddToCartModal(e, card, setValues)
                          }
                        >
                          <div className="sc_combo_div_all">
                            <div
                              className="hp_each_popular_image_combo_filter d-flex flex-column"
                              style={{
                                backgroundImage: `url(${card.image})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                              }}
                            >
                              {card.hasDiscount && (
                                <div className="mayAlsoLike_dis_filter w2 s2">
                                  {card.offer &&
                                    card.offer.discountType === "value" &&
                                    "Rs."}
                                  {card.offer.amount}
                                  {card.offer &&
                                    card.offer.discountType === "percent" &&
                                    "%"}
                                  <span className="ml-1" /> off
                                  {/* <span className='d-none d-sm-block'>- {card.offer.name}</span> */}
                                </div>
                              )}
                              {/* {card.brand && card.brand.name &&
                                                                <div className='eachitem_brand s1 d-flex'>
                                                                    <Avatar src={card.brand.logo} style={{ width: '30px', height: '30px' }} />
                                                                    <span className='ml-1 eachitem_brandname'>{card.brand.name}</span>
                                                                </div>} */}
                            </div>
                            <span className="c3 w3 s2">{card.name}</span>
                            {/* <p className="c7 s-12">{card.estimatedTime} min approx.</p> */}
                            <div className="sc_cm_item_div">
                              {card.items &&
                                card.items.map((eachItem, ind) => (
                                  <span key={ind} className="sc_cm_item">
                                    {eachItem.name}
                                    {eachItem.quantity > 1 &&
                                      ` X${eachItem.quantity}`}
                                  </span>
                                ))}
                            </div>
                            <div className="d-flex mt-2 mt-sm-0 align-items-center">
                              {card.hasDiscount && (
                                <span className="c7 w3 s2 mr-2 strikethrough">
                                  Rs. {card.price}
                                </span>
                              )}
                              <span className="s5 c3 w3">
                                Rs. {card.discountedPrice}
                              </span>
                            </div>
                          </div>
                        </Item>
                      </Grid>
                    ))}
                    {allCardsData?.length===0 ? 
                    <div className="ml-3 w-100 mt-5 d-flex flex-column gap-2 align-items-center justify-content-center">
                      <img
                        src={getS3Image("empty_cart.png")}
                        className="empty_cart_img"
                        alt=""
                      />
                      <span>
                        No items yet!
                      </span>
                    </div> : ''}
                </>
              )}
            </Grid>
          </div>

          {AddToCartModal(itemModalVisible, modalItem, modifiersSel, setValues)}

          {AlertError(errorPosition, errorText, setValues)}

          {FilterDrawer()}
        </div>
      }
    </>
  );
};
