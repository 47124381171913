import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Modal,
} from "@mui/material";
import Box from "@mui/material/Box";
import { X } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DELETEAPIAdmin,
  GetAPIAdmin,
  POSTAPI_ADMIN,
  POSTAPI_formAdmin,
  PUTAPIAdmin,
  PUT_API_formAdmin,
  isAuthenticatedAdmin,
} from "../../Auth/Index";
import {
  AlertError,
  CircularProgressCompSec,
  EditDeleteDeliveryPartner,
  EditDeleteQR,
  EditDeleteTax,
  HalfRightBtnComp,
  InputComp,
  InputComp_Drop,
  InputComp_Eye,
  InputComp_Num,
  LabelComp,
  SelectImage_Small,
  modalStyles,
} from "../../Components/Common";
import { isTextEmpty } from "../../Components/Validate_Admin";
import "../../css/Admin/settings.css";
import dayjs from "dayjs";
import { TimePicker } from "antd";

export const AdminSettings = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const employeeId = isAuthenticatedAdmin()
    ? isAuthenticatedAdmin().employeeId
    : null;
  const [values, setValues] = useState({
    old_password: "",
    new_password: "",
    loyaltyPoints: "",
    taxModalVisible: false,
    taxes: [],
    taxId: null,
    taxName: "",
    taxAmount: "",
    taxType: "percent",
    edAnchorTax: null,
    edClickedTax: null,
    busy: "",
    QRs: [],
    // delivery partners
    deliveryPartners: [],
    deliveryPartnerModal: false,
    deliveryPartnerName: "",
    deliveryPartnerId: null,
    deliveryPartnerLoading: false,
    // business hour
    businessHourOpening: dayjs(new Date(), "HH:mm"),
    businessHourClosing: dayjs(new Date(), "HH:mm"),
    errorPosition: "",
    errorText: "",
    first: true,
    passwordLoading: false,
    loyaltyLoading: false,
    taxLoading: false,
    kitchenStatusLoading: false,
    qrLoading: false,
    QRModal: false,
    qrName: "",
    qrImage: null,
    QRId: "",
  });
  const {
    old_password,
    new_password,
    loyaltyPoints,
    taxModalVisible,
    taxes,
    taxId,
    taxName,
    taxAmount,
    taxType,
    edAnchorTax,
    edClickedTax,
    busy,
    QRs,
    deliveryPartners,
    deliveryPartnerModal,
    deliveryPartnerName,
    deliveryPartnerId,
    deliveryPartnerLoading,
    businessHourOpening,
    businessHourClosing,
    errorPosition,
    errorText,
    first,
    passwordLoading,
    loyaltyLoading,
    taxLoading,
    qrLoading,
    //  kitchenStatusLoading
    QRModal,
    qrName,
    qrImage,
    QRId,
  } = values;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/settings/").then((res) => {
        if (res.success) {
          const [openHour, openMinute] = res.businessHours?.openingTime
            ?.split(":")
            .map(Number);
          const [closeHour, closeMinute] = res.businessHours?.closingTime
            ?.split(":")
            .map(Number);
          setValues((prev) => ({
            ...prev,
            loyaltyPoints: res.loyaltyPoints,
            taxes: res.taxes,
            QRs: res.codMethods,
            deliveryPartners: res.deliveryPartners,
            businessHourOpening: dayjs(
              new Date().setHours(openHour, openMinute, 0, 0),
            ),
            businessHourClosing: dayjs(
              new Date().setHours(closeHour, closeMinute, 0, 0),
            ),
            busy: res.busyDeliveryTime,
            first: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            first: false,
            errorPosition: "apiError",
            errorText: res.error,
          }));
      });
    }
  }, [first, employeeId]);

  const changePassword = () => {
    if (isTextEmpty(old_password)) {
      setValues((x) => ({
        ...x,
        errorPosition: "old_password",
        errorText: "Invalid value",
      }));
    } else if (isTextEmpty(new_password)) {
      setValues((x) => ({
        ...x,
        errorPosition: "new_password",
        errorText: "Invalid value",
      }));
    } else {
      setValues((x) => ({
        ...x,
        errorPosition: "",
        errorText: "",
        passwordLoading: true,
      }));
      var send = {
        employeeId: employeeId,
        oldPassword: old_password,
        newPassword: new_password,
      };
      POSTAPI_ADMIN("/employee/changePassword", send).then((resp) => {
        if (resp.success)
          setValues((x) => ({
            ...x,
            errorPosition: "success",
            errorText: resp.message,
            passwordLoading: false,
            old_password: "",
            new_password: "",
          }));
        else if (resp.error)
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: resp.error,
            passwordLoading: false,
          }));
      });
    }
  };

  const passwordBody = () => (
    <>
      {LabelComp("Old password", "old_password", errorPosition, errorText)}
      {InputComp_Eye(old_password, "old_password", setValues)}

      {LabelComp("New password", "new_password", errorPosition, errorText)}
      {InputComp_Eye(new_password, "new_password", setValues)}

      <div className="ad_btn_div">
        <Button
          className="ad_forgot_btn"
          onClick={() => navigate("/admin/reset")}
        >
          Forgot Password?
        </Button>
        <Button className="ad_change_btn" onClick={() => changePassword()}>
          {passwordLoading ? <CircularProgressCompSec /> : "Change Password"}
        </Button>
      </div>
    </>
  );

  const saveLoyalty = () => {
    if (loyaltyLoading) return;
    if (isTextEmpty(loyaltyPoints)) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Invalid value",
      }));
    } else {
      setValues((prev) => ({ ...prev, loyaltyLoading: true }));
      var sendData = { loyaltyPoints };
      POSTAPI_ADMIN("/settings/loyaltyPoints", sendData).then((res) => {
        if (res.success) {
          setValues((prev) => ({ ...prev, loyaltyLoading: false }));
        }
        if (res.error) {
          setValues((val) => ({
            ...val,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
        }
      });
    }
  };

  const loyaltyBody = () => (
    <>
      <div className="loyalty_input_div">
        <span className="loyalty_title">1 Loyalty Point</span>
        <span className="ml-auto mr-3">Rs.</span>
        {InputComp_Num(
          loyaltyPoints,
          "loyaltyPoints",
          setValues,
          "loyalty_input",
        )}
      </div>

      {HalfRightBtnComp("Save", saveLoyalty, loyaltyLoading)}
    </>
  );

  const setTaxFn = () => {
    if (taxLoading) return;
    if (isTextEmpty(taxName)) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Invalid tax name",
      }));
    } else if (isTextEmpty(taxAmount) || isNaN(taxAmount)) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Invalid tax amount",
      }));
    } else {
      setValues((prev) => ({ ...prev, taxLoading: true }));
      var sendData = { name: taxName, type: taxType, amount: taxAmount };
      POSTAPI_ADMIN("/settings/createTax", sendData).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            taxLoading: false,
            taxId: null,
            name: "",
            type: "percent",
            amount: "",
            taxModalVisible: false,
            taxes: res.taxes,
            errorPosition: "success",
            errorText: "Tax added",
          }));
        }
        if (res.error) {
          setValues((val) => ({
            ...val,
            errorPosition: "apiErr",
            errorText: res.error,
            taxLoading: false,
          }));
        }
      });
    }
  };

  const editTaxFn = () => {
    if (taxLoading) return;
    if (isTextEmpty(taxId)) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Invalid tax",
      }));
    } else if (isTextEmpty(taxName)) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Invalid tax name",
      }));
    } else if (isTextEmpty(taxAmount) || isNaN(taxAmount)) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Invalid tax amount",
      }));
    } else {
      setValues((prev) => ({ ...prev, taxLoading: true }));
      var sendData = {
        taxId: taxId,
        name: taxName,
        type: taxType,
        amount: taxAmount,
      };
      POSTAPI_ADMIN("/settings/editTax", sendData).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            taxLoading: false,
            taxId: null,
            name: "",
            type: "percent",
            amount: "",
            taxModalVisible: false,
            taxes: res.taxes,
            errorPosition: "success",
            errorText: "Tax updated",
          }));
        }
        if (res.error) {
          setValues((val) => ({
            ...val,
            errorPosition: "apiErr",
            errorText: res.error,
            taxLoading: false,
          }));
        }
      });
    }
  };

  const removeTax = (id) => {
    var sendData = { taxId: id };
    POSTAPI_ADMIN("/settings/removeTax", sendData).then((res) => {
      if (res.success) {
        setValues((prev) => ({
          ...prev,
          taxes: res.taxes,
        }));
      }
      if (res.error) {
        setValues((val) => ({
          ...val,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
      }
    });
  };

  const TaxesBody = () => (
    <>
      {taxes.map((eachTax, ind) => (
        <Button
          key={ind}
          className="each_tax"
          onClick={() => {
            setValues((val) => ({
              ...val,
              taxId: eachTax._id,
              taxName: eachTax.name,
              taxType: eachTax.type,
              taxAmount: eachTax.amount,
              taxModalVisible: true,
            }));
          }}
        >
          <span>{eachTax.name}</span>
          <span className="each_tax_value ml-auto">
            {eachTax.symbol === "Rs." && eachTax.symbol} {eachTax.amount}{" "}
            {eachTax.symbol === "%" && eachTax.symbol}
          </span>
          <EditDeleteTax
            onClick={(e) => e.stopPropagation()}
            id={eachTax._id}
            DeleteFn={removeTax}
            edAnchorTax={edAnchorTax}
            edClickedTax={edClickedTax}
            taxes={taxes}
            setValues={setValues}
          />
        </Button>
      ))}
      <Button
        className="add_tax_btn"
        onClick={() =>
          setValues((val) => ({
            ...val,
            taxId: null,
            taxName: "",
            taxAmount: "",
            taxType: "percent",
            taxModalVisible: true,
          }))
        }
      >
        + Add Tax
      </Button>
      <Modal
        open={taxModalVisible}
        onClose={() => setValues((val) => ({ ...val, taxModalVisible: false }))}
      >
        <Box style={modalStyles}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="w3 s3">{taxId ? "Edit Tax" : "Add Tax"}</span>
            <IconButton
              onClick={() =>
                setValues((val) => ({ ...val, taxModalVisible: false }))
              }
            >
              <X color="#000" />
            </IconButton>
          </div>
          <div>
            {LabelComp("Tax Name", "taxName", errorPosition, errorText)}
            {InputComp(taxName, "taxName", setValues, "add_category_input")}

            {LabelComp("Tax Amount", "taxAmount", errorPosition, errorText)}
            {InputComp_Drop(
              taxAmount,
              "taxAmount",
              setValues,
              taxType,
              "taxType",
            )}

            {taxId
              ? HalfRightBtnComp("Update tax", editTaxFn, taxLoading)
              : HalfRightBtnComp("Add tax", setTaxFn, taxLoading)}
          </div>
        </Box>
      </Modal>
    </>
  );

  const saveKitchenStatus = () => {
    if (loyaltyLoading) return;
    if (isTextEmpty(busy) || isNaN(busy)) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Invalid value",
      }));
      return;
    }
    setValues((prev) => ({ ...prev, kitchenStatusLoading: true }));
    var sendData = { busyDeliveryTime: busy };
    POSTAPI_ADMIN("/settings/busyDeliveryTime", sendData).then((res) => {
      if (res.success) {
        setValues((prev) => ({
          ...prev,
          kitchenStatusLoading: false,
          errorPosition: "success",
          errorText: "Updated busy delivery time",
        }));
      }
      if (res.error) {
        setValues((val) => ({
          ...val,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
      }
    });
  };

  const kitchenStatusBody = () => (
    <>
      <span className="w3">Busy Status</span>
      <div className="kitchen_status_input_div">
        <span>Increase delivery time by</span>
        <span>
          {InputComp_Num(busy, "busy", setValues, "kitchen_status_input")}mins
        </span>
      </div>

      <div className="mt-auto" />
      {HalfRightBtnComp("Save", saveKitchenStatus, loyaltyLoading)}
    </>
  );

  const addQrFn = () => {
    if (isTextEmpty(qrName))
      setValues((x) => ({
        ...x,
        errorPosition: "qrName",
        errorText: "Invalid value",
      }));
    else if (isTextEmpty(qrImage))
      setValues((x) => ({
        ...x,
        errorPosition: "qrImage",
        errorText: "Invalid image",
      }));
    else {
      setValues((prev) => ({ ...prev, qrLoading: true }));
      var formdata = new FormData();
      formdata.append("employeeId", employeeId);
      formdata.append("name", qrName);
      formdata.append("image", qrImage);
      if (isTextEmpty(QRId)) {
        //add
        POSTAPI_formAdmin("/settings/createCOD", formdata).then((res) => {
          if (res.success) {
            setValues((prev) => ({
              ...prev,
              qrLoading: false,
              qrName: "",
              qrImage: null,
              QRModal: false,
              errorPosition: "success",
              errorText: "QR added",
              QRs: res.codMethods,
            }));
          }
          if (res.error) {
            setValues((val) => ({
              ...val,
              errorPosition: "apiErr",
              errorText: res.error,
              qrLoading: false,
            }));
          }
        });
      } else {
        //edit
        formdata.append("codId", QRId);
        POSTAPI_formAdmin("/settings/editCOD", formdata).then((res) => {
          if (res.success) {
            setValues((prev) => ({
              ...prev,
              qrLoading: false,
              qrName: "",
              qrImage: null,
              QRModal: false,
              errorPosition: "success",
              errorText: "QR updated",
              QRs: res.codMethods,
            }));
          }
          if (res.error) {
            setValues((val) => ({
              ...val,
              errorPosition: "apiErr",
              errorText: res.error,
              qrLoading: false,
            }));
          }
        });
      }
    }
  };

  const removeQR = () => {
    const send_data = {
      employeeId,
      codId: edClickedTax,
    };
    POSTAPI_ADMIN("/settings/removeCOD", send_data).then((res) => {
      if (res.success) {
        setValues((prev) => ({
          ...prev,
          qrLoading: false,
          edAnchorTax: null,
          edClickedTax: null,
          errorPosition: "success",
          errorText: "QR deleted",
          QRs: res.codMethods,
        }));
      }
      if (res.error) {
        setValues((val) => ({
          ...val,
          errorPosition: "apiErr",
          errorText: res.error,
          qrLoading: false,
        }));
      }
    });
  };

  const QRCodeBody = () => (
    <>
      {QRs &&
        QRs.map((eachTax, ind) => (
          <div
            key={ind}
            className="each_tax d-flex align-items-center"
            onClick={() => {
              setValues((val) => ({
                ...val,
                QRId: eachTax.codId,
                qrName: eachTax.name,
                qrImage: eachTax.image,
                QRModal: true,
              }));
            }}
          >
            <span>{eachTax.name}</span>
            <span className="each_tax_value ml-auto">
              <Avatar
                src={eachTax.image}
                variant="square"
                sx={{ width: "34px", height: "34px" }}
              />
            </span>
            <EditDeleteQR
              onClick={(e) => e.stopPropagation()}
              id={eachTax.codId}
              DeleteFn={removeQR}
              edAnchorTax={edAnchorTax}
              edClickedTax={edClickedTax}
              QRs={QRs}
              setValues={setValues}
            />
          </div>
        ))}
      <Button
        className="secondary_btn w-50 ml-auto mr-auto"
        onClick={() => setValues((x) => ({ ...x, QRModal: true }))}
      >
        + Add QR
      </Button>
      <Modal
        open={QRModal}
        onClose={() =>
          setValues((x) => ({
            ...x,
            QRModal: false,
            qrName: "",
            qrImage: null,
          }))
        }
      >
        <Box style={modalStyles}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="w2 s3">
              {isTextEmpty(QRId) ? "Add QR" : "Edit QR"}
            </span>
            <IconButton
              onClick={() =>
                setValues((x) => ({
                  ...x,
                  QRModal: false,
                  qrName: "",
                  qrImage: null,
                }))
              }
            >
              <X size={25} color="#2E2E2E" />
            </IconButton>
          </div>
          {LabelComp("QR Name", "qrName", errorPosition, errorText)}
          {InputComp(qrName, "qrName", setValues, "")}
          {LabelComp("QR Image", "qrImage", errorPosition, errorText)}
          {SelectImage_Small(qrImage, "qrImage", setValues)}
          <div className="d-flex mt-3 justify-content-end">
            <Button
              className="borderless_btn"
              onClick={() => setValues((x) => ({ ...x, QRModal: false }))}
            >
              Cancel
            </Button>
            <Button className="primary_btn ml-2" onClick={() => addQrFn()}>
              {qrLoading ? (
                <CircularProgress className="full_btn_comp_loading" />
              ) : (
                <>{isTextEmpty(QRId) ? "Add QR" : "Update QR"}</>
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );

  const addDeliveryPartnerFn = () => {
    if (isTextEmpty(deliveryPartnerName))
      setValues((x) => ({
        ...x,
        errorPosition: "deliveryPartnerName",
        errorText: "Invalid value",
      }));
    else {
      setValues((prev) => ({ ...prev, deliveryPartnerLoading: true }));
      var formdata = new FormData();
      formdata.append("name", deliveryPartnerName);
      if (isTextEmpty(deliveryPartnerId)) {
        //add
        POSTAPI_formAdmin("/settings/createDeliveryPartner", formdata).then(
          (res) => {
            if (res.success) {
              setValues((prev) => ({
                ...prev,
                deliveryPartnerLoading: false,
                deliveryPartnerName: "",
                deliveryPartnerModal: false,
                errorPosition: "success",
                errorText: "Delivery Partner added",
                deliveryPartners: res.deliveryPartners,
              }));
            }
            if (res.error) {
              setValues((val) => ({
                ...val,
                errorPosition: "apiErr",
                errorText: res.error,
                deliveryPartnerLoading: false,
              }));
            }
          },
        );
      } else {
        //edit
        formdata.append("deliveryPartnerId", deliveryPartnerId);
        PUT_API_formAdmin("/settings/editDeliveryPartner", formdata).then(
          (res) => {
            if (res.success) {
              setValues((prev) => ({
                ...prev,
                ...prev,
                deliveryPartnerLoading: false,
                deliveryPartnerName: "",
                deliveryPartnerModal: false,
                errorPosition: "success",
                errorText: "Delivery Partner updated",
                deliveryPartners: res.deliveryPartners,
              }));
            }
            if (res.error) {
              setValues((val) => ({
                ...val,
                errorPosition: "apiErr",
                errorText: res.error,
                deliveryPartnerLoading: false,
              }));
            }
          },
        );
      }
    }
  };

  const removeDeliveryPartner = () => {
    const send_data = {
      deliveryPartnerId: edClickedTax,
    };
    DELETEAPIAdmin("/settings/removeDeliveryPartner", send_data).then((res) => {
      if (res.success) {
        setValues((prev) => ({
          ...prev,
          deliveryPartnerLoading: false,
          edAnchorTax: null,
          edClickedTax: null,
          errorPosition: "success",
          errorText: "Delivery Partner deleted",
          deliveryPartners: res.message,
        }));
      }
      if (res.error) {
        setValues((val) => ({
          ...val,
          errorPosition: "apiErr",
          errorText: res.error,
          deliveryPartnerLoading: false,
        }));
      }
    });
  };

  const DeliveryPartnerBody = () => (
    <>
      {deliveryPartners?.map((each, ind) => (
        <div
          key={"dp" + ind}
          className="each_tax d-flex align-items-center"
          onClick={() => {
            setValues((val) => ({
              ...val,
              deliveryPartnerId: each._id,
              deliveryPartnerName: each.name,
              deliveryPartnerModal: true,
            }));
          }}
        >
          <span className="mr-auto">{each.name}</span>
          <EditDeleteDeliveryPartner
            onClick={(e) => e.stopPropagation()}
            id={each._id}
            DeleteFn={removeDeliveryPartner}
            edAnchorTax={edAnchorTax}
            edClickedTax={edClickedTax}
            deliveryPartners={deliveryPartners}
            setValues={setValues}
          />
        </div>
      ))}
      <Button
        className="secondary_btn w-auto ml-auto mr-auto"
        onClick={() => setValues((x) => ({ ...x, deliveryPartnerModal: true }))}
      >
        + Delivery Partner
      </Button>
      <Modal
        open={deliveryPartnerModal}
        onClose={() =>
          setValues((x) => ({
            ...x,
            deliveryPartnerModal: false,
            deliveryPartnerName: "",
          }))
        }
      >
        <Box style={{ ...modalStyles, width: "462px", padding: "24px" }}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="w2 s3">
              {isTextEmpty(deliveryPartnerId)
                ? "Add Delivery Partner"
                : "Edit Delivery Partner"}
            </span>
            <IconButton
              onClick={() =>
                setValues((x) => ({
                  ...x,
                  deliveryPartnerModal: false,
                  deliveryPartnerName: "",
                }))
              }
            >
              <X size={25} color="#2E2E2E" />
            </IconButton>
          </div>
          {LabelComp(
            "Delivery Partner",
            "deliveryPartnerName",
            errorPosition,
            errorText,
          )}
          {InputComp(deliveryPartnerName, "deliveryPartnerName", setValues, "")}
          <div className="d-flex mt-3 justify-content-end">
            <Button
              className="borderless_btn"
              onClick={() =>
                setValues((x) => ({ ...x, deliveryPartnerModal: false }))
              }
            >
              Cancel
            </Button>
            <Button
              className="primary_btn ml-2"
              style={{ width: "250px" }}
              onClick={() => addDeliveryPartnerFn()}
            >
              {deliveryPartnerLoading ? (
                <CircularProgress className="full_btn_comp_loading" />
              ) : (
                <>
                  {isTextEmpty(deliveryPartnerId)
                    ? "Add Partner"
                    : "Update Partner"}
                </>
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );

  const handleBusinessHourChange = (open, close) => {
    const openTime = open.$H + ":" + open.$m;
    const closeTime = close.$H + ":" + close.$m;
    const send_data = {
      openingTime: openTime,
      closingTime: closeTime,
    };
    PUTAPIAdmin("/settings/editBusinessHour", send_data).then((res) => {
      if (res.success) {
        setValues((prev) => ({
          ...prev,
          errorPosition: "success",
          errorText: "Business Hours Updated",
        }));
      }
      if (res.error) {
        setValues((val) => ({
          ...val,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
      }
    });
  };

  const BusinessHoursBody = () => (
    <div
      className="d-flex flex-column"
      style={{
        gap: "24px",
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        {LabelComp(
          "Opening hours",
          "deliveryPartnerName",
          errorPosition,
          errorText,
        )}
        <TimePicker
          onChange={(time) => {
            handleBusinessHourChange(time, businessHourClosing);
            setValues((x) => ({ ...x, businessHourOpening: time }));
          }}
          value={businessHourOpening}
          style={{
            height: "45px",
            borderRadius: "12px",
            border: "1px solid #818090",
          }}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        {LabelComp(
          "Closing hours",
          "businessHourClosing",
          errorPosition,
          errorText,
        )}
        <TimePicker
          onChange={(time) => {
            handleBusinessHourChange(
              businessHourOpening,
              dayjs(time?.$d, "HH:mm"),
            );
            setValues((x) => ({
              ...x,
              businessHourClosing: dayjs(time?.$d, "HH:mm"),
            }));
          }}
          value={businessHourClosing}
          style={{
            height: "45px",
            borderRadius: "12px",
            border: "1px solid #818090",
          }}
        />
      </div>
    </div>
  );

  var tabs = [
    {
      title: "Password",
      body: passwordBody(),
    },
    {
      title: "Loyalty Points",
      body: loyaltyBody(),
    },
    {
      title: "Taxes",
      body: TaxesBody(),
    },
    {
      title: "KDS",
      body: kitchenStatusBody(),
    },
    {
      title: "QR Code",
      body: QRCodeBody(),
    },
    {
      title: "Delivery Partner",
      body: DeliveryPartnerBody(),
    },
    {
      title: "Business Hour",
      body: BusinessHoursBody(),
    },
  ];

  return (
    <div className="category_body">
      <div className="category_top">
        <span className="category_top_title">Settings</span>
      </div>

      <Box className="admin_settings_body">
        <Box className="admin_settings_btn_div">
          {tabs.map((each, index) => (
            <div className="admin_settings_btn_eachDiv" key={index}>
              <Button
                key={index}
                className={
                  index === step
                    ? "admin_settings_btn_sel"
                    : "admin_settings_btn"
                }
                onClick={() => setStep(index)}
              >
                {each.title}
              </Button>
            </div>
          ))}
        </Box>
        {tabs[step].body}
      </Box>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};
