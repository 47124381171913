import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
} from "@mui/material";
import {
  CaretDown,
  FacebookLogo,
  InstagramLogo,
  Phone,
  TwitterLogo,
} from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom/dist";
import { openInNewTab } from "../../Components/Common";
import { getS3Image } from "../../Components/s3";
import "../../css/Market/extraPage.css";
import "../../css/Market/footer.css";
import { Footer } from "./Footer";

export const WhoWeAre = () => {
  const navigate = useNavigate();
  return (
    <Grid container>
      <div id="scroll_here_id" />
      <div
        className="d-flex justify-content-between"
        style={{ width: "100vw", height: "100vh" }}
      >
        <Grid
          item
          className="d-flex"
          width={{
            xs: "",
            md: "calc(100vw - 570px)",
          }}
        >
          <Container
            container
            className="h-100 d-flex flex-column justify-content-center"
            style={{ maxWidth: "636px", gap: "40px" }}
          >
            <img
              src={getS3Image("Logo.svg")}
              alt="logo"
              className="cursor_pointer"
              style={{ width: "247px" }}
              onClick={(e) => navigate("/")}
            />
            <div className="d-flex flex-column" style={{ gap: "32px" }}>
              <span className="w3 c3 s3">
                Delivering happiness with good food.
              </span>
              <span className="s5 c3">
                Nova Delivers has a purpose – to deliver you quality food in an
                affordable and convenient manner. We provide you the easiest way
                to order food and have it delivered to your door. We bring you a
                wide range of food choices with the best quality ingredients to
                make sure you enjoy your meal.
              </span>
            </div>
          </Container>
        </Grid>
        <Grid item className="whowearebg" position={"relative"}>
          <Box
            sx={{
              position: "absolute",
              background: "rgba(57, 57, 57, 0.5)",
              left: 0,
              bottom: 0,
              width: "100vw",
              height: "100%",
              padding: "24px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              style={{ flexDirection: "column", gap: "40px" }}
              display={{ md: "none" }}
            >
              <img
                src={getS3Image("Logo.svg")}
                alt="logo"
                className="cursor_pointer"
                style={{ width: "140px" }}
                onClick={(e) => navigate("/")}
              />
              <div className="d-flex flex-column" style={{ gap: "32px" }}>
                <span className="w3 c4 s3">
                  Delivering happiness with good food.
                </span>
                <span className="s5 c4">
                  Nova Delivers has a purpose – to deliver you quality food in
                  an affordable and convenient manner. We provide you the
                  easiest way to order food and have it delivered to your door.
                  We bring you a wide range of food choices with the best
                  quality ingredients to make sure you enjoy your meal.
                </span>
              </div>
            </Box>
          </Box>
        </Grid>
      </div>
      <div
        className="d-flex align-items-center pt-5 pb-5 p-md-0 whoweare-hauto"
        style={{
          backgroundColor: "#fff",
          width: "100%",
        }}
      >
        <Container container>
          <p className="underlineWho s-40 w3 c3">Our Values</p>
          <Grid container spacing={3} marginTop="50px">
            <Grid
              item
              xs={12}
              md={4}
              className="d-flex flex-column align-items-center mb-3 mb-md-0"
              gap={"50px"}
            >
              <img
                src={getS3Image("values_1.png")}
                alt=""
                className="ourValuesImg"
              />
              <Box gap={{ xs: "", md: "24px" }}>
                <Grid item className="w3 s3 mb-4">
                  Food Safety
                </Grid>
                <p>
                  At Nova Foods, we take food safety very seriously. We train
                  all our staff on safety standards, delivery and storage
                  processes, food prep, food contamination, and food-borne
                  illnesses, all of which are vital for food handlers to
                  understand. We also use a vision-enabled camera in our
                  kitchens to monitor all our kitchen activities.
                </p>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className="d-flex flex-column align-items-center mb-3 mb-md-0"
              gap={"50px"}
            >
              <img
                src={getS3Image("values_2.png")}
                alt=""
                className="ourValuesImg"
              />
              <Box gap={{ xs: "", md: "24px" }}>
                <Grid item className="w3 s3 mb-4">
                  Customer First
                </Grid>
                <p>
                  We always put our customers at the centre of what we do.
                  Furthermore, we are always seeking ways to consistently and
                  proactively deliver a positive customer experience by
                  designing and delivering with the customer in mind.
                </p>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className="d-flex flex-column align-items-center"
              gap={"50px"}
            >
              <img
                src={getS3Image("values_3.png")}
                alt=""
                className="ourValuesImg"
              />
              <Box gap={{ xs: "", md: "24px" }}>
                <Grid item className="w3 s3 mb-4">
                  Affordability
                </Grid>
                <p>
                  Nova Foods wants to feed every hungry stomach in Nepal. So we
                  are always committed to provide our products and services to
                  our customers at affordable price forever.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div
        className="d-flex align-items-center pt-5 pb-5 p-md-0 whoweare-hauto"
        style={{
          backgroundColor: "#E5E5E5",
          width: "100%",
        }}
      >
        <Grid container className="d-flex whoweare-hauto">
          <Grid
            item
            xs={12}
            md={6}
            backgroundColor="#E5E5E5"
            className="d-flex flex-column justify-content-center p-3 p-md-5"
          >
            <p className="underlineWho s-40 w3 c3 mb-4 mt-5 mb-md-5 mt-md-0">
              Get in touch
            </p>
            <p className="s5 mb-3">
              <Phone size={30} className="mr-4" />
              9863594648
            </p>
            <div className="d-flex mt-3">
              <InstagramLogo
                onClick={() =>
                  openInNewTab("https://www.instagram.com/novadelivers/")
                }
                size={35}
                weight="fill"
                color="#8943EF"
                className="mr-3"
              />
              <FacebookLogo
                onClick={() =>
                  openInNewTab(
                    "https://www.facebook.com/profile.php?id=100086008584076",
                  )
                }
                size={35}
                weight="fill"
                color="#8943EF"
                className="mr-3"
              />
              <TwitterLogo
                onClick={() => openInNewTab("https://twitter.com/novadelivers")}
                size={35}
                weight="fill"
                color="#8943EF"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="d-flex align-items-center">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.1036158432494!2d85.31073757614722!3d27.683192226555192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19316b5a8c9f%3A0x6f211b614a0c2a48!2sNova%20Delivers%20-%20Online%20Food%20Delivery%20in%20Kathmandu!5e0!3m2!1sen!2snp!4v1703224332586!5m2!1sen!2snp"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </Grid>
  );
};

export const PrivacyPolicy = () => {
  return (
    <div className="fcon_each_title_body">
      <p className="fcon_each_title mb-3">Privacy Policy</p>
      <p className="fcon_each_title_body">
        At Nova Delivers, accessible from novadelivers.com, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by Nova
        Delivers and how we use it.
        <p className="fcon_each_title_body">
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </p>
        <p className="fcon_each_title_body">
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regard to the information that they
          shared and/or collect in Nova Delivers. This policy is not applicable
          to any information collected offline or via channels other than this
          website. Our Privacy Policy was created with the help of the Free
          Privacy Policy Generator.
        </p>
      </p>
      <p className="fcon_each_title_sm mb-3">Consent</p>
      <p className="fcon_each_title_body">
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>
      <p className="fcon_each_title_sm mb-3">Information we collect</p>
      <p className="fcon_each_title_body">
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information.
      </p>
      <p className="fcon_each_title_body">
        If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information
        you may choose to provide.
      </p>
      <p className="fcon_each_title_body">
        When you register for an Account, we may ask for your contact
        information, including items such as name, company name, address, email
        address, and telephone number.
      </p>
      <p className="fcon_each_title_sm mb-3">How we use your information</p>
      <p>
        We use the information we collect in various ways, including to:
        <ul className="mt-2">
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
          </li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>
      </p>
      <p className="fcon_each_title_sm mb-3">Log Files</p>
      <p>
        Nova Delivers follows a standard procedure of using log files. These
        files log visitors when they visit websites. All hosting companies do
        this and a part of hosting services' analytics. The information
        collected by log files include internet protocol (IP) addresses, browser
        type, Internet Service Provider (ISP), date and time stamp,
        referring/exit pages, and possibly the number of clicks. These are not
        linked to any information that is personally identifiable. The purpose
        of the information is for analyzing trends, administering the site,
        tracking users' movement on the website, and gathering demographic
        information.
      </p>
      <p className="fcon_each_title_sm mb-3">Cookies and Web Beacons</p>
      <p>
        Like any other website, Nova Delivers uses 'cookies'. These cookies are
        used to store information including visitors' preferences, and the pages
        on the website that the visitor accessed or visited. The information is
        used to optimize the users' experience by customizing our web page
        content based on visitors' browser type and/or other information.
        <p className="fcon_each_title_sm mb-3">
          {" "}
          Advertising Partners Privacy Policies
        </p>
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of Nova Delivers.
        <p className="mt-2">
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on Nova Delivers, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit. Note that
          Nova Delivers has no access to or control over these cookies that are
          used by third-party advertisers.
        </p>
        <p className="fcon_each_title_sm mb-3"> Third Party Privacy Policies</p>
        Nova Delivers's Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options.
        <p className="mt-2">
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </p>
      </p>
      <p className="fcon_each_title_sm mb-3">Children's Information</p>
      Another part of our priority is adding protection for children while using
      the internet.We encourage parents and guardians to observe, participate
      in, and / or monitor and guide their online activity.
      <p className="mt-2">
        Nova Delivers does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our website, we strongly
        encourage you to contact us immediately, and we will do our best efforts
        to promptly remove such information from our records.
      </p>
      <p className="fcon_each_title_sm mb-3">Data Security</p>
      The safety and security of your information depends on you.Where we have
      given you(or where you have chosen) a password for access to certain parts
      of our Website, you are responsible for keeping this password
      confidential.We ask you not to share your password with anyone.We urge you
      to be careful about giving out information in public areas of the Website
      like message boards.The information you share in public areas may be
      viewed by any user of the Website.
      <p className="mt-2">
        Unfortunately, the transmission of information via the internet is not
        completely secure.Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to our Website.Any transmission of personal
        information is at your own risk.We are not responsible for circumvention
        of any privacy settings or security measures contained on the Website.
      </p>
      <p className="fcon_each_title_sm mb-3">Changes to Our Privacy Policy</p>
      It is our policy to post any changes we make to our privacy policy on this
      page.The date the privacy policy was last revised is identified at the top
      of the page.You are responsible for periodically visiting our Website and
      this privacy policy to check for any changes.
      <p className="fcon_each_title_sm mb-3 mt-2">Contact Information</p>
      To ask questions or comment about this privacy policy and our privacy
      practices, contact us at:
      <p className="mt-2">Nova Foods Private Limited</p>
      <p>Bakhundole</p>
      <p>Lalitpur, Nepal</p>
      <p className="mb-5">Email: contact@novadelivers.com</p>
    </div>
  );
};

export const TermsAndConditions = () => {
  return (
    <div className="fcon_each_title_body">
      <p className="fcon_each_title mb-3">Terms & Conditions</p>
      <p className="fcon_each_title_sm mb-3">Introduction</p>
      <p>
        These Website Standard Terms and Conditions written on this webpage
        shall manage your use of our website, Nova Delivers accessible at
        novadelivers.com.
      </p>
      <p>
        These Terms will be applied fully and affect to your use of this
        Website. By using this Website, you agreed to accept all terms and
        conditions written in here. You must not use this Website if you
        disagree with any of these Website Standard Terms and Conditions.
      </p>
      <p>
        Minors or people below 13 years old are not allowed to use this Website.
      </p>
      <p className="fcon_each_title_sm mb-3">Intellectual Property Rights</p>
      <p>
        Other than the content you own, under these Terms, Nova Foods Private
        Limited and/or its licensors own all the intellectual property rights
        and materials contained in this Website.
      </p>
      <p>
        You are granted limited license only for purposes of viewing the
        material contained on this Website.
      </p>
      <p className="fcon_each_title_sm mb-3">Restrictions</p>
      <p>You are specifically restricted from all of the following:</p>
      <ul className="mt-2">
        <li>publishing any Website material in any other media;</li>
        <li>
          selling, sublicensing and/or otherwise commercializing any Website
          material;
        </li>
        <li>publicly performing and/or showing any Website material;</li>
        <li>
          using this Website in any way that is or may be damaging to this
          Website;
        </li>
        <li>
          using this Website in any way that impacts user access to this
          Website;
        </li>
        <li>
          using this Website contrary to applicable laws and regulations, or in
          any way may cause harm to the Website, or to any person or business
          entity;
        </li>
        <li>
          engaging in any data mining, data harvesting, data extracting or any
          other similar activity in relation to this Website;
        </li>
        <li>using this Website to engage in any advertising or marketing.</li>
      </ul>
      <p>
        Certain areas of this Website are restricted from being access by you
        and Nova Foods Private Limited may further restrict access by you to any
        areas of this Website, at any time, in absolute discretion. Any user ID
        and password you may have for this Website are confidential and you must
        maintain confidentiality as well.
      </p>
      <p className="fcon_each_title_sm mb-3">Your Privacy</p>
      <p>Please read our Privacy Policy.</p>
      <p className="fcon_each_title_sm mb-3">No warranties</p>
      <p>
        This Website is provided "as is," with all faults, and Nova Foods
        Private Limited express no representations or warranties, of any kind
        related to this Website or the materials contained on this Website.
        Also, nothing contained on this Website shall be interpreted as advising
        you.
      </p>
      <p className="fcon_each_title_sm mb-3">Limitation of liability</p>
      <p>
        In no event shall Nova Foods Private Limited, nor any of its officers,
        directors and employees, shall be held liable for anything arising out
        of or in any way connected with your use of this Website whether such
        liability is under contract. Nova Foods Private Limited, including its
        officers, directors and employees shall not be held liable for any
        indirect, consequential or special liability arising out of or in any
        way related to your use of this Website.
      </p>
      <p className="fcon_each_title_sm mb-3">Indemnification</p>
      <p>
        You hereby indemnify to the fullest extent Nova Foods Private Limited
        from and against any and/or all liabilities, costs, demands, causes of
        action, damages and expenses arising in any way related to your breach
        of any of the provisions of these Terms.
      </p>
      <p className="fcon_each_title_sm mb-3">Severability</p>
      <p>
        If any provision of these Terms is found to be invalid under any
        applicable law, such provisions shall be deleted without affecting the
        remaining provisions herein.
      </p>
      <p className="fcon_each_title_sm mb-3">Variation of Terms</p>
      <p>
        Nova Foods Private Limited is permitted to revise these Terms at any
        time as it sees fit, and by using this Website you are expected to
        review these Terms on a regular basis.
      </p>
      <p className="fcon_each_title_sm mb-3">Assignment</p>
      <p>
        The Nova Foods Private Limited is allowed to assign, transfer, and
        subcontract its rights and/or obligations under these Terms without any
        notification. However, you are not allowed to assign, transfer, or
        subcontract any of your rights and/or obligations under these Terms.
      </p>
      <p className="fcon_each_title_sm mb-3">Entire Agreement</p>
      <p>
        These Terms constitute the entire agreement between Nova Foods Private
        Limited and you in relation to your use of this Website, and supersede
        all prior agreements and understandings.
      </p>
      <p className="fcon_each_title_sm mb-3">Governing Law & Jurisdiction</p>
      <p>
        These Terms will be governed by and interpreted in accordance with the
        laws of the Nepal, and you submit to the non-exclusive jurisdiction of
        the state and federal courts located in Nepal for the resolution of any
        disputes.
      </p>
      <p className="fcon_each_title_sm mb-3">Contact Information</p>
      To ask questions or comment about this privacy policy and our privacy
      practices, contact us at:
      <p className="mt-2">Nova Foods Private Limited</p>
      <p>Bakhundole</p>
      <p>Lalitpur, Nepal</p>
      <p className="mb-5">Email: contact @novadelivers.com</p>
    </div>
  );
};

export const DeliveryChargesPage = () => {
  return (
    <div className="fcon_each_title_body">
      <p className="fcon_each_title mb-3">Delivery Charge</p>
      <p className="fcon_each_title_sm mb-3">Delivery Charge Policy </p>
      <p>
        The delivery charge is calculated based on the approximate road distance
        between the restaurants and delivery locations:
      </p>
      <p>Upto 3 km = Rs.120 ( 10% discount on food applied) </p>
      <p>Upto 5 km = Rs.120</p>
      <p>Between 5 km to 8 km = Rs.150 </p>
      <p>Between 8 km to 10 km = Rs. 200 ( Advance Payment is must) </p>
      <p>Above 10 km = Negotiable</p>
    </div>
  );
};

// nope
export const Refund = () => {
  return (
    <>
      <p className="fcon_each_title_sm mb-3">Refund & Cancellation</p>
      <p className="fcon_each_title_body">
        Welcome to Website Name! These terms and conditions outline the rules
        and regulations for the use of Company Name's Website, located at
        Website.com. By accessing this website we assume you accept these terms
        and conditions. Do not continue to use Website Name if you do not agree
        to take all of the terms and conditions stated on this page. The
        following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: “Client”, “You” and
        “Your” refers to you, the person log on this website and compliant to
        the Company's terms and conditions. “The Company”, “Ourselves”, “We”,
        “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”,
        refers to both the Client and ourselves. All terms refer to the offer,
        acceptance and consideration of payment necessary to undertake the
        process of our assistance to the Client in the most appropriate manner
        for the express purpose of meeting the Client's needs in respect of
        provision of the Company's stated services, in accordance with and
        subject to, prevailing law of Netherlands. Any use of the above
        terminology or other words in the singular, plural, capitalization
        and/or he/she or they, are taken as interchangeable and therefore as
        referring to same.
      </p>
      <p className="fcon_each_title_body">
        Except as otherwise expressly stated with respect to our products, all
        contents of the site are offered on an "as is" basis without any
        warranty whatsoever either express or implied. Nature's Basket makes no
        representations, express or implied, including without limitation
        implied warranties of merchantability and fitness for a particular
        purpose. Nature's Basket does not guarantee the functions contained in
        the site will be uninterrupted or error-free, that this site or its
        server will be free of viruses or other harmful components, or defects
        will be corrected even if Nature's Basket is aware of them.
      </p>

      <p className="fcon_each_title_sm mt-4 mb-3">Copyright and Trademark</p>
      <p className="fcon_each_title_body">
        Unless otherwise noted, all materials on this site are protected as the
        copyrights, trade dress, trademarks and/ or other intellectual
        properties owned by Nature's Basket or by other parties that have
        licensed their material to Nature's Basket . All rights not expressly
        granted are reserved.
      </p>

      <p className="fcon_each_title_sm mt-4 mb-3">Feedback and Submissions</p>
      <p className="fcon_each_title_body">
        You agree you are and shall remain solely responsible for the contents
        of any submissions you make, and you will not submit material that is
        unlawful, defamatory, abusive or obscene. You agree that you will not
        submit anything to the site that will violate any right of any third
        party, including copyright, trademark, privacy or other personal or
        proprietary right(s).
      </p>
      <p className="fcon_each_title_body">
        While we appreciate your interest in Nature's Basket, we do not want and
        cannot accept any ideas you consider to be proprietary regarding
        designs, product technology or other suggestions you may have developed.
        Consequently, any material you submit to this site will be deemed a
        grant of a royalty free non-exclusive right and license to use,
        reproduce, modify, display, transmit, adapt, publish, translate, create
        derivative works from and distribute these materials throughout the
        universe in any medium and through any methods of distribution,
        transmission and display whether now known or hereafter devised. In
        addition, you warrant that all so-called "moral rights" have been
        waived.
      </p>

      <p className="fcon_each_title_sm mt-4 mb-3">Personal Use</p>
      <p className="fcon_each_title_body">
        You agree you are and shall remain solely responsible for the contents
        of any submissions you make, and you will not submit material that is
        unlawful, defamatory, abusive or obscene. You agree that you will not
        submit anything to the site that will violate any right of any third
        party, including copyright, trademark, privacy or other personal or
        proprietary right(s).
      </p>
    </>
  );
};

// nope
export const OfferTerms = () => {
  return (
    <>
      <p className="fcon_each_title_sm mb-3">Offer Terms</p>
      <p className="fcon_each_title_body">
        Welcome to Website Name! These terms and conditions outline the rules
        and regulations for the use of Company Name's Website, located at
        Website.com. By accessing this website we assume you accept these terms
        and conditions. Do not continue to use Website Name if you do not agree
        to take all of the terms and conditions stated on this page. The
        following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: “Client”, “You” and
        “Your” refers to you, the person log on this website and compliant to
        the Company's terms and conditions. “The Company”, “Ourselves”, “We”,
        “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”,
        refers to both the Client and ourselves. All terms refer to the offer,
        acceptance and consideration of payment necessary to undertake the
        process of our assistance to the Client in the most appropriate manner
        for the express purpose of meeting the Client's needs in respect of
        provision of the Company's stated services, in accordance with and
        subject to, prevailing law of Netherlands. Any use of the above
        terminology or other words in the singular, plural, capitalization
        and/or he/she or they, are taken as interchangeable and therefore as
        referring to same.
      </p>
      <p className="fcon_each_title_body">
        Except as otherwise expressly stated with respect to our products, all
        contents of the site are offered on an "as is" basis without any
        warranty whatsoever either express or implied. Nature's Basket makes no
        representations, express or implied, including without limitation
        implied warranties of merchantability and fitness for a particular
        purpose. Nature's Basket does not guarantee the functions contained in
        the site will be uninterrupted or error-free, that this site or its
        server will be free of viruses or other harmful components, or defects
        will be corrected even if Nature's Basket is aware of them.
      </p>

      <p className="fcon_each_title_sm mt-4 mb-3">Copyright and Trademark</p>
      <p className="fcon_each_title_body">
        Unless otherwise noted, all materials on this site are protected as the
        copyrights, trade dress, trademarks and/ or other intellectual
        properties owned by Nature's Basket or by other parties that have
        licensed their material to Nature's Basket . All rights not expressly
        granted are reserved.
      </p>

      <p className="fcon_each_title_sm mt-4 mb-3">Feedback and Submissions</p>
      <p className="fcon_each_title_body">
        You agree you are and shall remain solely responsible for the contents
        of any submissions you make, and you will not submit material that is
        unlawful, defamatory, abusive or obscene. You agree that you will not
        submit anything to the site that will violate any right of any third
        party, including copyright, trademark, privacy or other personal or
        proprietary right(s).
      </p>
      <p className="fcon_each_title_body">
        While we appreciate your interest in Nature's Basket, we do not want and
        cannot accept any ideas you consider to be proprietary regarding
        designs, product technology or other suggestions you may have developed.
        Consequently, any material you submit to this site will be deemed a
        grant of a royalty free non-exclusive right and license to use,
        reproduce, modify, display, transmit, adapt, publish, translate, create
        derivative works from and distribute these materials throughout the
        universe in any medium and through any methods of distribution,
        transmission and display whether now known or hereafter devised. In
        addition, you warrant that all so-called "moral rights" have been
        waived.
      </p>

      <p className="fcon_each_title_sm mt-4 mb-3">Personal Use</p>
      <p className="fcon_each_title_body">
        You agree you are and shall remain solely responsible for the contents
        of any submissions you make, and you will not submit material that is
        unlawful, defamatory, abusive or obscene. You agree that you will not
        submit anything to the site that will violate any right of any third
        party, including copyright, trademark, privacy or other personal or
        proprietary right(s).
      </p>
    </>
  );
};

export const HelpAndSupport = () => {
  const current = 0;
  // const [current, setCurrent] = useState(0)
  const leftButtons = [
    "Using Nova",
    //  "Ordering", "Legal", "Account And Payments", "Delivery"
  ];
  // const handleClick = (i) => {
  //     setCurrent(i)
  // }

  return (
    <div className="fcon_each_title_body">
      <p className="fcon_each_title mb-3">Help And Support</p>
      <Grid
        container
        borderRadius="5px"
        border="1px solid #E9ECFF"
        className="mb-5"
      >
        {/* <Grid item
                    sm={12}
                    lg={3}
                    display='flex'
                    flexDirection={{ xs: 'row', lg: 'column' }}
                    width='100%'
                    overflow={'auto'}
                >
                    {
                        leftButtons.map((each, i) => (
                            <Button key={i}
                                onClick={() => handleClick(i)}
                                className={current === i ? 'helpCurrentTabSel' : 'helpCurrentTab'}>
                                {each}
                            </Button>
                        ))
                    }
                </Grid> */}
        <Grid item sm={12} md={12} padding={{ xs: "10px", md: "24px" }}>
          <p className="w3 s3 mb-3">{leftButtons[current]}</p>
          {helpAndSupportQuestions[current] &&
            helpAndSupportQuestions[current].map((each, i) => (
              <div>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<CaretDown />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <span>{each.question}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>{each.answer}</p>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

const helpAndSupportQuestions = [
  // Partner Onboarding
  [
    {
      question: "How does it work?",
      answer:
        "You can order from our website and Mobile App. Just open our mobile application or website and simply add to cart your favourite food and click place order button to confirm your order. Once your order is confirmed, you will receive a call from our CSR team for location confirmation and delivery price. After that, the kitchen department will print out your KOT and as soon as your food gets ready our rider will pick it up and bring it to you. We are only available on Google Play Store for a time being. We will also be available on the App Store after a few weeks.",
    },
    {
      question: "What are the available payment methods on Nova Delivers?",
      answer:
        "You can pay for your order using cash on delivery. Soon, Khalti, Esewa, mobile banking, International cards will also be accepted.",
    },
    {
      question: "Can I cancel my order?",
      answer:
        "As long as the restaurant has not yet started preparing your order, you’re able to cancel your order from My Order page. If the restaurant has already started preparing your order, and you want to cancel, please call us for further assistance.",
    },
    {
      question: "Can I add extra instruction to my order?",
      answer:
        "You can add the extra instruction to your order by mentioning these instruction while placing your order in the additional detail space. In case you forgot to do so, you can go call us and request to add instructions.",
    },
    {
      question: "How can I track my order?",
      answer:
        "Sorry, you cannot track your order, but dont worry, we are working on it.",
    },
    {
      question: "What is your restaurant timing?",
      answer: "Our operation time is from 12 PM to 4 AM.",
    },
    {
      question: "Is there a minimum spend required for order?",
      answer: "There is no minimum spend required on Nova Delivers.",
    },
    {
      question: "How long do you take to deliver?",
      answer:
        "Your order will usually take 30-45 minutes to deliver. Sometime, delay can happen due to various reasons like traffic, bad weather or too many orders.",
    },
    {
      question: "How do you calculate the delivery charge?",
      answer:
        "Delivery charge is levied as per the restaurant policy, it is applied according to distance. There are no extra charges imposed by Nova Delivers on your order.",
    },
    {
      question:
        "What do I do if I have concerns/complaints regarding my order or delivery executives?",
      answer:
        "All your complaints and concerns are always addressed on priority. You can directly call us at 9863594648 or email us at contact@novadelivers.com",
    },
  ],
  // Ordering
  [
    {
      question: "Is there a minimum order value?",
      answer:
        "Quantity and quality of the food is the restaurants responsibility. However in case of issues with the quality or quantity, kindly submit your feedback and we will pass it on to the restaurant",
    },
    {
      question: "Is there a minimum order value?",
      answer:
        "Quantity and quality of the food is the restaurants responsibility. However in case of issues with the quality or quantity, kindly submit your feedback and we will pass it on to the restaurant",
    },
    {
      question: "Is there a minimum order value?",
      answer:
        "Quantity and quality of the food is the restaurants responsibility. However in case of issues with the quality or quantity, kindly submit your feedback and we will pass it on to the restaurant",
    },
    {
      question: "Is there a minimum order value?",
      answer:
        "Quantity and quality of the food is the restaurants responsibility. However in case of issues with the quality or quantity, kindly submit your feedback and we will pass it on to the restaurant",
    },
    {
      question: "Is there a minimum order value?",
      answer:
        "Quantity and quality of the food is the restaurants responsibility. However in case of issues with the quality or quantity, kindly submit your feedback and we will pass it on to the restaurant",
    },
    {
      question: "Is there a minimum order value?",
      answer:
        "Quantity and quality of the food is the restaurants responsibility. However in case of issues with the quality or quantity, kindly submit your feedback and we will pass it on to the restaurant",
    },
  ],
];
