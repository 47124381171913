import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  IconButton,
  Menu as MUIMenu,
  MenuItem,
  Modal,
  Snackbar,
  Switch,
  createTheme,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, Dropdown, Input, Menu, Select as SelectAnt } from "antd";
import CryptoJS from "crypto-js";
import moment from "moment";
import {
  ArrowLeft,
  Check,
  DotsThree,
  DotsThreeVertical,
  Eye,
  EyeClosed,
  FileArrowDown,
  Plus,
  Warning,
  X,
  XCircle,
} from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { KEY_AES } from "../Auth/Config";
import {
  FB_ConversionAPI,
  LogoutKDS,
  POSTAPI,
  isAuthenticated,
  isAuthenticatedAdmin,
  isAuthenticatedKDS,
} from "../Auth/Index";
import { isTextEmpty } from "./Validate_Admin";
import "./common.css";
import { ORDER_STATUS } from "./staticData";
const { Option } = SelectAnt;
const CheckboxGroup = Checkbox.Group;

export const Fb_Con_data = async (type, order_amount) => {
  const { userId, email, phone } = isAuthenticated();
  var ip = "";
  getIp().then((ipVal) => {
    if (ipVal.ip) ip = ipVal.ip;
    var fb_conversion_body = { body: [] };
    var event_id = Math.random();
    switch (type) {
      case "Purchase":
        event_id = userId + "t" + new Date().getTime();
        fb_conversion_body = {
          data: [
            {
              event_time: new Date(),
              event_name: "Purchase",
              event_source_url: window.location.href,
              action_source: "website",
              event_id: event_id,
              currency: "NPR",
              value: order_amount,
              user_data: {
                client_user_agent: window.navigator.userAgent,
                em: CryptoJS.SHA256(email).toString(),
                client_ip_address: ip,
                phone: CryptoJS.SHA256(phone).toString(),
              },
            },
          ],
        };
        break;
      case "Add to cart":
        event_id = userId + "t" + new Date().getTime();
        fb_conversion_body = {
          data: [
            {
              event_time: new Date(),
              event_name: "Add to Cart",
              event_source_url: window.location.href,
              action_source: "website",
              event_id: event_id,
              user_data: {
                client_user_agent: window.navigator.userAgent,
                em: CryptoJS.SHA256(email).toString(),
                client_ip_address: ip,
                phone: CryptoJS.SHA256(phone).toString(),
              },
            },
          ],
        };
        break;
      case "Initiate checkout":
        event_id = userId + "t" + new Date().getTime();
        fb_conversion_body = {
          data: [
            {
              event_time: new Date(),
              event_name: "Initiate checkout",
              event_source_url: window.location.href,
              action_source: "website",
              event_id: event_id,
              user_data: {
                client_user_agent: window.navigator.userAgent,
                em: CryptoJS.SHA256(email).toString(),
                client_ip_address: ip,
                phone: CryptoJS.SHA256(phone).toString(),
              },
            },
          ],
        };
        break;
      case "Add payment info":
        event_id = userId + "t" + new Date().getTime();
        fb_conversion_body = {
          data: [
            {
              event_time: new Date(),
              event_name: "Add payment info",
              event_source_url: window.location.href,
              action_source: "website",
              event_id: event_id,
              user_data: {
                client_user_agent: window.navigator.userAgent,
                em: CryptoJS.SHA256(email).toString(),
                client_ip_address: ip,
                phone: CryptoJS.SHA256(phone).toString(),
              },
            },
          ],
        };
        break;
      default:
        fb_conversion_body = {
          data: [
            {
              event_time: new Date(),
              event_name: "TestEvent",
              event_source_url: window.location.href,
              action_source: "website",
              event_id: Math.random(),
              user_data: {
                client_user_agent: window.navigator.userAgent,
                em: CryptoJS.SHA256(email).toString(),
                client_ip_address: ip,
                phone: CryptoJS.SHA256(phone).toString(),
              },
            },
          ],
          test_event_code: "TEST30462",
        };
        break;
    }
    FB_ConversionAPI(fb_conversion_body);
    return;
  });
};

const getIp = async () => {
  try {
    const response = await fetch("https://api.ipify.org/?format=json");
    return await response.json();
  } catch (error) {
    return { error: error };
  }
};

// export const muiCache = () => {
//     const value = createCache({
//         key: "mui-datatables",
//         prepend: true
//     });
//     return value
// }

export const tableDisabledOptions = {
  download: false,
  print: false,
  search: false,
  filter: false,
  sort: true,
  viewColumns: false,
  tableBodyMaxHeight: "calc(100vh - 280px)",
  responsive: "vertical",
};

export const CircularProgressComp = () => {
  return <CircularProgress style={{ color: "#8943EF" }} />;
};

export const CircularProgressCompSec = () => {
  return <CircularProgress style={{ color: "#F7F4DB" }} />;
};

export function getDeliveryLocationStatusIcon(status) {
  const iconStyle = {
    fontSize: 24, // Adjust the size as needed
    display: "inline-block",
    verticalAlign: "middle",
  };

  switch (status) {
    case "Not Verified":
      return (
        <XCircle
          color="red"
          weight="fill"
          style={{
            ...iconStyle,
          }}
        />
      );
    case "Hold":
      return (
        <Warning
          style={{
            ...iconStyle,
            color: "yellow",
          }}
        />
      );
    case "Verified":
      return (
        // <CheckCircleIcon
        //   style={{
        //     ...iconStyle,
        //     color: "green",
        //   }}
        // />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="green" // Change the color as needed
          style={{
            ...iconStyle,
          }}
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.42z" />
        </svg>
      );
    default:
      return null;
  }
}

export const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTableSelectCell: {
        styleOverrides: {
          expandDisabled: {
            // Soft hide the button.
            visibility: "hidden",
            zIndex: "5",
          },
          root: {
            backgroundColor: "#ffffff",
            zIndex: "1",
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
            zIndex: "5",
          },
          stackedHeader: {
            verticalAlign: "middle",
          },
          stackedCommon: {
            verticalAlign: "middle",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            color: "#2E2E2E !important",
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: "bold",
            fontStyle: "normal",
            borderBottom: "3px solid #E9ECFF",
            padding: "20px 16px 10px 16px",
          },
          body: {
            color: "#2E2E2E !important",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "normal",
            fontStyle: "normal",
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          sortAction: {
            color: "#2E2E2E !important",
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: "bold",
            fontFamily: "Outfit",
            fontStyle: "normal",
            textTransform: "none",
          },
          sortActive: {
            color: "#2E2E2E !important", // whatever you need
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&$hover:hover": { backgroundColor: "#ffefef" },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
    },
  });

export const getMuiThemeSimple = () =>
  createTheme({
    components: {
      MUIDataTableSelectCell: {
        styleOverrides: {
          expandDisabled: {
            // Soft hide the button.
            visibility: "hidden",
            zIndex: "5",
          },
          root: {
            backgroundColor: "#ffffff",
            zIndex: "1",
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
            zIndex: "5",
          },
          stackedHeader: {
            verticalAlign: "middle",
          },
          stackedCommon: {
            verticalAlign: "middle",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            color: "#2E2E2E !important",
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: "bold",
            fontFamily: "Outfit !important",
            fontStyle: "normal",
            borderBottom: "3px solid #E9ECFF",
            padding: "20px 16px 10px 16px",
          },
          body: {
            color: "#2E2E2E !important",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "normal",
            fontFamily: "Outfit !important",
            fontStyle: "normal",
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          sortAction: {
            color: "#2E2E2E !important",
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: "bold",
            fontFamily: "Outfit",
            fontStyle: "normal",
            textTransform: "none",
          },
          sortActive: {
            color: "#2E2E2E !important", // whatever you need
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&$hover:hover": { backgroundColor: "#ffefef" },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            boxShadow: "none",
            border: "1px solid #E9ECFF",
          },
        },
      },
    },
  });

export const getMuiThemeDatagrid = {
  ".MuiDataGrid-columnHeaderTitle": {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
  },
  "&.MuiDataGrid-root": {
    fontFamily: "Outfit",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2E2E2E",
  },
};

export const DataGridNova = styled(DataGrid)(({ theme }) => ({
  "&.MuiDataGrid-root": {
    fontFamily: "Outfit",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2E2E2E",
    outline: "0",
    backgroundColor: "#fff",
    border: "none",
    // padding: '0 10px'
  },
  ".MuiDataGrid-virtualScroller::-webkit-scrollbar": {
    height: "5px",
    width: "5px",
  },
  ".MuiDataGrid-columnHeaderTitle": {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
  },
  ".MuiDataGrid-cell:focus": {
    outline: "0",
  },
  ".MuiDataGrid-columnHeader:focus": {
    outline: "0",
  },
  ".MuiDataGrid-virtualScroller": {
    scrollbarWidth: "thin",
  },
  "& .MuiDataGrid-renderingZone": {
    maxHeight: "none !important",
  },
  "& .MuiDataGrid-cell": {
    lineHeight: "unset !important",
    maxHeight: "none !important",
    whiteSpace: "normal",
    padding: "16px",
  },
  "& .MuiDataGrid-row": {
    maxHeight: "none !important",
  },
  "& .MuiDataGrid-columnSeparator": {
    visibility: "hidden",
  },
  ".MuiDataGrid-columnHeaderTitleContainer": {
    padding: "0 8px",
  },
  "& .super-app-theme--Canceled": {
    backgroundColor: "#FFDEDE",
    "&:hover": {
      backgroundColor: "#f8eaea",
    },
  },
  "& .super-app-theme--Failed": {
    backgroundColor: "#FFDEDE",
    "&:hover": {
      backgroundColor: "#f8eaea",
    },
  },
}));

export const EditDeleteMenu = (
  id,
  removeFn,
  editFn,
  add_edit_visible_ind,
  Set_add_edit_visible,
  ind,
) => {
  const menuData = (
    <Menu
      items={[
        {
          label: (
            <Button
              className="edit_delete_text"
              onClick={(e) => {
                e.stopPropagation();
                editFn(id);
                Set_add_edit_visible(null);
              }}
            >
              Edit
            </Button>
          ),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <Button
              className="edit_delete_text"
              onClick={(e) => {
                e.stopPropagation();
                removeFn(id);
                Set_add_edit_visible(null);
              }}
            >
              Delete
            </Button>
          ),
          key: "1",
        },
      ]}
    />
  );

  const menuClick = (e) => {
    e.stopPropagation();
    Set_add_edit_visible(ind);
  };

  return (
    <Dropdown
      visible={add_edit_visible_ind === ind}
      overlay={menuData}
      trigger={["click"]}
      onClick={(e) => menuClick(e)}
    >
      <Button>
        <DotsThree color="#2E2E2E" weight="bold" size={20} />
      </Button>
    </Dropdown>
  );
};

export const EditDeleteTax = (props) => {
  var { id, DeleteFn, edAnchorTax, edClickedTax, taxes, setValues } = props;
  const open = Boolean(edAnchorTax);
  const handleClick = (event) => {
    setValues((x) => ({
      ...x,
      edAnchorTax: event.currentTarget,
      edClickedTax: id,
    }));
  };
  const handleClose = () => {
    setValues((x) => ({ ...x, edAnchorTax: null, edClickedTax: null }));
  };
  const handleEdit = () => {
    const eachTax = taxes.find((x) => x._id === edClickedTax);
    setValues((x) => ({
      ...x,
      edAnchorTax: null,
      edClickedTax: null,
      taxId: eachTax._id,
      taxName: eachTax.name,
      taxType: eachTax.type,
      taxAmount: eachTax.amount,
      taxModalVisible: true,
    }));
  };
  const handleDelete = () => {
    setValues((x) => ({ ...x, edAnchorTax: null, edClickedTax: null }));
    DeleteFn();
  };
  return (
    <div onClick={props.onClick}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => handleClick(e)}
      >
        <DotsThree color="#2E2E2E" size={25} />
      </Button>
      <MUIMenu
        id="basic-menu"
        anchorEl={edAnchorTax}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleEdit()}>Edit</MenuItem>
        <MenuItem onClick={() => handleDelete()}>Delete</MenuItem>
      </MUIMenu>
    </div>
  );
};

export const EditDeleteQR = (props) => {
  var { id, DeleteFn, edAnchorTax, edClickedTax, QRs, setValues } = props;
  const open = Boolean(edAnchorTax);
  const handleClick = (event) => {
    setValues((x) => ({
      ...x,
      edAnchorTax: event.currentTarget,
      edClickedTax: id,
    }));
  };
  const handleClose = () => {
    setValues((x) => ({
      ...x,
      edAnchorTax: null,
      edClickedTax: null,
      qrName: "",
      qrImage: "",
    }));
  };
  const handleEdit = () => {
    const eachTax = QRs.find((x) => x.codId === edClickedTax);
    setValues((x) => ({
      ...x,
      edAnchorTax: null,
      edClickedTax: null,
      QRId: eachTax.codId,
      qrName: eachTax.name,
      qrImage: eachTax.image,
      QRModal: true,
    }));
  };
  const handleDelete = () => {
    setValues((x) => ({ ...x, edAnchorTax: null }));
    DeleteFn();
  };
  return (
    <div onClick={props.onClick}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => handleClick(e)}
      >
        <DotsThree color="#2E2E2E" size={25} />
      </Button>
      <MUIMenu
        id="basic-menu"
        anchorEl={edAnchorTax}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleEdit()}>Edit</MenuItem>
        <MenuItem onClick={() => handleDelete()}>Delete</MenuItem>
      </MUIMenu>
    </div>
  );
};

export const EditDeleteDeliveryPartner = (props) => {
  var { id, DeleteFn, edAnchorTax, edClickedTax, deliveryPartners, setValues } =
    props;
  const open = Boolean(edAnchorTax);
  const handleClick = (event) => {
    setValues((x) => ({
      ...x,
      edAnchorTax: event.currentTarget,
      edClickedTax: id,
    }));
  };
  const handleClose = () => {
    setValues((x) => ({
      ...x,
      edAnchorTax: null,
      edClickedTax: null,
      deliveryPartnerName: "",
    }));
  };
  const handleEdit = () => {
    const each = deliveryPartners.find((x) => x._id === edClickedTax);
    setValues((x) => ({
      ...x,
      edAnchorTax: null,
      edClickedTax: null,
      deliveryPartnerId: each._id,
      deliveryPartnerName: each.name,
      deliveryPartnerModal: true,
    }));
  };
  const handleDelete = () => {
    setValues((x) => ({ ...x, edAnchorTax: null }));
    DeleteFn();
  };
  return (
    <div onClick={props.onClick}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => handleClick(e)}
      >
        <DotsThree color="#2E2E2E" size={25} />
      </Button>
      <MUIMenu
        id="basic-menu"
        anchorEl={edAnchorTax}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleEdit()}>Edit</MenuItem>
        <MenuItem onClick={() => handleDelete()}>Delete</MenuItem>
      </MUIMenu>
    </div>
  );
};

export const AdOrderEditDeleteMenu = (
  prod,
  editFn,
  add_edit_visible_ind,
  Set_add_edit_visible,
  ind,
  itemsArr,
  setValues,
  setShowDiscountModal,
  setShowEditModifiersModal,
  orderData,
) => {
  const deleteItem = (product) => {
    const filteredArr = itemsArr.filter((item) => {
      if (product.identifierId) {
        if (product.comboId) {
          return !(
            item.comboId === product.comboId &&
            item.identifierId === product.identifierId
          );
        } else if (product.itemId) {
          return !(
            item.itemId === product.itemId &&
            item.identifierId === product.identifierId
          );
        } else if (product.customId) {
          return item.customId !== product.customId;
        }
        return true;
      } else {
        if (product.comboId) {
          return item.comboId !== product.comboId || item.identifierId;
        } else if (product.itemId) {
          return item.itemId !== product.itemId || item.identifierId;
        } else if (product.customId) {
          return item.customId !== product.customId;
        }
        return true;
      }
    });
    setValues((prev) => ({
      ...prev,
      itemsArr: filteredArr,
    }));
  };

  const setEditItem = (e) => {
    e.stopPropagation();
    setValues((x) => ({
      ...x,
      currentItem: prod,
      spiceLevel: prod?.hasSpiceLevel ? getSpiceValue(prod.spiceLevel) : null,
      modifiersSel: prod?.modifiers?.length > 0 ? prod.modifiers : null,
    }));
  };

  const setDiscountItem = (e) => {
    setValues((x) => ({
      ...x,
      currentItem: prod,
      customDiscount: {
        discountType: prod?.discount?.type || "percent",
        discountValue: prod?.discount?.value || null,
      },
    }));
  };

  // var menuData = null;
  // if (
  //   (prod?.modifiers && prod?.modifiers.length > 0) ||
  //   prod.hasSpiceLevel === true
  // ) {
  //   menuData = (
  //     <Menu
  //       items={[
  //         {
  //           label: (
  //             <Button
  //               className="edit_delete_text"
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 setEditItem(e);
  //                 setShowEditModifiersModal(true);
  //                 Set_add_edit_visible(null);
  //               }}
  //             >
  //               Edit Custom
  //             </Button>
  //           ),
  //           key: "0",
  //         },
  //         {
  //           type: "divider",
  //         },
  //         {
  //           label: (
  //             <Button
  //               className="edit_delete_text"
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 setDiscountItem(e);
  //                 setShowDiscountModal(true);
  //                 Set_add_edit_visible(null);
  //               }}
  //             >
  //               Apply Discount
  //             </Button>
  //           ),
  //           key: "1",
  //         },
  //         {
  //           type: "divider",
  //         },
  //         {
  //           label: (
  //             <Button
  //               className="edit_delete_text"
  //               onClick={(e) => {
  //                 e.stopPropagation(e);
  //                 deleteItem(prod);
  //                 Set_add_edit_visible(null);
  //               }}
  //             >
  //               Delete
  //             </Button>
  //           ),
  //           key: "2",
  //         },
  //       ]}
  //     />
  //   );
  // } else if (
  //   prod?.modifiers &&
  //   prod?.modifiers?.length === 0 &&
  //   prod?.hasSpiceLevel !== true
  // ) {
  //   menuData = (
  //     <Menu
  //       items={[
  //         {
  //           label: (
  //             <Button
  //               className="edit_delete_text"
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 setDiscountItem(e);
  //                 setShowDiscountModal(true);
  //                 Set_add_edit_visible(null);
  //               }}
  //             >
  //               Apply Discount
  //             </Button>
  //           ),
  //           key: "0",
  //         },
  //         {
  //           type: "divider",
  //         },
  //         {
  //           label: (
  //             <Button
  //               className="edit_delete_text"
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 deleteItem(prod);
  //                 Set_add_edit_visible(null);
  //               }}
  //             >
  //               Delete
  //             </Button>
  //           ),
  //           key: "1",
  //         },
  //       ]}
  //     />
  //   );
  // }

  var menuData = <></>;
  var showApplyDiscountButton = true; // Initialize a variable to control Apply Discount button visibility

  // Check if orderData exists and isAdminCreated is false
  if (orderData && orderData?.isAdminCreated === false) {
    showApplyDiscountButton = false;
  } else if (prod.hasOwnProperty("customId")) {
    showApplyDiscountButton = false;
  } else {
    showApplyDiscountButton = true;
  }

  if (
    (prod?.modifiers && prod?.modifiers.length > 0) ||
    prod.hasSpiceLevel === true
  ) {
    const buttons = [
      {
        label: (
          <Button
            className="edit_delete_text"
            onClick={(e) => {
              e.stopPropagation();
              setEditItem(e);
              setShowEditModifiersModal(true);
              Set_add_edit_visible(null);
            }}
          >
            Edit Custom
          </Button>
        ),
        key: "0",
      },
      {
        type: "divider",
      },
    ];

    if (showApplyDiscountButton) {
      // Only add Apply Discount button if showApplyDiscountButton is true
      buttons.push({
        label: (
          <Button
            className="edit_delete_text"
            onClick={(e) => {
              e.stopPropagation();
              setDiscountItem(e);
              setShowDiscountModal(true);
              Set_add_edit_visible(null);
            }}
          >
            Apply Discount
          </Button>
        ),
        key: "1",
      });
      buttons.push({
        type: "divider",
      });
    }

    buttons.push({
      label: (
        <Button
          className="edit_delete_text"
          onClick={(e) => {
            e.stopPropagation(e);
            deleteItem(prod);
            Set_add_edit_visible(null);
          }}
        >
          Delete
        </Button>
      ),
      key: "2",
    });

    menuData = <Menu items={buttons} />;
  } else if (prod?.hasSpiceLevel !== true) {
    const buttons = [];

    if (showApplyDiscountButton) {
      // Only add Apply Discount button if showApplyDiscountButton is true
      buttons.push({
        label: (
          <Button
            className="edit_delete_text"
            onClick={(e) => {
              e.stopPropagation();
              setDiscountItem(e);
              setShowDiscountModal(true);
              Set_add_edit_visible(null);
            }}
          >
            Apply Discount
          </Button>
        ),
        key: "0",
      });
      buttons.push({
        type: "divider",
      });
    }

    buttons.push({
      label: (
        <Button
          className="edit_delete_text"
          onClick={(e) => {
            e.stopPropagation();
            deleteItem(prod);
            Set_add_edit_visible(null);
          }}
        >
          Delete
        </Button>
      ),
      key: "1",
    });

    menuData = <Menu items={buttons} />;
  }

  const handleVisibleChange = (visible) => {
    if (!visible) {
      Set_add_edit_visible(null);
      setValues((x) => ({
        ...x,
        currentItem: prod,
        customDiscount: { discountType: "percent", discountValue: null },
        modifiersSel: null,
      }));
    }
  };

  const menuClick = (e) => {
    e.stopPropagation();
    Set_add_edit_visible(ind);
  };

  return (
    <Dropdown
      visible={add_edit_visible_ind === ind}
      overlay={menuData}
      trigger={["click"]}
      onClick={(e) => menuClick(e)}
      onVisibleChange={handleVisibleChange}
    >
      <Button>
        <DotsThree color="#2E2E2E" weight="bold" size={20} />
      </Button>
    </Dropdown>
  );
};

export const HomeMenu = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    anchorElUser: null,
  });
  const { anchorElUser } = values;
  const openUser = Boolean(anchorElUser);
  const handleClick = (name, event) => {
    setValues((x) => ({ ...x, [name]: event.currentTarget }));
  };
  const handleClose = (name) => {
    setValues((x) => ({ ...x, [name]: null }));
  };
  return (
    <div>
      <Button
        className="avatar_name"
        aria-label="user"
        id="user-button"
        aria-controls={openUser ? "user-menu" : undefined}
        aria-expanded={openUser ? "true" : undefined}
        aria-haspopup="true"
        onClick={(e) => handleClick("anchorElUser", e)}
      >
        <DotsThreeVertical color="#2E2E2E" weight="bold" size={20} />
      </Button>
      <MUIMenu
        id="user-menu"
        MenuListProps={{
          "aria-labelledby": "user-button",
        }}
        anchorEl={anchorElUser}
        open={openUser}
        onClose={() => handleClose("anchorElUser")}
        PaperProps={{
          style: {
            width: "150px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/kds/history");
          }}
        >
          Order History
        </MenuItem>
        <MenuItem
          onClick={() => {
            LogoutKDS();
            navigate("/kds/login");
          }}
        >
          Logout
        </MenuItem>
      </MUIMenu>
    </div>
  );
};

export const HomeMenuKDS = () => {
  const navigate = useNavigate();

  const menuData = (
    <Menu
      items={[
        {
          label: (
            <Button
              className="kds_menu_each"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/kds/history");
              }}
            >
              Order History
            </Button>
          ),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <Button
              className="kds_menu_each"
              onClick={(e) => {
                e.stopPropagation();
                LogoutKDS();
                navigate("/kds/login");
              }}
            >
              Logout
            </Button>
          ),
          key: "1",
        },
      ]}
    />
  );

  const menuClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Dropdown
      className="mr-5"
      overlay={menuData}
      placement="bottomRight"
      trigger={["click"]}
      onClick={(e) => menuClick(e)}
    >
      <Button>
        <DotsThreeVertical color="#2E2E2E" weight="bold" size={20} />
      </Button>
    </Dropdown>
  );
};

export const LabelComp = (
  labelName,
  varName,
  errorPosition,
  errorText,
  className,
) => (
  <div className={`d-flex mb-1 align-items-center ${className}`}>
    <span className="mr-1 mb-1">{labelName}</span>
    {ErrorsComp(varName, errorPosition, errorText)}
  </div>
);

export const LabelComp_Index = (
  labelName,
  varName,
  varIndex,
  errorPosition,
  errorText,
  errorIndex,
) => (
  <div className="d-flex mb-1 mt-2 align-items-center">
    <span className="mr-2">{labelName}</span>
    {ErrorsCompIndex(varName, varIndex, errorPosition, errorText, errorIndex)}
  </div>
);

export const ErrorsComp = (varName, errorPosition, errorText) => {
  return (
    <>
      {errorPosition === varName ? (
        <>
          <span className="errText ml-auto align-self-center">{errorText}</span>
        </>
      ) : null}
    </>
  );
};

export const ErrorsCompIndex = (
  varName,
  varIndex,
  errorPosition,
  errorText,
  errorIndex,
) => {
  return (
    <>
      {errorPosition === varName &&
      varIndex.toString() === errorIndex.toString() ? (
        <span className="errText align-self-center">{errorText}</span>
      ) : null}
    </>
  );
};

export const InputComp = (value, name, setFn, className, placeholder) => {
  return (
    <input
      type="text"
      value={value}
      style={{ marginBottom: "10px" }}
      placeholder={placeholder}
      className={isTextEmpty(className) ? "admin_input" : className}
      onChange={(e) => setFn((prev) => ({ ...prev, [name]: e.target.value }))}
    />
  );
};

export const InputComp_Num = (value, name, setFn, className) => {
  return (
    <input
      type="number"
      value={value}
      style={{ marginBottom: "10px" }}
      className={className}
      onChange={(e) => setFn((prev) => ({ ...prev, [name]: e.target.value }))}
    />
  );
};

export const InputComp_Eye = (value, name, setFn, className) => {
  return (
    <Input.Password
      className={className ? className : "add_category_input"}
      value={value}
      onChange={(e) => setFn((prev) => ({ ...prev, [name]: e.target.value }))}
      iconRender={(visible) =>
        visible ? <Eye className="mr-2" /> : <EyeClosed className="mr-2" />
      }
    />
  );
};

export const InputComp_Drop = (value, name, setFn, dropValue, dropName) => {
  const selectBefore = (
    <SelectAnt
      defaultValue="%"
      className="select-before ant_tax_drop"
      value={dropValue}
      bordered={false}
      onChange={(e) => setFn((prev) => ({ ...prev, [dropName]: e }))}
    >
      <Option value="percent">%</Option>
      <Option value="value">Rs.</Option>
    </SelectAnt>
  );

  return (
    <Input
      addonAfter={selectBefore}
      bordered={false}
      value={value}
      defaultValue="mysite"
      size="large"
      className="add_category_input"
      onChange={(e) => setFn((prev) => ({ ...prev, [name]: e.target.value }))}
    />
  );
};

export const InputCompTextarea = (value, name, setFn, className) => {
  return (
    <textarea
      name="novaTextarea"
      rows="5"
      value={value}
      className={"p-2 " + className}
      style={{ height: "150px" }}
      onChange={(e) => setFn((prev) => ({ ...prev, [name]: e.target.value }))}
    />
  );
};

export const ReactSelectDrop = (
  varValue,
  varName,
  handleChangeFun,
  selectData,
) => {
  const handleValue = (e) => {
    handleChangeFun((mainValues) => ({ ...mainValues, [varName]: e.value }));
  };

  return (
    <Select
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      maxMenuHeight={"200px"}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      options={selectData?.map((item) =>
        item._id ? { label: item.name, value: item._id } : item,
      )}
      value={selectData
        ?.filter((option) => option._id === varValue)
        ?.map((item) => ({ label: item.name, value: item._id }))}
      name={varName}
      onChange={(e) => handleValue(e)}
      className="mb-2"
    />
  );
};

export const ReactSelectDropMulti = (
  varValue,
  varName,
  handleChangeFun,
  selectData,
) => {
  const handleValue = (e) => {
    var newVal = e.map((item) =>
      item.label ? { _id: item.value, name: item.label } : item,
    );
    handleChangeFun((mainValues) => ({ ...mainValues, [varName]: newVal }));
  };
  return (
    <Select
      maxMenuHeight={"200px"}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      options={selectData.map((item) =>
        item.name ? { label: item.name, value: item._id } : item,
      )}
      value={varValue.map((item) =>
        item.name ? { label: item.name, value: item._id } : item,
      )}
      isMulti
      name={varName}
      onChange={(e) => handleValue(e)}
    />
  );
};

export const FullBtnComp = (text, clickFn, loading) => {
  return (
    <Button className="full_btn_comp" onClick={() => clickFn()}>
      {loading ? <CircularProgress className="full_btn_comp_loading" /> : text}
    </Button>
  );
};

export const HalfRightBtnComp = (text, clickFn, loading) => {
  return (
    <Button className="half_right_btn_comp" onClick={() => clickFn()}>
      {loading ? <CircularProgress className="full_btn_comp_loading" /> : text}
    </Button>
  );
};

export const BtnComp = (text, clickFn, loading) => {
  return (
    <Button className="btn_comp" onClick={() => clickFn()}>
      {loading ? <CircularProgress className="full_btn_comp_loading" /> : text}
    </Button>
  );
};

export const AlertError = (errorPosition, errorText, setValues) => {
  var sev = "";
  if (errorPosition === "success") sev = "success";
  else if (errorPosition === "apiErr") sev = "error";
  return (
    (errorPosition === "mainErr" ||
      errorPosition === "apiErr" ||
      errorPosition === "success") && (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={
          errorPosition === "mainErr" ||
          errorPosition === "apiErr" ||
          errorPosition === "success"
        }
        autoHideDuration={3000}
        onClick={() =>
          setValues((val) => ({ ...val, errorPosition: "", errorText: "" }))
        }
        onClose={() =>
          setValues((val) => ({ ...val, errorPosition: "", errorText: "" }))
        }
        message="I love snacks"
        key={"top+center"}
      >
        <Alert variant="filled" severity={sev}>
          {errorText}
        </Alert>
      </Snackbar>
    )
  );
};

export const TopBackComp = (text, path) => {
  const navigate = useNavigate();
  return (
    <Button className="category_top_add" onClick={() => navigate(path)}>
      <ArrowLeft size={30} />
      <span className="ml-2">{text}</span>
    </Button>
  );
};

export const TopAddComp = (text, path, btnText, state) => {
  const navigate = useNavigate();
  return (
    <div className="category_top">
      <span className="category_top_title">{text}</span>
      <Button
        onClick={() => navigate(path, { state })}
        className="each_top_btn"
      >
        <Plus className="mr-1" color="#fff" weight="bold" />
        {btnText}
      </Button>
    </div>
  );
};

export const SelectImage = (image, name, setValues) => (
  <div className="add_category_image_box">
    {!image ? (
      <div className="upload-btn-wrapper">
        <IconButton>
          <FileArrowDown color="#0C72DE" style={{ fontSize: 80 }} />
        </IconButton>
        <p className="browseText">
          Drop your file here or
          <span className="browseTextBold"> Browse</span>
        </p>
        <input
          type="file"
          onChange={(event) =>
            setValues((val) => ({ ...val, [name]: event.target.files[0] }))
          }
        />
      </div>
    ) : (
      <>
        {typeof image === "object" ? (
          <div className="upload-btn-wrapper-show">
            <div className="add_category_image_div">
              <img
                src={URL.createObjectURL(image)}
                alt=""
                className="add_category_image"
              />
               <XCircle
                  className="prodEachImageCancel"
                  style={{
                    top: 5,
                    right: 5,
                    width : 20,
                    height : 20
                  }}
                  onClick={(event) => setValues((val) => ({ ...val, [name]: null }))
                }
                />
            </div>
            <input
              type="file"
              onChange={(event) =>
                setValues((val) => ({ ...val, [name]: event.target.files[0] }))
              }
            />
          </div>
        ) : (
          <div className="upload-btn-wrapper-show">
            <div className="add_category_image_div">
              <img src={image} alt="" className="add_category_image" />
            </div>
            <input
              type="file"
              onChange={(event) =>
                setValues((val) => ({ ...val, [name]: event.target.files[0] }))
              }
            />
          </div>
        )}
      </>
    )}
  </div>
);

export const SelectImageDouble = (images, name, setFn, removeIds) => {
  const handleDeleteImage = (ind) => {
    var editImages = [...images];

    var nowDelete = editImages[ind];
    editImages.splice(ind, 1);

    var deleted = [...removeIds];
    if (typeof nowDelete === "string") deleted.push(nowDelete);
    setFn((val) => ({
      ...val,
      [name]: editImages,
      removeIds: deleted,
    }));
  };

  return (
    <div className="add_category_image_box">
      {images.length === 0 ? (
        <div className="upload-btn-wrapper">
          <IconButton>
            <FileArrowDown color="#192FA4" style={{ fontSize: 80 }} />
          </IconButton>
          <p className="browseText">
            Drop your file here or
            <span className="browseTextBold"> Browse</span>
          </p>
          <input
            type="file"
            onChange={(event) =>
              setFn((val) => ({ ...val, [name]: [event.target.files[0]] }))
            }
          />
        </div>
      ) : (
        <div className="upload-btn-wrapper-show">
          <div className="prodEachImageDiv">
            {images.map((eachImg, indexEachImg) => {
              var urla =
                typeof eachImg === "object"
                  ? URL.createObjectURL(eachImg)
                  : eachImg;
              return (
                <div className="prodEachImageInside" key={indexEachImg}>
                  <img src={urla} alt="" className="prodEachImage" />
                  <XCircle
                    className="prodEachImageCancel"
                    onClick={(event) => handleDeleteImage(indexEachImg)}
                  />
                </div>
              );
            })}
            {images.length === 1 && (
              <div className="addAnotherImgDiv">
                <Plus className="addAnotherImg" />
              </div>
            )}
          </div>
          {images.length === 1 && (
            <input
              type="file"
              onChange={(event) => {
                var newImages = [...images];
                newImages.push(event.target.files[0]);
                setFn((val) => ({ ...val, [name]: newImages }));
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const SelectImage_Small = (image, name, setValues) => (
  <div className="add_small_image_box">
    {!image ? (
      <div className="upload-btn-wrapper-small">
        <IconButton>
          <FileArrowDown color="#192FA4" style={{ fontSize: 50 }} />
        </IconButton>
        <p className="browseText_small">
          Drop your file here or
          <span className="browseTextBold_small"> Browse</span>
        </p>
        <input
          type="file"
          onChange={(event) =>
            setValues((val) => ({ ...val, [name]: event.target.files[0] }))
          }
        />
      </div>
    ) : (
      <>
        {typeof image === "object" ? (
          <div className="upload-btn-wrapper-show-small">
            <div className="add_small_image_div">
              <img
                src={URL.createObjectURL(image)}
                alt=""
                className="add_small_image"
              />
              <XCircle
                className="small_ImageCancel"
                onClick={() => setValues((val) => ({ ...val, [name]: null }))}
              />
            </div>
            <input
              type="file"
              onChange={(event) =>
                setValues((val) => ({ ...val, [name]: event.target.files[0] }))
              }
            />
          </div>
        ) : (
          <div className="upload-btn-wrapper-show-small">
            <div className="add_small_image_div">
              <img src={image} alt="" className="add_small_image" />
              <XCircle
                className="small_ImageCancel"
                onClick={() => setValues((val) => ({ ...val, image: null }))}
              />
            </div>
            <input
              type="file"
              onChange={(event) =>
                setValues((val) => ({ ...val, [name]: event.target.files[0] }))
              }
            />
          </div>
        )}
      </>
    )}
  </div>
);

export const copyUserDetails = (e, data, setValues) => {
  e.stopPropagation();
  const valuesToCopy = [];

  if (data?.trackingNumber) {
    valuesToCopy.push(`${data?.trackingNumber}`);
  }

  if (data.isAdminCreated && data?.delivery?.location?.name) {
    valuesToCopy.push(`${data?.delivery?.location?.name}`);
  }

  if (!data.isAdminCreated && data?.delivery?.location?.name) {
    valuesToCopy.push(`${data?.delivery?.location?.name}`);
  }

  if (!data.isAdminCreated && !data?.delivery?.location?.name) {
    valuesToCopy.push(`${data?.userInfo?.name}`);
  }

  if (data.isAdminCreated && data?.delivery?.location?.phone) {
    valuesToCopy.push(`${data?.delivery?.location?.phone}`);
  }

  if (!data.isAdminCreated && data?.delivery?.location?.phone) {
    valuesToCopy.push(`${data?.delivery?.location?.phone}`);
  }

  if (!data.isAdminCreated && !data?.delivery?.location?.phone) {
    valuesToCopy.push(`${data?.userInfo?.phone}`);
  }

  if (data?.delivery?.location?.mapUrl) {
    valuesToCopy.push(`${data?.delivery?.location.mapUrl}`);
  } else {
    if (
      data?.delivery?.location?.latitude &&
      data?.delivery?.location?.latitude
    ) {
      valuesToCopy.push(
        `https://www.google.com/maps/search/?api=1&query=${data?.delivery?.location?.latitude},${data?.delivery?.location?.longitude}`,
      );
    }
  }

  if (data?.payment?.remainingAmount && data?.payment?.remainingAmount > 0) {
    valuesToCopy.push(`Rs.${data?.payment?.remainingAmount}`);
  }

  const text = valuesToCopy.join("\n");
  navigator.clipboard.writeText(text);
  setValues((x) => ({
    ...x,
    errorPosition: "success",
    errorText: "Copied User Details",
  }));
};

export const SelectImage_SmallIndex = (
  images,
  image,
  name,
  index,
  setValues,
  removeIds,
) => {
  const fileSelected = (e, index) => {
    var nowImages = [...images];
    var deleted = [...removeIds];
    if (typeof nowImages[index] === "string") {
      deleted[index] = true;
    }
    nowImages[index] = e.target.files[0];
    setValues((val) => ({ ...val, [name]: nowImages, removeIds: deleted }));
  };

  const fileRemoved = (e, index) => {
    var nowImages = [...images];
    var deleted = [...removeIds];
    if (typeof nowImages[index] === "string") {
      deleted[index] = true;
    }
    nowImages[index] = null;
    setValues((val) => ({ ...val, [name]: nowImages, removeIds: deleted }));
  };

  return (
    <div className="add_small_image_box">
      {!image ? (
        <div className="upload-btn-wrapper-small">
          <IconButton>
            <FileArrowDown color="#192FA4" style={{ fontSize: 50 }} />
          </IconButton>
          <p className="browseText_small">
            Drop your file here or
            <span className="browseTextBold_small"> Browse</span>
          </p>
          <input type="file" onChange={(e) => fileSelected(e, index)} />
        </div>
      ) : (
        <>
          {typeof image === "object" ? (
            <div className="upload-btn-wrapper-show-small">
              <div className="add_small_image_div">
                <img
                  src={URL.createObjectURL(image)}
                  alt=""
                  className="add_small_image"
                />
                <XCircle
                  className="small_ImageCancel"
                  onClick={(e) => fileRemoved(e, index)}
                />
              </div>
              <input type="file" onChange={(e) => fileSelected(e, index)} />
            </div>
          ) : (
            <div className="upload-btn-wrapper-show-small">
              <div className="add_small_image_div">
                <img src={image} alt="" className="add_small_image" />
                <XCircle
                  className="small_ImageCancel"
                  onClick={(e) => fileRemoved(e, index)}
                />
              </div>
              <input type="file" onChange={(e) => fileSelected(e, index)} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const MobileDatePickerComp = (value, name, setFn) => {
  const handleChange = (date, dateString) => {
    setFn((prev) => ({ ...prev, [name]: dateString }));
  };

  return (
    // <LocalizationProvider dateAdapter={AdapterDateFns}>
    //     <Stack spacing={3}>
    //         <MobileDatePicker
    //             label="Date mobile"
    //             inputFormat="MM/dd/yyyy"
    //             value={value}
    //             onChange={handleChange}
    //             renderInput={(params) => <TextField {...params} />}
    //         />
    //     </Stack>
    // </LocalizationProvider>
    <DatePicker
      onChange={handleChange}
      defaultValue={moment(value, "YYYY/MM/DD")}
      style={{ borderRadius: "12px", borderColor: "#818091", height: "40px" }}
    />
  );
};

export const InvalidPage = () => {
  const navigate = useNavigate();

  return (
    <div className="invalid_div">
      <img
        src="https://nova-foods.s3.ap-south-1.amazonaws.com/404.webp"
        className="invalid_img"
        alt=""
      />
      <span className="s4 w3 mt-3 c3">404 | Page not found</span>
      <p>This page doesn’t exist or was removed. We suggest you go back.</p>
      <Button className="invalid_btn mt-3" onClick={() => navigate(-1)}>
        Go back
      </Button>
    </div>
  );
};

// 429 page
export const Page429 = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    errorPosition: "apiErr",
    errorText: "Too many otp requests",
  });
  const { errorPosition, errorText } = values;
  return (
    <div className="invalid_div">
      <img
        src="https://nova-foods.s3.ap-south-1.amazonaws.com/429error.webp"
        className="invalid_img"
        alt=""
      />
      <span className="s4 w3 mt-3 c3">429 | Too many requests</span>
      <p>
        You have sent too many requests. Please wait for a moment and try again.
      </p>
      <Button className="invalid_btn mt-3" onClick={() => navigate(-1)}>
        Go back
      </Button>
      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const NotSupported = () => {
  return (
    <div className="invalid_div">
      <img
        src="https://nova-foods.s3.ap-south-1.amazonaws.com/Illustration.svg"
        className="invalid_img"
        alt=""
      />
      <p className="invalid_t2 mt-3">KDS is not supported on mobile. </p>
      <p>Open this link on a desktop.</p>
    </div>
  );
};

// date format

// dd/mm/yyyy
export const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString();
};

// Mar 28 2022
export const formatDateNext = (dateString) => {
  var original = new Date(dateString).toDateString();
  return original.slice(original.indexOf(" ") + 1);
};

// 5:32 AM
export const formatTime = (dateString) => {
  var date = new Date(dateString);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

// dd/mm/yyyy 5:32 AM
export const formatDateTime = (dateString) => {
  var date = new Date(dateString);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  var strDate = new Date(dateString).toLocaleDateString();
  return strDate + " " + strTime;
};

// Mar 28 2022 5:32 AM
export const formatDateTimeNext = (dateString) => {
  var date = new Date(dateString);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  var strDate = formatDateNext(date);
  return strDate + " " + strTime;
};

// Mar 28 2022 5:32 AM
export const formatDateTimeKDS = (dateString) => {
  var date = new Date(dateString);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  var strDate = formatDateNext(date);
  var split = strDate.split(" ");
  split = split[0] + " " + split[1];
  var day = getDay(date);
  return day + " , " + split + " || " + strTime;
};

// minutes to hours, minutes, seconds
export const convertMinutesToHoursAndMinutes = (minutes) => {
  if (!minutes) return "-";
  else if (minutes <= 60) return minutes + " mins";
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;
  return hours + " hr " + parseFloat(remainingMinutes).toFixed(0) + " mins";
};

const getDay = (date) => {
  var day = new Date(date).getDay();
  switch (day) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      break;
  }
};

// card item add remove
export const CartQuantityChange = (quantitySel, name, setValues) => {
  const handleQuanChange = (type) => {
    var quantity = quantitySel;
    if (type === "add") {
      quantity = quantitySel + 1;
    } else if (type === "minus" && quantitySel > 1) {
      quantity = quantitySel - 1;
    }
    setValues((prev) => ({ ...prev, [name]: quantity }));
  };

  return (
    <div className="cart_quan_div">
      <Button
        className="cq_minus_plus"
        onClick={() => handleQuanChange("minus")}
      >
        -
      </Button>
      <div className="cq_line" />
      <Button className="cq_text">{quantitySel ? quantitySel : ""}</Button>
      <div className="cq_line" />
      <Button className="cq_minus_plus" onClick={() => handleQuanChange("add")}>
        +
      </Button>
    </div>
  );
};

// side cart
export const CartQuantitySide = (prod, edit_one, ind) => {
  const handleQuanChange = (type) => {
    if (type === "add") {
      edit_one(prod, 1, ind);
    } else if (type === "minus") {
      edit_one(prod, -1, ind);
    }
  };

  return (
    <div className="cart_quan_div">
      <Button
        className="cq_minus_plus"
        onClick={() => handleQuanChange("minus")}
      >
        -
      </Button>
      <div className="cq_line" />
      <Button className="cq_text">{prod.quantity ? prod.quantity : ""}</Button>
      <div className="cq_line" />
      <Button className="cq_minus_plus" onClick={() => handleQuanChange("add")}>
        +
      </Button>
    </div>
  );
};

export const CartQuantityAdmin = (quantity, setValues) => {
  const handleQuanChange = (type) => {
    if (type === "add") {
      quantity = quantity ? quantity + 1 : 2;
      setValues((x) => ({ ...x, quanSel: quantity }));
    } else if (type === "minus") {
      quantity = quantity && quantity !== 1 ? quantity - 1 : quantity;
      setValues((x) => ({ ...x, quanSel: quantity }));
    }
  };

  return (
    <>
      {quantity && (
        <div className="cart_quan_div">
          <Button
            className="cq_minus_plus"
            onClick={() => handleQuanChange("minus")}
          >
            -
          </Button>
          <div className="cq_line" />
          <Button className="cq_text">{quantity ? quantity : 1}</Button>
          <div className="cq_line" />
          <Button
            className="cq_minus_plus"
            onClick={() => handleQuanChange("add")}
          >
            +
          </Button>
        </div>
      )}
    </>
  );
};

// admin order create, in each item selected
export const CartQuantityAdminArr = (prod, itemsArr, setValues, editing) => {
  const handleQuanChange = (type) => {
    const updatedItemsArr = itemsArr.map((item) => {
      if (item.itemId && !item.identifierId) {
        if (item.itemId === prod.itemId && !prod.identifierId) {
          if (type === "add") {
            if (editing) {
              return {
                ...item,
                isCooked: false,
                quantity: (item.quantity || 1) + 1,
              };
            }
            return { ...item, quantity: (item.quantity || 1) + 1 };
          } else if (type === "minus") {
            const newQuantity =
              item.quantity && item.quantity !== 1
                ? item.quantity - 1
                : item.quantity;
            return { ...item, quantity: newQuantity };
          }
        } else return item;
      }

      if (item.itemId && item.identifierId) {
        if (
          item.itemId === prod.itemId &&
          item.identifierId === prod.identifierId
        ) {
          if (type === "add") {
            if (editing) {
              return {
                ...item,
                isCooked: false,
                quantity: (item.quantity || 1) + 1,
              };
            }
            return { ...item, quantity: (item.quantity || 1) + 1 };
          } else if (type === "minus") {
            const newQuantity =
              item.quantity && item.quantity !== 1
                ? item.quantity - 1
                : item.quantity;
            return { ...item, quantity: newQuantity };
          }
        } else return item;
      }

      if (item.comboId && !item.identifierId) {
        if (item.comboId === prod.comboId && !prod.identifierId) {
          if (type === "add") {
            if (editing) {
              return {
                ...item,
                isCooked: false,
                quantity: (item.quantity || 1) + 1,
              };
            }
            return { ...item, quantity: (item.quantity || 1) + 1 };
          } else if (type === "minus") {
            const newQuantity =
              item.quantity && item.quantity !== 1
                ? item.quantity - 1
                : item.quantity;
            return { ...item, quantity: newQuantity };
          }
        } else return item;
      }

      if (item.comboId && item.identifierId) {
        if (
          item.comboId === prod.comboId &&
          item.identifierId === prod.identifierId
        ) {
          if (type === "add") {
            if (editing) {
              return {
                ...item,
                isCooked: false,
                quantity: (item.quantity || 1) + 1,
              };
            }
            return { ...item, quantity: (item.quantity || 1) + 1 };
          } else if (type === "minus") {
            const newQuantity =
              item.quantity && item.quantity !== 1
                ? item.quantity - 1
                : item.quantity;
            return { ...item, quantity: newQuantity };
          }
        } else return item;
      }

      if (item.customId) {
        if (item.customId === prod.customId) {
          if (type === "add") {
            if (editing) {
              return {
                ...item,
                isCooked: false,
                quantity: (item.quantity || 1) + 1,
              };
            }
            return { ...item, quantity: (item.quantity || 1) + 1 };
          } else if (type === "minus") {
            const newQuantity =
              item.quantity && item.quantity !== 1
                ? item.quantity - 1
                : item.quantity;
            return { ...item, quantity: newQuantity };
          }
        }
      }
      return item;
    });
    setValues((x) => ({ ...x, itemsArr: updatedItemsArr }));
  };

  return (
    <>
      {prod && (
        <div className="cart_quan_div">
          <Button
            className="cq_minus_plus"
            onClick={() => handleQuanChange("minus")}
          >
            -
          </Button>
          <div className="cq_line" />
          <Button className="cq_text">
            {prod.quantity ? prod.quantity : 1}
          </Button>
          <div className="cq_line" />
          <Button
            className="cq_minus_plus"
            onClick={() => handleQuanChange("add")}
          >
            +
          </Button>
        </div>
      )}
    </>
  );
};

export const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#8943EF",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#8943EF",
  },
}));

export const statusButtonClass = (stat) => {
  switch (stat) {
    case ORDER_STATUS.DRAFT:
      return ["oh_status_btn oh_draft", stat];
    case ORDER_STATUS.QUEUE:
      return ["oh_status_btn oh_queue", stat];
    case ORDER_STATUS.COOKING:
      return ["oh_status_btn oh_cooking", stat];
    case ORDER_STATUS.NOT_ASSIGNED:
      return ["oh_status_btn oh_not_assigned", stat];
    case ORDER_STATUS.PENDING:
      return ["oh_status_btn oh_cooking", stat];
    case ORDER_STATUS.PICKED_UP:
      return ["oh_status_btn oh_picked_up", stat];
    case ORDER_STATUS.COMPLETED:
      return ["oh_status_btn oh_comp", stat];
    case ORDER_STATUS.CANCELED:
      return ["oh_status_btn oh_cancelled", stat];
    case "Cancelled":
      return ["oh_status_btn oh_cancelled", stat];
    case ORDER_STATUS.FAILED:
      return ["oh_status_btn oh_cancelled", stat];
    default:
      return ["", ""];
  }
};

// change location homepage modal
export const ChangeLocationModalStyle = {
  position: "absolute",
  width: "400px",
  height: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFFFFF",
  borderColor: "#FFF",
  boxShadow: 24,
  maxHeight: "95vh",
  overflowX: "hidden",
  overflowY: "auto",
  scrollbarWidth: "thin",
  borderRadius: "12px",
  padding: "20px",
};

// login modal style
export const styleLoginModal = {
  position: "absolute",
  width: "400px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFFFFF",
  borderColor: "#FFF",
  boxShadow: 24,
  maxHeight: "95vh",
  borderRadius: "12px",
  overflow: "hidden",
};

// generic modal
export const modalStyles = {
  position: "absolute",
  // width: '400px',
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFFFFF",
  borderColor: "#FFF",
  boxShadow: 24,
  maxHeight: "95vh",
  overflowX: "hidden",
  overflowY: "auto",
  scrollbarWidth: "thin",
  borderRadius: "12px",
  padding: "16px",
};

export const popupModalStyles = {
  position: "absolute",
  width: "auto",
  height: "auto",
  // height: 'auto',
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowX: "hidden",
  overflowY: "hidden",
  maxHeight: "90vh",
};

export const ModalTop = (title, closeModal) => (
  <div className="d-flex justify-content-between align-items-center mb-3">
    <span className="ad_body_title">{title}</span>
    <IconButton onClick={() => closeModal()}>
      <X color="#000" />
    </IconButton>
  </div>
);

// spcie level
export const getSpiceText = (spiceLevel) => {
  var spiceLevelVal = "Low";
  switch (spiceLevel) {
    case 0:
      spiceLevelVal = "No";
      break;
    case 28:
      spiceLevelVal = "Low";
      break;
    case 66:
      spiceLevelVal = "Medium";
      break;
    case 100:
      spiceLevelVal = "High";
      break;
    default:
      spiceLevelVal = "Low";
      break;
  }
  return spiceLevelVal;
};

// get spice value
export const getSpiceValue = (spiceLevel) => {
  var spiceLevelVal = 28;
  switch (spiceLevel) {
    case "No":
      spiceLevelVal = 0;
      break;
    case "Low":
      spiceLevelVal = 28;
      break;
    case "Medium":
      spiceLevelVal = 66;
      break;
    case "High":
      spiceLevelVal = 100;
      break;
    default:
      spiceLevelVal = 28;
      break;
  }
  return spiceLevelVal;
};

// edit delete mui menu
export const TableEditDelete = (props) => {
  var { id, EditFn, DeleteFn, edAnchor, setValues, edClicked, status } = props;
  const open = Boolean(edAnchor);
  const handleClick = (event) => {
    event.stopPropagation();
    setValues((x) => ({ ...x, edAnchor: event.currentTarget, edClicked: id }));
  };
  const handleClose = () => {
    setValues((x) => ({ ...x, edAnchor: null, edClicked: null }));
  };
  const handleEdit = () => {
    EditFn(id, status);
    setValues((x) => ({ ...x, edAnchor: null, edClicked: null }));
  };
  const handleDelete = () => {
    setValues((x) => ({ ...x, edAnchor: null, edClicked: null }));
    DeleteFn(id);
  };
  return (
    <div onClick={props.onClick}>
      <Button
        id="basic-button"
        aria-controls={open && edClicked === id ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open && edClicked === id ? "true" : undefined}
        onClick={(e) => handleClick(e)}
      >
        <DotsThree color="#2E2E2E" size={25} />
      </Button>
      <MUIMenu
        id="basic-menu"
        anchorEl={edAnchor}
        open={open && edClicked === id}
        onClose={() => handleClose()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.EditFn && <MenuItem onClick={() => handleEdit()}>Edit</MenuItem>}
        {props.DeleteFn && (
          <MenuItem onClick={() => handleDelete()}>Delete</MenuItem>
        )}
      </MUIMenu>
    </div>
  );
};

// cart total prices part
export const CartSubTotalDiv = (cart, deliveryCharge, tip) => {
  return (
    <>
      <div className="d-flex justify-content-between mt-3">
        <p className="s2">Subtotal</p>
        <p className="w3 s2">Rs. {parseFloat(cart.subTotal).toFixed(2)}</p>
      </div>
      {tip && (
        <div className="d-flex justify-content-between">
          <p className="s2">Rider Tip</p>
          <p className="w3 s2">Rs. {tip}</p>
        </div>
      )}
      {cart.promo && (
        <div className="d-flex justify-content-between">
          <p className="s2">
            Promo{" "}
            <b>
              (<b style={{ color: "#192FA4" }}>{cart.promo.promoCode}</b>)
            </b>
          </p>
          <p className="w3 s2 c1">- Rs. {cart.promo.discount}</p>
        </div>
      )}
      {cart.taxes &&
        cart.taxes.map((eachTax, i) => (
          <div key={i} className="d-flex justify-content-between">
            <p className="s2">{eachTax.name}</p>
            <p className="w3 s2">Rs. {eachTax.taxableAmount}</p>
          </div>
        ))}
      {/* {deliveryCharge >0 ? (
        <div className="d-flex justify-content-between">
          <p className="s2">Delivery charge</p>
          <p className="w3 s2">Rs. {deliveryCharge}</p>
        </div>
      ) : null} */}
      <div className="divider" />
      {cart.total && (
        <div className="d-flex justify-content-between">
          <p className="s2">Total</p>
          <p className="w3 s3">
            Rs.
            {deliveryCharge
              ? parseFloat(
                  parseFloat(cart.total) +
                    parseFloat(deliveryCharge) +
                    parseFloat(tip ? tip : 0),
                ).toFixed(2)
              : parseFloat(
                  tip ? parseFloat(cart.total) + parseFloat(tip) : cart.total,
                ).toFixed(2)}
          </p>
        </div>
      )}
    </>
  );
};

//order history prices part
export const AccountSubTotalDiv = (cart) => {
  return (
    <>
      <div className="d-flex c3 justify-content-between mt-3">
        <p className="s2">Subtotal</p>
        <p className="w3 s2">
          Rs. {parseFloat(cart.payment.subTotal).toFixed(2)}
        </p>
      </div>
      {cart.payment.taxes
        ? cart.payment.taxes.map((eachTax, i) => (
            <div key={i} className="d-flex justify-content-between">
              <p className="s2">{eachTax.name}</p>
              <p className="w3 s2">Rs. {eachTax.taxableAmount}</p>
            </div>
          ))
        : null}
      {cart.payment.deliveryCharge ? (
        <div className="d-flex justify-content-between">
          <p className="s2">Delivery charge</p>
          <p className="w3 s2">Rs. {cart.payment.deliveryCharge}</p>
        </div>
      ) : null}
      {cart.payment?.tipAmount ? (
        <div className="d-flex justify-content-between">
          <p className="s2">Rider tip</p>
          <p className="w3 s2">Rs. {cart.payment.tipAmount}</p>
        </div>
      ) : null}
      {cart.payment.total && (
        <div className="d-flex justify-content-between">
          <p className="s2">Total</p>
          <p className="w3 s3">
            Rs. {parseFloat(cart.payment.total).toFixed(2)}
          </p>
        </div>
      )}
    </>
  );
};

// modal loading
export const LoadingModal = (modal_loading_box, text, setValues) => {
  // modal style
  const styleModal = {
    position: "absolute",
    // width: '400px',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    borderColor: "#FFF",
    boxShadow: 24,
    maxHeight: "95vh",
    borderRadius: "12px",
    overflow: "hidden",
    padding: "20px 100px",
  };

  return (
    <Modal
      open={modal_loading_box}
      onClose={
        () => null
        //  setValues(x => ({ ...x, modal_loading_box: false }))
      }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal} className="d-flex align-items-center ">
        <CircularProgress style={{ color: "#8943EF" }} />
        <span className="ml-3 w2">{text}</span>
      </Box>
    </Modal>
  );
};

// read notification
export const ReadNotification = ({ userId, notificationId }) => {
  POSTAPI("/notification/read", { userId, notificationId });
};

// start of a day
export const startOfaDay = (date) => {
  var start = new Date(date);
  start.setUTCHours(0, 0, 0, 0);
  return start;
};

// end of a day
export const endOfaDay = (date) => {
  var end = new Date(date);
  end.setUTCHours(23, 59, 59, 999);
  return end;
};

// get admin/kds ID
export const admin_kdsId = () => {
  const userId =
    isAuthenticatedKDS() && isAuthenticatedKDS().employeeId
      ? isAuthenticatedKDS().employeeId
      : isAuthenticatedAdmin() && isAuthenticatedAdmin().employeeId
        ? isAuthenticatedAdmin().employeeId
        : null;
  return userId;
};

export const admin_kdsInfo = () => {
  const userId =
    isAuthenticatedKDS() && isAuthenticatedKDS().employeeId
      ? isAuthenticatedKDS()
      : isAuthenticatedAdmin() && isAuthenticatedAdmin().employeeId
        ? isAuthenticatedAdmin()
        : null;
  return userId;
};

// filter page
export const SwipeableEdgeDrawer = (
  openFilterDrawer,
  setOpenFilterDrawer,
  data,
  searchText,
  setValuesFn,
  swipeableEdgeDrawerIndex,
) => {
  const [values, setValues] = useState({
    indeterminate_Offer: false,
    minPrice: "",
    maxPrice: "",
    sortValue: "Default",
    allOffersOptions: data.offers ? data.offers.map((x) => x.name) : [],
    checkAll_Offer: false,
    checkedList_Offer: [],
  });
  const {
    indeterminate_Offer,
    minPrice,
    maxPrice,
    sortValue,
    allOffersOptions,
    checkAll_Offer,
    checkedList_Offer,
  } = values;

  useEffect(() => {
    setValues((x) => ({
      ...x,
      indeterminate_Offer: false,
      minPrice: "",
      maxPrice: "",
      sortValue: "Default",
      allOffersOptions: data.offers ? data.offers.map((x) => x.name) : [],
      checkAll_Offer: false,
      checkedList_Offer: [],
    }));
  }, [data]);

  const priceChanged = async (min, max) => {
    if ((isNaN(min) && min !== "") || (isNaN(max) && max !== "")) {
      return;
    }
    setValues((x) => ({
      ...x,
      minPrice: min.trim(),
      maxPrice: max.trim(),
    }));
  };

  const onCheckAllChange = async (e, type, options, all, indeterminate_val) => {
    setValues((x) => ({
      ...x,
      [type]: e.target.checked ? options : [],
      [indeterminate_val]: false,
      [all]: e.target.checked,
    }));
  };

  // checklist of offers
  const onChange = async (list, type, options, all, indeterminate_val) => {
    setValues((x) => ({
      ...x,
      [type]: list,
      [indeterminate_val]: !!list.length && list.length < options.length,
      [all]: list.length === options.length,
    }));
  };

  const sortChanged = async (val) => {
    setValues((x) => ({ ...x, sortValue: val }));
  };

  // filter closed, so handle data
  const handleFilter = async () => {
    // if (location.pathname !== '/search') { //go to search page with the queries
    //     var send_data = {
    //         queryText: searchText,
    //         offerIds: checkedList_Offer,
    //         minPrice: isNaN(parseInt(minPrice)) ? null : parseInt(minPrice),
    //         maxPrice: isNaN(parseInt(maxPrice)) ? null : parseInt(maxPrice),
    //         requestSortQuery: sortValue
    //     }
    //     return
    // }
    // if in search page already
    setOpenFilterDrawer(false);
    var send_data = {
      queryText: searchText,
      offerIds: checkedList_Offer,
      minPrice: isNaN(parseInt(minPrice)) ? null : parseInt(minPrice),
      maxPrice: isNaN(parseInt(maxPrice)) ? null : parseInt(maxPrice),
      requestSortQuery: sortValue,
    };
    var send = await getIdsFromNameArray(send_data);
    POSTAPI("/homepage/search", send).then((resp) => {
      if (resp.success) {
        setValuesFn((x) => ({ ...x, searchOptionsVal: resp }));
      }
    });
  };

  const getIdsFromNameArray = (send_data) => {
    var offerArr = [];
    send_data.offerIds.forEach((element) => {
      var found = data.offers.find((x) => x.name === element);
      if (found) {
        offerArr.push(found._id);
      }
    });
    send_data.offerIds = offerArr;
    return send_data;
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={openFilterDrawer}
      onClose={() => handleFilter()}
    >
      <Box className="d-flex justify-content-between p-3 pb-0">
        <span className="w3 s5">Filter</span>
        <IconButton onClick={() => handleFilter()}>
          <X size={20} />
        </IconButton>
      </Box>
      <Box className="p-3">
        {/* offer */}
        <div className="mb-3">
          <Checkbox
            indeterminate={indeterminate_Offer}
            onChange={(e) =>
              onCheckAllChange(
                e,
                "checkedList_Offer",
                allOffersOptions,
                "checkAll_Offer",
                indeterminate_Offer,
              )
            }
            checked={checkAll_Offer}
          >
            <span className="cb_main">Offers</span>
          </Checkbox>
          <CheckboxGroup
            options={allOffersOptions}
            value={checkedList_Offer}
            onChange={(list) =>
              onChange(
                list,
                "checkedList_Offer",
                allOffersOptions,
                "checkAll_Offer",
                indeterminate_Offer,
              )
            }
          />
        </div>

        {/* price */}
        {swipeableEdgeDrawerIndex === 0 && (
          <div style={{ width: "fit-content" }}>
            <span className="cb_main">Price (Rs.)</span>
            <div className="d-flex justify-content-between mt-2">
              <input
                type="text"
                className="fm_left_price"
                value={minPrice}
                name="minPrice"
                onChange={(e) => priceChanged(e.target.value, maxPrice)}
              />
              <span className="ml-3 mr-3">_</span>
              <input
                type="text"
                className="fm_left_price"
                value={maxPrice}
                name="maxPrice"
                onChange={(e) => priceChanged(minPrice, e.target.value)}
              />
            </div>
          </div>
        )}

        {/* sort */}
        {swipeableEdgeDrawerIndex === 0 && (
          <>
            <p className="w3 mt-3">Sort </p>
            <div className="d-flex flex-column">
              {[
                "Default",
                "High to Low",
                "Low to High",
                "Popularity",
                "Fastest Delivery",
              ].map((each, i) => (
                <Button
                  key={i}
                  className="sort_xs_btn_each"
                  onClick={() => sortChanged(each)}
                >
                  <span className={`${sortValue === each ? "c1 w3" : "c3 w1"}`}>
                    {each}
                  </span>
                  <span className="ml-auto">
                    {sortValue === each && <Check color="#8943EF" size={20} />}
                  </span>
                </Button>
              ))}
            </div>
          </>
        )}
      </Box>
    </Drawer>
  );
};

// MUI generic menu
export const MUIMenuTwo = ({ EditFn, DeleteFn }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleEdit = () => {
    setAnchorEl(null);
    EditFn();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="del_assign_btn ml-5"
      >
        <DotsThree size={25} weight="bold" color="#2E2E2E" />
      </Button>
      <MUIMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        {/* <MenuItem onClick={handleClose}>Delete</MenuItem> */}
      </MUIMenu>
    </div>
  );
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

// encrypt text AES256
export const EncryptPasswordAES = (text) => {
  return CryptoJS.AES.encrypt(text, KEY_AES).toString();
};

// decrypt text AES256
export const DecryptPasswordAES = (text) => {
  var decrypted = CryptoJS.AES.decrypt(text, KEY_AES);
  const str = decrypted.toString(CryptoJS.enc.Utf8);
  return str;
};
