import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  createTheme,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  ThemeProvider,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import {
  CaretDown,
  Check,
  CheckCircle,
  ForkKnife,
  PlusCircle,
  X,
} from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { isAuthenticated, POSTAPI_USER } from "../../Auth/Index";
import {
  AlertError,
  CartSubTotalDiv,
  EditDeleteMenu,
  Fb_Con_data,
  FullBtnComp,
  InputComp,
  LabelComp,
  LoadingModal,
  RedSwitch,
} from "../../Components/Common";
import { PAYMENT_METHODS } from "../../Components/staticData";
import { isTextEmpty, ValidateAddress } from "../../Components/Validate_Admin";
import "../../css/Market/shippingPage.css";
import { MapBox } from "../MapBox";
import { Item } from "./ReactSlick";
import { purchase_gtag_report_conversion } from "../../Components/Gtag/basketgtagConversion";

// const { Step } = Steps

const style = {
  position: "absolute",
  width: "400px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFFFFF",
  borderColor: "#FFF",
  boxShadow: 24,
  maxHeight: "95vh",
  overflowX: "hidden",
  overflowY: "auto",
  scrollbarWidth: "thin",
  borderRadius: "12px",
  padding: "16px",
};

const theme = createTheme({
  components: {
    // Name of the component
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid #E9ECFF",
          borderRadius: "12px",
          backgroundColor: "#F9F9F9",
          padding: "12px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0",
          minHeight: "0px",
          "&.Mui-expanded": {
            minHeight: "0px",
          },
        },
        content: {
          margin: "0px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
        expandIconWrapper: {
          color: "#2E2E2E",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#F9F9F9",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
          color: "#818091",
        },
      },
    },
  },
});

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#8943EF",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#8943EF",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#8943EF",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#8943EF",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#8943EF",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const steps = ["Delivery", "Payment & Review"];

export const DeliveryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = isAuthenticated();
  const [values, setValues] = useState({
    step: 0,
    errorPosition: "",
    errorText: "",
    loading: false,
    modal_loading_box: false,
    first: true,
    main_loading: true,
    method: PAYMENT_METHODS.CASH,
    cart: [],
    deliveryCharge: 0,
    add_Address_modal: false,
    ab_loading: false,
    shipData: {},
    addInd: 0,
    selectedAddress: null,
    add_id: "",
    add_title: "",
    add_email: "",
    add_phone: "",
    add_name: "",
    add_address: null,
    add_landmark: "",
    add_lat: null,
    add_lng: null,
    promoCode: "",
    promo_loading: false,
    // payment
    esewaID: "",
    khaltiID: "",
    note: "",
    needCutlery: true,
    // map
    alt: "",
    str: "",
    initMapCenter: [27.7172, 85.324],
    mapCenter: [27.7172, 85.324],
  });

  var {
    step,
    errorPosition,
    errorText,
    loading,
    modal_loading_box,
    first,
    main_loading,
    method,
    cart,
    deliveryCharge,
    add_Address_modal,
    ab_loading,
    shipData,
    addInd,
    selectedAddress,
    add_id,
    add_title,
    add_name,
    add_phone,
    add_address,
    add_lat,
    add_lng,
    add_landmark,
    // esewaID,
    khaltiID,
    note,
    needCutlery,
    // mapCenter,
    initMapCenter,
  } = values;
  const [add_edit_visible_ind, Set_add_edit_visible] = useState(false);
  const OutletContext = useOutletContext();
  var setnumberOfItemsFn = OutletContext ? OutletContext[6] : null;

  // tip
  const tips = [20, 30, 40];
  const [tip, setTip] = useState(location.state?.tip ?? null);
  const [tipType, setTipType] = useState();
  useEffect(() => {
    const tips = [20, 30, 40];
    const getInitTipType = () => {
      if (!location.state?.tip) return "";
      const val = location.state?.tip;
      if (tips.includes(val)) setTipType(val);
      else setCustomTip(val);
    };
    getInitTipType();
  }, [location]);
  const [customTip, setCustomTip] = useState(false);
  const handleChangeCustomTip = (e) => {
    const value = e?.target?.value;
    if (customTip) {
      setCustomTip(value);
      setTip(value);
    }
    if (isTextEmpty(value)) {
      setCustomTip(true);
      setTip(null);
    }
  };

  const handleTip = (tipTypeVal, custom) => {
    setCustomTip(custom);
    setTipType(tipType === tipTypeVal ? null : tipTypeVal);
    setTip(tipType === tipTypeVal ? null : tipTypeVal);
  };
  const TipsBody = () => (
    <div className="d-flex flex-column gap-2 mb-2">
      {/* text */}
      <div className="d-flex flex-column gap-1">
        <span className="s2 w3">Tip your rider</span>
        <span className="s1 c-secondary">
          The entire amount will be transferred to the rider
        </span>
      </div>
      {/* tip amount */}
      <div className="row gap-2">
        {tips?.map((each, i) => (
          <Button
            style={{
              height: i === 1 ? "62px" : "40px",
              borderRadius: "5px",
            }}
            key={"tip_" + i}
            onClick={(e) => handleTip(each, false)}
            className={`d-flex flex-column p-0 s2 col c3 px-0
                                    ${tipType === tips[i] ? "bor-primary" : "bor"}`}
          >
            <span className={`${i === 1 && "mt-auto"}`}>
              {each !== "Custom" && "Rs."} {each}
            </span>
            {i === 1 && (
              <div
                style={{ fontSize: "10px" }}
                className="bg-dark c4 mt-auto px-1 w-100"
              >
                Most Tipped
              </div>
            )}
          </Button>
        ))}
        <Button
          style={{ height: "40px", borderRadius: "5px" }}
          onClick={(e) => handleTip("", !customTip)}
          className={`px-0 s2 col c3 ${customTip ? "bor-primary" : "bor"}`}
        >
          Custom
        </Button>
      </div>
      {customTip && (
        <input
          type="number"
          inputMode="numeric"
          autoFocus
          value={customTip}
          onChange={handleChangeCustomTip}
          className="px-2 py-2 bor c3 s1"
          style={{ borderRadius: "5px", outline: "none" }}
        />
      )}
    </div>
  );
  //  const tipVal = customTip && customTip?.length>0 ? customTip : !customTip && tipType > 0 ? tipType : null

  // khalti
  // let config = {
  //     // replace this key with yours
  //     "publicKey": KHALTI_KEY,
  //     "productIdentity": userId,
  //     "productName": name,
  //     "productUrl": "https://www.novadelivers.com",
  //     "eventHandler": {
  //         onSuccess(payload) {
  //             setValues(x => ({ ...x, modal_loading_box: true }))
  //             // hit merchant api for initiating verfication
  //             var send_data = {
  //                 token: payload.token,
  //                 amount: parseFloat(cart.total * 100).toFixed(2)
  //             }
  //             POSTAPI_USER('/order/verifyKhalti', send_data)
  //                 .then(res => {
  //                     if (res.success) {
  //                         var send_data = {
  //                             userId, addressId: shipData.addresses[addInd].addressId, note,
  //                             paymentInfo: {
  //                                 method: PAYMENT_METHODS.KHALTI,
  //                                 idx: res.verificationResponse.idx
  //                             }
  //                         }
  //                         Fb_Con_data('Purchase', cart.total)
  //                         POSTAPI_USER('/order/create', send_data)
  //                             .then(res => {
  //                                 if (res.success) {
  //                                     setnumberOfItemsFn && setnumberOfItemsFn(0)
  //                                     navigate('/thankyou', { state: res })
  //                                 }
  //                                 if (res.error) setValues(prev => ({
  //                                     ...prev,
  //                                     errorPosition: 'apiErr',
  //                                     errorText: res.error, loading: false, modal_loading_box: false
  //                                 }))
  //                             })
  //                     }
  //                     else if (res.error)
  //                         setValues(prev => ({
  //                             ...prev,
  //                             errorPosition: 'apiErr',
  //                             errorText: 'Could not verify transaction', loading: false,
  //                             modal_loading_box: false,
  //                             method: PAYMENT_METHODS.CASH
  //                         }))
  //                 })
  //         },
  //         // onError handler is optional
  //         onError(error) {
  //             setValues(x => ({ ...x, errorPosition: 'apiErr', errorText: 'Something went wrong!', method: PAYMENT_METHODS.CASH }))
  //             // handle errors
  //         },
  //         onClose() { //widget is closing
  //             setValues(x => ({ ...x, method: PAYMENT_METHODS.CASH }))
  //         }
  //     },
  //     "paymentPreference": ["KHALTI", "EBANKING", "MOBILE_BANKING", "CONNECT_IPS", "SCT"],
  // };

  // var checkout = new KhaltiCheckout(config)

  useEffect(() => {
    if (first) {
      POSTAPI_USER("/cart/one", { userId }).then((res) => {
        if (res.success)
          setValues((prev) => ({
            ...prev,
            cart: res.cart,
            deliveryCharge: res.deliveryCharge,
            needCutlery: res.cart.needCutlery ? res.cart.needCutlery : false,
            shipData: res.userInfo,
            selectedAddress:
              res.userInfo.addresses && res.userInfo.addresses[0]
                ? res.userInfo.addresses[addInd]
                : null,
            // esewaID: res.userInfo.paymentInfo.filter(x => x.name === PAYMENT_METHODS.ESEWA)[0] ? res.userInfo.paymentInfo.filter(x => x.name === PAYMENT_METHODS.ESEWA)[0].id : '',
            khaltiID: res.userInfo.paymentInfo.filter(
              (x) => x.name === PAYMENT_METHODS.KHALTI,
            )[0]
              ? res.userInfo.paymentInfo.filter(
                  (x) => x.name === PAYMENT_METHODS.KHALTI,
                )[0].id
              : "",
            first: false,
            main_loading: false,
          }));
        else if (res.error) {
          setValues((x) => ({ ...x, false: false, main_loading: false }));
        }
      });
    }
  }, [first, userId, addInd]);

  const next = (stepVal) => {
    if (stepVal === 1) {
      Fb_Con_data("Initiate checkout");
      if (shipData.addresses[addInd])
        setValues((val) => ({ ...val, step: stepVal }));
      else
        setValues((val) => ({
          ...val,
          errorPosition: "apiErr",
          errorText: "Please select an address",
        }));
    } else if (stepVal === 2) {
      switch (method) {
        // case PAYMENT_METHODS.ESEWA:
        //     if (isTextEmpty(esewaID)) setValues(val => ({
        //         ...val, errorPosition: 'apiErr',
        //         errorText: 'Please enter your Esewa ID'
        //     }))
        //     else setValues(val => ({ ...val, step: stepVal }))
        //     break;
        case PAYMENT_METHODS.KHALTI:
          if (isTextEmpty(khaltiID))
            setValues((val) => ({
              ...val,
              errorPosition: "apiErr",
              errorText: "Please enter your khalti ID",
            }));
          else setValues((val) => ({ ...val, step: stepVal }));
          break;
        default:
          setValues((val) => ({ ...val, step: stepVal }));
          break;
      }
    } else if (stepVal === 3) {
      //place order
      if (loading) return;
      setValues((val) => ({ ...val, loading: true }));
      var send_data = {
        userId,
        addressId: shipData.addresses[addInd].addressId,
        deliveryCharge: deliveryCharge,
        note,
        paymentInfo: {
          method,
        },
        tipAmount: tip,
      };
      Fb_Con_data("Purchase", cart.total);
      POSTAPI_USER("/order/create", send_data).then((res) => {
        if (res.success) {
          setnumberOfItemsFn && setnumberOfItemsFn(0);
          purchase_gtag_report_conversion(res)
          navigate("/thankyou", { state: res });
        }
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            errorText: res.error,
            loading: false,
          }));
      });
    } else {
      setValues((val) => ({ ...val, step: stepVal }));
    }
  };

  const clickedOnStep = (i) => {
    if (i === 0) {
      setValues((prev) => ({ ...prev, step: 0 }));
    } else if (i === 1 && step >= 1) {
      setValues((prev) => ({ ...prev, step: 1 }));
    } else return;
  };

  const StepperComp = () => (
    <Stack sx={{ width: "100%" }}>
      <Stepper
        alternativeLabel
        activeStep={step}
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label} onClick={() => clickedOnStep(index)}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );

  const AddAddressFn = async () => {
    if (ab_loading) return;
    var validated = await ValidateAddress(
      add_title,
      add_name,
      add_phone,
      add_lat,
      add_lng,
      setValues,
    );
    if (validated) {
      var send_data = {
        userId,
        title: add_title,
        name: add_name,
        phone: add_phone,
        address: add_address,
        longitude: add_lng,
        latitude: add_lat,
        closestLandmark: add_landmark,
      };
      setValues((prev) => ({ ...prev, ab_loading: true }));
      POSTAPI_USER("/user/address/add", send_data).then((res) => {
        if (res.success)
          setValues((prev) => ({
            ...prev,
            add_Address_modal: false,
            add_address: "",
            add_email: "",
            add_phone: "",
            add_title: "",
            add_name: "",
            add_landmark: "",
            first: true,
            ab_loading: false,
          }));
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            ab_loading: false,
          }));
      });
    }
  };

  const removeAddressFn = async (id) => {
    var send_data = {
      userId,
      addressId: id,
    };
    POSTAPI_USER("/user/address/remove", send_data).then((res) => {
      if (res.success) {
        var index = "";
        shipData.addresses.filter((x, i) =>
          x.addressId === id ? (index = i) : null,
        );
        var nowAddresses = shipData.addresses;
        nowAddresses.splice(index, 1);
        shipData.addresses = nowAddresses;
        setValues((prev) => ({
          ...prev,
          shipData: shipData,
        }));
      } else if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
    });
  };

  const editFn = async (id) => {
    var toEdit = shipData.addresses.filter((x) => x.addressId === id);
    if (toEdit.length === 1) {
      toEdit = toEdit[0];
      setValues((val) => ({
        ...val,
        add_id: toEdit.addressId,
        add_title: toEdit.title,
        add_name: toEdit.name,
        add_phone: toEdit.phone,
        add_address: toEdit.address,
        mapCenter: [toEdit.longitude, toEdit.latitude],
        add_Address_modal: true,
        add_lng: toEdit.longitude,
        add_lat: toEdit.latitude,
        add_landmark: toEdit.closestLandmark,
      }));
    }
  };

  const ShippingDiv = () => (
    <div>
      <p className="proceed_title mb-4">Choose Delivery Address</p>

      <Grid
        container
        mt={"2px"}
        mb={"30px"}
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
      >
        {shipData &&
          shipData.addresses &&
          shipData.addresses.map((card, ind) => (
            <Grid key={ind} item sm={12} xs={12} md={12} lg={12}>
              <Item
                onClick={() =>
                  setValues((prev) => ({
                    ...prev,
                    addInd: ind,
                    selectedAddress: card,
                  }))
                }
                className={
                  ind === addInd
                    ? "each_shipping_address_sel h-100"
                    : "each_shipping_address h-100"
                }
              >
                <div className="d-flex justify-content-between align-items-center">
                  <p className="esa_title">{card.title}</p>
                  {EditDeleteMenu(
                    card.addressId,
                    removeAddressFn,
                    editFn,
                    add_edit_visible_ind,
                    Set_add_edit_visible,
                    ind,
                  )}
                </div>
                <p>{card.name}</p>
                {card.email && <p>{card.email}</p>}
                {card.phone && <p>{card.phone}</p>}
                <p>{card.address}</p>
              </Item>
            </Grid>
          ))}
      </Grid>

      <Button
        className="add_new_address"
        onClick={() =>
          setValues((val) => ({ ...val, add_Address_modal: true }))
        }
      >
        <PlusCircle /> <span className="ml-1">Add new address</span>
      </Button>
    </div>
  );

  const NoteDiv = () => (
    <div className="">
      <p className="proceed_title mb-4">Additional Details</p>
      {TipsBody()}
      <p className="s2">Note</p>
      <textarea
        className="order_note"
        value={note}
        onChange={(e) => setValues((x) => ({ ...x, note: e.target.value }))}
      />
      <div className="d-flex mb-3 mt-3">
        <ForkKnife size={26} color="#8943EF" />
        <div className="ml-3 w-100">
          <div className="d-flex justify-content-between">
            <span>Do you need cutlery?</span>
            <RedSwitch checked={needCutlery} onChange={() => CutleryChange()} />
          </div>
          <span className="infoText mr-5">
            Consider using your own cutlery to reduce plastic use
          </span>
        </div>
      </div>
      <Button className="proceed_btn" onClick={() => next(1)}>
        {loading ? (
          <CircularProgress style={{ padding: "5px" }} />
        ) : (
          "Proceed to pay"
        )}
      </Button>
    </div>
  );

  const ShippingDivWithNote = () => (
    <div>
      {selectedAddress && (
        <Item className="each_shipping_address mt-0 h-100 mt-4">
          <div className="d-flex justify-content-between mb-4">
            <span className="proceed_title mt-0 mb-0">Delivery Details</span>
            <Button
              className="btn_trfm c9 s2"
              onClick={() => {
                editFn(selectedAddress.addressId);
                Set_add_edit_visible(null);
              }}
            >
              Edit
            </Button>
          </div>
          <p className="esa_title">{selectedAddress.title}</p>
          <p>{selectedAddress.name}</p>
          {selectedAddress.email && <p>{selectedAddress.email}</p>}
          {selectedAddress.phone && <p>{selectedAddress.phone}</p>}
          <p>{selectedAddress.address}</p>
        </Item>
      )}

      <Item className="each_shipping_address h-100 mt-3">
        <div className="d-flex justify-content-between mb-4">
          <span className="proceed_title mt-0 mb-0">Additional Details</span>
          <Button
            className="btn_trfm c9 s2"
            onClick={() => {
              clickedOnStep(0);
            }}
          >
            Edit
          </Button>
        </div>
        {tip && (
          <div className="d-flex justify-content-between">
            <span className="esa_title mb-2">Rider Tip</span>
            <p className="justify_text">Rs. {tip}</p>
          </div>
        )}
        {!isTextEmpty(note) && (
          <div className="d-flex flex-column">
            <span className="esa_title mb-2">Note</span>
            <p className="justify_text">{note}</p>
          </div>
        )}
        <div className="d-flex align-items-center">
          <ForkKnife size={26} color="#8943EF" />
          <span className="esa_title ml-1">Cutlery</span>
          <span className="ml-auto">{needCutlery ? "Yes" : "No"}</span>
        </div>
      </Item>
    </div>
  );

  const PaymentDiv = () => (
    <div>
      <p className="proceed_title mb-4">Choose Payment Method</p>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={PAYMENT_METHODS.CASH}
          value={method}
          onClick={(e) =>
            setValues((val) => ({ ...val, method: e.target.value }))
          }
          name="radio-buttons-group"
        >
          <FormControlLabel
            value={PAYMENT_METHODS.CASH}
            control={<Radio />}
            label="Cash on Delivery"
          />
          {method === PAYMENT_METHODS.CASH && (
            <p className="cod_text">
              You can pay for the products at your own doorstep.
            </p>
          )}
          {/* <FormControlLabel value={PAYMENT_METHODS.ESEWA} control={<Radio />} label={PAYMENT_METHODS.ESEWA} />
                    // {method === PAYMENT_METHODS.ESEWA && <>
                    //     {LabelComp('Esewa ID', esewaID, errorPosition, errorText)}
                    //     {InputComp(esewaID, 'esewaID', setValues, 'addside_input')}
                    // </>} */}

          {/* <FormControlLabel value={PAYMENT_METHODS.FONEPAY} control={<Radio />}
                        label={PAYMENT_METHODS.FONEPAY} />
                    {method === PAYMENT_METHODS.FONEPAY && <p className='cod_text'>
                        Scan and pay at your own doorstep.</p>} */}
          {/* <FormControlLabel value="Khalti"
                        control={<Radio onClick={() => checkout.show({
                            // amount: parseFloat(199 * 100).toFixed(2),
                            amount: parseFloat(cart.total * 100).toFixed(2),
                            // mobile: parseInt(khaltiID)
                        })} />}
                        label="Khalti" /> */}
        </RadioGroup>
      </FormControl>
      {method !== PAYMENT_METHODS.KHALTI && (
        <Button className="proceed_btn" onClick={() => next(3)}>
          {loading ? (
            <CircularProgress className="full_btn_comp_loading" />
          ) : (
            "Place Order"
          )}
        </Button>
      )}
    </div>
  );

  const CutleryChange = () => {
    var send_data = {
      userId,
      needCutlery: !cart.needCutlery,
    };
    POSTAPI_USER("/cart/edit", send_data).then((res) => {
      if (res.success) {
        var nowCart = { ...cart };
        nowCart.needCutlery = res.cart.needCutlery;
        setValues((prev) => ({
          ...prev,
          cart: nowCart,
          needCutlery: res.cart.needCutlery,
        }));
      } else if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
    });
  };

  const OrderSummary = () => (
    <Box>
      <p className="proceed_title mb-4 mt-3 ml-3">Order Summary</p>

      <ThemeProvider theme={theme}>
        {cart &&
          cart.foods &&
          cart.foods.length > 0 &&
          cart.foods.map((prod, ind) => (
            <div className="eachCart_div" key={ind}>
              <div className="mb-2 d-flex" key={ind}>
                <Avatar
                  src={prod.image}
                  className="shipping_eachCart_image"
                  variant="rounded"
                />
                <div className="d-flex flex-column w-100 justify-content-center">
                  <p className="eachCart_name">{prod.name}</p>
                  <div className="d-flex w-100 justify-content-between">
                    <span>
                      Qty : <b>{prod.quantity}</b>
                    </span>
                    <span className="eachCart_price">
                      Rs. {parseFloat(prod.price).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>

              {/* customizations */}
              {((prod.modifiers && prod.modifiers.length > 0) ||
                prod.spiceLevel !== null) && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<CaretDown weight="bold" className="ml-2" />}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    Customizations
                  </AccordionSummary>
                  <AccordionDetails>
                    {prod.modifiers && prod.modifiers.length > 0 && (
                      <>
                        {prod.modifiers.map((each, i) => (
                          <div key={i}>
                            <span className="c3 w3 s2">{each.name}</span>
                            <div className="d-flex flex-column mt-2">
                              {each.options &&
                                each.options.map((eachOP, ind) => {
                                  return (
                                    <div
                                      key={ind}
                                      className="
                                                            d-flex justify-content-between
                                                             align-items-center mb-2"
                                    >
                                      <span>{eachOP.name}</span>
                                      <span>Rs. {eachOP.price}</span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    {prod.spiceLevel !== null && (
                      <div className={"d-flex flex-column"}>
                        <span className="c3 w3 s2 mb-1">Spice Level</span>
                        <span className="tile_mod_text">{prod.spiceLevel}</span>
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          ))}
      </ThemeProvider>
      <div className="bottomDiv_ship mt-3">
        {/* <div className='bottomDiv_ship--prep'>
                    <Clock color='#FFF' size={20} weight='bold' />
                    <span className='c4 w3 ml-2'>Preparation Time</span>
                    <span className='ml-auto c4'>{cart.time ? (cart.time + ' mins approx') : '-'}</span>
                </div> */}

        {CartSubTotalDiv(cart, deliveryCharge, tip)}
        <span className="c7">
          *Our customer care center will call you for the delivery charge.
        </span>
      </div>
    </Box>
  );

  // openstreetmap
  // const setMapCoordinates = (index, address, mapCenter) => {
  //     var split = index.split(',')
  //     setValues(prev => ({
  //         ...prev, alt: index, add_address: address,
  //         add_lng: split[0], add_lat: split[1], mapCenter: mapCenter
  //     }))
  // }

  const setMapCoordinatesMapBox = (newlnglat) => {
    // var split = index.split(',')
    setValues((prev) => ({
      ...prev,
      alt: `${newlnglat[0]},${newlnglat[1]}`,
      add_address: "",
      add_lng: newlnglat[0],
      add_lat: newlnglat[1],
      mapCenter: newlnglat,
    }));
  };

  const EditAddressFn = async () => {
    if (ab_loading) return;
    var validated = await ValidateAddress(
      add_title,
      add_name,
      add_phone,
      add_lat,
      add_lng,
      setValues,
    );
    if (validated) {
      var send_data = {
        userId,
        addressId: add_id,
        title: add_title,
        name: add_name,
        phone: add_phone,
        address: add_address,
        longitude: add_lng,
        latitude: add_lat,
        closestLandmark: add_landmark,
      };
      setValues((prev) => ({
        ...prev,
        ab_loading: true,
        errorPosition: "",
        errorText: "",
      }));
      POSTAPI_USER("/user/address/edit", send_data).then((res) => {
        if (res.success)
          setValues((prev) => ({
            ...prev,
            add_Address_modal: false,
            add_id: "",
            add_address: "",
            add_email: "",
            add_phone: "",
            add_title: "",
            add_name: "",
            add_landmark: "",
            first: true,
            ab_loading: false,
          }));
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            ab_loading: false,
          }));
      });
    }
  };

  const closeModal = () => {
    setValues((val) => ({
      ...val,
      add_Address_modal: false,
      add_id: "",
      add_title: "",
      add_name: "",
      add_email: "",
      add_phone: "",
      add_address: "",
      add_landmark: "",
      mapCenter: initMapCenter,
    }));
  };

  return (
    <div>
      {main_loading ? (
        <div className="d-flex justify-content-center mt-5">
          <CircularProgress style={{ color: "#8943EF" }} />
        </div>
      ) : (
        <div className="shippingMainDiv mb-3">
          <div className="proceed__left">
            <div className="proceed__left--stepper">
              <StepperComp />
            </div>

            {step === 0 && (
              <div className="shipping_layout_right">
                {/* left */}
                <div className="step__left">{ShippingDiv()}</div>
                {/* right */}
                <div className="step__right">{NoteDiv()}</div>
              </div>
            )}

            {step === 1 && (
              <div className="shipping_layout_left">
                {/* left */}
                <div className="step__left">{ShippingDivWithNote()}</div>

                {/* right */}
                <div className="step__right">{PaymentDiv()}</div>
              </div>
            )}
          </div>

          <div className="proceed__right">{OrderSummary()}</div>
        </div>
      )}

      {AlertError(errorPosition, errorText, setValues)}

      {LoadingModal(modal_loading_box, "Creating order...", setValues)}

      <Modal
        open={add_Address_modal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          {/* top */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="ad_body_title">
              {add_id ? "Edit Address" : "Add Address"}
            </span>
            <IconButton onClick={() => closeModal()}>
              <X color="#000" />
            </IconButton>
          </div>

          {LabelComp("Address Title", "add_title", errorPosition, errorText)}
          {InputComp(add_title, "add_title", setValues, "ab_input")}

          {LabelComp("Full Name", "add_name", errorPosition, errorText)}
          {InputComp(add_name, "add_name", setValues, "ab_input")}

          {LabelComp("Phone", "add_phone", errorPosition, errorText)}
          {InputComp(add_phone, "add_phone", setValues, "ab_input")}

          {LabelComp(
            "Closest landmark",
            "add_landmark",
            errorPosition,
            errorText,
          )}
          {InputComp(add_landmark, "add_landmark", setValues, "ab_input")}

          {LabelComp("Address", "add_address", errorPosition, errorText)}

          {/* open street map */}
          {/* <div className='mb-2 pickMapDiv'>
                        <Test setMapCoordinates={setMapCoordinates}
                            mapCenter={mapCenter}
                            markCoord={isTextEmpty(add_lng) ? null : [add_lng, add_lat]}
                            markAdd={add_address}
                            type={isTextEmpty(add_id) ? 'Add Address' : 'Edit Address'} />
                    </div> */}

          <MapBox
            lnglat={[add_lng, add_lat]}
            setLngLat={setMapCoordinatesMapBox}
          />

          {FullBtnComp(
            isTextEmpty(add_id) ? "Add Address" : "Edit Address",
            isTextEmpty(add_id) ? AddAddressFn : EditAddressFn,
            ab_loading,
          )}
        </Box>
      </Modal>
    </div>
  );
};

export const ThankYouPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  var data = location.state;

  useEffect(() => {
    if (!data) navigate("/");
  }, [data, navigate]);

  const { name } = isAuthenticated();
  return (
    <>
      {data && (
        <div className="d-flex flex-column justify-content-center align-items-center mt-5">
          <CheckCircle
            color="#4BD37B"
            width="150px"
            height="150px"
            weight="fill"
          />
          <Box
            className="s4 w3 great_text mb-2"
            width={{ xs: "95%", sm: "400px" }}
          >
            <span>Great! {name.split(" ")[0]}, everything is ready</span>
          </Box>
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (data.order && data.order.foods) {
                navigate("/order/" + data.order._id, { state: data.order });
              }
            }}
          >
            Your order ID is <b>#{data.order.trackingNumber}</b>
          </p>
          <p>
            You will receive your order in{" "}
            <b>{data.time ? data.time : "45"} minutes</b> appox.
          </p>
          <div className="d-flex mt-2 mb-5">
            <Button className="back_home_btn" onClick={() => navigate("/")}>
              Back to Home
            </Button>
            {/* <Button className='track_order_btn' onClick={() => navigate(`/trackOrder/${data.orderId}`)}>Track Order</Button> */}
          </div>
        </div>
      )}
    </>
  );
};
