import { Box, Button, Modal } from "@mui/material";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { isAuthenticated } from "../../../Auth/Index";
import { AlertError, modalStyles } from "../../../Components/Common";
import BannerCarousel from "../../../Components/banner-carousel";
import "../../../css/Market/homepage.css";
import { AddToCartModal } from "../AddToCartFn";
import { FallbackComponent } from "../MarketLayout";
import Carousel from "../ReactSlick";
import { CarouselCategory } from "./Categories";
import CarouselCombo from "./Combo";

const LazyCarouselCategoryMb = lazy(() => import("../Home/CarouselCategoryMb"));

export const Homepage = () => {
  const userId = isAuthenticated()?.userId;
  const outletContext = useOutletContext();
  const respData = outletContext ? outletContext[9] : null;
  const navigate = useNavigate();
  const [values, setValues] = useState({
    allData: {},
    // pastOrders: [],
    cats: [],
    // brands: [],
    offers: [],
    combos: [],
    newItems: [],
    items: [],
    first: true,
    loading: true,
    // loading: false,
    errorPosition: "",
    errorText: "",
    testModal: false,
    modalItem: null,
    itemModalVisible: false,
    modifiersSel: [],
  });
  const {
    allData,
    // pastOrders,
    // cats,
    // brands,
    //  offers,
    combos,
    // newItems,
    // items,
    first,
    //  errorPosition, errorText
    loading,
    modalItem,
    itemModalVisible,
    modifiersSel,
    errorPosition,
    errorText,
  } = values;

  useEffect(() => {
    if (first && respData) {
      let copiedRespData = { ...respData };
      if (respData.success) {
        var priority_cats = [
          "Pizza",
          "FB VALUE MEALS",
          "FB BURGERS",
          "FB WRAPS",
          "All-In-One Meal Box",
          "Indian Curries",
        ];
        let categories = [...copiedRespData.categories];
        categories.sort(function (a, b) {
          return priority_cats.indexOf(a.name) - priority_cats.indexOf(b.name);
        });
        var sorted_cats_items_only = categories.splice(-6);
        var sorted_cats = sorted_cats_items_only.concat(categories);
        copiedRespData.categories = sorted_cats;
        // const newItems_pizza = respData.filter(x => (x.name === 'Pizza'))
        setValues((prev) => ({
          ...prev,
          allData: copiedRespData,
          pastOrders: copiedRespData.pastOrders,
          // cats: respData.categories,
          cats: sorted_cats,
          combos: copiedRespData.combos,
          newItems: copiedRespData.items?.filter(
            (x) => x.categoryName === "Pizza",
          ),
          // items: respData.items?.filter(x => x.brandName === 'Funky Buns'),
          items: copiedRespData.items,
          brands: copiedRespData.brands,
          offers: copiedRespData.offers,
          first: false,
          loading: false,
        }));
      } else if (respData.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          first: false,
          errorText: respData.error,
          //  loading: false
        }));
    }
  }, [
    first,
    userId,
    navigate,
    respData,
    // outletContext
  ]);

  return (
    <>
      {/* category div  bigger screen */}
      <div className="hp_cat_div_lg">
        <CarouselCategory
          data={allData}
          itemsArr={allData?.categories ?? []}
          loading={loading}
        />
      </div>

      <BannerCarousel />

      {/* for mobile */}
      <div className="hp_cat_div m-0 mt-2">
        <Suspense fallback={<FallbackComponent />}>
          <LazyCarouselCategoryMb
            data={allData}
            itemsArr={allData?.categories}
            loading={loading}
          />
        </Suspense>
      </div>

      {/* combos */}
      <CarouselCombo
        data={allData}
        itemsArr={combos}
        loading={loading}
        setValuesFnHm={setValues}
      />

      {/* Best Selling Items */}
      <>
        <div className="hp_each_title_div mb-3">
          <span className="w3 elec">
            {allData?.specialItems?.name ?? "Best Selling Items"}
          </span>
        </div>
        <Carousel
          type="5"
          data={allData}
          itemsArr={allData?.specialItems?.items ?? []}
          title="Best Selling"
          loading={loading}
          setValuesFnHm={setValues}
          totalVisibleItemsVal={8}
        />
      </>

      {allData?.featuredCategories?.map((eachFeature, i) => (
        <div key={"home_feature_" + i}>
          <div className="hp_each_title_div mb-3">
            <span className="w3 elec">{eachFeature.name}</span>
            <Button
              className="ml-auto c9 w3 s1"
              onClick={(e) =>
                navigate(`/filter?cid=${eachFeature.categoryId}&&name=${eachFeature.name}`, {
                  state: {
                    name: eachFeature.name,
                    catId: eachFeature.categoryId,
                    offerId: null,
                  },
                })
              }
            >
              View All
            </Button>
          </div>
          <Carousel
            itemsArr={eachFeature.items}
            loading={loading}
            setValuesFnHm={setValues}
            totalVisibleItemsVal={4}
          />
        </div>
      ))}

      {AddToCartModal(itemModalVisible, modalItem, modifiersSel, setValues)}
      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};
export const TestModalScroll = () => {
  const [values, setValues] = useState({
    testModal: false,
  });
  const { testModal } = values;

  const openTestModal = () => {
    setValues((x) => ({ ...x, testModal: true }));
  };

  const closeTestModal = () => {
    setValues((x) => ({ ...x, testModal: false }));
  };

  return (
    <>
      <Button onClick={openTestModal}>Open modal</Button>

      <Modal
        open={testModal}
        onClose={closeTestModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyles}>hihi</Box>
      </Modal>
    </>
  );
};
