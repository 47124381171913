import { Grid, Skeleton } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AlertError } from "../../Components/Common";
import "../../css/Market/alice.css";
import "./a.css";
import { OpenAddToCartModal } from "./AddToCartFn";

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#2E2E2E",
  display: "flex",
  flexDirection: "column",
  boxShadow: "none",
}));

export default function Carousel({
  itemsArr,
  loading,
  setValuesFnHm,
  totalVisibleItemsVal,
}) {
  const [values, setValues] = useState({
    totalVisibleItems: totalVisibleItemsVal,
    errorPosition: "",
    errorText: "",
  });
  const { totalVisibleItems, errorPosition, errorText } = values;

  return (
    <>
      <Grid
        container
        mb={"10px"}
        rowSpacing={{ xs: 1, sm: 2, md: 3 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {!loading &&
          itemsArr?.map((card, ind) => (
            <Grid
              key={ind}
              item
              xs={6}
              sm={4}
              md={4}
              lg={3}
              display={ind < totalVisibleItems ? "" : "none"}
            >
              {ind < totalVisibleItems && (
                <Item
                  style={{ cursor: "pointer" }}
                  onClick={(e) => OpenAddToCartModal(e, card, setValuesFnHm)}
                  className="d-flex align-items-start"
                >
                  <div
                    className="hp_each_popular_image"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundImage: `url(${card.image})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                  >
                    {card.hasDiscount && (
                      <div className="mayAlsoLike_dis d-flex w2 s2">
                        {card.offer &&
                          card.offer.discountType === "value" &&
                          "Rs."}
                        {card.offer.amount}
                        {card.offer &&
                          card.offer.discountType === "percent" &&
                          "%"}
                        <span className="ml-1" /> off
                      </div>
                    )}
                  </div>
                  <span className="c3 s2">{card.name}</span>
                  <div className="d-flex mt-2 mt-sm-0 align-items-center">
                    {card.hasDiscount && (
                      <span className="c7 w3 s2 mr-2 strikethrough">
                        Rs. {card.price}
                      </span>
                    )}
                    <span className="s5 c3 w3">Rs. {card.discountedPrice}</span>
                  </div>
                </Item>
              )}
            </Grid>
          ))}
        {loading &&
          Array(12)
            .fill("")
            .map((card, ind) => (
              <Grid key={ind} item sm={4} xs={6} md={3} lg={3}>
                <Item>
                  <Skeleton
                    className="hp_each_popular_skel"
                    variant="rectangular"
                  />
                </Item>
              </Grid>
            ))}
      </Grid>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
}
