import { Box, Button, Grid } from "@mui/material";
import { Copy } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertError, CircularProgressComp } from "../../Components/Common";
import "../../css/Market/viewOffers.css";
import { useGetHomeQuery } from "../../slices/apiSlice";
import { Item } from "./ReactSlick";

export const ViewOffers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(location.state?.step ?? 0);
  const [values, setValues] = useState({
    offers: [],
    promo: [],
    errorPosition: "",
    errorText: "",
    error_optionsIndex: "",
  });
  const {
    offers,
    promo,
    errorPosition,
    errorText,
    //  error_optionsIndex
  } = values;

  const { isLoading, data: res } = useGetHomeQuery();
  useEffect(() => {
    if(!res) return
    setValues((prev) => ({
      ...prev,
      offers: res?.offers ?? [],
      promo: res?.promos ?? [],
    }));
  }, [res]);

  const offerBody = () => (
    <Grid
      container
      mb={"30px"}
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      {offers &&
        offers.map((each, ind) => (
          <Grid key={ind} item xs={12} sm={6} md={6} lg={4}>
            <Item>
              <div
                className="sc_offer_all"
                key={ind}
                onClick={() =>
                  navigate(`/filter?oid=${each._id}`, {
                    state: {
                      offerId: each._id,
                    },
                  })
                }
              >
                <img src={each.image} alt="" className="sc_offer_item" />
              </div>
            </Item>
          </Grid>
        ))}
      {offers && offers.length === 0 && (
        <div className="ml-3">No offers yet.</div>
      )}
    </Grid>
  );

  const promoBody = () => (
    <Grid
      container
      mb={"30px"}
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      {promo &&
        promo.map((each, ind) => (
          <Grid key={ind} item xs={12} sm={4} md={4} lg={3}>
            <Item>
              <div className="sc_promo" key={ind}>
                <span className="sc_promo_title">{each.name}</span>
                <span>
                  Get{" "}
                  {each.symbol === "%"
                    ? each.amount + "% "
                    : "Rs. " + each.amount + " "}
                  discount using this promo code.
                </span>
                <div className="d-flex mt-3">
                  <Button className="testing_shape">
                    <div className="first_shape" />
                    <div className="middle_shape">{each.promoCode}</div>
                    <div className="last_shape" />
                  </Button>
                  <Button
                    className="sc_promo_copy"
                    onClick={() => {
                      navigator.clipboard.writeText(each.promoCode);
                      setValues((x) => ({
                        ...x,
                        errorPosition: "success",
                        errorText: "Copied",
                      }));
                    }}
                  >
                    <Copy size={20} />
                  </Button>
                </div>
              </div>
            </Item>
          </Grid>
        ))}
    </Grid>
  );

  var tabs = [
    {
      title: "Password",
      body: offerBody(),
    },
    {
      title: "Password",
      body: promoBody(),
    },
  ];

  return (
    <>
      {AlertError(errorPosition, errorText, setValues)}

      <Box className="vo_body">
        <Box className="vo_btn_div">
          <div className="vo_btn_eachDiv">
            <Button
              className={step === 0 ? "vo_btn_sel" : "vo_btn"}
              onClick={() => setStep(0)}
            >
              Offers
            </Button>
          </div>
          <div className="vo_btn_eachDiv">
            <Button
              className={step === 1 ? "vo_btn_sel" : "vo_btn"}
              onClick={() => setStep(1)}
            >
              Promo
            </Button>
          </div>
        </Box>
        {tabs[step].body}
        {isLoading && (
          <div className="d-flex justify-content-center">
            <CircularProgressComp />
          </div>
        )}
      </Box>
    </>
  );
};
