import { Box, Button, Fab, IconButton, Modal } from "@mui/material";
import { WhatsappLogo, X } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { popupModalStyles } from "../../../Components/Common";
import { ORDER_STATUS_TYPE } from "../../../Components/constants";
import { LoaderComp } from "../../../Components/order-loader";
import { getS3Image } from "../../../Components/s3";

export const NovaPopup = ({ orderStatus, image }) => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(true);
  const [height, setHeight] = useState(0)
  const [heightMb, setHeightMb] = useState(0)
  // const [popupBottom, setPopupBottom] = useState(false)
  useEffect(()=>{
    getImageDimensions(image)
      .then(dimensions => {
          const heightval =  (dimensions.height * 490)/(dimensions.width)
          const heightvalMb =  (dimensions.height * 85)/(dimensions.width)
          setHeight(heightval)
          setHeightMb(heightvalMb)
      })
      .catch(err=>{
        console.log(err);
      })
  },[image])
  const handleWhatsappClick = () => {
    const phoneNumber = "+9779863594648"; // Replace with your desired number or group
    const whatsappURL = `https://wa.me/${phoneNumber}`;
    window.open(whatsappURL, "_blank");
  };
  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: 24,
          right: 16,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Fab
          style={{
            width: "50px",
            height: "50px",
            background: "linear-gradient(0deg, #1FAF38 -9900%, #60D669 100%)",
            position: "relative",
          }}
          onClick={handleWhatsappClick}
          aria-label="add"
        >
          <WhatsappLogo size={30} color="#fff" />
        </Fab>
        {/* FAB for order status */}
        {orderStatus?.length > 0 && (
          <Button
            style={orderStatusStyle}
            className="d-flex justify-content-center align-items-center bg-white"
            onClick={() => {
              if (orderStatus?.length === 1)
                return navigate("/order/" + orderStatus?.[0]?._id, {
                  state: orderStatus?.[0],
                });
              navigate("/myAccount/", { state: { goToStep: 2 } });
            }}
          >
            <LoaderComp />
            {orderStatus?.length === 1 ? (
              OrderStatusCompImg(orderStatus)
            ) : (
              <span className="position-fixed s3 c3">
                {orderStatus?.length}
              </span>
            )}
          </Button>
        )}
      </div>
      {/* larger screen popup */}
      <Modal
        open={popup && image}
        disableRestoreFocus
        disableScrollLock
        onClose={() => {
          setPopup(false);
        }}
        aria-labelledby="modal-popup"
        aria-describedby="modal-popup"
        className=""
      >
        <Box sx={popupModalStyles}>
          {/* <img src={image} alt="" /> */}
          <Box
            width={{ xs: "85vw", md: `490px` }}
            height={{ xs: `${heightMb}vw`, md: height }}
            // style={{
            //   display: "flex",
            //   background: `url(${image})`,
            //   backgroundRepeat: "no-repeat",
            //   backgroundPosition: "center center",
            // }}
            onClick={(e) =>
              window.open(
                "https://play.google.com/store/apps/details?id=studio.fantasticstory.developer.novaapp",
                "_blank",
              )}
            // {
            //   setPopup(false);
            //   navigate("/filter?cid=66587af663c14bf76a2ec03c&&name=BOGO (Weekdays Offer)", {
            //     state: {
            //       name: 'BOGO (Weekdays Offer)',
            //       catId: '66587af663c14bf76a2ec03c',
            //       offerId: null,
            //     },
            //   })}
            // }
          >
            <img src={image} alt="" className="w-100 h-100"/>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setPopup(false);
              }}
              className="bg-black"
              style={{position:'absolute', height: "30px", width: "30px", right: 10, top : 10 }}
            >
              <X weight="bold" style={{ color: "#fff" }} />
            </IconButton>
          </Box>
        </Box>
      </Modal>
      {/* smaller screen popup */}
      {/* {popupBottom && <div className="displayNoneLg"
        onClick={e=>window.open('https://play.google.com/store/apps/details?id=studio.fantasticstory.developer.novaapp','_blank')}
        style={{
            width:'calc(100vw - 32px)',
            backgroundColor:'#222227',
            borderRadius:'12px',
            position:'sticky',
            bottom:20,
            left:16,
            color:'#fff',
            padding:'12px 10px',
        }}>
        <div style={{
            display:'flex',
            alignItems:'center'
        }}>
          <IconButton className="p-0" onClick={e => {
                e.stopPropagation()
                setPopupBottom(false)
            }}>
            <X size={20} color="#fff"/>
        </IconButton>
        <img src={getS3Image('app_icon.svg')} alt="" className="ml-2 mr-2"/>
        <div className="s-12 w1 mr-2">
            Flat <span className="w3">10% OFF</span> on your first app order
            <div>
                Use code: <span className="w3">NOVANEW</span>
            </div>
        </div>
        <Button style={{
            padding:'4px 12px',
            backgroundColor:'#8943EF',
            color:'#fff',
            borderRadius:'25px',
            textTransform:'none'
            }}>
            <span className="s-14">Install</span>
        </Button>
      </div>
    </div>} */}
    </>
  );
};

const orderStatusStyle = {
  position: "relative",
  height: "50px",
  minHeight: "50px",
  minWidth: "50px",
  maxWidth: "50px",
  borderRadius: "50%",
  padding: 0,
  alignSelf: "flex-end",
};

const getImage = (status) => {
  let image = null;
  switch (status) {
    case ORDER_STATUS_TYPE.DRAFT:
      image = getS3Image("order_preparing.gif");
      break;
    case ORDER_STATUS_TYPE.QUEUE:
      image = getS3Image("order_preparing.gif");
      break;
    case ORDER_STATUS_TYPE.COOKING:
      image = getS3Image("order_cooking.gif");
      break;
    case ORDER_STATUS_TYPE.PENDING:
      image = getS3Image("order_pending.gif");
      break;
    case ORDER_STATUS_TYPE.NOT_ASSIGNED:
      image = getS3Image("order_pending.gif");
      break;
    case ORDER_STATUS_TYPE.PICKED_UP:
      image = getS3Image("order_rider.gif");
      break;
    default:
      break;
  }
  return image;
};

export const OrderStatusCompImg = (orderStatus) => {
  return (
    <img
      src={getImage(orderStatus?.[0]?.status)}
      alt=""
      style={{
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        position: "fixed",
      }}
    />
  );
};

function getImageDimensions(url) {
  return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function() {
          resolve({ width: this.naturalWidth, height: this.naturalHeight });
      };
      img.onerror = function() {
          // reject(new Error('Failed to load image'));
      };
      img.src = url;
  });
}