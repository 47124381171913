import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import { styleLoginModal } from "../../Components/Common";

export const TestScroll = () => {
  const [testModal, setTestModal] = useState(false);
  return (
    <div>
      {Array(100)
        .fill("hi")
        .map((each, i) => (
          <div className="m-2">
            {i === 45 ? (
              <Button onClick={() => setTestModal(true)}>aas</Button>
            ) : (
              <>{i + " " + each}</>
            )}
          </div>
        ))}
      <Modal
        open={testModal}
        disableRestoreFocus
        disableScrollLock
        onClose={() => setTestModal(false)}
      >
        <Box style={styleLoginModal}>asj</Box>
      </Modal>
    </div>
  );
};
