import L from "leaflet";

export default L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});

export const ORDER_STATUS_TYPE = {
  DRAFT: "Draft",
  QUEUE: "Queue",
  COOKING: "Cooking",
  FINISHED: "Finished",
  NOT_ASSIGNED: "Not Assigned",
  PENDING: "Pending",
  PICKED_UP: "Picked Up",
  COMPLETED: "Completed",
  CANCELED: "Canceled",
  FAILED: "Failed",
};

export const ORDER_ORIGIN = {
  APP: "app",
  WEBSITE: "website",
  ADMIN: "admin",
  DAY_SALES: "day_sales",
  NIGHT_SALES: "night_sales",
  OTHER: "other",
};