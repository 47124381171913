import {
  Avatar,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider,
} from "@mui/material";
// import MUIDataTable from 'mui-datatables'
import { Plus } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DELETEAPIAdmin,
  GetAPIAdmin,
  POSTAPI_formAdmin,
  PUT_API_formAdmin,
} from "../../Auth/Index";
import {
  AlertError,
  DataGridNova,
  getMuiTheme,
  HalfRightBtnComp,
  InputComp,
  InputComp_Eye,
  LabelComp,
  MobileDatePickerComp,
  ReactSelectDrop,
  SelectImage,
  SelectImageDouble,
  TableEditDelete,
  TopBackComp,
} from "../../Components/Common";
import {
  isTextEmpty,
  ValidateEmployees,
} from "../../Components/Validate_Admin";
import "../../css/Admin/employee.css";
import "../../css/Admin/item.css";

export const Employees = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    step: 0,
    employees: [],
    prev_employees: [],
    designationData: [],
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    edAnchor: null,
    edClicked: null,
    pageSize: 10,
  });
  const {
    step,
    employees,
    prev_employees,
    designationData,
    first,
    loading,
    errorPosition,
    errorText,
    edAnchor,
    edClicked,
    pageSize,
  } = values;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/employee/").then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            employees: res.currentEmployees,
            prev_employees: res.previousEmployees,
            designationData: res.designations,
            first: false,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first]);

  const columns = [
    {
      field: "name",
      headerName: "Employee Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <Avatar style={{ borderRadius: "12px" }} src={params.row.image} />
            <span className="ml-3">{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "phone",
      headerName: "Contact",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "designationTitle",
      headerName: "Designation",
      width: 150,
    },
    {
      field: "icon",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        return (
          <TableEditDelete
            onClick={(e) => e.stopPropagation()}
            id={params.row._id}
            EditFn={EditFn}
            DeleteFn={DeleteFn}
            edAnchor={edAnchor}
            setValues={setValues}
            edClicked={edClicked}
          />
        );
      },
    },
  ];

  const prev_columns = [
    {
      field: "name",
      headerName: "Employee Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <Avatar style={{ borderRadius: "12px" }} src={params.row.image} />
            <span className="ml-3">{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "phone",
      headerName: "Contact",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "designationTitle",
      headerName: "Designation",
    },
    {
      field: "icon",
      headerName: "",
      renderCell: (params) => {
        return (
          <TableEditDelete
            onClick={(e) => e.stopPropagation()}
            id={params.row._id}
            EditFn={null}
            DeleteFn={DeleteFn}
            edAnchor={edAnchor}
            setValues={setValues}
            edClicked={edClicked}
          />
        );
      },
    },
  ];

  const EditFn = () => {
    var item = employees.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      item.designationData = designationData;
      navigate("/hr/employees/edit", { state: item });
    }
  };

  const DeleteFn = () => {
    var allEmp = employees.concat(prev_employees);
    var item = allEmp.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      DELETEAPIAdmin("/employee/remove", { employeeId: edClicked }).then(
        (res) => {
          if (res.success) {
            setValues((x) => ({
              ...x,
              errorPosition: "success",
              errorText: `Employee ${item.name} has been removed.`,
              employees: res.currentEmployees,
              prev_employees: res.previousEmployees,
            }));
          } else if (res.error)
            setValues((x) => ({
              ...x,
              errorPosition: "apiErr",
              errorText: res.error,
            }));
        },
      );
    }
  };

  const handleRowClick = (rowData, rowMeta) => {
    var item = employees[rowMeta.dataIndex];
    item.designationData = designationData;
    navigate("/hr/employees/edit", { state: item });
  };

  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No employees yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  // };

  // const prev_options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No employees yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  // };

  return (
    <div className="category_body w-75">
      <div className="emp_top">
        <span className="category_top_title">Employees</span>

        <div className="d-flex justify-content-center">
          <Button
            className={step === 0 ? "emp_btn_sel" : "emp_btn_unsel"}
            onClick={() => setValues((x) => ({ ...x, step: 0 }))}
          >
            Current Employees
          </Button>
          <Button
            className={step === 1 ? "emp_btn_sel" : "emp_btn_unsel"}
            onClick={() => setValues((x) => ({ ...x, step: 1 }))}
          >
            Previous Employees
          </Button>
        </div>

        {step === 0 ? (
          <Button
            onClick={() =>
              navigate("/hr/employees/add", { state: { designationData } })
            }
            className="each_top_btn"
          >
            <Plus className="mr-1" color="#fff" weight="bold" />
            Add Employee
          </Button>
        ) : (
          <div style={{ height: "45px" }} />
        )}
      </div>

      <ThemeProvider theme={getMuiTheme()}>
        {/* {step === 0 ?
                    <MUIDataTable
                        data={employees}
                        columns={columns}
                        options={options}
                    />
                    :
                    <MUIDataTable
                        data={prev_employees}
                        columns={prev_columns}
                        options={prev_options}
                    />} */}
      </ThemeProvider>

      <div style={{ height: "65vh", width: "100%" }}>
        {step === 0 ? (
          <DataGridNova
            loading={loading}
            getRowHeight={() => "auto"}
            headerHeight={70}
            rows={employees}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageSizeChange={(newPageSize) =>
              setValues((x) => ({ ...x, pageSize: newPageSize }))
            }
            getRowId={(row) => row._id}
            disableSelectionOnClick
            onCellClick={(e) => handleRowClick(e.row)}
          />
        ) : (
          <DataGridNova
            loading={loading}
            getRowHeight={() => "auto"}
            headerHeight={70}
            rows={prev_employees}
            columns={prev_columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageSizeChange={(newPageSize) =>
              setValues((x) => ({ ...x, pageSize: newPageSize }))
            }
            getRowId={(row) => row._id}
            disableSelectionOnClick
            onCellClick={(e) => handleRowClick(e.row)}
          />
        )}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const AddEmployees = () => {
  const navigate = useNavigate();
  var location = useLocation();
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    designationId: "",
    joinedAt: new Date(),
    profileImage: null,
    docImagesType: "citizenship",
    docImages: [],
    designationData: location.state ? location.state.designationData : [],
    errorPosition: "",
    errorText: "",
    loading: false,
  });
  const {
    name,
    email,
    password,
    phone,
    designationId,
    joinedAt,
    profileImage,
    docImagesType,
    docImages,
    designationData,
    errorPosition,
    errorText,
    loading,
  } = values;

  useEffect(() => {}, []);

  const AddEmployee = async () => {
    if (loading) return;
    var validated = await ValidateEmployees(
      name,
      email,
      phone,
      designationId,
      profileImage,
      docImages,
      setValues,
    );
    if (validated) {
      setValues((prev) => ({
        ...prev,
        loading: true,
        errorPosition: "",
        errorText: "",
      }));
      var formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("password", password);
      formData.append("designationId", designationId);
      formData.append("joinedAt", joinedAt);
      formData.append("profileImage", profileImage);
      formData.append("docImagesType", docImagesType);
      docImages.forEach((element) => {
        formData.append("docImages", element);
      });
      POSTAPI_formAdmin("/employee/create", formData).then((res) => {
        if (res.success) navigate("/hr/employees");
        else if (res.error)
          setValues((prev) => ({
            ...prev,
            first: false,
            loading: false,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    }
  };

  return (
    <>
      {TopBackComp("Add Employee", "/hr/employees")}

      <div className="add_single_item_body">
        <div className="d-flex justify-content-between">
          <div className="single_item_left">
            {LabelComp("Name", "name", errorPosition, errorText)}
            {InputComp(name, "name", setValues, "add_category_input_full")}

            {LabelComp("Email", "email", errorPosition, errorText)}
            {InputComp(email, "email", setValues, "add_category_input_full")}

            {LabelComp("Password", "Email", errorPosition, errorText)}
            {InputComp_Eye(
              password,
              "password",
              setValues,
              "add_category_input mb-2",
            )}

            {LabelComp("Contact Number", "phone", errorPosition, errorText)}
            {InputComp(phone, "phone", setValues, "add_category_input_full")}

            {LabelComp(
              "Designation",
              "designationId",
              errorPosition,
              errorText,
            )}
            {ReactSelectDrop(
              designationId,
              "designationId",
              setValues,
              designationData,
            )}

            {LabelComp("Joined at", "joinedAt", errorPosition, errorText)}
            {MobileDatePickerComp(joinedAt, "joinedAt", setValues)}
          </div>

          <div className="single_item_left">
            {LabelComp(
              "Profile Image",
              "profileImage",
              errorPosition,
              errorText,
            )}
            {SelectImage(profileImage, "profileImage", setValues)}

            <div className="mt-4" />
            {LabelComp("Documents", "docImages", errorPosition, errorText)}
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={docImagesType}
                onChange={(e) =>
                  setValues((val) => ({
                    ...val,
                    docImagesType: e.target.value,
                  }))
                }
              >
                <FormControlLabel
                  value="citizenship"
                  control={<Radio />}
                  label="Citizenship"
                />
                <FormControlLabel
                  value="license"
                  control={<Radio />}
                  label="License"
                />
              </RadioGroup>
            </FormControl>
            {/*  */}
            {SelectImageDouble(docImages, "docImages", setValues, [])}
          </div>
        </div>

        {HalfRightBtnComp("Add Employee", AddEmployee, loading)}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

export const EditEmployees = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (!location.state) navigate("/hr/employees");

  const [values, setValues] = useState({
    _id: location.state ? location.state._id : "",
    name: location.state ? location.state.name : "",
    email: location.state ? location.state.email : "",
    password: "",
    phone: location.state ? location.state.phone : "",
    designationId: location.state ? location.state.designationId : "",
    joinedAt: location.state ? new Date(location.state.joinedAt) : new Date(),
    profileImage: location.state ? location.state.image : null,
    docImagesType: location.state
      ? location.state.docImages.type
      : "citizenship",
    docImages: location.state ? location.state.docImages.images : [],
    removeIds: [],
    designationData: location.state ? location.state.designationData : [],
    errorPosition: "",
    errorText: "",
    loading: false,
    first: true,
  });
  const {
    name,
    email,
    password,
    phone,
    designationId,
    joinedAt,
    profileImage,
    docImagesType,
    docImages,
    removeIds,
    designationData,
    errorPosition,
    errorText,
    loading,
  } = values;

  useEffect(() => {}, []);

  const UpdateEmployee = async () => {
    if (loading) return;
    var validated = await ValidateEmployees(
      name,
      email,
      phone,
      designationId,
      profileImage,
      docImages,
      setValues,
    );
    if (validated) {
      setValues((prev) => ({
        ...prev,
        loading: true,
        errorPosition: "",
        errorText: "",
      }));
      var formData = new FormData();
      formData.append("employeeId", location.state._id);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      if (!isTextEmpty(password)) {
        formData.append("isPasswordUpdated", true);
        formData.append("password", password);
      }
      formData.append("designationId", designationId);
      formData.append("joinedAt", joinedAt);
      formData.append("removeUrls", JSON.stringify(removeIds));
      formData.append("profileImage", profileImage);
      formData.append("docImagesType", docImagesType);
      docImages.forEach((element) => {
        formData.append("docImages", element);
      });
      PUT_API_formAdmin("/employee/edit", formData).then((res) => {
        if (res.success) navigate("/hr/employees");
        else if (res.error)
          setValues((prev) => ({
            ...prev,
            first: false,
            loading: false,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    }
  };

  return (
    <>
      {TopBackComp("Update Employee", "/hr/employees")}

      <div className="add_single_item_body">
        <div className="d-flex justify-content-between">
          <div className="single_item_left">
            {LabelComp("Name", "name", errorPosition, errorText)}
            {InputComp(name, "name", setValues, "add_category_input_full")}

            {LabelComp("Email", "email", errorPosition, errorText)}
            {InputComp(email, "email", setValues, "add_category_input_full")}

            {LabelComp("Password", "Email", errorPosition, errorText)}
            {InputComp_Eye(
              password,
              "password",
              setValues,
              "add_category_input mb-2",
            )}

            {LabelComp("Contact Number", "phone", errorPosition, errorText)}
            {InputComp(phone, "phone", setValues, "add_category_input_full")}

            {LabelComp(
              "Designation",
              "designationId",
              errorPosition,
              errorText,
            )}
            {ReactSelectDrop(
              designationId,
              "designationId",
              setValues,
              designationData,
            )}

            {LabelComp("Joined at", "joinedAt", errorPosition, errorText)}
            {MobileDatePickerComp(joinedAt, "joinedAt", setValues)}
          </div>
          <div className="single_item_left">
            {LabelComp(
              "Profile Image",
              "profileImage",
              errorPosition,
              errorText,
            )}
            {SelectImage(profileImage, "profileImage", setValues)}
            <div className="mb-2" />

            {LabelComp("Documents", "docImages", errorPosition, errorText)}
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={docImagesType}
                onChange={(e) =>
                  setValues((val) => ({
                    ...val,
                    docImagesType: e.target.value,
                  }))
                }
              >
                <FormControlLabel
                  value="citizenship"
                  control={<Radio />}
                  label="Citizenship"
                />
                <FormControlLabel
                  value="license"
                  control={<Radio />}
                  label="License"
                />
              </RadioGroup>
            </FormControl>
            {SelectImageDouble(docImages, "docImages", setValues, removeIds)}
          </div>
        </div>

        {HalfRightBtnComp("Update Employee", UpdateEmployee, loading)}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};
