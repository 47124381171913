import { Button } from "@mui/material";

export const Pay = () => {
  // var path = "https://uat.esewa.com.np/epay/main";
  // var params = {
  //     amt: 100,
  //     psc: 0,
  //     pdc: 0,
  //     txAmt: 0,
  //     tAmt: 100,
  //     pid: "ee2c3ca1-696b-4cc5-a6be-2c40d929d453",
  //     scd: "EPAYTEST",
  //     su: "http://merchant.com.np/page/esewa_payment_success",
  //     fu: "http://merchant.com.np/page/esewa_payment_failed"
  // }

  function post(path, params) {
    var form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", path);

    for (var key in params) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();
  }

  return (
    <>
      <Button onClick={() => post()}>Go</Button>
    </>
  );
};
