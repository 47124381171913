import { Button, IconButton, Skeleton } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { CaretCircleLeft, CaretCircleRight } from "@phosphor-icons/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AlertError } from "../../../Components/Common";
import "../../../css/Market/alice.css";
import "./../a.css";
import { OpenAddToCartModal } from "./../AddToCartFn";

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#2E2E2E",
  display: "flex",
  flexDirection: "column",
  boxShadow: "none",
}));

export default function CarouselCombo({
  data,
  itemsArr,
  loading,
  setValuesFnHm,
}) {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    errorPosition: "",
    errorText: "",
  });
  const { errorPosition, errorText } = values;
  const [sliderRef, setSliderRef] = useState(null);
  const path = "/allCombos";

  const sliderSettings = {
    arrows: false,
    infinite: true,
    variableWidth: true,
    speed: 500,
    adaptiveHeight: false,
    autoplay: false,
  };

  const CarBody = (card, loadingVal) => {
    return (
      <>
        {loadingVal && (
          <Skeleton className="sc_combo_div_skel" variant="rectangular" />
        )}
        {!loadingVal && (
          <div
            className="sc_combo_div_slick"
            onClick={(e) => {
              const newCard = { ...card };
              newCard.isCombo = true;
              OpenAddToCartModal(e, newCard, setValuesFnHm);
            }}
          >
            <div
              className="hp_each_popular_image_combo mb-3"
              style={{
                backgroundImage: `url(${card.image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            ></div>
            <span className="c3 w3 s2">{card.name}</span>
            <p className="sc_cm_item_div mt-1">
              {card.items &&
                card.items.map((eachItem, ind) => (
                  <span key={ind} className="sc_cm_item">
                    {eachItem.name}
                    {eachItem.quantity > 1 && ` X${eachItem.quantity}`}
                  </span>
                ))}
            </p>
            <div className="d-flex mt-2 mt-sm-0 align-items-center">
              <span className="s5 c3 w3">Rs. {card.price}</span>
            </div>
          </div>
        )}
      </>
    );
  };

  const viewAllBtn = () => {
    path && navigate(path, { state: { data } });
  };

  return (
    <>
      <div className="hp_past_orders mt-4">
        {!loading && itemsArr?.length > 0 && (
          <div className="hp_each_title_div">
            <span className="w3 elec mr-auto">Combo Meals</span>
            <Button
              className="c9 w3 s1 text-transform-none"
              onClick={() => viewAllBtn()}
            >
              See all
            </Button>
            <IconButton onClick={sliderRef?.slickPrev}>
              <CaretCircleLeft />
            </IconButton>
            <IconButton onClick={sliderRef?.slickNext}>
              <CaretCircleRight />
            </IconButton>
          </div>
        )}
        {loading && <Skeleton width={"100%"} className="hp_each_title_div" />}
        <div className="content" id="as">
          <Slider ref={setSliderRef} {...sliderSettings}>
            {!loading &&
              itemsArr?.map((card, index) => (
                <div className="d-flex h-100" key={index}>
                  {CarBody(card, loading)}
                </div>
              ))}
            {loading &&
              [1, 2, 3, 4, 5].map((card, index) => (
                <div className="d-flex h-100" key={index}>
                  {CarBody(card, loading)}
                </div>
              ))}
          </Slider>
        </div>
      </div>
      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
}
