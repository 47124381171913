import {
  Avatar,
  Box,
  Button,
  createTheme,
  Grid,
  Paper,
  Popover,
  styled,
  ThemeProvider
} from "@mui/material";
import {
  ArrowDown,
  ArrowUp,
  CaretRight,
  Circle,
  Minus,
  Pizza,
  UsersThree
} from "@phosphor-icons/react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertError, CircularProgressComp } from "../../../Components/Common";
import "../../../css/Admin/admin_dashboard.css";
import { useGetDashboardQuery } from "../../../slices/apiSlice";
import { ad_dashFrom, ad_dashOrigin, ad_dashTo, setDashDate, setDashOrigin } from "../../../slices/novaSlice";
import PieChartComp, { pie_colors } from "./Piechart";
const { RangePicker } = DatePicker;

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#F2F2F2",
          },
        },
      },
    },
    MuiPopover:{
      styleOverrides:{
        paper:{
          boxShadow:'none',
          border:'1px solid #D9D9D9',
          borderRadius:'5px'
        }
      }
    }
  },
});

const CustomButton = styled(Button)({
  '&:hover': {
    backgroundColor: '#F2F2F2',
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  alignItems: "center",
  cursor: "pointer",
  borderRadius: "12px",
}));

export const rangePresets = [
  {
    label: "Today",
    value: [
      dayjs().subtract(1, "day").startOf("day").add(12, "hours"),
      dayjs().startOf("day").add(4, "hours"),
    ],
  },
  {
    label: "Yesterday",
    value: [
      dayjs().subtract(2, "day").startOf("day").add(12, "hours"),
      dayjs().subtract(1, "day").startOf("day").add(4, "hours"),
    ],
  },
  {
    label: "This Week",
    value: [dayjs().startOf("week").add(12, "hours"), dayjs()],
  },
  {
    label: "Last Week",
    value: [
      dayjs().subtract(1, "week").startOf("week").add(12, "hours"),
      dayjs()
        .subtract(1, "week")
        .endOf("week")
        .add(1, "day")
        .startOf("day")
        .add(4, "hours"),
    ],
  },
  {
    label: "This Month",
    value: [
      dayjs().startOf("month").add(12, "hours"),
      dayjs().endOf("month").add(1, "day").startOf("day").add(4, "hours"),
    ],
  },
  {
    label: "Last Month",
    value: [
      dayjs().subtract(1, "month").startOf("month").add(12, "hours"),
      dayjs()
        .subtract(1, "month")
        .endOf("month")
        .add(1, "day")
        .startOf("day")
        .add(4, "hours"),
    ],
  },
  {
    label: "Last 7 Days",
    value: [
      dayjs().subtract(7, "day").startOf("day").add(12, "hours"),
      dayjs().startOf("day").add(4, "hours"),
    ],
  },
  {
    label: "Last 30 Days",
    value: [
      dayjs().subtract(30, "day").startOf("day").add(12, "hours"),
      dayjs().startOf("day").add(4, "hours"),
    ],
  },
];

export const rangePresetsNew = [
  {
    label: "Today",
    value: [dayjs().startOf("day").add(12, "hours"), dayjs()],
  },
  {
    label: "Yesterday",
    value: [
      dayjs().subtract(1, "day").startOf("day").add(12, "hours"),
      dayjs().startOf("day").add(4, "hours"),
    ],
  },
  {
    label: "This Week",
    value: [dayjs().startOf("week").add(12, "hours"), dayjs()],
  },
  {
    label: "Last Week",
    value: [
      dayjs().subtract(1, "week").startOf("week").add(12, "hours"),
      dayjs()
        .subtract(1, "week")
        .endOf("week")
        .add(1, "day")
        .startOf("day")
        .add(4, "hours"),
    ],
  },
  {
    label: "This Month",
    value: [
      dayjs().startOf("month").add(12, "hours"),
      dayjs().endOf("month").add(1, "day").startOf("day").add(4, "hours"),
    ],
  },
  {
    label: "Last Month",
    value: [
      dayjs().subtract(1, "month").startOf("month").add(12, "hours"),
      dayjs()
        .subtract(1, "month")
        .endOf("month")
        .add(1, "day")
        .startOf("day")
        .add(4, "hours"),
    ],
  },
  {
    label: "Last 7 Days",
    value: [
      dayjs().subtract(7, "day").startOf("day").add(12, "hours"),
      dayjs().startOf("day").add(4, "hours"),
    ],
  },
  {
    label: "Last 30 Days",
    value: [
      dayjs().subtract(30, "day").startOf("day").add(12, "hours"),
      dayjs().startOf("day").add(4, "hours"),
    ],
  },
];

const origins_data = [
  {
    _id : 'all',
    name : 'All'
  },
  {
    _id : 'admin,website,app',
    name : 'Online Order'
  },
  {
    _id : 'day_sales',
    name : 'Day Sales'
  },
  {
    _id : 'night_sales',
    name : 'Night Sales'
  },
]

export const AdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    from: useSelector(ad_dashFrom),
    to: useSelector(ad_dashTo),
    activePie : 0,
    errorPosition: "",
    errorText: "",
  });
  const { from, to, activePie, errorPosition, errorText } = values;
  const origin = useSelector(ad_dashOrigin)
  const query = `from=${new Date(from).toISOString()}&to=${new Date(to).toISOString()}&origin=${origin}`;
  const { isLoading, data } = useGetDashboardQuery(query,{
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const dateFormat = "MMMM D, YYYY h:mm A";
  const onChange = (date) => {
    setValues((x) => ({
      ...x,
      from: date[0],
      to: date[1],
      first: true,
      loading: true,
    }));
    dispatch(
      setDashDate({
        dashFrom: date[0],
        dashTo: date[1],
      }),
    );
  };

  const dateNow = new Date().getHours();

  useEffect(() => {}, [data]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverIndex, setPopoverIndex] = useState(null);
  const open = Boolean(anchorEl);
  const handlePopoverOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setPopoverIndex(index);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverIndex(null);
  };

  const customTooltip = () => {
    const tooltipData = data?.pieChartData?.[activePie] ?? []
    if(!tooltipData) return
    return(
    <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: '6px 8px' }}>
          {popoverIndex !== null && 
            <div style={{
              display:'flex',
              gap:'8px'
            }}>
              <div className="d-flex flex-column gap-1">
                <div className="d-flex align-items-center gap-1">
                  <Circle weight="fill" size={10} color="#16B857"/>
                  <span className="s-8 c-secondary w2">COMPLETE ORDERS</span>
                </div>
                <div className="d-flex flex-column s-10 gap-1">
                  <span className="w3">{tooltipData.completedNumberOfOrders} orders</span>
                  <span>Rs. {tooltipData.completedAmount}</span>
                </div>
              </div>
              <div className="d-flex flex-column gap-1">
                <div className="d-flex align-items-center gap-1">
                  <Circle weight="fill" size={10} color="#D81E04"/>
                  <span className="s-8 c-secondary w2">FAILED ORDERS</span>
                </div>
                <div className="d-flex flex-column s-10 gap-1">
                  <span className="w3">{tooltipData.failedNumberOfOrders} orders</span>
                  <span>Rs. {tooltipData.failedAmount}</span>
                </div>
              </div>
            </div>}
        </Box>
      </Popover>
  )
}

  if (isLoading)
    return (
      <div className="d-flex justify-content-center">
        <CircularProgressComp />
      </div>
    );
  return (
    <div className="d-flex flex-column" style={{ gap: "24px", position:'relative' }}>
     <div className="d-flex justify-content-between">
      <select
            name="origin"
            id="origin"
            className="select"
            value={origin}
            onChange={(e) =>
              dispatch(setDashOrigin({origin : e.target.value}))
            }
          >
            <option className="select_option" disabled value="">
              Select an option
            </option>

            {origins_data.map(({ _id, name }) => {
              return (
                <option key={_id} value={_id} className="select_option">
                  {name}
                </option>
              );
            })}
      </select>
      <Space direction="vertical" size={12} className="ml-auto">
        <RangePicker
          presets={dateNow >= 12 ? rangePresetsNew : rangePresets}
          showTime
          format={"YYYY-MM-DD HH:mm"}
          style={{ width: "330px" }}
          className="ml-auto s1"
          size="large"
          value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
          onChange={onChange}
        />
      </Space>
      </div>
      <Grid container spacing={2}>
        {data?.stats?.map((each, i) => (
          <Grid key={"ad_dash_header_" + i} item xs={12} sm={12} md={4} lg={3}>
            <Item className="d-flex p-3">
              <div className="ad_dash_top_value_div">
                <span className="s-10 c-secondary text-uppercase w3">
                  {each.title}
                </span>
                <span className="s3 c3">
                  {each.amount}
                </span>
                <div className="d-flex gap-2 align-items-center">
                  {renderIncDec(each.type)}
                  {each.percent} %
                </div>
              </div>
            </Item>
          </Grid>
        ))}
      </Grid>
      <ThemeProvider theme={theme}>
        <div className="d-flex" style={{ gap: "24px" }}>
          <div
          style={{ height: "fit-content", paddingBottom:16, width: "410px", backgroundColor:'#fff' }}>
            <div style={{padding:'14px 16px'}} className="w3">Sales Distribution</div>
            <div style={{ height:'210px' }}>
              <PieChartComp active={activePie} data={data?.pieChartData ?? []}/>
            </div>
            <div className="d-flex flex-column" style={{paddingInline:'16px'}}>
              {
                data?.pieChartData?.map((each,i)=>(
                  <CustomButton
                  onMouseEnter={e=> {
                      setValues(x=>({...x, activePie : i}))
                      handlePopoverOpen(e, i)
                    }}
                  onMouseLeave={handlePopoverClose}
                   className="s-12 c-pri d-flex" 
                   style={{fontFamily:'Outfit', padding:'8px', justifyContent:'flex-start', textTransform:'none'}}>
                    <div className="d-flex align-items-center gap-1">
                      <Circle weight="fill" color={pie_colors.find(x=>x.name===each.name).fill ?? '#fff'}/> 
                      <span>{each.name}</span>
                    </div>
                    <div className="d-flex flex-column align-items-start c-pri ml-auto mr-2 w-25">
                      <span className="s-12 w3">{data?.pieChartData?.[i]?.numberOfOrders ?? ''} orders</span>
                      <span className="s-12">Rs. {data?.pieChartData?.[i]?.amount ?? ''}</span>
                    </div>
                    <CaretRight />
                    {customTooltip()}
                  </CustomButton>
                ))
              }
            </div>
          </div>
          <div style={{ height: "570px", width: "330px" }}>
            <Button
              className="c3 w3 w-100 justify-content-between p-3 bg-white s1"
              style={{textTransform:'none'}}
              onClick={(e) => navigate("items")}
            >
              <span>Top Selling Items</span>
              <CaretRight className="c1" size={20} />
            </Button>
            <div
              className="d-flex justify-content-between s-12 w3"
              style={{
                gap: "16px",
                padding: "8px 16px",
                backgroundColor: "#F2F2F2",
              }}
            >
              <div className="d-flex" style={{ gap: 16 }}>
                <span className="s1 c5"># 1</span>
                <span className="ml-1">Items</span>
              </div>
              <span>Qty Sold</span>
            </div>
            <div
              className="d-flex flex-column bg-white"
              style={{ minHeight: "296px" }}
            >
              {data?.top5Items?.map((each, i) => (
                <div
                  key={"top5Items_" + i}
                  className="d-flex justify-content-between"
                  style={{ gap: "16px", padding: "8px 16px" }}
                >
                  <div className="d-flex" style={{ gap: 16 }}>
                    <span># {i + 1}</span>
                    <div className="d-flex" style={{ gap: "8px" }}>
                      <Avatar
                        src={each.image}
                        style={{ width: 32, height: 32 }}
                      />
                      <div className="d-flex flex-column">
                        <span className="s1 w3">{each.name}</span>
                        <span className="s-12">Rs. {each.price}</span>
                      </div>
                    </div>
                  </div>
                  <span>
                    {each.soldUnits} unit{each.soldUnits > 1 && "s"}
                  </span>
                </div>
              ))}
              {data?.top5Items?.length === 0 && (
                <Item className="d-flex p-5 s-12 c-secondary flex-column gap-2">
                  <Pizza size={25} />
                  <span>No items yet</span>
                </Item>
              )}
            </div>
          </div>
          <div style={{ height: "570px", width: "330px" }}>
            <Button
              className="c3 w3 w-100 justify-content-between p-3 bg-white s1"
              style={{textTransform:'none'}}
              onClick={(e) => navigate("cus")}
            >
              <span>Top Customers</span>
              <CaretRight className="c1" size={20} />
            </Button>
            <div
              className="d-flex justify-content-between s-12 w3"
              style={{
                gap: "16px",
                padding: "8px 16px",
                backgroundColor: "#F2F2F2",
              }}
            >
              <div className="d-flex" style={{ gap: 16 }}>
                <span className="s1 c5"># 1</span>
                <span className="ml-1">Customer</span>
              </div>
              <span>Times Ordered</span>
            </div>
            <div
              className="d-flex flex-column bg-white"
              style={{ minHeight: "296px" }}
            >
              {data?.top5Customers?.map((each, i) => (
                <div
                  key={"top5Items_" + i}
                  className="d-flex justify-content-between bg-white"
                  style={{ gap: "16px", padding: "8px 16px" }}
                >
                  <div className="d-flex" style={{ gap: 16 }}>
                    <span># {i + 1}</span>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "8px" }}
                    >
                      <Avatar
                        src={each.image}
                        style={{ width: 32, height: 32 }}
                      />
                      <div className="d-flex flex-column">
                        <div className="s1 w3">
                          {each.name}
                          <span className="c4">.</span>{" "}
                        </div>
                        <span className="s-12 c-secondary">{each.phone}</span>
                      </div>
                    </div>
                  </div>
                  <span>{each.orderedCount} times</span>
                </div>
              ))}
              {data?.top5Customers?.length === 0 && (
                <Item className="d-flex p-5 s-12 c-secondary flex-column gap-2">
                  <UsersThree size={25} />
                  <span>No customers yet</span>
                </Item>
              )}
            </div>
          </div>
        </div>
      </ThemeProvider>
      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const renderIncDec = (type) => {
  let icon = <Minus size={14} color="#9D9CA3" />;
  let bg = "#F2F2F2";
  switch (type) {
    case "increase":
      icon = <ArrowUp size={14} color="#16B857" />;
      bg = "#16B85740";
      break;
    case "decrease":
      icon = <ArrowDown size={14} color="#D81E04" />;
      bg = "#D81E0440";
      break;
    default:
      break;
  }
  return (
    <div
      className={`rounded-circle d-flex justify-content-center align-items-center`}
      style={{ width: 16, height: 16, backgroundColor: bg }}
    >
      {icon}
    </div>
  );
};
