import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Drawer,
  IconButton,
  ThemeProvider,
  Toolbar,
  createTheme,
} from "@mui/material";
import {
  Check,
  Power,
  ShoppingBag,
  Tag,
  UserCircle,
  X,
} from "@phosphor-icons/react";
import * as Sentry from "@sentry/react";
import { Checkbox } from "antd";
import React, { Fragment, Suspense, lazy, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { API } from "../../Auth/Config";
import {
  AuthenticateNova,
  Logout,
  POSTAPI_USER,
  isAuthenticated,
} from "../../Auth/Index";
import { AlertError, formatDateTimeNext } from "../../Components/Common";
import { isTextEmpty } from "../../Components/Validate_Admin";
import { getS3Image } from "../../Components/s3";
import { ENTITY_TYPE } from "../../Components/staticData";
import "../../css/Market/landingPage.css";
import "../../css/Market/layout.css";
import "../../css/fonts.css";
import { useGetHomeQuery, useGetPopupImagesQuery } from "../../slices/apiSlice";
import {
  AddToCartModal,
  LoginModalView,
  OpenAddToCartModal,
} from "./AddToCartFn";
import { Footer } from "./Footer";
import { SidenavCart } from "./SidenavCart";
import { NovaPopup } from "./Layout/Popup";
const Context = React.createContext();
const CheckboxGroup = Checkbox.Group;

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "none !important",
      fontFamily: "Poppins",
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "#FFF",
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 770,
      lg: 1200,
    },
  },
});

export const FallbackComponent = () => {
  return <div></div>;
};

const LazyAppbarBody = lazy(() => import("./Layout/Appbar"));

const MarketLayout = (props) => {
  const { isError, error, isSuccess, data: res } = useGetHomeQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [values, setValues] = useState({
    userId: isAuthenticated()?.userId,
    locationValue: location.state
      ? location.state.location
      : isAuthenticated()
        ? isAuthenticated()?.locationVal
        : "",
    locationOptions: [],
    itemValue: "",
    itemsOptions: [
      { value: "chocolate", label: "Balaju" },
      { value: "strawberry", label: "Strawberry" },
      { value: "vanilla", label: "Vanilla" },
    ],
    searchText: "",
    searchOptions: [],
    searchOptionsVal: [],
    isSearchLoading : false,
    first: true,
    changeLocation_loading: false,
    locModal: false,
    errorPosition: "",
    errorText: "",
    firstLoading: true,
    //for search
    itemModalVisible: false,
    modalItem: null,
    modifiersSel: [],
    data: {},
    searchIconMobile: false,
    mobileDrawerOpen: false,
    openSearch: false,
    socket: null,
    socketFirst: true,
    unreadCount: null,
    orderStatusChange: null,
    notifications: [],
    swipeableEdgeDrawerIndex: 0,
    cartLoading: true,
    searchSortLoad: false, //for search filter page,
    loginModal: false,
    send_data_after_login: null,
    loginFromCart: false,
    orderStatus: [],
  });
  const {
    userId,
    locationValue,
    locationOptions,
    locModal,
    changeLocation_loading,
    searchText,
    searchOptions,
    searchOptionsVal,
    isSearchLoading,
    // first,
    errorPosition,
    errorText,
    firstLoading,
    itemModalVisible,
    modalItem,
    modifiersSel,
    data,
    searchIconMobile,
    mobileDrawerOpen,
    openSearch,
    socket,
    socketFirst,
    unreadCount,
    orderStatusChange,
    orderStatus,
    notifications,
    swipeableEdgeDrawerIndex,
    cartLoading,
    searchSortLoad,
    loginModal,
    send_data_after_login,
    loginFromCart,
  } = values;
  // const [popup, setPopup] = useState(true)
  const [openCartSide, setopenCartSide] = useState(false);
  const [cartUpdatedForMycart, setCartUpdatedForMycart] = useState(false);
  const [cart, setCart] = useState([]);
  // notification menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElAcc, setAnchorElAcc] = React.useState(null);
  const businessHours = res?.businessHours ?? null;
  const isKitchenBusy = res?.isKitchenBusy ?? null;
  const [hideBusinessHours, setHideBusinessHours] = React.useState(false);
  const [hideisKitchenBusy, sethideisKitchenBusy] = React.useState(false);
  const [numberOfItems, setnumberOfItems] = React.useState(
    res?.numberOfItems ?? 0,
  );
  const open = Boolean(anchorEl);
  const openAcc = Boolean(anchorElAcc);
  const { data : popupImagesData } = useGetPopupImagesQuery()
  // const [respData, setRespData] = useState(null);
  const respData = res;
  // read notification
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    POSTAPI_USER("/notification/read", { userId }).then((res) => {
      setValues((x) => ({
        ...x,
        notifications: res.notifications,
        unreadCount: 0,
      }));
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAcc = () => {
    setAnchorElAcc(null);
  };
  // API
  useEffect(() => {
    if (firstLoading && res && isSuccess) {
      setValues((x) => ({ ...x, firstLoading: false, data: res }));
    }
    if (isError) {
      setValues((prev) => ({
        ...prev,
        // first: false,
        errorPosition: "apiErr",
        errorText: error?.data?.error,
      }));
    }
    if (!isTextEmpty(searchText)) setValues((x) => ({ ...x, open: true }));
    else setValues((x) => ({ ...x, open: false }));
  }, [firstLoading, searchText, res, isError, isSuccess, error]);
  // socket
  useEffect(() => {
    if (!socket && userId && socketFirst) {
      var thisSocket = io(API, {
        reconnection: true,
        reconnectionAttempts: Infinity,
      });
      setValues((x) => ({
        ...x,
        socket: thisSocket,
        socketFirst: false,
      }));
      thisSocket.emit("onConnection", {
        userId,
      });
      thisSocket.on("onConnectionResponse", (res) => {});
      POSTAPI_USER("/notification/user", { userId }).then((res) => {
        setValues((x) => ({ ...x, unreadCount: res.unReadCount }));
      });
    }
    if (socket) {
      socket.on("notification", (res) => {
        setValues((x) => ({ ...x, unreadCount: res.unReadCount }));
      });
      socket.on("orderStatusChange", (res) => {
        setValues((x) => ({ ...x, orderStatusChange: res }));
      });
      socket?.emit("currentOrders", { userId });
      socket?.on("currentOrdersResponse", (message) => {
        setValues((x) => ({ ...x, orderStatus: message }));
      });
    }
  }, [userId, socket, socketFirst, firstLoading]);
  const openCartBtnCLicked = () => {
    setopenCartSide(true);
    POSTAPI_USER("/cart/one", { userId }).then((cartVal) => {
      if (cartVal.success) {
        setCart(cartVal.cart);
        // data.numberOfItems = cartVal.numberOfItems;
        setValues((x) => ({ ...x, cartLoading: false, data: data }));
        setnumberOfItems(cartVal.cart.numberOfItems);
      } else if (cartVal.error) {
        setValues((x) => ({ ...x, cartLoading: false }));
      }
    });
  };
  const updateUserId = (newUserId) => {
    setValues((prev) => ({ ...prev, userId: newUserId }));
  };

  const closeModal = () => {
    setValues((x) => ({ ...x, locModal: false }));
  };

  const changeLocationFn = () => {
    if (!isTextEmpty(locationValue)) {
      var novaData = isAuthenticated();
      if (!novaData) novaData = {};
      novaData.locationVal = locationValue;
      AuthenticateNova(novaData);
      setValues((x) => ({ ...x, locModal: false }));
    }
  };

  const renderTitle = (title) => (
    <div className="d-flex w-100 justify-content-between mt-2">
      <span className="w3 s2 c3">{title}</span>
    </div>
  );

  const searchItemClicked = (e, element) => {
    setValues((x) => ({ ...x, openSearch: false }));
    OpenAddToCartModal(e, element, setValues);
  };

  const renderItem = (element) => ({
    name: element.name,
    value: element.name,
    label: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={(e) => searchItemClicked(e, element)}
      >
            <>
              {!isNaN(element.numberOfItems) || !isNaN(element.itemsCount) ? (
                <div className="ml-0 s2 pt-1 pb-1">
                  <span> {element.name}</span>
                  {!isNaN(element.numberOfItems) && (
                    <span className="ml-1">
                      &#40;{element.numberOfItems}&#41;
                    </span>
                  )}
                  {!isNaN(element.itemsCount) && (
                    <span className="ml-1">&#40;{element.itemsCount}&#41;</span>
                  )}
                </div>
              ) : (
                <>
                  <Avatar src={element.image} />
                  <span className="ml-2 s2">{element.name}</span>
                </>
              )}
            </>
      </div>
    ),
  });

  const searchFn = (e) => {
    var val = isTextEmpty(searchText) ? e.target.value.trim() : e.target.value;
    if (isTextEmpty(val)) {
      setValues((x) => ({ ...x, searchText: "", openSearch: false }));
      return;
    }
    setValues((x) => ({ ...x, searchText: val, openSearch: true, isSearchLoading : true }));
    POSTAPI_USER("/homepage/search", { queryText: val }).then((resp) => {
      if (resp.success) {
        var options = [];
        var thisOp = [];
        if (resp.itemsAndCombos.length > 0) {
          thisOp = [];
          resp.itemsAndCombos.forEach((element, i) => {
            if (i < 10) thisOp.push(renderItem(element));
          });
          options.push({
            label: renderTitle("Items and Combos"),
            options: thisOp,
          });
        }
        setValues((x) => ({
          ...x,
          searchOptions: options,
          isSearchLoading : false
        }));
      }
      else {
        setValues(x=>({...x, isSearchLoading : false}))
      }
    });
  };

  const searchIconMobileFn = () => {
    setValues((x) => ({ ...x, searchIconMobile: true }));
  };

  // notiification clicked
  const handleMenuItem = (item, type) => {
    switch (type) {
      case "track":
        handleClose();
        navigate(`/order/${item.entityId}`);
        break;
      default:
        break;
    }
  };

  const NotificationBody = (option) => {
    switch (option.entity) {
      case ENTITY_TYPE.ORDER:
        return (
          <div
            className="d-flex"
            onClick={(e) => {
              e.stopPropagation();
              handleMenuItem(option, "track");
            }}
          >
            <div style={{ width: "40px" }}>
              <ShoppingBag size={32} color="#8943EF" />
            </div>
            <div
              className="d-flex flex-column ml-2"
              style={{
                width: "calc(100% - 50px)",
                whiteSpace: "initial",
              }}
            >
              {option.body === "Our rider has picked up your order." ? (
                <span className="s2 hover_link">{option.body}</span>
              ) : (
                <span className="ad_notif_break">{option.body}</span>
              )}
              <span className="c7 mt-2">
                {formatDateTimeNext(option.createdAt)}
              </span>
            </div>
          </div>
        );
      case ENTITY_TYPE.OFFER:
        return (
          <div
            className="d-flex"
            onClick={() => {
              navigate("/allOffers", {
                state: {
                  step: 1,
                },
              });
              handleClose();
            }}
          >
            <div style={{ width: "40px" }}>
              <ShoppingBag size={32} color="#8943EF" />
            </div>
            <div
              className="d-flex flex-column ml-2"
              style={{
                width: "calc(100% - 50px)",
                whiteSpace: "initial",
              }}
            >
              <span>{option.body}</span>
              {option.image && (
                <img className="mt-2" src={option.image} alt="" />
              )}
              <span className="c7 mt-2">
                {formatDateTimeNext(option.createdAt)}
              </span>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  var mobileList = [
    // { name: 'Location', icon: <MapPin className='#8943EF' size={25} /> },
    {
      name: "Offers",
      path: "/allOffers",
      icon: <Tag className="#8943EF" size={25} />,
    },
    {
      name: "My Account",
      path: "/myAccount",
      icon: <UserCircle className="#8943EF" size={25} />,
    },
  ];

  const handlesetopenCartSide = () => {
    setopenCartSide(false);
    setCartUpdatedForMycart(false);
  };

  const handleMobileDrawerClick = (i) => {
    setValues((x) => ({ ...x, mobileDrawerOpen: false }));
    switch (i) {
      case 0:
        navigate("/allOffers", {
          state: {
            data: data,
          },
        });
        break;
      case 1:
        userId
          ? navigate("/myAccount")
          : setValues((val) => ({
              ...val,
              loginModal: true,
              send_data_after_login: send_data_after_login,
            }));
        break;
      default:
        break;
    }
  };

  const getMobileDrawerClass = (each) => {
    if (location.pathname === each.path) return "md_body_btn_sel";
    else return "md_body_btn";
  };

  const MobileDrawer = () => (
    <div>
      <Fragment key="left">
        <Drawer
          sx={{
            // display: { xs: 'block', sm: 'none' }, //for mobile
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: "300px" },
          }}
          anchor="left"
          open={mobileDrawerOpen}
          onClose={() => setValues((x) => ({ ...x, mobileDrawerOpen: false }))}
        >
          <img
            src={getS3Image("Logo.svg")}
            alt=""
            className="mobile_drawer_logo cursor_pointer"
          />
          <div className="md_body">
            {mobileList.map((each, i) => (
              <Button
                key={i}
                className={"s1 w3 " + getMobileDrawerClass(each)}
                onClick={() => handleMobileDrawerClick(i)}
              >
                {each.icon}
                <span className="ml-2">{each.name}</span>
              </Button>
            ))}
          </div>
          {userId && (
            <Button
              className={"s1 w3 md_body_btn mt-auto mb-5"}
              onClick={() => {
                Logout();
                navigate("/");
                setValues((x) => ({
                  ...x,
                  errorPosition: "success",
                  errorText: "Logged out",
                  userId: null,
                  mobileDrawerOpen: false,
                  data: { ...x.data, pastOrders: [] },
                }));
                setnumberOfItems(0);
                updateUserId(null);
                handleCloseAcc();
              }}
            >
              <Power className="#8943EF" size={25} />
              <span className="ml-2">Log Out</span>
            </Button>
          )}
        </Drawer>
      </Fragment>
    </div>
  );

  return (
    <div id="market_layout_main" className="market_layout_main">
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div id="scroll_here_id" />
          <Suspense fallback={<FallbackComponent />}>
            <LazyAppbarBody
              location={location}
              searchIconMobile={searchIconMobile}
              mobileDrawerOpen={mobileDrawerOpen}
              setValues={setValues}
              searchText={searchText}
              searchOptions={searchOptions}
              setOpenFilterDrawer={setOpenFilterDrawer}
              numberOfItems={numberOfItems}
              notifications={notifications}
              NotificationBody={NotificationBody}
              openCartBtnCLicked={openCartBtnCLicked}
              userId={userId}
              handleClose={handleClose}
              open={open}
              anchorEl={anchorEl}
              unreadCount={unreadCount}
              handleClick={handleClick}
              navigate={navigate}
              handleCloseAcc={handleCloseAcc}
              updateUserId={updateUserId}
              setnumberOfItems={setnumberOfItems}
              openAcc={openAcc}
              setAnchorElAcc={setAnchorElAcc}
              anchorElAcc={anchorElAcc}
              data={data}
              searchFn={searchFn}
              openSearch={openSearch}
              searchIconMobileFn={searchIconMobileFn}
              locationOptions={locationOptions}
              locationValue={locationValue}
              changeLocationFn={changeLocationFn}
              changeLocation_loading={changeLocation_loading}
              openFilterDrawer={openFilterDrawer}
              locModal={locModal}
              closeModal={closeModal}
              isSearchLoading={isSearchLoading}
            />
          </Suspense>
          <Toolbar className="ml_toolbar" />
          {InfoComponent(
            hideisKitchenBusy,
            isKitchenBusy,
            sethideisKitchenBusy,
            businessHours,
            firstLoading,
            hideBusinessHours,
            setHideBusinessHours,
          )}
          <div
            id="market_width_main"
            className={
              location.pathname === "/whoweare"
                ? "market_width_main_whoweare"
                : "market_width_main"
            }
          >
            <div
              className={
                location.pathname === "/whoweare"
                  ? "market_width_main_whoweare"
                  : "w-100 pt-4"
              }
            >
              {MobileDrawer()}
              <SidenavCart
                open={openCartSide}
                close={() => handlesetopenCartSide()}
                cart={cart}
                setCart={setCart}
                cartLoading={cartLoading}
                setValues={setValues}
                setNumberOfItemsFn={setnumberOfItems}
                setCartUpdatedForMycart={setCartUpdatedForMycart}
                businessHours={businessHours}
              />
              <Context.Provider value={{ locationValue: "aashsj" }}>
                {
                  <Outlet
                    context={[
                      updateUserId,
                      locationValue,
                      openFilterDrawer,
                      setOpenFilterDrawer,
                      searchOptionsVal,
                      setValues,
                      setnumberOfItems,
                      cartUpdatedForMycart,
                      setCartUpdatedForMycart,
                      respData,
                      searchSortLoad,
                      businessHours,
                      orderStatusChange,
                      orderStatus,
                    ]}
                  />
                }
              </Context.Provider>
            </div>
          </div>

          {/* for search result  */}
          {AddToCartModal(
            itemModalVisible,
            modalItem,
            modifiersSel,
            setValues,
            true
          )}

          {AlertError(errorPosition, errorText, setValues)}
        </ThemeProvider>
        <Footer />
        {SwipeableEdgeDrawer(
          openFilterDrawer,
          setOpenFilterDrawer,
          data,
          searchText,
          setValues,
          swipeableEdgeDrawerIndex,
        )}

        {/* Login Register */}
        <LoginModalView
          OutletContext={[
            updateUserId,
            locationValue,
            openFilterDrawer,
            setOpenFilterDrawer,
            searchOptionsVal,
            setValues,
            setnumberOfItems,
            cartUpdatedForMycart,
            setCartUpdatedForMycart,
            respData,
            searchSortLoad,
            loginModal,
          ]}
          loginModal={loginModal}
          send_data_after_login={send_data_after_login}
          fromCart={loginFromCart}
          setFn={setValues}
        />

        {/* popup */}
        <NovaPopup orderStatus={orderStatus} image = {popupImagesData?.webPopupImages?.[0] ?? null} />
      </Sentry.ErrorBoundary>
    </div>
  );
};

export default MarketLayout;

const SwipeableEdgeDrawer = (
  openFilterDrawer,
  setOpenFilterDrawer,
  data,
  searchText,
  setValuesFn,
  swipeableEdgeDrawerIndex,
) => {
  const [values, setValues] = useState({
    indeterminate_Offer: false,
    minPrice: "",
    maxPrice: "",
    sortValue: "Default",
    allOffersOptions: data.offers ? data.offers.map((x) => x.name) : [],
    checkAll_Offer: false,
    checkedList_Offer: [],
  });
  const {
    indeterminate_Offer,
    minPrice,
    maxPrice,
    sortValue,
    allOffersOptions,
    checkAll_Offer,
    checkedList_Offer,
  } = values;

  useEffect(() => {
    setValues((x) => ({
      ...x,
      indeterminate_Offer: false,
      minPrice: "",
      maxPrice: "",
      sortValue: "Default",
      allOffersOptions: data.offers ? data.offers.map((x) => x.name) : [],
      checkAll_Offer: false,
      checkedList_Offer: [],
    }));
  }, [data]);

  const priceChanged = async (min, max) => {
    if ((isNaN(min) && min !== "") || (isNaN(max) && max !== "")) {
      return;
    }
    setValues((x) => ({
      ...x,
      minPrice: min.trim(),
      maxPrice: max.trim(),
    }));
  };

  const onCheckAllChange = async (e, type, options, all, indeterminate_val) => {
    setValues((x) => ({
      ...x,
      [type]: e.target.checked ? options : [],
      [indeterminate_val]: false,
      [all]: e.target.checked,
    }));
  };

  // checklist of offers
  const onChange = async (list, type, options, all, indeterminate_val) => {
    setValues((x) => ({
      ...x,
      [type]: list,
      [indeterminate_val]: !!list.length && list.length < options.length,
      [all]: list.length === options.length,
    }));
  };

  const sortChanged = async (val) => {
    setValues((x) => ({ ...x, sortValue: val }));
  };

  // filter closed, so handle data
  const handleFilter = async () => {
    // if (location.pathname !== '/search') { //go to search page with the queries
    //     var send_data = {
    //         queryText: searchText,
    //         offerIds: checkedList_Offer,
    //         minPrice: isNaN(parseInt(minPrice)) ? null : parseInt(minPrice),
    //         maxPrice: isNaN(parseInt(maxPrice)) ? null : parseInt(maxPrice),
    //         requestSortQuery: sortValue
    //     }
    //     return
    // }
    // if in search page already
    setOpenFilterDrawer(false);
    var send_data = {
      queryText: searchText,
      offerIds: checkedList_Offer,
      minPrice: isNaN(parseInt(minPrice)) ? null : parseInt(minPrice),
      maxPrice: isNaN(parseInt(maxPrice)) ? null : parseInt(maxPrice),
      requestSortQuery: sortValue,
    };
    var send = await getIdsFromNameArray(send_data);
    setValuesFn((x) => ({ ...x, searchSortLoad: true }));
    POSTAPI_USER("/homepage/search", send).then((resp) => {
      if (resp.success) {
        setValuesFn((x) => ({
          ...x,
          searchOptionsVal: resp,
          searchSortLoad: false,
        }));
      } else if (resp.error)
        setValuesFn((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: resp.error,
          searchSortLoad: false,
        }));
    });
  };

  const getIdsFromNameArray = (send_data) => {
    var offerArr = [];
    send_data.offerIds.forEach((element) => {
      var found = data.offers.find((x) => x.name === element);
      if (found) {
        offerArr.push(found._id);
      }
    });
    send_data.offerIds = offerArr;
    return send_data;
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={openFilterDrawer}
      onClose={() => handleFilter()}
    >
      <Box className="d-flex justify-content-between p-3 pb-0">
        <span className="w3 s5">Filter</span>
        <IconButton onClick={() => handleFilter()}>
          <X size={20} />
        </IconButton>
      </Box>
      <Box className="p-3">
        {/* offer */}
        <div className="mb-3">
          <Checkbox
            indeterminate={indeterminate_Offer}
            onChange={(e) =>
              onCheckAllChange(
                e,
                "checkedList_Offer",
                allOffersOptions,
                "checkAll_Offer",
                indeterminate_Offer,
              )
            }
            checked={checkAll_Offer}
          >
            <span className="cb_main">Offers</span>
          </Checkbox>
          <CheckboxGroup
            options={allOffersOptions}
            value={checkedList_Offer}
            onChange={(list) =>
              onChange(
                list,
                "checkedList_Offer",
                allOffersOptions,
                "checkAll_Offer",
                indeterminate_Offer,
              )
            }
          />
        </div>

        {/* price */}
        {swipeableEdgeDrawerIndex === 0 && (
          <div style={{ width: "fit-content" }}>
            <span className="cb_main">Price (Rs.)</span>
            <div className="d-flex justify-content-between mt-2">
              <input
                type="text"
                className="fm_left_price"
                value={minPrice}
                name="minPrice"
                onChange={(e) => priceChanged(e.target.value, maxPrice)}
              />
              <span className="ml-3 mr-3">_</span>
              <input
                type="text"
                className="fm_left_price"
                value={maxPrice}
                name="maxPrice"
                onChange={(e) => priceChanged(minPrice, e.target.value)}
              />
            </div>
          </div>
        )}

        {/* sort */}
        {swipeableEdgeDrawerIndex === 0 && (
          <>
            <p className="w3 mt-3">Sort </p>
            <div className="d-flex flex-column">
              {[
                "Default",
                "High to Low",
                "Low to High",
                "Popularity",
                //  'Fastest Delivery'
              ].map((each, i) => (
                <Button
                  key={i}
                  className="sort_xs_btn_each"
                  onClick={() => sortChanged(each)}
                >
                  <span className={`${sortValue === each ? "c1 w3" : "c3 w1"}`}>
                    {each}
                  </span>
                  <span className="ml-auto">
                    {sortValue === each && <Check color="#8943EF" size={20} />}
                  </span>
                </Button>
              ))}
            </div>
          </>
        )}
      </Box>
    </Drawer>
  );
};

const InfoComponent = (
  hideisKitchenBusy,
  isKitchenBusy,
  sethideisKitchenBusy,
  businessHours,
  firstLoading,
  hideBusinessHours,
  setHideBusinessHours,
) => {
  if ((hideisKitchenBusy && hideBusinessHours) || firstLoading) return null;
  return (
    <>
      {!hideBusinessHours && !businessHours?.isOpen ? (
        <Toolbar className="kitchenBusyToolbar kitchenBusyToolbarBlack">
          <Toolbar
            className="market_width_toolbar_main kitchenBusyToolbar
             kitchenBusyToolbarBlack d-flex justify-content-between"
          >
            <span className="c5 mt-2">
              Restaurant is closed. Nova Delivers will reopen at{" "}
              {businessHours?.openingTime}
            </span>
            <IconButton onClick={() => setHideBusinessHours(true)}>
              <X color="#fff" />
            </IconButton>
          </Toolbar>
        </Toolbar>
      ) : (
        <>
          {!hideisKitchenBusy && isKitchenBusy && (
            <Toolbar className="kitchenBusyToolbar kitchenBusyToolbarRed">
              <Toolbar className="market_width_toolbar_main kitchenBusyToolbar kitchenBusyToolbarRed d-flex justify-content-between">
                <span className="c5">
                  We are currently overloaded with orders so there might be
                  slight increase in the delivery time. Thank you for
                  understanding - Nova
                </span>
                <IconButton onClick={() => sethideisKitchenBusy(true)}>
                  <X color="#fff" />
                </IconButton>
              </Toolbar>
            </Toolbar>
          )}
        </>
      )}
    </>
  );
};
