import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Menu as MUIMenu,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Slider as SliderMUI,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { DatePicker } from "antd";
import _ from "lodash";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
// import MUIDataTable from 'mui-datatables'
import {
  ArrowLeft,
  CalendarBlank,
  CaretDown,
  Copy,
  ForkKnife,
  MagnifyingGlass,
  Pen,
  Plus,
  SortAscending,
  X,
} from "@phosphor-icons/react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Select from "react-select";
import {
  DELETEAPI,
  GETAPI_OrderTypes,
  POSTAPI_ADMIN,
  POSTAPI_CreateInfoAdmin,
} from "../../Auth/Index";
import {
  AdOrderEditDeleteMenu,
  AlertError,
  CartQuantityAdmin,
  CartQuantityAdminArr,
  DataGridNova,
  HalfRightBtnComp,
  InputComp,
  InputCompTextarea,
  LabelComp,
  MUIMenuTwo,
  ReactSelectDrop,
  RedSwitch,
  TableEditDelete,
  copyUserDetails,
  formatDate,
  formatDateTimeNext,
  formatTime,
  getDeliveryLocationStatusIcon,
  getMuiTheme,
  getSpiceText,
  getSpiceValue,
  modalStyles,
  statusButtonClass,
} from "../../Components/Common";
import { OrderViewTable } from "../../Components/MuiTable";
import {
  ValidateLetAddItemBtn,
  isTextEmpty,
} from "../../Components/Validate_Admin";
import { ORDER_STATUS } from "../../Components/staticData";
import "../../css/Admin/orders.css";
// import { MapBox } from "../MapBox";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { searchOrdersById } from "./Search";
const { RangePicker } = DatePicker;

const textEllipsisStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const style = {
  position: "absolute",
  width: "400px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFFFFF",
  borderColor: "#FFF",
  boxShadow: 24,
  maxHeight: "95vh",
  overflowX: "hidden",
  overflowY: "auto",
  scrollbarWidth: "thin",
  borderRadius: "12px",
  padding: 3,
};

const modalTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          width: "50px",
          height: "50px",
          backgroundColor: "transparent",
          backgroundImage: `url("https://nova-foods.s3.amazonaws.com/miscellaneous/emojione_fire.svg")`,
          boxShadow: "none",
          transition: "none",
          border: "none",
          "::before": {
            boxShadow: "none",
            transition: "none",
          },
          ":hover": {
            boxShadow: "none",
            transition: "none",
          },
          "&.Mui-active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid #E9ECFF",
          borderRadius: "12px",
          backgroundColor: "#FFF",
          padding: "12px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0",
          minHeight: "0px",
          "&.Mui-expanded": {
            minHeight: "0px",
          },
        },
        content: {
          margin: "0px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
        expandIconWrapper: {
          color: "#2E2E2E",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
          color: "#818091",
        },
      },
    },
  },
});

const statusMenuArr = [
  "Queue",
  "Cooking",
  "Not Assigned",
  "Pending",
  "Picked Up",
  "Completed",
  "Canceled",
  "Failed",
];

const statusMenuArrOnline = [
  "Draft",
  "Queue",
  "Cooking",
  "Not Assigned",
  "Pending",
  "Picked Up",
  "Completed",
  "Canceled",
  "Failed",
];

export const Orders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dateFormat = "YYYY/MM/DD";
  const [values, setValues] = useState({
    orders: [],
    searchedOrders: [],
    riders: [],
    status: location.state?.onlineOrders?.status
      ? location.state?.onlineOrders?.status
      : [
          ORDER_STATUS.DRAFT,
          ORDER_STATUS.QUEUE,
          ORDER_STATUS.COOKING,
          ORDER_STATUS.NOT_ASSIGNED,
          ORDER_STATUS.PENDING,
          ORDER_STATUS.PICKED_UP,
          ORDER_STATUS.COMPLETED,
          ORDER_STATUS.CANCELED,
          ORDER_STATUS.FAILED,
        ],
    // status: [ORDER_STATUS.COOKING, ORDER_STATUS.NOT_ASSIGNED],
    from:
      location.state?.onlineOrders.fromDate ||
      moment().subtract(1, "days").toISOString(), //1 day ago
    to:
      location.state?.onlineOrders.toDate ||
      moment().endOf("day").toISOString(),
    first: true,
    loading: true,
    edAnchor: null,
    edClicked: null,
    errorPosition: "",
    errorText: "",
    pageSize: 100,
  });
  const {
    orders,
    searchedOrders,
    status,
    from,
    to,
    first,
    loading,
    edAnchor,
    edClicked,
    errorPosition,
    errorText,
    pageSize,
  } = values;
  const outletContext = useOutletContext();
  const newOrder =
    outletContext.length > 0 && outletContext[0] !== null
      ? outletContext[0]
      : null;
  const setNewOrderFn = outletContext ? outletContext[1] : null;

  useEffect(() => {
    if (location.state?.onlineOrders) {
      setValues((prev) => ({
        ...prev,
        status: location.state?.onlineOrders.status,
        from:
          location.state?.onlineOrders?.fromDate ||
          moment().subtract(1, "days").toISOString(),
        to:
          location.state?.onlineOrders?.toDate ||
          moment().endOf("day").toISOString(),
      }));
    }
  }, [location.state?.onlineOrders]);

  const setNewOrders = () => {
    var oldOrders = [...orders];
    newOrder && oldOrders.unshift(newOrder[0]);
    var non_duplicates = oldOrders.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj._id).indexOf(obj._id) === pos;
    });
    if (
      equalArray(status, [ORDER_STATUS.COOKING, ORDER_STATUS.NOT_ASSIGNED]) ||
      status === statusMenuArrOnline
    )
      setValues((x) => ({
        ...x,
        orders: non_duplicates,
        searchedOrders: non_duplicates,
      }));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setValues((x) => ({ ...x, first: true }));
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setValues((x) => ({ ...x, first: true }));
  }, [from, to, status]);

  useEffect(() => {
    if (first) {
      POSTAPI_ADMIN("/order/", {
        statuses: status,
        from: from,
        to: to,
        isAdminCreated: false,
      }).then((res) => {
        setNewOrderFn((x) => ({ ...x, newOrder: null }));
        setValues((x) => ({ ...x, first: false }));
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            orders: res.orders,
            searchedOrders: res.orders,
            riders: res.riders,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            errorText: res.error,
            loading: false,
          }));
      });
    } else {
      setNewOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, outletContext]);

  // const columns = [
  //     {
  //         name: "trackingNumber",
  //         label: "Tracking Number",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             // customBodyRender: (value, tableMeta, updateValue) => '# ' + value[3]
  //         }
  //     },
  //     {
  //         name: "createdAt",
  //         label: "Date",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => formatDateTimeNext(value)
  //         }
  //     },
  //     {
  //         name: "delivery",
  //         label: "Location",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => value.location.address
  //         }
  //     },
  //     {
  //         name: "payment",
  //         label: "Amount",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => (
  //                 <>{value ? 'Rs. ' + parseFloat(value.total).toFixed(2) : ''}</>
  //             )
  //         }
  //     },
  //     {
  //         name: "_id",
  //         label: "",
  //         options: {
  //             display: false,
  //         }
  //     },
  //     {
  //         name: "status",
  //         label: 'Status',
  //         options: {
  //             filter: true,
  //             sort: true,
  //             // customHeadRender: () => <th style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }} />,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 // var id = tableMeta.rowData[4]
  //                 return (
  //                     <Button style={{ width: '250px' }} className={'w-100 ' + statusButtonClass(value)[0]}>
  //                         {statusButtonClass(value)[1]}
  //                     </Button>
  //                 )
  //             }
  //         }
  //     },
  //     {
  //         name: "",
  //         label: "",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <TableEditDelete id={tableMeta.rowData[4]} EditFn={EditFn}
  //                         edAnchor={edAnchor} setValues={setValues} edClicked={edClicked} />
  //                 )
  //             }
  //         }
  //     }
  // ];
  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     // onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No orders',
  //         },
  //     },
  //     ...tableDisabledOptions,
  //     // expandableRows: true,// Try Adding This
  // };

  const copyGoogleLocation = (e, loc) => {
    e.stopPropagation();
    if (loc && loc.latitude && loc.longitude) {
      var lat = loc.latitude;
      var long = loc.longitude;
      var googleLocation = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      navigator.clipboard.writeText(googleLocation);
      setValues((x) => ({
        ...x,
        errorPosition: "success",
        errorText: "Copied location",
      }));
    }
  };

  const deleteFn = async (id) => {
    await DELETEAPI("/order/remove", { orderId: id }).then((res) => {
      if (res.success) {
        let updatedArr = orders.filter((item) => item._id !== id);
        setValues((x) => ({
          ...x,
          errorPosition: "success",
          errorText: "Order deleted",
          orders: updatedArr,
          searchedOrders: updatedArr,
        }));
      } else if (res.error) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
      }
    });
  };

  const copyMapUrl = (url) => {
    navigator.clipboard.writeText(url);
    setValues((x) => ({
      ...x,
      errorPosition: "success",
      errorText: "Copied location",
    }));
  };

  const columnsData = [
    {
      field: "trackingNumber",
      headerName: "Order ID",
      width: 110,
      renderCell: (params) => {
        return (
          <span style={{ width: "100px", overflow: "hidden" }}>
            <span style={textEllipsisStyle}>#{params.value} </span>
          </span>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 212,
      renderCell: (params) => {
        return (
          <span style={{ width: "200px", overflow: "hidden" }}>
            <span style={textEllipsisStyle}>
              {moment(params.value).format("MMM Do, YYYY h:mm A")}
            </span>
          </span>
        );
      },
    },
    {
      field: "delivery",
      headerName: "Location",
      width: 200,
      renderCell: (params) => {
        const location = params.value;

        if (location.location && location?.location?.mapUrl) {
          return (
            <div className="d-flex align-items-center">
              <span>{location.location?.mapUrl.slice(0, 14 - 3) + "..."}</span>

              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  copyMapUrl(location?.location?.mapUrl);
                }}
              >
                <Copy />
              </IconButton>
            </div>
          );
        } else if (
          location.location &&
          !location?.location?.mapUrl &&
          location?.location.latitude &&
          location?.location.longitude
        ) {
          return (
            <div className="d-flex align-items-center">
              <span>
                {parseFloat(location.location.latitude).toFixed(2)}
                ..
              </span>
              <span className="ml-2 mr-1">
                {parseFloat(location.location.longitude).toFixed(2)}
                ..
              </span>
              <IconButton
                onClick={(e) => {
                  copyGoogleLocation(e, location.location);
                }}
              >
                <Copy />
              </IconButton>
            </div>
          );
        } else {
          return "";
        }
      },
    },

    {
      field: "payment",
      headerName: "Amount",
      width: 100,
      renderCell: (params) => {
        return <>{params.value ? "Rs. " + parseInt(params.value.total) : ""}</>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            style={{ width: "150px", paddingInline: "15px" }}
            className={statusButtonClass(params.value)[0]}
          >
            {statusButtonClass(params.value)[1]}
          </Button>
        );
      },
    },
    {
      field: "locationStatus",
      headerName: "location Status",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center w-100 ">
            {getDeliveryLocationStatusIcon(params.value)}
          </div>
        );
      },
    },
    {
      field: "..",
      headerName: "",
      width: 20,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center w-100 ">
            <IconButton
              onClick={(e) => copyUserDetails(e, params.row, setValues)}
            >
              <Copy />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: "icon",
      headerName: "",
      description: "Click for edit delete options",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <TableEditDelete
              id={params.row._id}
              EditFn={EditFn}
              edAnchor={edAnchor}
              DeleteFn={deleteFn}
              setValues={setValues}
              edClicked={edClicked}
              status={params.row.status}
            />
          </>
        );
      },
    },
  ];

  const EditFn = (id, status) => {
    // var item = orders.filter((x) => x._id === edClicked);
    // if (item.length === 1) {
    //   item = item[0];
    //   item.riders = riders;
    //   if (item.status === ORDER_STATUS.QUEUE)
    //     navigate("/orders/draft/edit", { state: item });
    //   else navigate("/orders/created/view", { state: item });
    // }
    if (
      status.status === ORDER_STATUS.COMPLETED ||
      status.status === ORDER_STATUS.CANCELED ||
      status.status === ORDER_STATUS.FAILED
    ) {
      navigate(`/orders/created/view/${id}`);
    } else {
      navigate(`/orders/draft/edit/${id}`);
    }
  };

  const onChangeDate = (date, dateString) => {
    setValues((prev) => ({
      ...prev,
      from: moment(dateString[0]).toISOString(),
      to: moment(dateString[1]).endOf("day").toISOString(),
      first: true,
    }));
  };
  const handleSearch = async (e) => {
    const searchText = e.target.value;
    const results = await searchOrdersById(searchText, orders);
    setValues((x) => ({ ...x, searchedOrders: results }));
  };
  const handleRowClick = (item) => {
    // var item = orders[rowMeta.dataIndex]
    // item.riders = riders
    // navigate('/orders/created/view',
    //     { state: item })
    // if (item) {
    //   item.riders = riders;
    //   if (item.status === ORDER_STATUS.QUEUE)
    //     navigate("/orders/draft/edit", { state: item });
    //   else navigate("/orders/created/view", { state: item });
    // }
    if (item && item._id) {
      const url = `/orders/draft/view/${item._id}`;
      const newTab = window.open(url, "_blank");
      newTab.focus();
      return;
    }
    // if (item && item._id) {
    //   navigate(`/orders/draft/view/${item._id}`, {
    //     state: {
    //       onlineOrders: {
    //         status: status,
    //         fromDate: from,
    //         toDate: to,
    //       },
    //     },
    //   });
    // }
  };

  return (
    <>
      {/* top */}
      <div
        style={{ width: "1050px" }}
        className="d-flex justify-content-between"
      >
        <p className="w3 s3">Online Orders</p>
        <Paper
          component="form"
          sx={{
            p: "0px 16px",
            display: "flex",
            border: "1px solid #E9ECFF",
            borderRadius: "12px",
            alignItems: "center",
            boxShadow: "none",
            height: "45px",
            mb: "10px",
            width: "400px",
            ml: "auto",
          }}
        >
          <InputBase
            sx={{ width: "400px" }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearch}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <MagnifyingGlass />
          </IconButton>
        </Paper>
      </div>
      <div className="d-flex mb-4" style={{ width: "1070px" }}>
        <MUIMenuStatusOnline status={status} setValues={setValues} />

        <RangePicker
          className="ad_or_top_btn_date"
          suffixIcon={<CalendarBlank color="#8943EF" size={18} weight="bold" />}
          size="small"
          onChange={onChangeDate}
          style={{
            borderRadius: "12px",
            borderColor: "#818091",
            height: "40px",
          }}
          value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
          format={dateFormat}
        />
        <Button
          className="ad_or_top_btn_2"
          onClick={() => navigate("/orders/created/create")}
        >
          <Plus size={18} weight="bold" color="#FFF" />
          Create Order
        </Button>
      </div>

      {/* table */}

      <div style={{ height: "62vh", width: "1050px" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={searchedOrders}
          columns={columnsData}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

export const CreatedOrders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dateFormat = "YYYY/MM/DD";
  const [values, setValues] = useState({
    orders: [],
    searchedOrders: [],
    riders: [],
    vendors: [],
    status: location.state?.createdOrders?.status
      ? location.state?.createdOrders?.status
      : [
          ORDER_STATUS.QUEUE,
          ORDER_STATUS.COOKING,
          ORDER_STATUS.NOT_ASSIGNED,
          ORDER_STATUS.PENDING,
          ORDER_STATUS.PICKED_UP,
          ORDER_STATUS.COMPLETED,
          ORDER_STATUS.CANCELED,
          ORDER_STATUS.FAILED,
        ],
    vendorsArr: [],
    vendorsMenuArrBool: [],
    trackVendors: false,
    from:
      location.state?.createdOrders?.fromDate ||
      moment().subtract(1, "days").toISOString(), //1 day ago
    to:
      location.state?.createdOrders?.toDate ||
      moment().endOf("day").toISOString(),
    first: false,
    loading: true,
    edAnchor: null,
    edClicked: null,
    errorPosition: "",
    errorText: "",
    pageSize: 100,
  });
  const {
    orders,
    searchedOrders,
    vendors,
    status,
    vendorsArr,
    vendorsMenuArrBool,
    trackVendors,
    from,
    to,
    first,
    loading,
    edAnchor,
    edClicked,
    errorPosition,
    errorText,
    pageSize,
  } = values;
  const outletContext = useOutletContext();
  const newOrder =
    outletContext.length > 0 && outletContext[0] !== null
      ? outletContext[0]
      : null;
  const setNewOrderFn = outletContext ? outletContext[1] : null;

  useEffect(() => {
    GETAPI_OrderTypes(`/order/orderType`).then((res) => {
      if (res.success) {
        setValues((prev) => ({
          ...prev,
          vendors: res.orderTypes.map((orderType) => orderType.name),
          first: true,
        }));
      }
    });
  }, []);

  useEffect(() => {
    if (location.state?.createdOrders) {
      setValues((prev) => ({
        ...prev,
        // vendors: location.state?.createdOrders.vendors,
        status: location.state?.createdOrders.status,
        from:
          location.state?.createdOrders.fromDate ||
          moment().subtract(1, "days").toISOString(),
        to:
          location.state?.createdOrders.toDate ||
          moment().endOf("day").toISOString(),
      }));
    }
  }, [location.state?.createdOrders]);

  useEffect(() => {
    if (!trackVendors && vendors.length > 0) {
      setValues((prev) => ({
        ...prev,
        vendorsArr: _.cloneDeep(vendors),
        vendorsMenuArrBool: vendors.map((orderType) => true),
        trackVendors: true,
      }));
    }
  }, [vendors, trackVendors, vendorsArr]);

  const setNewOrders = () => {
    var oldOrders = [...orders];
    newOrder && oldOrders.unshift(newOrder[0]);
    var non_duplicates = oldOrders.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj._id).indexOf(obj._id) === pos;
    });
    // if (
    //   equalArray(status, [ORDER_STATUS.COOKING, ORDER_STATUS.NOT_ASSIGNED]) ||
    //   status === statusMenuArr
    // )
    setValues((x) => ({
      ...x,
      orders: non_duplicates,
      searchedOrders: non_duplicates,
    }));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setValues((x) => ({ ...x, first: true }));
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setValues((x) => ({ ...x, first: true }));
  }, [from, to, vendors, vendorsMenuArrBool, status]);

  useEffect(() => {
    POSTAPI_ADMIN("/order/", {
      apps: vendors,
      statuses: status,
      from: from,
      to: to,
      isAdminCreated: true,
    }).then((res) => {
      setNewOrderFn((x) => ({ ...x, newOrder: null }));
      setValues((x) => ({ ...x, first: false }));
      if (res.success) {
        setValues((prev) => ({
          ...prev,
          orders: res.orders,
          searchedOrders: res.orders,
          riders: res.riders,
          loading: false,
          first: false,
        }));
      } else if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          errorText: res.error,
          loading: false,
          first: false,
        }));
    });
  }, [from, to, vendors, vendorsMenuArrBool, status, setNewOrderFn]);

  useEffect(() => {
    if (vendors.length === 0) return;
    if (first) {
      POSTAPI_ADMIN("/order/", {
        apps: vendors,
        statuses: status,
        from: from,
        to: to,
        isAdminCreated: true,
      }).then((res) => {
        setNewOrderFn((x) => ({ ...x, newOrder: null }));
        setValues((x) => ({ ...x, first: false }));
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            orders: res.orders,
            searchedOrders: res.orders,
            riders: res.riders,
            loading: false,
            first: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            errorText: res.error,
            loading: false,
            first: false,
          }));
      });
    } else {
      setNewOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, outletContext]);

  // const deleteFn = async (id) => {
  //   await DELETEAPI("/order/remove", { orderId: id }).then((res) => {
  //     if (res.success) {
  //       let updatedArr = orders.filter((item) => item._id !== id);
  //       setValues((x) => ({
  //         ...x,
  //         errorPosition: "success",
  //         errorText: "Order deleted",
  //         orders: updatedArr,
  //         searchedOrders: updatedArr
  //       }));
  //     } else if (res.error) {
  //       setValues((x) => ({
  //         ...x,
  //         errorPosition: "apiErr",
  //         errorText: res.error,
  //       }));
  //     }
  //   });
  // };

  const columnsData = [
    {
      field: "trackingNumber",
      headerName: "Order ID",
      width: 100,
      renderCell: (params) => {
        return (
          <span style={{ width: "100px", overflow: "hidden" }}>
            <span style={textEllipsisStyle}>#{params.value} </span>
          </span>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 212,
      renderCell: (params) => {
        return (
          <span style={{ width: "280px", overflow: "hidden" }}>
            <span style={textEllipsisStyle}>
              {moment(params.value).format("MMM Do, YYYY h:mm A")}
            </span>
          </span>
        );
      },
    },
    {
      field: "payment",
      headerName: "Amount",
      width: 130,
      renderCell: (params) => {
        return <>{params.value ? "Rs. " + parseInt(params.value.total) : ""}</>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            style={{ width: "150px", paddingInline: "15px" }}
            className={statusButtonClass(params.value)[0]}
          >
            {statusButtonClass(params.value)[1]}
          </Button>
        );
      },
    },
    {
      field: "delivery",
      headerName: "Apps",
      width: 150,
      renderCell: (params) => {
        return <span>{params.value?.deliveryPartner?.name}</span>;
      },
    },
    {
      field: "locationStatus",
      headerName: "location Status",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center w-100 ">
            {getDeliveryLocationStatusIcon(params.value)}
          </div>
        );
      },
    },
    {
      field: "..",
      headerName: "",
      width: 20,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center w-100 ">
            <IconButton
              onClick={(e) => copyUserDetails(e, params.row, setValues)}
            >
              <Copy />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: "icon",
      headerName: "",
      description: "Click for edit delete options",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <TableEditDelete
              id={params.row._id}
              EditFn={EditFn}
              edAnchor={edAnchor}
              // DeleteFn={deleteFn}
              setValues={setValues}
              edClicked={edClicked}
              status={params.row.status}
            />
          </>
        );
      },
    },
  ];

  const EditFn = (id, status) => {
    // var item = orders.filter((x) => x._id === edClicked);
    // if (item.length === 1) {
    //   item = item[0];
    //   item.riders = riders;
    //   // if (item.status === ORDER_STATUS.QUEUE)
    //   //   navigate("/orders/draft/edit", { state: item });
    //   // else navigate("/orders/created/view", { state: item });
    // }

    if (
      status.status === ORDER_STATUS.COMPLETED ||
      status.status === ORDER_STATUS.CANCELED ||
      status.status === ORDER_STATUS.FAILED
    ) {
      navigate(`/orders/created/view/${id}`);
    } else {
      navigate(`/orders/draft/edit/${id}`);
    }
  };

  const onChangeDate = (date, dateString) => {
    setValues((prev) => ({
      ...prev,
      from: moment(dateString[0]).toISOString(),
      to: moment(dateString[1]).endOf("day").toISOString(),
      first: true,
      loading: true,
      orders: [],
      searchedOrders: [],
    }));
  };

  const handleRowClick = (item) => {
    // if (item) {
    //   item.riders = riders;
    //   // if (item.status === ORDER_STATUS.QUEUE)
    //   //   navigate("/orders/draft/edit", { state: item });
    //   // else navigate("/orders/created/view", { state: item });
    // }
    if (item && item._id) {
      const url = `/orders/draft/view/${item._id}`;
      const newTab = window.open(url, "_blank");
      newTab.focus();
    }
    // return

    // if (item && item._id) {
    //   navigate(`/orders/draft/view/${item._id}`, {
    //     state: {
    //       createdOrders: {
    //         status: status,
    //         vendors: vendors,
    //         fromDate: from,
    //         toDate: to,
    //       },
    //     },
    //   });
    // }
  };

  const handleSearch = async (e) => {
    const searchText = e.target.value;
    const results = await searchOrdersById(searchText, orders);
    setValues((x) => ({ ...x, searchedOrders: results }));
  };

  const MUIMenuVendors = ({ vendors, setValues }) => {
    // const a = vendors.map((item) => item.toLowerCase().trim());
    // const initialChecked = vendorsArr.map((item) =>
    //   a.includes(item.toLowerCase().trim())
    // );
    // const [checked, setChecked] = useState(initialChecked);

    const [checked, setChecked] = useState(vendorsMenuArrBool);
    const [apps, setApps] = useState(vendors);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
      event.stopPropagation();
      setAnchorEl(null);
      var send_arr = [];
      checked.map((eachBool, i) => {
        if (eachBool) {
          send_arr.push(vendorsArr[i]);
          return eachBool;
        }
        return eachBool;
      });
      setApps(send_arr);
      setValues((prev) => ({
        ...prev,
        vendors: send_arr,
        vendorsMenuArrBool: checked,
        first: true,
      }));
    };
    const handleChangeAll = (event) => {
      var checkedNow = [...checked];
      checkedNow.fill(event.target.checked);
      setChecked(checkedNow);
    };
    const handleChange = (event, i) => {
      var checkedNow = [...checked];
      checkedNow[i] = event.target.checked;
      setChecked(checkedNow);
    };

    return (
      <div className="ml-auto">
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="ad_or_top_btn ml-auto"
          style={{ width: "200px" }}
        >
          <SortAscending color="#8943EF" size={18} weight="bold" />
          <span className="ad_or_top_btn_text ml-1">{apps.join(", ")}</span>
          <CaretDown />
        </Button>
        <MUIMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem className="d-flex flex-column">
            <FormControlLabel
              label="All"
              className="mr-auto mb-0"
              control={
                <Checkbox
                  sx={{
                    color: "#8943EF",
                    "&.Mui-checked": {
                      color: "#8943EF",
                    },
                  }}
                  checked={checked?.every((v) => v === true)}
                  onChange={handleChangeAll}
                />
              }
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {vendorsArr.map((each, i) => (
                <FormControlLabel
                  key={i}
                  className="mb-0"
                  label={each}
                  control={
                    <Checkbox
                      sx={{
                        color: "#8943EF",
                        "&.Mui-checked": {
                          color: "#8943EF",
                        },
                      }}
                      checked={checked[i]}
                      onChange={(e) => handleChange(e, i)}
                    />
                  }
                />
              ))}
            </Box>
          </MenuItem>
        </MUIMenu>
      </div>
    );
  };
  return (
    <>
      {/* top */}
      <div
        style={{ width: "1050px" }}
        className="d-flex justify-content-between"
      >
        <p className="w3 s3">Created Orders</p>
        <Paper
          component="form"
          sx={{
            p: "0px 16px",
            display: "flex",
            border: "1px solid #E9ECFF",
            borderRadius: "12px",
            alignItems: "center",
            boxShadow: "none",
            height: "45px",
            mb: "10px",
            width: "400px",
            ml: "auto",
          }}
        >
          <InputBase
            sx={{ width: "400px" }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearch}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <MagnifyingGlass />
          </IconButton>
        </Paper>
      </div>
      <div className="d-flex mb-4" style={{ width: "1070px" }}>
        <MUIMenuStatus status={status} setValues={setValues} />
        <MUIMenuVendors vendors={vendors} setValues={setValues} />

        <RangePicker
          className="ad_or_top_btn_date"
          suffixIcon={<CalendarBlank color="#8943EF" size={18} weight="bold" />}
          size="small"
          onChange={onChangeDate}
          style={{
            borderRadius: "12px",
            borderColor: "#818091",
            height: "40px",
          }}
          value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
          format={dateFormat}
        />
        <Button
          className="ad_or_top_btn_2"
          onClick={() => navigate("/orders/created/create")}
        >
          <Plus size={18} weight="bold" color="#FFF" />
          Create Order
        </Button>
      </div>

      {/* table */}
      <div style={{ height: "62vh", width: "1050px" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={searchedOrders}
          columns={columnsData}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

const equalArray = (array1, array2) => {
  // if the other array is a falsy value, return
  if (!array1 || !array2) return false;

  // compare lengths - can save a lot of time
  if (array1.length !== array2.length) return false;

  for (var i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) return false;
  }
  return true;
};

// MUI generic menu
const MUIMenuStatus = ({ status, setValues }) => {
  const a = status.map((item) => item.toLowerCase().trim());
  const initialChecked = statusMenuArr.map((item) =>
    a.includes(item.toLowerCase().trim()),
  );
  const [checked, setChecked] = useState(initialChecked);

  // const [checked, setChecked] = useState(statusMenuArrBool);
  const [statuses, setstatuses] = useState(status);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    var send_arr = [];
    checked.map((eachBool, i) => {
      if (eachBool) {
        send_arr.push(statusMenuArr[i]);
        return eachBool;
      }
      return eachBool;
    });
    setstatuses(send_arr);
    setValues((prev) => ({ ...prev, status: send_arr, first: true }));
  };
  const handleChangeAll = (event) => {
    var checkedNow = [...checked];
    checkedNow.fill(event.target.checked);
    setChecked(checkedNow);
  };
  const handleChange = (event, i) => {
    var checkedNow = [...checked];
    checkedNow[i] = event.target.checked;
    setChecked(checkedNow);
  };

  return (
    <div className="ml-auto">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="ad_or_top_btn ml-auto"
      >
        <SortAscending color="#8943EF" size={18} weight="bold" />
        <span className="ad_or_top_btn_text ml-1">{statuses.join(", ")}</span>
        <CaretDown />
      </Button>
      <MUIMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem className="d-flex flex-column">
          <FormControlLabel
            label="All"
            className="mr-auto mb-0"
            control={
              <Checkbox
                sx={{
                  color: "#8943EF",
                  "&.Mui-checked": {
                    color: "#8943EF",
                  },
                }}
                checked={checked.every((v) => v === true)}
                onChange={handleChangeAll}
              />
            }
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {statusMenuArr.map((each, i) => (
              <FormControlLabel
                key={i}
                className="mb-0"
                label={each}
                control={
                  <Checkbox
                    sx={{
                      color: "#8943EF",
                      "&.Mui-checked": {
                        color: "#8943EF",
                      },
                    }}
                    checked={checked[i]}
                    onChange={(e) => handleChange(e, i)}
                  />
                }
              />
            ))}
          </Box>
          {/* <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                        <FormControlLabel
                            label="Child 1"
                            control={<Checkbox checked={checked[0]} onChange={e => handleChange(e, 0)} />}
                        />
                        <FormControlLabel
                            label="Child 2"
                            control={<Checkbox checked={checked[1]} onChange={e => handleChange(e, 1)} />}
                        />
                    </Box> */}
        </MenuItem>
      </MUIMenu>
    </div>
  );
};

const MUIMenuStatusOnline = ({ status, setValues }) => {
  const a = status.map((item) => item.toLowerCase().trim());
  const initialChecked = statusMenuArrOnline.map((item) =>
    a.includes(item.toLowerCase().trim()),
  );
  const [checked, setChecked] = useState(initialChecked);
  // const [checked, setChecked] = useState(statusMenuArrBoolOnline);

  const [statuses, setstatuses] = useState(status);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    var send_arr = [];
    checked.map((eachBool, i) => {
      if (eachBool) {
        send_arr.push(statusMenuArrOnline[i]);
        return eachBool;
      }
      return eachBool;
    });
    setstatuses(send_arr);
    setValues((prev) => ({ ...prev, status: send_arr, first: true }));
  };
  const handleChangeAll = (event) => {
    var checkedNow = [...checked];
    checkedNow.fill(event.target.checked);
    setChecked(checkedNow);
  };
  const handleChange = (event, i) => {
    var checkedNow = [...checked];
    checkedNow[i] = event.target.checked;
    setChecked(checkedNow);
  };

  return (
    <div className="ml-auto">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="ad_or_top_btn ml-auto"
      >
        <SortAscending color="#8943EF" size={18} weight="bold" />
        <span className="ad_or_top_btn_text ml-1">{statuses.join(", ")}</span>
        <CaretDown />
      </Button>
      <MUIMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem className="d-flex flex-column">
          <FormControlLabel
            label="All"
            className="mr-auto mb-0"
            control={
              <Checkbox
                sx={{
                  color: "#8943EF",
                  "&.Mui-checked": {
                    color: "#8943EF",
                  },
                }}
                checked={checked.every((v) => v === true)}
                onChange={handleChangeAll}
              />
            }
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {statusMenuArrOnline.map((each, i) => (
              <FormControlLabel
                key={i}
                className="mb-0"
                label={each}
                control={
                  <Checkbox
                    sx={{
                      color: "#8943EF",
                      "&.Mui-checked": {
                        color: "#8943EF",
                      },
                    }}
                    checked={checked[i]}
                    onChange={(e) => handleChange(e, i)}
                  />
                }
              />
            ))}
          </Box>
          {/* <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                        <FormControlLabel
                            label="Child 1"
                            control={<Checkbox checked={checked[0]} onChange={e => handleChange(e, 0)} />}
                        />
                        <FormControlLabel
                            label="Child 2"
                            control={<Checkbox checked={checked[1]} onChange={e => handleChange(e, 1)} />}
                        />
                    </Box> */}
        </MenuItem>
      </MUIMenu>
    </div>
  );
};

export const DraftOrders = () => {
  const navigate = useNavigate();
  const dateFormat = "YYYY/MM/DD";
  const [values, setValues] = useState({
    orders: [],
    riders: [],
    from: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 1,
    ), //1 day ago
    to: new Date(),
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    edAnchor: null,
    edClicked: null,
    pageSize: 10,
  });
  const {
    orders,
    riders,
    from,
    to,
    first,
    loading,
    errorPosition,
    errorText,
    edAnchor,
    edClicked,
    pageSize,
  } = values;
  const outletContext = useOutletContext();
  const newOrder =
    outletContext.length > 0 && outletContext[0] !== null
      ? outletContext[0]
      : null;
  const setNewOrderFn = outletContext ? outletContext[1] : null;

  useEffect(() => {
    if (first) {
      var send_data = {
        statuses: [ORDER_STATUS.DRAFT],
        from: new Date(from),
        to: new Date(to),
      };
      POSTAPI_ADMIN("/order/", send_data).then((res) => {
        if (res.success) {
          setNewOrderFn((x) => ({ ...x, newOrder: null }));
          setValues((prev) => ({
            ...prev,
            orders: res.orders,
            riders: res.riders,
            first: false,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first, from, to, outletContext, newOrder, orders, setNewOrderFn]);

  const EditFn = () => {
    var item = orders.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      item.riders = riders;
      navigate("/orders/draft/edit", { state: item });
    }
  };

  // const DeleteFn = () => {
  //   var item = orders.filter((x) => x._id === edClicked);
  //   if (item.length === 1) {
  //     item = item[0];
  //     DELETEAPI("/order/remove", { orderId: item._id }).then((res) => {
  //       if (res.success) {
  //         setValues((x) => ({
  //           ...x,
  //           errorPosition: "success",
  //           errorText: "Order deleted",
  //         }));
  //       } else if (res.error)
  //         setValues((x) => ({
  //           ...x,
  //           errorPosition: "apiErr",
  //           errorText: res.error,
  //         }));
  //     });
  //   }
  // };

  // const columns = [
  //     {
  //         name: "trackingNumber",
  //         label: "Tracking Number",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => '# ' + value
  //         }
  //     },
  //     {
  //         name: "createdAt",
  //         label: "Date",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => formatDateTimeNext(value)
  //         }
  //     },
  //     {
  //         name: "delivery",
  //         label: "Location",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => value.location.address
  //         }
  //     },
  //     {
  //         name: "payment",
  //         label: "Amount",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => (
  //                 <>{value ? 'Rs. ' + value.total : ''}</>
  //             )
  //         }
  //     },
  //     {
  //         name: "_id",
  //         label: "",
  //         options: {
  //             display: false,
  //         }
  //     },
  //     {
  //         name: "",
  //         label: "",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <TableEditDelete id={tableMeta.rowData[4]} EditFn={EditFn}
  //                         DeleteFn={DeleteFn}
  //                         edAnchor={edAnchor} setValues={setValues} edClicked={edClicked} />
  //                 )
  //             }
  //         }
  //     }
  // ];
  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     // onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No orders',
  //         },
  //     },
  //     ...tableDisabledOptions,
  //     // expandableRows: true,// Try Adding This
  // };
  // const handleRowClick = (rowData, rowMeta) => {
  //     var item = orders[rowMeta.dataIndex]
  //     item.riders = riders
  //     navigate('/orders/created/view',
  //         { state: item })
  // };

  const columnsData = [
    {
      field: "trackingNumber",
      headerName: "Tracking Number",
      description: "This column is sortable",
      width: 175,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
      renderCell: (params) => {
        return <span>{formatDateTimeNext(params.value)}</span>;
      },
    },
    {
      field: "delivery",
      headerName: "Location",
      flex: 1,
      renderCell: (params) => {
        return <span>{params.value.location.address}</span>;
      },
    },
    {
      field: "payment",
      headerName: "Amount",
      width: 175,
      renderCell: (params) => {
        return <>{params.value ? "Rs. " + parseInt(params.value.total) : ""}</>;
      },
    },
    {
      field: "icon",
      headerName: "",
      description: "Click for edit delete options",
      sortable: false,
      renderCell: (params) => {
        return (
          <TableEditDelete
            id={params.row._id}
            EditFn={EditFn}
            // DeleteFn={DeleteFn}
            edAnchor={edAnchor}
            setValues={setValues}
            edClicked={edClicked}
          />
        );
      },
    },
  ];

  const onChangeDate = (date, dateString) => {
    setValues((prev) => ({
      ...prev,
      from: dateString[0],
      to: new Date(new Date(dateString[1]).setHours(23, 59, 59, 999)),
      first: true,
    }));
  };

  return (
    <>
      {/* top */}
      <div className="d-flex mb-4">
        <RangePicker
          className="ad_or_top_btn_date ml-auto"
          suffixIcon={<CalendarBlank color="#8943EF" size={18} weight="bold" />}
          size="small"
          onChange={onChangeDate}
          style={{
            borderRadius: "12px",
            borderColor: "#818091",
            height: "40px",
          }}
          value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
          format={dateFormat}
        />
      </div>

      {/* table */}
      <ThemeProvider theme={getMuiTheme()}>
        {/* <MUIDataTable
                    data={orders}
                    columns={columns}
                    options={options}
                /> */}
      </ThemeProvider>

      <div style={{ height: "55vh", width: "100%" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={orders}
          columns={columnsData}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

export const ViewOrder = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [values, setValues] = useState({
    stateData: null,
    assignTo: null,
    errorPosition: "",
    errorText: "",
    changeDriverModal: false,
    riders: null,
    assign_loading: false,
    data_loading: true,
    anchorElChangeStatus: null,
    changeState_loading: false,
  });
  const {
    stateData,
    assignTo,
    changeDriverModal,
    errorPosition,
    errorText,
    riders,
    data_loading,
    assign_loading,
    anchorElChangeStatus,
    changeState_loading,
  } = values;
  const openChangeStatus = Boolean(anchorElChangeStatus);

  useEffect(() => {
    if (!id) {
      navigate("/orders/created");
    }
  }, [id, navigate]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      POSTAPI_ADMIN("/order/one", { orderId: id }).then((res) => {
        if (res.success) {
          setValues((x) => ({
            ...x,
            stateData: res.order,
          }));
        } else if (res.error)
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    }, 60000);

    POSTAPI_ADMIN("/order/one", { orderId: id }).then((res) => {
      if (res.success) {
        setValues((x) => ({
          ...x,
          stateData: res.order,
          data_loading: false,
        }));
      } else if (res.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          data_loading: false,
        }));
    });

    POSTAPI_ADMIN("/rider/orders").then((res) => {
      if (res.success) {
        setValues((x) => ({
          ...x,
          riders: res.ridersInfo,
        }));
      } else if (res.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
    });

    return () => clearInterval(intervalId);
  }, [id]);

  const copyGoogleLocation = () => {
    if (
      stateData.delivery.location &&
      stateData.delivery.location.latitude &&
      stateData.delivery.location.longitude
    ) {
      var lat = stateData.delivery.location.latitude;
      var long = stateData.delivery.location.longitude;
      var googleLocation = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      navigator.clipboard.writeText(googleLocation);
      setValues((x) => ({
        ...x,
        errorPosition: "success",
        errorText: "Copied location",
      }));
    }
  };

  const copyMapUrl = (url) => {
    navigator.clipboard.writeText(url);
    setValues((x) => ({
      ...x,
      errorPosition: "success",
      errorText: "Copied location",
    }));
  };

  const UserDetails = () => {
    var user = stateData;
    if (user) {
      return (
        <div className="d-flex flex-column">
          <div className="ov_right">
            <Avatar
              src={user?.userInfo?.image}
              className="ov_img"
              variant="rounded"
            />
            {user?.delivery?.location?.name && (
              <p className="ovr_name">{user?.delivery?.location.name}</p>
            )}
            {stateData.delivery?.location && (
              <span className="ovr_phone mt-2">
                {stateData?.delivery?.location?.phone}
              </span>
            )}
            {user?.delivery?.location?.email && (
              <span className="ovr_phone mt-1 mb-1">
                {user?.delivery?.location?.email}
              </span>
            )}
            <p className="ovr_red">Date</p>
            <p>{formatDateTimeNext(new Date(stateData?.createdAt))}</p>

            {stateData?.delivery?.location &&
              (stateData?.delivery?.mapUrl ||
                (stateData.delivery.location.latitude &&
                  stateData.delivery.location.longitude)) && (
                <span className="ovr_red d-flex flex-row  align-items-center gap-2">
                  Delivery Address{"   "}
                  {stateData.locationStatus &&
                    getDeliveryLocationStatusIcon(stateData?.locationStatus)}
                </span>
              )}
            {/* <StatusIcon status="Not Verified" />
            <StatusIcon status="Hold" />
            <StatusIcon status="Verified" /> */}
            {/* <span>
              {stateData.delivery &&
                stateData.delivery.location &&
                stateData.delivery.location?.address}
            </span> */}

            {stateData.delivery && stateData?.delivery?.location?.mapUrl && (
              <div className="d-flex align-items-center">
                <span>
                  {stateData?.delivery?.location?.mapUrl.slice(0, 25 - 3) +
                    "..."}
                </span>

                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    copyMapUrl(stateData?.delivery?.location?.mapUrl);
                  }}
                >
                  <Copy />
                </IconButton>
              </div>
            )}
            {stateData.delivery &&
              !stateData?.delivery?.location?.mapUrl &&
              stateData.delivery.location?.latitude &&
              stateData.delivery.location?.longitude && (
                <div className="d-flex align-items-center">
                  <span>
                    {parseFloat(stateData.delivery.location.latitude).toFixed(
                      3,
                    )}
                    ...
                  </span>
                  <span className="ml-2 mr-1">
                    {parseFloat(stateData.delivery.location.longitude).toFixed(
                      3,
                    )}
                    ...
                  </span>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      copyGoogleLocation();
                    }}
                  >
                    <Copy />
                  </IconButton>
                </div>
              )}
            {stateData?.payment && (
              <span className="ovr_red mb-1">Payment Method</span>
            )}
            <p>{stateData?.payment?.method}</p>
            <p className="d-flex align-items-center gap-2 mt-2">
              Copy Details{" "}
              <IconButton
                onClick={(e) => copyUserDetails(e, stateData, setValues)}
              >
                <Copy />
              </IconButton>
            </p>
          </div>
          {stateData.note && (
            <div className="ovr_note">
              <p className="ovr_red">Order note</p>
              <p style={{ wordWrap: "break-word" }}>{stateData?.note}</p>
            </div>
          )}
        </div>
      );
    }
  };

  const assignDriverFn = () => {
    if (isTextEmpty(assignTo)) {
      // setValues((x) => ({
      //   ...x,
      //   errorPosition: "assignTo",
      //   errorText: "Assign error",
      // }));
      return;
    }
    setValues((x) => ({ ...x, assign_loading: true }));
    if (isTextEmpty(assignTo)) {
      setValues((x) => ({
        ...x,
        errorPosition: "assignTo",
        errorText: "Assign error",
      }));
      return;
    }
    setValues((x) => ({ ...x, errorPosition: "", errorText: "" }));
    var send = {
      orderId: stateData._id,
      riderId: assignTo,
    };
    POSTAPI_ADMIN("/order/rider/assign", send).then((res) => {
      if (res.success) {
        POSTAPI_ADMIN("/order/one", { orderId: id }).then((res) => {
          if (res.success) {
            setValues((x) => ({
              ...x,
              stateData: res.order,
              data_loading: false,
            }));
          } else if (res.error)
            setValues((x) => ({
              ...x,
              errorPosition: "apiErr",
              errorText: res.error,
              data_loading: false,
            }));
        });
        setValues((x) => ({
          ...x,
          errorPosition: "success",
          errorText: "Rider updated",
          assign_loading: false,
          changeDriverModal: false,
          stateData: res.order[0],
        }));
      } else if (res.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          assign_loading: false,
        }));
    });
  };

  const EditDriverClickedFn = () => {
    setValues((x) => ({
      ...x,
      changeDriverModal: true,
      assignTo: stateData.delivery.riderInfo.riderId,
    }));
  };

  const handleChangeStatusByAdmin = (newStatus) => {
    setValues((x) => ({ ...x, changeState_loading: true }));
    POSTAPI_ADMIN("/order/status/change", {
      orderId: stateData._id,
      newStatus: newStatus,
    }).then((res) => {
      if (res.success) {
        var newStateData = { ...stateData };
        newStateData.status = newStatus;
        setValues((x) => ({
          ...x,
          errorPosition: "success",
          errorText: "Status updated",
          stateData: newStateData,
          changeState_loading: false,
          anchorElChangeStatus: null,
        }));
        // navigate('/orders/created')
      } else if (res.error) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          changeState_loading: false,
          anchorElChangeStatus: null,
        }));
      }
    });
  };

  const FinishCookingPrint = (props) => {
    return (
      <>
        <div>
          <div
            id="printId"
            className="printNeo"
            style={{ fontFamily: "Arial" }}
          >
            <div
              className="printNeoBody d-flex flex-column"
              style={{ fontFamily: "Arial", fontWeight: "400" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "18px",
                    marginBottom: "5px",
                  }}
                >
                  NOVA FOODS PVT. LTD
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "5px",
                  }}
                >
                  BAKHUNDOLE, LALITPUR
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "5px",
                  }}
                >
                  PAN NO. 610284707
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "5px",
                  }}
                >
                  ABBREVIATED TAX INVOICE
                </span>
              </div>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "12px",
                  lineHeight: "14px",
                  marginBottom: "5px",
                }}
              >
                <span>Order No. : #{props.data.trackingNumber}</span>
                <span> {formatDate(props.data.createdAt)}</span>
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "12px",
                  lineHeight: "14px",
                  marginBottom: "5px",
                }}
              >
                <span>
                  Bill To :{" "}
                  {props.data?.isAdminCreated &&
                  !props.data?.delivery?.deliveryPartner?.isDefaultPartner
                    ? props.data?.delivery?.deliveryPartner?.name
                    : ""}
                  {props.data?.isAdminCreated &&
                  props.data?.delivery?.location?.name
                    ? props.data?.delivery?.location?.name
                    : ""}
                  {!props.data?.isAdminCreated &&
                  props.data?.delivery?.location?.name
                    ? props.data?.delivery?.location?.name
                    : ""}
                </span>
                <span>{formatTime(props.data.createdAt)}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {props.data?.delivery?.location?.phone ? (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        lineHeight: "14px",
                      }}
                    >
                      Phone number : {props.data?.delivery?.location?.phone}
                    </span>
                  </>
                ) : (
                  ""
                )}
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                  }}
                >
                  Payment Method : {props.data.payment.method}
                </span>
              </div>

              <div
                style={{
                  margin: "10px 0",
                  borderTop: "2px solid #000",
                }}
              />
              <div>
                {props &&
                  props.data &&
                  props.data.foods &&
                  props.data.foods.length > 0 &&
                  props.data.foods.map((each, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "15px",
                        }}
                      >
                        <div>
                          <span>{each.quantity}</span>
                          <span style={{ marginLeft: "5px" }}>
                            {each.name}{" "}
                          </span>
                          {each.modifiers && each.modifiers.length > 0 ? (
                            <span
                              style={{
                                width: "fit-content",
                                marginLeft: "auto",
                                float: "right",
                              }}
                            >
                              Rs.{each?.unitPrice}
                            </span>
                          ) : (
                            ""
                          )}

                          {!each?.modifiers || each.modifiers.length === 0 ? (
                            <span
                              style={{
                                width: "fit-content",
                                marginLeft: "auto",
                                float: "right",
                              }}
                            >
                              Rs.{each.price}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        {each?.modifiers && each?.modifiers.length > 0 ? (
                          <>
                            {each.modifiers.map((item, i) =>
                              item.options.map((x, a) => (
                                <div
                                  key={a}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginLeft: "10px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <span>{x.name}</span>
                                  <span>Rs. {x.price}</span>
                                </div>
                              )),
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {each?.spiceLevel && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginLeft: "10px",
                              marginTop: "5px",
                            }}
                          >
                            <span>Spice level</span>
                            <span>{each.spiceLevel}</span>
                          </div>
                        )}

                        {each?.modifiers && each?.modifiers.length > 0 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "5px",
                            }}
                          >
                            <span>Rs. {each.price}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
              </div>
              <div
                style={{
                  margin: "10px 0",
                  borderTop: "2px solid #000",
                }}
              />
              {props.data &&
                props.data.payment &&
                props.data.payment.subTotal && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                      lineHeight: "14px",
                      marginBottom: "3px",
                    }}
                  >
                    <span>Subtotal</span>
                    <span>Rs. {props.data.payment.subTotal}</span>
                  </div>
                )}

              {props.data &&
              props.data.payment &&
              props.data.payment?.deliveryCharge &&
              props.data.payment?.deliveryCharge > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Delivery Charge</span>
                  <span>Rs. {props.data.payment.deliveryCharge}</span>
                </div>
              ) : (
                ""
              )}
              {/* {props.data &&
              props.data.payment &&
              props.data.payment.discount ? (
                <>
                  {props.data.payment.discount > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        lineHeight: "14px",
                        marginBottom: "3px",
                      }}
                    >
                      <span>Discount</span>
                      <span>Rs. {props.data.payment.discount}</span>
                    </div>
                  )}
                </>
              ) : null} */}
              {props.data &&
              props.data.payment &&
              props.data.payment?.promoDiscount ? (
                <>
                  {props.data.payment?.promoDiscount > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        lineHeight: "14px",
                        marginBottom: "3px",
                      }}
                    >
                      <span>Promo Discount</span>
                      <span>- Rs. {props.data.payment.promoDiscount}</span>
                    </div>
                  )}
                </>
              ) : null}

              {props.data &&
              props.data.payment &&
              props.data.payment.taxes &&
              props.data.payment.taxes.length > 0
                ? props.data.payment.taxes.map((each, i) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        lineHeight: "14px",
                        marginBottom: "3px",
                      }}
                      key={i}
                    >
                      <span>{each.name}</span>
                      <span>
                        {each.type === "value" && "Rs."} {each.amount}{" "}
                        {each.type === "percent" && "%"}
                      </span>
                    </div>
                  ))
                : ""}
              {props.data &&
              props.data.payment &&
              props.data.payment?.paidAmount &&
              props.data.payment?.paidAmount > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Paid Amount</span>
                  <span>Rs. {props.data.payment?.paidAmount}</span>
                </div>
              ) : (
                ""
              )}
              {props.data && props.data.payment && props.data.payment.total && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Total</span>
                  <span>Rs. {props.data.payment.total}</span>
                </div>
              )}
              {props.data &&
              props.data.payment &&
              props.data.payment?.remainingAmount &&
              props.data.payment?.remainingAmount > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Remaining Amount</span>
                  <span>Rs. {props.data.payment?.remainingAmount}</span>
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  margin: "10px 0",
                  borderTop: "1px solid #000",
                }}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={{ fontSize: "10px", lineHeight: "12px" }}>
                  THANK YOU FOR YOUR ORDER
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handlePrintED = (props) => {
    var prtContent = document.getElementById(`printId`);
    if (!prtContent) return;
    var printWindow = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0",
    );
    printWindow.document.write(prtContent.innerHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  if (data_loading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress />
      </div>
    );
  }

  if (id && stateData === null) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        No order details found
      </div>
    );
  }

  return (
    <>
      {id && stateData && Object.keys(stateData).length > 0 && (
        <div className="d-flex">
          <div className="d-flex flex-column align-items-start col-9">
            {/* top */}
            <div className="d-flex w-100 mb-5 align-items-center">
              <Button
                className="transparent_button"
                onClick={() => {
                  location?.state?.returnUrl
                    ? navigate(location.state.returnUrl)
                    : stateData.isAdminCreated
                      ? navigate("/orders/created", {
                          state: {
                            createdOrders: location.state?.createdOrders,
                          },
                        })
                      : navigate("/orders/onlineorders", {
                          state: { onlineOrders: location.state?.onlineOrders },
                        });
                }}
              >
                <ArrowLeft size={25} />
                <span className="ov_top">
                  Order #{stateData.trackingNumber}
                </span>
              </Button>
              <Button
                className={
                  "pl-5 pr-5 " + statusButtonClass(stateData.status)[0]
                }
              >
                {statusButtonClass(stateData.status)[1]}
              </Button>
              <div className="ml-auto">
                {/* print bill in not assigned and pending status */}
                {(stateData.status === "Not Assigned" ||
                  stateData.status === "Pending") && (
                  <Button
                    className="mr-2 print_bill_btn"
                    onClick={() => handlePrintED()}
                  >
                    Print Bill
                  </Button>
                )}
              </div>
            </div>
            {/* delivery assign */}

            {stateData.status !== ORDER_STATUS.DRAFT &&
              stateData.status !== ORDER_STATUS.QUEUE &&
              stateData.status !== ORDER_STATUS.COOKING && (
                <>
                  {stateData.delivery.riderInfo ? null : (
                    <div className="del_assign mb-4">
                      <p className="w3">Delivery Assign</p>
                      {LabelComp(
                        "Assign to",
                        "assignTo",
                        errorPosition,
                        errorText,
                      )}
                      {ReactSelectDrop(assignTo, "assignTo", setValues, riders)}
                      {HalfRightBtnComp(
                        "Assign",
                        assignDriverFn,
                        assign_loading,
                      )}
                    </div>
                  )}

                  {/* show rider */}
                  {stateData &&
                    stateData.delivery &&
                    stateData.delivery.riderInfo &&
                    stateData.delivery.riderInfo.name && (
                      <div className="del_assign_view cursor_pointer mb-4">
                        <p className="w3">Delivery</p>
                        <div
                          className="del_assign_box d-flex"
                          onClick={() =>
                            setValues((x) => ({
                              ...x,
                              changeDriverModal: true,
                              assignTo: stateData.delivery.riderInfo.riderId,
                            }))
                          }
                        >
                          <Avatar
                            src={stateData.delivery.riderInfo.image}
                            variant="square"
                            style={{ width: "90px", height: "90px" }}
                          />
                          <div className="ml-3 w-100">
                            <div
                              className="d-flex w-100 justify-content-between align-items-center"
                              style={{ height: "fit-content" }}
                            >
                              <span className="w3 s3 mr-5">
                                {stateData.delivery.riderInfo.name}
                              </span>
                              <MUIMenuTwo
                                EditFn={EditDriverClickedFn}
                                // DeleteFn={null}
                              />
                            </div>
                            <p className="mb-0 mt-0 w3 s2 c7">Driver</p>
                            <p className="mb-0 mt-0 s2 c7">
                              {stateData.delivery.riderInfo.phone}
                            </p>
                          </div>
                        </div>
                        <Modal
                          open={changeDriverModal}
                          onClose={() =>
                            setValues((x) => ({
                              ...x,
                              changeDriverModal: false,
                            }))
                          }
                        >
                          <Box style={modalStyles} sx={{ width: "400px" }}>
                            <div className="d-flex justify-content-between">
                              <p className="w3 s3 mr-4">Delivery assign</p>
                              <IconButton
                                onClick={() =>
                                  setValues((x) => ({
                                    ...x,
                                    changeDriverModal: false,
                                  }))
                                }
                              >
                                <X color="#2E2E2E" size={30} />
                              </IconButton>
                            </div>
                            {LabelComp(
                              "Assign To",
                              assignTo,
                              errorPosition,
                              errorText,
                            )}
                            {ReactSelectDrop(
                              assignTo,
                              "assignTo",
                              setValues,
                              riders,
                            )}
                            {HalfRightBtnComp(
                              "Assign",
                              assignDriverFn,
                              assign_loading,
                            )}
                          </Box>
                        </Modal>
                      </div>
                    )}
                </>
              )}

            {/* table */}
            <div className="d-flex w-100">
              <div className="ov_left">
                <Button
                  variant="contained"
                  endIcon={<Pen />}
                  className="float-right mb-3 w-5"
                  sx={{
                    backgroundColor: "red",
                    fontSize: "15px",
                    width: "100px",
                    "&:hover": {
                      backgroundColor: "red",
                    },
                  }}
                  onClick={(e) => {
                    navigate(`/orders/draft/edit/${id}`);
                  }}
                >
                  Edit
                </Button>
                {OrderViewTable(stateData, stateData.foods)}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column ">
            <div>
              <Button
                className="float-right mb-4 "
                aria-controls={openChangeStatus ? "user-menu" : undefined}
                aria-expanded={openChangeStatus ? "true" : undefined}
                style={{
                  width: "180px",
                  height: "40px",
                  border: "1px solid #8943EF",
                  color: "#8943EF",
                  fontSize: "15px",
                }}
                aria-haspopup="true"
                endIcon={!changeState_loading && <CaretDown />}
                onClick={(e) =>
                  setValues((x) => ({
                    ...x,
                    anchorElChangeStatus: e.currentTarget,
                  }))
                }
              >
                {changeState_loading ? (
                  <CircularProgress style={{ color: "#8943EF" }} size={20} />
                ) : (
                  "Change Status"
                )}
              </Button>
              <Menu
                id="user-menu"
                MenuListProps={{
                  "aria-labelledby": "user-button",
                }}
                anchorEl={anchorElChangeStatus}
                open={openChangeStatus}
                onClose={() =>
                  setValues((x) => ({ ...x, anchorElChangeStatus: null }))
                }
                PaperProps={{
                  style: {
                    width: "180px",
                  },
                }}
              >
                {!stateData.isAdminCreated &&
                stateData.status === ORDER_STATUS.DRAFT ? (
                  <MenuItem
                    onClick={() => {
                      handleChangeStatusByAdmin(ORDER_STATUS.QUEUE);
                    }}
                  >
                    Queue
                  </MenuItem>
                ) : (
                  ""
                )}
                <MenuItem
                  onClick={() => {
                    handleChangeStatusByAdmin(ORDER_STATUS.COMPLETED);
                  }}
                >
                  Completed
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleChangeStatusByAdmin(ORDER_STATUS.FAILED);
                  }}
                >
                  Failed
                </MenuItem>
              </Menu>
            </div>
            {UserDetails()}
          </div>
        </div>
      )}

      {!stateData ||
        (Object.keys(stateData).length === 0 && (
          <div>No Order details found</div>
        ))}

      {AlertError(errorPosition, errorText, setValues)}
      {FinishCookingPrint({ data: stateData })}
    </>
  );
};

export const CreateOrder = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    itemsArr: [],
    orderId: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    orderTypeId: "",
    deliveryPartners: [],
    note: "",
    needCutlery: false,
    itemModalVisible: false,
    spiceLevel: 28,
    item: null,
    thisItem: null,
    allItems: [],
    combo: null,
    allCombos: [],
    thisCombo: null,
    errorPosition: "",
    errorText: "",
    first: true,
    loading: false,
    // mapCenter: [27.7172, 85.3240],
    add_lat: "",
    add_lng: "",
    add_address: null,
    add_id: "",
    // modal
    type: "",
    locationStatus: "",
    allModifiers: [],
    modifiersSel: [],
    quanSel: 1,
    add_item_loading: false,
    editItemModal: false,
    currentItem: null,
    customDiscount: { discountType: "percent", discountValue: null },
    customDeliveryCharge: null,
    subTotalPrice: null,
    thisCustomItem: null,
    mapUrl: null,
    taxes: [],
  });
  const {
    itemsArr,
    name,
    phone,
    email,
    // address,
    orderTypeId,
    deliveryPartners,
    locationStatus,
    note,
    needCutlery,
    itemModalVisible,
    spiceLevel,
    item,
    thisItem,
    allItems,
    currentItem,
    // combo,
    allCombos,
    thisCombo,
    errorPosition,
    errorText,
    first,
    thisCustomItem,
    // mapCenter,
    add_lat,
    add_lng,
    add_address,
    //  add_id,
    type,
    // allModifiers,
    modifiersSel,
    quanSel,
    add_item_loading,
    loading,
    customDiscount,
    customDeliveryCharge,
    subTotalPrice,
    mapUrl,
    taxes,
  } = values;
  const [add_edit_visible_ind, Set_add_edit_visible] = useState(null);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showEditModifiersModal, setShowEditModifiersModal] = useState(false);
  const [coordinates, setCoordinates] = useState({ data: "" });

  const calculateTotalItemPrices = useMemo(() => {
    return () => {
      let totalItemPrices = 0;

      itemsArr.forEach((prod) => {
        let totalCostModifiers = 0;

        if (prod.modifiers) {
          for (const modifier of prod.modifiers) {
            for (const option of modifier.options) {
              if (option.sel) {
                totalCostModifiers += option.price;
              } else {
                totalCostModifiers += 0;
              }
            }
          }
        }

        const percentPrice =
          prod.discount && prod.discount.value
            ? Math.ceil(
                parseFloat(
                  Math.ceil(parseFloat(prod?.price)) -
                    (Math.ceil(parseFloat(prod?.discount?.value)) / 100) *
                      Math.ceil(parseFloat(prod?.price)),
                ),
              )
            : 0;
        const percentPriceWithModifiers =
          percentPrice +
          (prod?.modifiers?.length > 0
            ? Math.ceil(parseFloat(totalCostModifiers))
            : 0);
        const valuePrice =
          prod.discount && prod.discount.value
            ? Math.ceil(
                parseFloat(
                  Math.ceil(parseFloat(prod?.price)) -
                    Math.ceil(parseFloat(prod.discount?.value)),
                ),
              )
            : 0;
        const valuePriceWithModifiers =
          valuePrice +
          (prod?.modifiers?.length > 0
            ? Math.ceil(parseFloat(totalCostModifiers))
            : 0);

        if (
          prod.discount &&
          prod.discount.type === "percent" &&
          prod.discount.value !== null &&
          prod.discount.value > 0
        ) {
          totalItemPrices += Math.ceil(
            parseFloat(percentPriceWithModifiers * prod.quantity),
          );
        } else if (
          prod.discount &&
          prod.discount.type === "value" &&
          prod.discount.value !== null &&
          prod.discount.value > 0
        ) {
          totalItemPrices += Math.ceil(
            parseFloat(valuePriceWithModifiers * prod.quantity),
          );
        } else {
          totalItemPrices += Math.ceil(
            parseFloat(
              (Math.ceil(parseFloat(prod.price)) +
                Math.ceil(parseFloat(totalCostModifiers))) *
                prod.quantity,
            ),
          );
        }
        // if (prod.hasDiscount === true) {
        //   totalItemPrices += parseInt(
        //     (parseInt(prod.discountedPrice) + parseInt(totalCostModifiers)) *
        //       prod.quantity
        //   );
      });

      setValues((x) => ({
        ...x,
        subTotalPrice: Math.ceil(parseFloat(totalItemPrices)),
      }));
    };
  }, [itemsArr]);

  useEffect(() => {
    calculateTotalItemPrices();
  }, [itemsArr, calculateTotalItemPrices]);

  useEffect(() => {
    if (first) {
      POSTAPI_CreateInfoAdmin().then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            allItems: res.items,
            allCombos: res.combos,
            allItemsCombos: res.items.concat(res.combos),
            first: false,
          }));
        }
      });

      GETAPI_OrderTypes(`/order/orderType`).then((res) => {
        if (res.success) {
          res.orderTypes.forEach((orderType) => {
            if (orderType.isDefaultPartner === true) {
              setValues((x) => ({ ...x, orderTypeId: orderType._id }));
            }
          });

          setValues((prev) => ({
            ...prev,
            deliveryPartners: res.orderTypes,
          }));
        }
      });

      GETAPI_OrderTypes(`/settings`).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            taxes: res.taxes,
          }));
        }
      });
    }
  }, [first]);

  //returns boolean checking order type is default partner or not
  const selectedPartner = useMemo(() => {
    if (deliveryPartners.length === 0) return false;
    return deliveryPartners.find((partner) => partner._id === orderTypeId)
      ?.isDefaultPartner;
  }, [orderTypeId, deliveryPartners]);

  const handleDiscountTypeChange = (e) => {
    setValues((x) => ({
      ...x,
      customDiscount: {
        discountType: e.target.value,
        discountValue: customDiscount.discountValue,
      },
    }));
  };

  const handleDiscountValueChange = (e) => {
    setValues((x) => ({
      ...x,
      customDiscount: {
        discountType: customDiscount.discountType,
        discountValue: e.target.value,
      },
    }));
  };

  const applyDiscount = () => {
    if (!customDiscount.discountValue && !customDiscount.discountType) {
      return;
    }

    if (customDiscount.discountType === "value") {
      if (
        parseFloat(customDiscount.discountValue) > parseFloat(currentItem.price)
      ) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Value can't be more than the product price.",
        }));
        return;
      }
      if (parseFloat(customDiscount.discountValue) < 0) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Value can't be less than 0.",
        }));
        return;
      }
    }

    if (customDiscount.discountType === "percent") {
      if (parseFloat(customDiscount.discountValue) > 100) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Can't apply more than 100%.",
        }));
        return;
      }
      if (parseFloat(customDiscount.discountValue) < 0) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Cant't apply less than 0.",
        }));
        return;
      }
    }

    const updatedArr = itemsArr.map((item) => {
      if (item.itemId && !item.identifierId) {
        if (item.itemId === currentItem.itemId && !currentItem.identifierId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.itemId && item.identifierId) {
        if (
          item.itemId === currentItem.itemId &&
          item.identifierId === currentItem.identifierId
        ) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.comboId && !item.identifierId) {
        if (item.comboId === currentItem.comboId && !currentItem.identifierId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.comboId && item.identifierId) {
        if (
          item.comboId === currentItem.comboId &&
          item.identifierId === currentItem.identifierId
        ) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.customId) {
        if (item.customId === currentItem.customId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        }
      }
      return item;
    });

    setValues((x) => ({
      ...x,
      errorPosition: "",
      errorText: "",
    }));
    setValues((x) => ({ ...x, itemsArr: updatedArr }));
    setShowDiscountModal(false);
    Set_add_edit_visible(null);
    setValues((x) => ({
      ...x,
      customDiscount: { discountType: "percent", discountValue: null },
    }));
  };

  const handleAddItemBtn = () => {
    // var passValidation =
    //   orderTypeId === "" ? false : selectedPartner ? false : true;
    var passValidation = true;
    var validated = ValidateLetAddItemBtn(
      orderTypeId,
      name,
      phone,
      add_lat,
      add_lng,
      setValues,
      passValidation,
    );
    if (!validated) return;
    setValues((x) => ({
      ...x,
      itemModalVisible: true,
      errorPosition: "",
      errorText: "",
    }));
  };

  // const setMapCoordinates = (index, address, mapCenter) => {
  //     var split = index.split(',')
  //     setValues(prev => ({
  //         ...prev, alt: index, add_address: address,
  //         add_lng: split[0], add_lat: split[1], mapCenter: mapCenter
  //     }))
  // }

  useEffect(() => {
    if (itemsArr.length === 0) {
      setValues((x) => ({ ...x, customDeliveryCharge: null }));
    }
  }, [itemsArr]);

  // const setMapCoordinatesMapBox = (newlnglat) => {
  //   // var split = index.split(',')
  //   setValues((prev) => ({
  //     ...prev,
  //     alt: `${newlnglat[0]},${newlnglat[1]}`,
  //     add_address: "",
  //     add_lng: newlnglat[0],
  //     add_lat: newlnglat[1],
  //     mapCenter: newlnglat,
  //   }));
  // };

  const closeModal = () => {
    setValues((val) => ({
      ...val,
      itemModalVisible: false,
      editItemModal: false,
      type: "",
      spiceLevel: 28,
      item: null,
      modifiersSel: [],
      quanSel: 1,
      thisItem: null,
      thisCombo: null,
      add_item_loading: false,
      thisCustomItem: null,
      errorPosition: "",
      errorText: "",
    }));
  };

  // single item select drop handle (for item select)
  const ItemSelectWithDetails = (e) => {
    var thisItem;
    var refer;
    thisItem = allItems.filter((x) => x._id === e.value);
    thisItem = thisItem.length > 0 ? thisItem[0] : null;
    if (!thisItem) return;
    refer = JSON.parse(JSON.stringify(thisItem));
    var allModifiersData = refer.modifiers;
    setValues((prev) => ({
      ...prev,
      item: e.value,
      thisItem: thisItem,
      allModifiers: allModifiersData,
      modifiersSel: allModifiersData,
    }));
  };

  const ComboSelected = (e) => {
    var thisComboItem;
    var refer;
    thisComboItem = allCombos.filter((x) => x._id === e.value);
    thisComboItem = thisComboItem.length > 0 ? thisComboItem[0] : null;
    if (!thisComboItem) return;
    refer = JSON.parse(JSON.stringify(thisComboItem));
    setValues((prev) => ({
      ...prev,
      item: e.value,
      thisCombo: refer,
    }));
    // handleChangeFun(mainValues => ({ ...mainValues, [varName]: e.value }))
  };

  const modifierSelected = (modInd, optionInd) => {
    var nowMods = [...modifiersSel];
    var selected = nowMods[modInd].options[optionInd];
    selected.sel = selected.sel ? false : true;
    setValues((prev) => ({ ...prev, modifiersSel: nowMods }));
  };

  const modifierSelectedEdit = (modIndex, optionsIndex) => {
    const newModifiers = _.cloneDeep(modifiersSel);
    const selected = newModifiers[modIndex].options[optionsIndex];
    selected.sel = !selected.sel;
    setValues((prev) => ({ ...prev, modifiersSel: newModifiers }));
  };

  const handleChangeSlide = (e, newVal) => {
    setValues((x) => ({ ...x, spiceLevel: newVal }));
  };

  // add item button at end
  const addItem = () => {
    if (type === "") return;
    if (type === "Combo Item" && !thisCombo) {
      return;
    }
    if (type === "Item" && !thisItem) {
      return;
    }
    if (type === "Custom Item" && !thisCustomItem) {
      return;
    }

    if (type === "Custom Item" && thisCustomItem) {
      if (!thisCustomItem.name || !thisCustomItem.price) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide name and price.",
        }));
        return;
      }
    }
    let itemExists = false;
    let comboExists = false;

    setValues((x) => ({ ...x, add_item_loading: true }));
    if (thisCustomItem) {
      //custom item
      let newCustomItem = {
        customId: uuidv4(),
        name: thisCustomItem.name,
        price: thisCustomItem.price,
        unitPrice: thisCustomItem.price,
        quantity: quanSel,
        spiceLevel: null,
        hasSpiceLevel: false,
        modifiers: [],
      };

      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, newCustomItem],
      }));
      closeModal();
      return;
    }
    var thisItem_a = thisItem;
    var send_data = {
      name,
      phone,
      email,
      address: add_address,
      latitude: add_lat,
      longitude: add_lng,
      needCutlery,
      note,
      orderTypeId,
    };
    var itemInfo = {};
    var comboInfo = {};

    if (thisItem_a) {
      //item
      const productExists = itemsArr.some(
        (item) => item.itemId === thisItem_a._id,
      );
      if (productExists) {
        if (
          !item.modifiers &&
          thisItem_a?.modifiers?.length === 0 &&
          thisItem_a?.hasSpiceLevel === false
        ) {
          setValues((x) => ({ ...x, add_item_loading: true }));
          closeModal();
          return;
        }
        itemExists = true;
      }
      thisItem_a.spiceLevel = getSpiceText(spiceLevel);
      var modsArr = [];
      if (modifiersSel.length > 0) {
        modifiersSel.forEach((element) => {
          var thisEl = { ...element };
          thisEl.options = [];
          element.options.forEach((op) => {
            // if (op.sel) {
            //   thisEl.options.push({
            //     name: op.name,
            //     price: op.price,
            //   });
            // }
            thisEl.options.push({
              name: op.name,
              price: op.price,
              sel: op.sel === true ? true : false,
            });
          });
          if (thisEl.options.length > 0) {
            modsArr.push(thisEl);
          }
        });
      }
      itemInfo = {
        itemId: thisItem_a._id,
        quantity: quanSel,
        spiceLevel: thisItem.hasSpiceLevel ? getSpiceText(spiceLevel) : null,
        modifiers: modsArr,
        image: thisItem_a.image,
        name: thisItem_a.name,
        price: thisItem_a.price,
        hasDiscount: thisItem_a.hasDiscount,
        discountedPrice: thisItem_a.discountedPrice,
        hasSpiceLevel: thisItem_a.hasSpiceLevel,
      };
      if (itemExists) {
        itemInfo.identifierId = uuidv4();
      }
      send_data = {
        ...send_data,
        itemInfo,
      };
      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, send_data.itemInfo],
      }));
      closeModal();
    } else {
      //combo

      thisItem_a = allCombos.filter((x) => x._id === item);
      if (thisItem_a.length === 0) return;
      thisItem_a = thisItem_a[0];
      const productExists = itemsArr.some(
        (item) => item.comboId === thisItem_a._id,
      );
      if (productExists) {
        if (
          !item.modifiers &&
          thisItem_a?.modifiers?.length === 0 &&
          thisItem_a?.hasSpiceLevel === false
        ) {
          setValues((x) => ({ ...x, add_item_loading: true }));
          closeModal();
          return;
        }
        comboExists = true;
      }
      comboInfo = {
        comboId: thisItem_a._id,
        quantity: quanSel,
        spiceLevel: getSpiceText(spiceLevel),
        image: thisItem_a.image,
        name: thisItem_a.name,
        price: thisItem_a.price,
        hasDiscount: thisItem_a.hasDiscount,
        hasSpiceLevel: thisItem_a.hasSpiceLevel,
        discountedPrice: thisItem_a.discountedPrice,
      };
      if (comboExists) {
        comboInfo.identifierId = uuidv4();
      }
      send_data = {
        ...send_data,
        comboInfo,
      };
      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, send_data.comboInfo],
      }));
      closeModal();
    }
  };

  // edit clicked in menu
  const editItemFn = (prod) => {
    if (prod.itemId) {
      var modTestArr = [];
      prod.allModifiers.forEach((element, ind) => {
        var selected = null;
        prod.modifiers.filter((x) => {
          if (x.modifierId === element.modifierId) {
            selected = x;
          }
          return selected;
        });
        if (selected) {
          //has modifier selected
          selected.options.forEach((op, i) => {
            var index = element.options.findIndex(
              (obj) => obj.name === op.name,
            );
            op.sel = true;
            element.options[index] = op;
          });
          modTestArr.push(element);
        } else modTestArr.push(element);
      });
      prod.modifiers = modTestArr;
      setValues((prev) => ({
        ...prev,
        item: prod.itemId,
        thisItem: prod,
        allModifiers: prod.allModifiers,
        modifiersSel: prod.modifiers,
        editItemModal: true,
        errorPosition: "",
        errorText: "",
        spiceLevel: prod.spiceLevel ? getSpiceValue(prod.spiceLevel) : null,
      }));
    } else if (prod.comboId) {
      setValues((prev) => ({
        ...prev,
        item: prod.comboId,
        thisCombo: prod,
        editItemModal: true,
        errorPosition: "",
        errorText: "",
        spiceLevel: prod.spiceLevel ? getSpiceValue(prod.spiceLevel) : null,
        quanSel: prod.quantity,
      }));
    } else return;
  };

  const updateFnAPI = () => {
    if (currentItem.itemId && !currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.itemId === currentItem.itemId && !item.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
              modifiers: modifiersSel?.length > 0 ? modifiersSel : [],
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr, modifiersSel: null }));
    }

    if (currentItem.itemId && currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.itemId === currentItem.itemId &&
        item.identifierId === currentItem.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
              modifiers: modifiersSel?.length > 0 ? modifiersSel : [],
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr, modifiersSel: null }));
    }

    if (currentItem.comboId && !currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.comboId === currentItem.comboId && !item.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr }));
    }

    if (currentItem.comboId && currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.comboId === currentItem.comboId &&
        item.identifierId === currentItem.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr }));
    }

    setShowEditModifiersModal(false);
    setValues((x) => ({ ...x, spiceLevel: 28 }));
    // var thisItem_a = thisItem;
    // var send_data = {
    //   name,
    //   phone,
    //   email,
    //   address: add_address,
    //   latitude: add_lat,
    //   longitude: add_lng,
    //   needCutlery,
    //   note,
    // };
    // var itemInfo = {};
    // var comboInfo = {};
    // if (currentItem.itemId) {
    //   //item
    //   thisItem_a.spiceLevel = spiceLevel;
    //   var modsArr = [];
    //   if (itemsArr.modifiers.length > 0) {
    //     itemsArr.modifiers.forEach((element) => {
    //       var thisEl = { ...element };
    //       thisEl.options = [];
    //       element.options.forEach((op) => {
    //         if (op.sel) {
    //           thisEl.options.push({
    //             name: op.name,
    //             price: op.price,
    //           });
    //         }
    //       });
    //       if (thisEl.options.length > 0) {
    //         modsArr.push(thisEl);
    //       }
    //     });
    //   }
    //   itemInfo = {
    //     itemId: currentItem.itemId,
    //     quantity: quanSel,
    //     spiceLevel:
    //       thisItem.hasSpiceLevel || !isTextEmpty(thisItem.spiceLevel)
    //         ? getSpiceText(spiceLevel)
    //         : null,
    //     modifiers: modsArr,
    //   };
    //   send_data = {
    //     ...send_data,
    //     itemInfo,
    //   };
    // } else {
    //   //combo
    //   thisItem_a = allCombos.filter((x) => x._id === item);
    //   if (thisItem_a.length === 0) return;
    //   thisItem_a = thisItem_a[0];
    //   comboInfo = {
    //     comboId: thisItem_a._id,
    //     quantity: quanSel,
    //     spiceLevel: getSpiceText(spiceLevel),
    //   };
    //   send_data = {
    //     ...send_data,
    //     comboInfo,
    //   };
    // }
    // // return
    // if (!isTextEmpty(orderId)) {
    //   //edit
    //   send_data = { ...send_data, orderId };
    //   POSTAPI("/order/admin/edit", send_data).then((res) => {
    //     if (res.success) {
    //       setValues((x) => ({
    //         ...x,
    //         itemsArr: res.order.foods,
    //         orderId: res.order._id,
    //       }));
    //     } else if (res.error)
    //       setValues((x) => ({
    //         ...x,
    //         errorPosition: "apiErr",
    //         errorText: res.error,
    //       }));
    //     closeModal();
    //   });
    // }
  };

  const createFn = async () => {
    if (!orderTypeId) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: "Please provide a order type",
      }));
      return;
    }

    if (itemsArr.length === 0) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: "Please provide an item",
      }));
      return;
    }

    if (selectedPartner) {
      // const phonePattern = /^(98|97|96|985|984|980)\d{7}$/;
      // const phonePattern = /^(?:\(?\+977\)?)?[9][6-9]\d{8}|01[-]?[0-9]{7}$/;
      const phonePattern = /^[9][6-9]\d{8}$/;

      if (phone === "") {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide phone number",
        }));
        return;
      }
      if (!phonePattern.test(phone)) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide a valid phone number",
        }));
        return;
      }
    }

    // reomve sel proprty and also remove those with sel prperty
    const updatedItemsArr = itemsArr.map((item) => {
      if (item.modifiers && item.modifiers.length > 0) {
        // Filter out options with sel set to false and remove the sel property
        const updatedModifiers = item.modifiers.map((modifier) => ({
          ...modifier,
          options: modifier.options
            .filter((option) => option.sel)
            .map(({ sel, ...rest }) => rest),
        }));
        // Remove modifiers with no selected options
        const filteredModifiers = updatedModifiers.filter(
          (modifier) => modifier.options.length > 0,
        );
        return {
          ...item,
          modifiers: filteredModifiers,
        };
      }
      return item;
    });

    const itemsInfo = [];
    const combosInfo = [];
    const customItemsInfo = updatedItemsArr
      .filter((item) => item?.customId)
      .map((item) => {
        const { spiceLevel, hasSpiceLevel, modifiers, ...rest } = item;
        // Here, return the filtered properties inside an object
        return { ...rest };
      });

    updatedItemsArr.forEach((item) => {
      if (!item?.customId) {
        if (item.itemId) {
          itemsInfo.push(item);
        } else if (item.comboId) {
          combosInfo.push(item);
        }
      }
    });

    setValues((x) => ({ ...x, loading: true }));
    let data = selectedPartner
      ? {
          orderTypeId,
          name,
          phone,
          email,
          address: add_address,
          latitude: add_lat,
          longitude: add_lng,
          mapUrl,
          locationStatus,
          needCutlery,
          note,
          itemsInfo,
          combosInfo,
          customItemsInfo,
          customDeliveryCharge: Math.ceil(parseFloat(customDeliveryCharge)),
        }
      : {
          orderTypeId,
          note,
          itemsInfo,
          combosInfo,
          customItemsInfo,
          customDeliveryCharge: Math.ceil(parseFloat(customDeliveryCharge)),
        };

    POSTAPI_ADMIN("/order/admin/create", data).then((res) => {
      if (res.success) {
        POSTAPI_ADMIN("/order/status/change", {
          orderId: res.order._id,
          newStatus: ORDER_STATUS.QUEUE,
        }).then((res) => {
          if (res.success) {
            navigate("/orders/created");
            setValues((x) => ({ ...x, loading: false }));
          } else if (res.error) {
            setValues((x) => ({
              ...x,
              errorPosition: "apiErr",
              errorText: res.error,
              loading: false,
            }));
          }
        });
      } else if (res.error) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          loading: false,
        }));
      }
      closeModal();
    });
  };

  const handleCloseEditModal = () => {
    setShowEditModifiersModal(false);
    setValues((x) => ({
      ...x,
      currentItem: null,
      spiceLevel: null,
      modifiersSel: null,
    }));
  };

  const handleCloseDiscountModal = () => {
    setShowDiscountModal(false);
    setValues((x) => ({ ...x, currentItem: null }));
    setValues((x) => ({
      ...x,
      customDiscount: { discountType: "percent", discountValue: null },
      errorPosition: "",
      errorText: "",
    }));
  };

  useEffect(() => {
    if (coordinates?.data?.includes(",")) {
      let a = coordinates?.data?.split(",").map((item) => item.trim());
      setValues((x) => ({ ...x, add_lat: a[0], add_lng: a[1] }));
    } else {
      let b = coordinates?.data.split(" ").map((item) => item.trim());
      setValues((x) => ({ ...x, add_lat: b[0], add_lng: b[1] }));
    }
  }, [coordinates.data]);

  const form = (
    <div>
      {LabelComp("Name", "name")}
      {InputComp(name, "name", setValues, "")}
      {LabelComp("Phone", "phone")}
      {InputComp(phone, "phone", setValues, "")}
      {LabelComp("Email (optional)", "email", errorPosition, errorText)}
      {InputComp(email, "email", setValues, "")}
      {/* {LabelComp("Address", "address", errorPosition, errorText)} */}
      {/* <div className='mb-2 pickMapDiv'>
            <Test setMapCoordinates={setMapCoordinates}
                mapCenter={mapCenter} //[add_lat 27,add_lng 85]
                markCoord={isTextEmpty(add_lng) ? null : [add_lat, add_lng]}
                markAdd={add_address}
                type={isTextEmpty(add_id) ? 'Add Address' : 'Edit Address'} />
        </div> */}
      {/* <MapBox lnglat={[add_lng, add_lat]} setLngLat={setMapCoordinatesMapBox} /> */}
      {LabelComp("Address co-ordinates", "coordinates")}
      {InputComp(
        coordinates.data,
        "data",
        setCoordinates,
        "",
        "27.683206, 85.313344",
      )}
      <p className="">{LabelComp("Address URL", "mapUrl")}</p>
      {InputComp(mapUrl, "mapUrl", setValues, "")}

      <p className="">{LabelComp("Location Status", "locationStatus")}</p>
      <RadioGroup
        aria-label="location-status"
        name="location-status"
        value={locationStatus}
        onChange={(e) => {
          setValues((x) => ({ ...x, locationStatus: e.target.value }));
        }}
        row={true}
      >
        <FormControlLabel
          value="Verified"
          control={<Radio color="error" />}
          label="Verified"
        />
        <FormControlLabel
          value="Hold"
          control={<Radio color="error" />}
          label="Hold"
        />
        <FormControlLabel
          value="Not Verified"
          control={<Radio color="error" />}
          label="Not Verified"
        />
      </RadioGroup>
      <p className="s2 w3 ">Additional Order Details</p>
      <div className="d-flex mb-3">
        <ForkKnife size={26} color="#8943EF" />
        <div className="ml-3 w-100">
          <div className="d-flex justify-content-between">
            <span>Do you need cutlery?</span>
            <RedSwitch
              checked={needCutlery}
              onChange={() =>
                setValues((x) => ({
                  ...x,
                  needCutlery: !needCutlery,
                }))
              }
            />
          </div>
          <span className="infoText">
            Consider using your own cutlery to reduce plastic use
          </span>
        </div>
      </div>
    </div>
  );

  const subTotalWithTax = useMemo(() => {
    return taxes.reduce((subtotal, item) => {
      if (item.type === "percent") {
        const taxAmount =
          (parseFloat(item.amount) / 100) * parseFloat(subTotalPrice);
        return parseFloat(subtotal + taxAmount);
      }
      if (item.type === "value") {
        const taxAmount = parseFloat(item.amount);
        return parseFloat(subtotal + taxAmount);
      }
      return subtotal;
    }, parseFloat(subTotalPrice));
  }, [subTotalPrice, taxes]);

  return (
    <>
      <ThemeProvider theme={modalTheme}>
        <Button
          className="category_top_add"
          onClick={() => navigate("/orders/created")}
        >
          <ArrowLeft size={30} />
          <span className="ml-2">Create Order</span>
        </Button>

        <div className="ado_create_div">
          <div className="adoc_left">
            <div className="mb-4">
              <p className="s2 w3">Order Type</p>
              {LabelComp("Order Type", "orderTypeId", errorPosition, errorText)}
              <select
                name="orderTypeId"
                id="orderTypeId"
                className="adocr_select"
                value={orderTypeId}
                onChange={(e) =>
                  setValues((x) => ({
                    ...x,
                    orderTypeId: e.target.value,
                  }))
                }
              >
                <option className="adocr_select_op" disabled value="">
                  Select an option
                </option>

                {deliveryPartners.map(({ _id, name }) => {
                  return (
                    <option key={_id} value={_id} className="adocr_select_op">
                      {name}
                    </option>
                  );
                })}
              </select>
            </div>

            <p className="s2 w3">
              {orderTypeId && selectedPartner ? "Customer" : "Order"} Details
            </p>

            <p className="s2 w3">
              {orderTypeId === ""
                ? form
                : orderTypeId.length > 0 && selectedPartner && form}
            </p>

            <h5>
              <b>Note</b>
            </h5>
            {InputCompTextarea(note, "note", setValues, "order_note")}
          </div>

          <div className="adoc_left">
            {itemsArr && itemsArr.length > 0 && (
              <div className="adocr_right_items_div">
                {itemsArr.map((prod, ind) => {
                  let totalCostModifiers = 0;

                  if (prod?.modifiers) {
                    for (const modifier of prod.modifiers) {
                      for (const option of modifier.options) {
                        if (option.sel) {
                          totalCostModifiers += option.price;
                        } else {
                          totalCostModifiers += 0;
                        }
                      }
                    }
                  }

                  const percentPrice =
                    prod.discount && prod.discount.value
                      ? Math.ceil(parseFloat(prod.price)) -
                        (Math.ceil(parseFloat(prod.discount.value)) / 100) *
                          Math.ceil(parseFloat(prod.price))
                      : 0;
                  const percentPriceWithModifiers =
                    percentPrice +
                    (prod?.modifiers?.length > 0
                      ? Math.ceil(parseFloat(totalCostModifiers))
                      : 0);
                  const valuePrice = prod?.discount?.value
                    ? Math.ceil(parseFloat(prod?.price)) -
                      Math.ceil(parseFloat(prod?.discount?.value))
                    : 0;
                  const valuePriceWithModifiers =
                    valuePrice +
                    (prod?.modifiers?.length > 0
                      ? Math.ceil(parseFloat(totalCostModifiers))
                      : 0);

                  return (
                    <div className="eachCart_div" key={ind}>
                      <div className="mb-2 d-flex" key={ind}>
                        <Avatar
                          src={prod.image}
                          className="eachCart_image"
                          variant="rounded"
                        />
                        <div className="d-flex flex-column w-100 justify-content-center">
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="eachCart_name">{prod.name}</span>
                            {AdOrderEditDeleteMenu(
                              prod,
                              editItemFn,
                              add_edit_visible_ind,
                              Set_add_edit_visible,
                              ind,
                              itemsArr,
                              setValues,
                              setShowDiscountModal,
                              setShowEditModifiersModal,
                            )}
                          </div>
                          <div className="d-flex w-100 justify-content-between mt-2">
                            {/* <span>Qty : {prod.quantity}</span> */}
                            {CartQuantityAdminArr(prod, itemsArr, setValues)}
                            <span className="eachCart_price">
                              {prod.discount &&
                              prod.discount.type === "percent" &&
                              prod.discount.value !== null &&
                              prod.discount.value > 0 ? (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      Math.ceil(
                                        parseFloat(percentPriceWithModifiers),
                                      ) * Math.ceil(parseFloat(prod.quantity)),
                                    ),
                                  )}
                                  <del
                                    className="text-muted ml-1"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Rs.{" "}
                                    {Math.ceil(
                                      parseFloat(
                                        (Math.ceil(parseFloat(prod.price)) +
                                          Math.ceil(
                                            parseFloat(totalCostModifiers),
                                          )) *
                                          Math.ceil(parseFloat(prod.quantity)),
                                      ),
                                    )}
                                  </del>
                                </>
                              ) : prod.discount &&
                                prod.discount.type === "value" &&
                                prod.discount.value !== null &&
                                prod.discount.value > 0 ? (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      Math.ceil(
                                        parseFloat(valuePriceWithModifiers),
                                      ) * parseFloat(prod.quantity),
                                    ),
                                  )}
                                  <del
                                    className="text-muted ml-1"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Rs.{" "}
                                    {Math.ceil(
                                      parseFloat(
                                        (Math.ceil(parseFloat(prod.price)) +
                                          Math.ceil(
                                            parseFloat(totalCostModifiers),
                                          )) *
                                          prod.quantity,
                                      ),
                                    )}
                                  </del>
                                </>
                              ) : (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      (Math.ceil(parseFloat(prod.price)) +
                                        Math.ceil(
                                          parseFloat(totalCostModifiers),
                                        )) *
                                        prod.quantity,
                                    ),
                                  )}{" "}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* customizations */}
                      {((prod.modifiers &&
                        prod.modifiers
                          .map((item) => ({
                            ...item,
                            options: item.options.filter(
                              (option) => option.sel,
                            ),
                          }))
                          .filter((item) => item.options.length > 0).length >
                          0) ||
                        prod.hasSpiceLevel === true) && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={
                              <CaretDown weight="bold" className="ml-2" />
                            }
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            Customizations
                          </AccordionSummary>
                          <AccordionDetails>
                            {prod.modifiers && prod.modifiers.length > 0 && (
                              <>
                                {prod.modifiers
                                  .map((item) => ({
                                    ...item,
                                    options: item.options.filter(
                                      (option) => option.sel,
                                    ),
                                  }))
                                  .filter((item) => item.options.length > 0)
                                  .map((each, i) => (
                                    <div key={i}>
                                      <span className="c3 w3 s2">
                                        {each.name}
                                      </span>
                                      <div className="d-flex flex-column mt-2">
                                        {each.options &&
                                          each.options.map((eachOP, ind) => {
                                            return (
                                              <div
                                                key={ind}
                                                className="
                                                                  d-flex justify-content-between
                                                                   align-items-center mb-2"
                                              >
                                                <span>{eachOP.name}</span>
                                                <span>Rs. {eachOP.price}</span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  ))}
                              </>
                            )}
                            {prod.hasSpiceLevel && prod.spiceLevel !== null && (
                              <div className={"d-flex flex-column"}>
                                <span className="c3 w3 s2 mb-1">
                                  Spice Level
                                </span>
                                <span className="tile_mod_text">
                                  {prod.spiceLevel}
                                </span>
                              </div>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                  );
                })}

                <div className="mt-4 mb-1 border-top" />
                <div className="d-flex flex-column pl-3 pr-3 w-100 justify-content-between align-items-center mt-4">
                  <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                    <div>Subtotal</div>
                    <div className="fs-6 fw-semibold ">
                      Rs. {Math.ceil(parseFloat(subTotalPrice))}
                    </div>
                  </div>
                  {taxes &&
                    taxes.length > 0 &&
                    taxes.map((item) => {
                      return (
                        <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                          <div>{item.name}</div>
                          <div className="fs-6 fw-semibold ">
                            {item.type === "value" && `Rs. ${item.amount}`}
                            {item.type === "percent" &&
                              `Rs. ${parseFloat(
                                parseFloat(
                                  (parseFloat(item.amount) / 100) *
                                    subTotalPrice,
                                ).toFixed(2),
                              )} (${item.amount} %)`}
                          </div>
                        </div>
                      );
                    })}
                  {selectedPartner ? (
                    <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                      <div>Delivery charge</div>
                      <div className="fs-6  fw-semibold d-flex flex-row justify-content-center align-items-center">
                        <div className="mr-2">Rs.{"   "}</div>
                        <TextField
                          type="number"
                          color="error"
                          placeholder="Amount..."
                          value={customDeliveryCharge}
                          onChange={(e) => {
                            setValues((x) => ({
                              ...x,
                              customDeliveryCharge: e.target.value,
                            }));
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "15px",
                              borderColor: "#8943EF",
                              width: "100px",
                              height: "40px",
                              "&:focus": {
                                outlineColor: "#black",
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="d-flex  flex-row w-100 mt-3 justify-content-between align-items-center">
                    <div>Total</div>
                    <div className="fs-3 fw-bold ">
                      Rs.{" "}
                      {taxes && taxes.length > 0
                        ? customDeliveryCharge > 0 &&
                          customDeliveryCharge !== null
                          ? parseFloat(
                              parseFloat(subTotalWithTax) +
                                parseFloat(customDeliveryCharge),
                            ).toFixed(2)
                          : parseFloat(subTotalWithTax).toFixed(2)
                        : customDeliveryCharge > 0
                          ? parseFloat(
                              parseFloat(subTotalPrice) +
                                parseFloat(customDeliveryCharge),
                            ).toFixed(2)
                          : parseFloat(subTotalPrice).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Button
              className="adocr_btn mt-3"
              onClick={() => handleAddItemBtn()}
            >
              + Add Item
            </Button>

            {itemsArr && itemsArr.length > 0 && (
              <div className="mt-auto">
                {HalfRightBtnComp("Create Order", createFn, loading)}
              </div>
            )}
          </div>
        </div>

        <Modal
          open={itemModalVisible}
          onClose={() => closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* top */}
            <div className="d-flex justify-content-between align-items-center">
              <span className="ad_body_title">Add Item</span>
              <IconButton onClick={() => closeModal()}>
                <X color="#000" />
              </IconButton>
            </div>

            {/* body */}
            {LabelComp("Type", "type", errorPosition, errorText)}
            <select
              name="items"
              id="items"
              className="adocr_select"
              value={type}
              onChange={(e) =>
                setValues((x) => ({
                  ...x,
                  type: e.target.value,
                  item: null,
                  thisItem: null,
                }))
              }
            >
              <option className="adocr_select_op" value="">
                Select an option
              </option>
              <option className="adocr_select_op" value="Item">
                Item
              </option>
              <option className="adocr_select_op" value="Combo Item">
                Combo Item
              </option>
              <option className="adocr_select_op" value="Custom Item">
                Custom Item
              </option>
            </select>

            {/* <div
              className="adocr_select_container"
              style={{
                borderRadius: "15px",
                border: "1px solid rgba(0, 0, 0, 0.2)",
                padding: "10px 25px 10px 20px ",
                fontSize: "20px",
                marginBottom: "15px",
              }}
            >
              <div className="adocr_select_option d-flex align-items-center mb-1">
                <label htmlFor="item" className="adocr_select_label ">
                  Item
                </label>
                <Radio
                  color="error"
                  id="item"
                  checked={type === "Item"}
                  className="adocr_select_radio ms-auto"
                  onChange={() =>
                    setValues((x) => ({
                      ...x,
                      type: "Item",
                      item: null,
                      thisItem: null,
                    }))
                  }
                />
              </div>

              <div className="adocr_select_option d-flex align-items-center mb-1">
                <label htmlFor="combo-item" className="adocr_select_label">
                  Combo Item
                </label>

                <Radio
                  color="error"
                  id="combo-item"
                  checked={type === "Combo Item"}
                  className="adocr_select_radio ms-auto"
                  onChange={() =>
                    setValues((x) => ({
                      ...x,
                      type: "Combo Item",
                      item: null,
                      thisItem: null,
                    }))
                  }
                />
              </div>

              <div className="adocr_select_option d-flex align-items-center ">
                <label htmlFor="custom-item" className="adocr_select_label">
                  Custom Item
                </label>
                <Radio
                  color="error"
                  id="custom-item"
                  checked={type === "Custom Item"}
                  className="adocr_select_radio ms-auto"
                  onChange={() =>
                    setValues((x) => ({
                      ...x,
                      type: "Custom Item",
                      item: null,
                      thisItem: null,
                    }))
                  }
                />
              </div>
            </div> */}

            {/* item */}
            {type === "Item" && (
              <div>
                {/* item */}
                {LabelComp("Item", "item", errorPosition, errorText)}
                <Select
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  maxMenuHeight={"200px"}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  options={allItems.map((item) =>
                    item._id ? { label: item.name, value: item._id } : item,
                  )}
                  value={allItems
                    .filter((option) => option._id === item)
                    .map((item) => ({ label: item.name, value: item._id }))}
                  name={"item"}
                  onChange={(e) => ItemSelectWithDetails(e)}
                  className="mb-2"
                />

                {thisItem && (
                  <>
                    <p className="mt-3 mb-2">Price: Rs. {thisItem.price}</p>
                    <p>Quantity</p>
                    {CartQuantityAdmin(quanSel, setValues)}
                  </>
                )}

                {thisItem &&
                  thisItem.modifiers &&
                  thisItem.modifiers.length > 0 && (
                    <>
                      <FormGroup className="mt-3">
                        {thisItem.modifiers.map((each, i) => (
                          <div key={i}>
                            <p className="c3 w3 s2">{each.name}</p>
                            <div className="d-flex flex-column">
                              {each.options &&
                                each.options.map((eachOP, ind) => {
                                  return (
                                    <div
                                      key={ind}
                                      className="
                                                            d-flex justify-content-between
                                                             align-items-center"
                                    >
                                      <FormControlLabel
                                        key={ind}
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: "#8943EF",
                                              "&.Mui-checked": {
                                                color: "#8943EF",
                                              },
                                            }}
                                            // checked={checkedOrNot(ind, thisItem.modifiersArr_Selected[i])}
                                            value={each.sel}
                                            onClick={() =>
                                              modifierSelected(i, ind, thisItem)
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        }
                                        label={eachOP.name}
                                      />
                                      <span>Rs. {eachOP.price}</span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ))}
                      </FormGroup>
                    </>
                  )}

                {/* spice level */}
                {thisItem && thisItem.hasSpiceLevel && (
                  <>
                    {" "}
                    <p className="spiceLevel_text ml-auto mr-auto mt-4">
                      {getSpiceText(spiceLevel)}
                    </p>
                    <SliderMUI
                      defaultValue={[20, 40]}
                      value={spiceLevel}
                      color="error"
                      aria-label="modifier"
                      step={null}
                      onChange={handleChangeSlide}
                      valueLabelDisplay="off"
                      marks={[0, 28, 66, 100].map((each) => ({
                        value: each,
                        label: "",
                      }))}
                    />
                  </>
                )}
              </div>
            )}

            {/* combo item */}
            {type === "Combo Item" && (
              <div className="d-flex flex-column mt-2">
                {/* item */}
                {LabelComp("Item", "item", errorPosition, errorText)}
                {/* {ReactSelectDrop(item, 'item', setValues, allCombos)} */}
                <Select
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  maxMenuHeight={"200px"}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  options={allCombos.map((item) =>
                    item._id ? { label: item.name, value: item._id } : item,
                  )}
                  value={allCombos
                    .filter((option) => option._id === item)
                    .map((item) => ({ label: item.name, value: item._id }))}
                  name={"item"}
                  onChange={(e) => ComboSelected(e)}
                  className="mb-2"
                />

                {thisCombo && (
                  <>
                    <p className="mt-3 mb-2">Price: Rs. {thisCombo.price}</p>
                    <p>Quantity</p>
                    {CartQuantityAdmin(quanSel, setValues)}
                  </>
                )}

                {/* spice level */}
                {thisCombo && thisCombo.hasSpiceLevel && (
                  <>
                    {" "}
                    <p className="spiceLevel_text ml-auto mr-auto mt-4">
                      {getSpiceText(spiceLevel)}
                    </p>
                    <SliderMUI
                      defaultValue={[20, 40]}
                      value={spiceLevel}
                      color="error"
                      aria-label="modifier"
                      step={null}
                      onChange={handleChangeSlide}
                      valueLabelDisplay="off"
                      marks={[0, 28, 66, 100].map((each) => ({
                        value: each,
                        label: "",
                      }))}
                    />{" "}
                  </>
                )}
              </div>
            )}

            {/* Custom Item */}
            {type === "Custom Item" && (
              <div className="d-flex flex-column mt-2">
                {LabelComp("Name", "name", errorPosition, errorText)}
                <TextField
                  type="text"
                  value={thisCustomItem?.name || ""}
                  onChange={(e) => {
                    setValues((x) => ({
                      ...x,
                      thisCustomItem: {
                        ...x.thisCustomItem,
                        name: e.target.value,
                      },
                    }));
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
                <div className="mt-2" />
                {LabelComp("Price", "price", errorPosition, errorText)}
                <TextField
                  type="number"
                  value={thisCustomItem?.price || null}
                  onChange={(e) => {
                    setValues((x) => ({
                      ...x,
                      thisCustomItem: {
                        ...x.thisCustomItem,
                        price: e.target.value,
                      },
                    }));
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />

                <div className="mt-2" />

                {thisCustomItem && thisCustomItem.name && (
                  <>
                    <p>Quantity</p>
                    {CartQuantityAdmin(quanSel, setValues)}
                  </>
                )}
              </div>
            )}

            {/* end */}
            <div className="d-flex justify-content-end mt-3">
              <Button className="adocr_cancel" onClick={() => closeModal()}>
                {" "}
                Close
              </Button>
              <Button className="adocr_add_btn" onClick={() => addItem()}>
                {add_item_loading ? (
                  <CircularProgress style={{ color: "#fff" }} />
                ) : (
                  "Add Item"
                )}
              </Button>
            </div>
          </Box>
        </Modal>

        {/* Discount Item Modal */}
        <Modal
          open={showDiscountModal}
          onClose={() => handleCloseDiscountModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-between align-items-center">
              <span className="ad_body_title">Add Discount</span>
              <IconButton onClick={() => handleCloseDiscountModal()}>
                <X color="#000" />
              </IconButton>
            </div>

            <FormGroup className="mt-3">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="discount-type"
                  name="discount-type"
                  value={customDiscount.discountType}
                  onChange={handleDiscountTypeChange}
                >
                  <FormControlLabel
                    value="percent"
                    control={<Radio color="error" />}
                    label="Percent"
                  />
                  <FormControlLabel
                    value="value"
                    control={<Radio color="error" />}
                    label="Value"
                  />
                </RadioGroup>
              </FormControl>

              <TextField
                type="number"
                color="error"
                value={customDiscount.discountValue}
                onChange={handleDiscountValueChange}
                InputProps={{
                  endAdornment:
                    customDiscount.discountType === "percent" ? "%" : "Rs",
                  style: {
                    borderRadius: "20px",
                    borderColor: "#8943EF",
                    "&:focus": {
                      outlineColor: "#8943EF",
                    },
                  },
                }}
              />
            </FormGroup>

            <div className="d-flex justify-content-end mt-3">
              <Button
                className="adocr_cancel"
                onClick={() => handleCloseDiscountModal()}
              >
                {" "}
                Cancel
              </Button>
              <Button
                className="adocr_update_btn"
                onClick={() => applyDiscount()}
              >
                Apply
              </Button>
            </div>
          </Box>
        </Modal>

        {/* Edit Item Modal */}
        <Modal
          open={showEditModifiersModal}
          onClose={() => handleCloseEditModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* top */}
            <div className="d-flex justify-content-between align-items-center">
              <span className="ad_body_title">Edit Customizations</span>
              <IconButton onClick={() => handleCloseEditModal()}>
                <X color="#000" />
              </IconButton>
            </div>

            {currentItem &&
              currentItem?.modifiers &&
              currentItem?.modifiers?.length > 0 && (
                <>
                  <FormGroup className="mt-3">
                    {currentItem &&
                      currentItem.modifiers.map((each, i) => {
                        return (
                          <div key={i}>
                            <p className="c3 w3 s2">{each.name}</p>
                            <div className="d-flex flex-column">
                              {each.options &&
                                each.options.map((item, ind) => {
                                  return (
                                    <div
                                      key={ind}
                                      className="
                                                          d-flex justify-content-between
                                                           align-items-center"
                                    >
                                      <FormControlLabel
                                        key={ind}
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: "#8943EF",
                                              "&.Mui-checked": {
                                                color: "#8943EF",
                                              },
                                            }}
                                            defaultChecked={item.sel}
                                            value={item.sel}
                                            onChange={() =>
                                              modifierSelectedEdit(
                                                i,
                                                ind,
                                                currentItem,
                                              )
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        }
                                        label={item.name}
                                      />
                                      <span>Rs. {item.price}</span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </FormGroup>
                </>
              )}

            {/* spice level */}
            {currentItem &&
              currentItem.hasSpiceLevel === true &&
              !isTextEmpty(currentItem.spiceLevel) && (
                <>
                  {" "}
                  <p className="spiceLevel_text ml-auto mr-auto mt-4">
                    {getSpiceText(spiceLevel)}
                  </p>
                  <SliderMUI
                    defaultValue={[20, 40]}
                    value={spiceLevel}
                    color="error"
                    aria-label="modifier"
                    step={null}
                    onChange={handleChangeSlide}
                    valueLabelDisplay="off"
                    marks={[0, 28, 66, 100].map((each) => ({
                      value: each,
                      label: "",
                    }))}
                  />
                </>
              )}

            {/* end */}
            <div className="d-flex justify-content-end mt-3">
              <Button
                className="adocr_cancel"
                onClick={() => handleCloseEditModal()}
              >
                {" "}
                Cancel
              </Button>
              <Button
                className="adocr_update_btn"
                onClick={() => updateFnAPI()}
              >
                Update
              </Button>
            </div>
          </Box>
        </Modal>
      </ThemeProvider>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

export const EditOrder = () => {
  let { id } = useParams();
  let editing = true;
  const navigate = useNavigate();
  let [orderData, setOrderData] = useState({});
  let [promoAmount, setPromoAmount] = useState(0);

  const filteredArray = useMemo(() => {
    if (orderData && orderData?.foods?.length > 0) {
      return filterAndModifyFoods(orderData.foods);
    }
    return [];
  }, [orderData]);

  useEffect(() => {
    if (
      !orderData.isAdminCreated &&
      orderData?.payment?.hasOwnProperty("promoDiscount") &&
      orderData?.payment?.promoDiscount
    ) {
      setPromoAmount(orderData?.payment?.promoDiscount);
    } else {
      setPromoAmount(0);
    }
  }, [orderData]);

  const [values, setValues] = useState({
    itemsArr: filteredArray,
    orderId: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    orderTypeId: "",
    paidAmount: 0,
    deliveryPartners: [],
    note: "",
    needCutlery: false,
    itemModalVisible: false,
    spiceLevel: 28,
    item: null,
    thisItem: null,
    allItems: [],
    combo: null,
    allCombos: [],
    thisCombo: null,
    errorPosition: "",
    errorText: "",
    first: true,
    loading: false,
    // mapCenter: [27.7172, 85.3240],
    add_lat: "",
    add_lng: "",
    add_address: null,
    add_id: "",
    // modal
    type: "",
    locationStatus: "",
    allModifiers: [],
    modifiersSel: [],
    quanSel: 1,
    add_item_loading: false,
    editItemModal: false,
    currentItem: null,
    customDiscount: { discountType: "percent", discountValue: null },
    customDeliveryCharge: null,
    subTotalPrice: null,
    thisCustomItem: null,
    mapUrl: null,
    taxes: [],
    data_loading: true,
  });
  const {
    itemsArr,
    orderId,
    name,
    phone,
    email,
    orderTypeId,
    paidAmount,
    deliveryPartners,
    locationStatus,
    note,
    needCutlery,
    itemModalVisible,
    spiceLevel,
    item,
    thisItem,
    allItems,
    currentItem,
    allCombos,
    thisCombo,
    errorPosition,
    errorText,
    first,
    thisCustomItem,
    // mapCenter,
    add_lat,
    add_lng,
    add_address,
    //  add_id,
    type,
    // allModifiers,
    modifiersSel,
    quanSel,
    add_item_loading,
    loading,
    customDiscount,
    customDeliveryCharge,
    subTotalPrice,
    mapUrl,
    taxes,
    data_loading,
  } = values;
  const [add_edit_visible_ind, Set_add_edit_visible] = useState(null);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showEditModifiersModal, setShowEditModifiersModal] = useState(false);
  const [coordinates, setCoordinates] = useState({ data: "" });

  useEffect(() => {
    setValues((x) => ({
      ...x,
      itemsArr: filteredArray,
    }));
  }, [filteredArray]);

  useEffect(() => {
    if (coordinates?.data?.includes(",")) {
      let a = coordinates?.data?.split(",").map((item) => item.trim());
      setValues((x) => ({ ...x, add_lat: a[0], add_lng: a[1] }));
    } else {
      let b = coordinates?.data.split(" ").map((item) => item.trim());
      setValues((x) => ({ ...x, add_lat: b[0], add_lng: b[1] }));
    }
  }, [coordinates.data]);

  // useEffect(() => {
  //   if (!orderData.isAdminCreated) {
  //     if (add_lat && add_lng) {
  //       POSTAPI("/settings/calculateDeliveryCharge", {
  //         latitude: add_lat,
  //         longitude: add_lng,
  //       }).then((res) => {
  //         if (res.success) {
  //           setValues((x) => ({
  //             ...x,
  //             customDeliveryCharge: res?.deliveryCharge,
  //           }));
  //         } else if (res.error)
  //           setValues((x) => ({
  //             ...x,
  //             errorPosition: "apiErr",
  //             errorText: "Couldn't get address price",
  //             customDeliveryCharge: orderData?.payment?.deliveryCharge || null,
  //           }));
  //       });
  //     }
  //   }
  // }, [
  //   add_lat,
  //   add_lng,
  //   orderData.isAdminCreated,
  //   orderData?.payment?.deliveryCharge,
  // ]);

  useEffect(() => {
    if (orderData && Object.keys(orderData).length > 0) {
      setValues((x) => ({
        ...x,
        orderId: orderData._id,
        name: orderData?.delivery?.location?.name || "",
        phone: orderData?.delivery?.location?.phone || "",
        email:
          orderData.delivery && orderData.delivery.location
            ? orderData.delivery.location.email
            : "",
        address: orderData?.delivery?.location?.address || "",
        orderTypeId: orderData?.delivery?.deliveryPartner?._id || "",
        note: orderData?.note || "",
        needCutlery: orderData?.needCutlery ? orderData?.needCutlery : false,
        mapUrl: orderData?.delivery?.location?.mapUrl || "",
        add_lat: orderData?.delivery?.location?.latitude || "",
        add_lng: orderData?.delivery?.location?.longitude || "",
        add_address: orderData?.delivery?.location?.address || "",
        locationStatus: orderData?.locationStatus || "",
        customDeliveryCharge: orderData.isAdminCreated
          ? orderData?.payment?.deliveryCharge || null
          : orderData?.payment?.deliveryCharge || null,
        paidAmount: orderData?.payment?.paidAmount
          ? orderData?.payment?.paidAmount
          : 0,
      }));
    }

    if (
      orderData?.delivery?.location?.latitude &&
      orderData?.delivery?.location?.longitude
    ) {
      setCoordinates((x) => ({
        ...x,
        data: `${orderData?.delivery?.location?.latitude} ${orderData?.delivery?.location?.longitude}`,
      }));
    } else {
      setCoordinates((x) => ({
        ...x,
        data: "",
      }));
    }
  }, [orderData, filteredArray]);

  //compares with all modifiers and creates and array with a property of selected or not
  function filterAndModifyFoods(foods) {
    return foods.map((food) => {
      if (food.customId) {
        return {
          ...food,
          spiceLevel: "Low",
          hasSpiceLevel: false,
          modifiers: [],
        };
      } else {
        // If customId doesn't exist
        if (
          !food.modifiers ||
          !food.allModifiers ||
          food.modifiers.length === 0 ||
          food.allModifiers.length === 0
        ) {
          return food;
        } else {
          const allModifiersMap = food.allModifiers.reduce((map, modifier) => {
            map[modifier.modifierId] = modifier;
            return map;
          }, {});
          const allModifiersFiltered = Object.values(allModifiersMap);

          const updatedItems = allModifiersFiltered.map((item) => {
            const updatedOptions = item.options.map((option) => ({
              ...option,
              sel: food.modifiers.some(
                (modifier) =>
                  modifier.modifierId === item.modifierId &&
                  modifier.name === item.name &&
                  modifier.options.some(
                    (modifierOption) =>
                      modifierOption.name === option.name &&
                      modifierOption.price === option.price,
                  ),
              ),
            }));

            return {
              ...item,
              options: updatedOptions,
            };
          });

          // Create the final modified food object
          return {
            ...food,
            modifiers: updatedItems,
          };
        }
      }
    });
  }

  useEffect(() => {
    if (!id) {
      navigate("/orders/created");
    }
  }, [id, navigate]);

  useEffect(() => {
    POSTAPI_ADMIN("/order/adminOne/", { orderId: id }).then((res) => {
      if (res.success) {
        setOrderData(res.order);
        setValues((x) => ({
          ...x,
          data_loading: false,
        }));
      } else if (res.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          data_loading: false,
        }));
    });
  }, [id]);

  const calculateTotalItemPrices = useMemo(() => {
    return () => {
      let totalItemPrices = 0;

      itemsArr.forEach((prod) => {
        let totalCostModifiers = 0;

        if (prod.modifiers) {
          for (const modifier of prod.modifiers) {
            for (const option of modifier.options) {
              if (option.sel) {
                totalCostModifiers += option.price;
              } else {
                totalCostModifiers += 0;
              }
            }
          }
        }

        const percentPrice =
          prod.discount && prod.discount.value
            ? Math.ceil(
                parseFloat(
                  Math.ceil(parseFloat(prod?.actualPrice)) -
                    (prod?.discount?.value / 100) *
                      Math.ceil(parseFloat(prod?.actualPrice)),
                ),
              )
            : 0;
        const percentPriceWithModifiers =
          percentPrice +
          (prod?.modifiers?.length > 0
            ? Math.ceil(parseFloat(totalCostModifiers))
            : 0);
        const valuePrice =
          prod.discount && prod.discount.value
            ? Math.ceil(
                parseFloat(
                  Math.ceil(parseFloat(prod?.actualPrice)) -
                    Math.ceil(parseFloat(prod.discount?.value)),
                ),
              )
            : 0;
        const valuePriceWithModifiers =
          valuePrice +
          (prod?.modifiers?.length > 0
            ? Math.ceil(parseFloat(totalCostModifiers))
            : 0);

        if (
          prod.discount &&
          prod.discount.type === "percent" &&
          prod.discount.value !== null &&
          prod.discount.value > 0
        ) {
          totalItemPrices += Math.ceil(
            parseFloat(
              Math.ceil(parseFloat(percentPriceWithModifiers)) *
                parseFloat(prod.quantity),
            ),
          );
        } else if (
          prod.discount &&
          prod.discount.type === "value" &&
          prod.discount.value !== null &&
          prod.discount.value > 0
        ) {
          totalItemPrices += Math.ceil(
            parseFloat(
              Math.ceil(parseFloat(valuePriceWithModifiers)) * prod.quantity,
            ),
          );
        } else {
          totalItemPrices += Math.ceil(
            parseFloat(
              (Math.ceil(parseFloat(prod.actualPrice)) +
                Math.ceil(parseFloat(totalCostModifiers))) *
                prod.quantity,
            ),
          );
        }
      });

      setValues((x) => ({
        ...x,
        subTotalPrice: Math.ceil(parseFloat(totalItemPrices)),
      }));
    };
  }, [itemsArr]);

  useEffect(() => {
    calculateTotalItemPrices();
  }, [itemsArr, calculateTotalItemPrices]);

  useEffect(() => {
    if (first) {
      POSTAPI_CreateInfoAdmin().then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            allItems: res.items,
            allCombos: res.combos,
            allItemsCombos: res.items.concat(res.combos),
            first: false,
          }));
        }
      });

      GETAPI_OrderTypes(`/order/orderType`).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            deliveryPartners: res.orderTypes,
          }));
        }
      });

      GETAPI_OrderTypes(`/settings`).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            taxes: res.taxes,
          }));
        }
      });
    }
  }, [first]);

  //returns boolean checking order type is default partner or not
  const selectedPartner = useMemo(() => {
    if (deliveryPartners.length === 0) return false;
    return deliveryPartners.find((partner) => partner._id === orderTypeId)
      ?.isDefaultPartner;
  }, [orderTypeId, deliveryPartners]);

  const handleDiscountTypeChange = (e) => {
    setValues((x) => ({
      ...x,
      customDiscount: {
        discountType: e.target.value,
        discountValue: customDiscount.discountValue,
      },
    }));
  };

  const handleDiscountValueChange = (e) => {
    setValues((x) => ({
      ...x,
      customDiscount: {
        discountType: customDiscount.discountType,
        discountValue: e.target.value,
      },
    }));
  };

  const applyDiscount = () => {
    if (!customDiscount.discountValue && !customDiscount.discountType) {
      return;
    }

    if (customDiscount.discountType === "value") {
      if (
        parseFloat(customDiscount.discountValue) >
        parseFloat(currentItem.actualPrice)
      ) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Value can't be more than the product price.",
        }));
        return;
      }
      if (parseFloat(customDiscount.discountValue) < 0) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Value can't be less than 0.",
        }));
        return;
      }
    }

    if (customDiscount.discountType === "percent") {
      if (parseFloat(customDiscount.discountValue) > 100) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Can't apply more than 100%.",
        }));
        return;
      }
      if (parseFloat(customDiscount.discountValue) < 0) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Cant't apply less than 0.",
        }));
        return;
      }
    }

    const updatedArr = itemsArr.map((item) => {
      if (item.itemId && !item.identifierId) {
        if (item.itemId === currentItem.itemId && !currentItem.identifierId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.itemId && item.identifierId) {
        if (
          item.itemId === currentItem.itemId &&
          item.identifierId === currentItem.identifierId
        ) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.comboId && !item.identifierId) {
        if (item.comboId === currentItem.comboId && !currentItem.identifierId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.comboId && item.identifierId) {
        if (
          item.comboId === currentItem.comboId &&
          item.identifierId === currentItem.identifierId
        ) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.customId) {
        if (item.customId === currentItem.customId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        }
      }
      return item;
    });

    setValues((x) => ({
      ...x,
      errorPosition: "",
      errorText: "",
    }));
    setValues((x) => ({ ...x, itemsArr: updatedArr }));
    setShowDiscountModal(false);
    Set_add_edit_visible(null);
    setValues((x) => ({
      ...x,
      customDiscount: { discountType: "percent", discountValue: null },
    }));
  };

  const handleAddItemBtn = () => {
    // var passValidation =
    //   orderTypeId === "" ? false : selectedPartner ? false : true;
    var passValidation = true;
    var validated = ValidateLetAddItemBtn(
      orderTypeId,
      name,
      phone,
      add_lat,
      add_lng,
      setValues,
      passValidation,
    );
    if (!validated) return;
    setValues((x) => ({
      ...x,
      itemModalVisible: true,
      errorPosition: "",
      errorText: "",
    }));
  };

  useEffect(() => {
    if (itemsArr.length === 0) {
      setValues((x) => ({ ...x, customDeliveryCharge: null }));
    }
  }, [itemsArr]);

  // const setMapCoordinatesMapBox = (newlnglat) => {
  //   // var split = index.split(',')
  //   setValues((prev) => ({
  //     ...prev,
  //     alt: `${newlnglat[0]},${newlnglat[1]}`,
  //     add_address: "",
  //     add_lng: newlnglat[0],
  //     add_lat: newlnglat[1],
  //     mapCenter: newlnglat,
  //   }));
  // };

  const closeModal = () => {
    setValues((val) => ({
      ...val,
      itemModalVisible: false,
      editItemModal: false,
      type: "",
      spiceLevel: 28,
      item: null,
      modifiersSel: [],
      quanSel: 1,
      thisItem: null,
      thisCombo: null,
      add_item_loading: false,
      thisCustomItem: null,
      errorPosition: "",
      errorText: "",
    }));
  };

  // single item select drop handle (for item select)
  const ItemSelectWithDetails = (e) => {
    var thisItem;
    var refer;
    thisItem = allItems.filter((x) => x._id === e.value);
    thisItem = thisItem.length > 0 ? thisItem[0] : null;
    if (!thisItem) return;
    refer = JSON.parse(JSON.stringify(thisItem));
    var allModifiersData = refer.modifiers;
    setValues((prev) => ({
      ...prev,
      item: e.value,
      thisItem: thisItem,
      allModifiers: allModifiersData,
      modifiersSel: allModifiersData,
    }));
  };

  const ComboSelected = (e) => {
    var thisComboItem;
    var refer;
    thisComboItem = allCombos.filter((x) => x._id === e.value);
    thisComboItem = thisComboItem.length > 0 ? thisComboItem[0] : null;
    if (!thisComboItem) return;
    refer = JSON.parse(JSON.stringify(thisComboItem));
    setValues((prev) => ({
      ...prev,
      item: e.value,
      thisCombo: refer,
    }));
    // handleChangeFun(mainValues => ({ ...mainValues, [varName]: e.value }))
  };

  const modifierSelected = (modInd, optionInd) => {
    var nowMods = [...modifiersSel];
    var selected = nowMods[modInd].options[optionInd];
    selected.sel = selected.sel ? false : true;
    setValues((prev) => ({ ...prev, modifiersSel: nowMods }));
  };

  const modifierSelectedEdit = (modIndex, optionsIndex) => {
    const newModifiers = _.cloneDeep(modifiersSel);
    const selected = newModifiers[modIndex].options[optionsIndex];
    selected.sel = !selected.sel;
    setValues((prev) => ({ ...prev, modifiersSel: newModifiers }));
  };

  const handleChangeSlide = (e, newVal) => {
    setValues((x) => ({ ...x, spiceLevel: newVal }));
  };

  // add item button at end
  const addItem = () => {
    if (type === "") return;
    if (type === "Combo Item" && !thisCombo) {
      return;
    }
    if (type === "Item" && !thisItem) {
      return;
    }
    if (type === "Custom Item" && !thisCustomItem) {
      return;
    }

    if (type === "Custom Item" && thisCustomItem) {
      if (!thisCustomItem.name || !thisCustomItem.price) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide name and price.",
        }));
        return;
      }
    }

    setValues((x) => ({ ...x, add_item_loading: true }));
    if (thisCustomItem) {
      //custom item
      let newCustomItem = {
        customId: uuidv4(),
        name: thisCustomItem.name,
        price: thisCustomItem.price,
        actualPrice: thisCustomItem.price,
        unitPrice: thisCustomItem.price,
        quantity: quanSel,
        spiceLevel: null,
        hasSpiceLevel: false,
        isCooked: false,
        modifiers: [],
      };

      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, newCustomItem],
      }));
      closeModal();
      return;
    }
    var thisItem_a = thisItem;
    var send_data = {
      name,
      phone,
      email,
      address: add_address,
      latitude: add_lat,
      longitude: add_lng,
      needCutlery,
      note,
      orderTypeId,
    };
    var itemInfo = {};
    var comboInfo = {};

    let itemExists = false;
    let comboExists = false;

    if (thisItem_a) {
      //item
      const productExists = itemsArr.some(
        (item) => item.itemId === thisItem_a._id,
      );
      if (productExists) {
        if (
          !item.modifiers &&
          thisItem_a?.modifiers?.length === 0 &&
          thisItem_a?.hasSpiceLevel === false
        ) {
          setValues((x) => ({ ...x, add_item_loading: true }));
          closeModal();
          return;
        }
        itemExists = true;
      }
      thisItem_a.spiceLevel = getSpiceText(spiceLevel);
      var modsArr = [];
      if (modifiersSel.length > 0) {
        modifiersSel.forEach((element) => {
          var thisEl = { ...element };
          thisEl.options = [];
          element.options.forEach((op) => {
            // if (op.sel) {
            //   thisEl.options.push({
            //     name: op.name,
            //     price: op.price,
            //   });
            // }
            thisEl.options.push({
              name: op.name,
              price: op.price,
              sel: op.sel === true ? true : false,
            });
          });
          if (thisEl.options.length > 0) {
            modsArr.push(thisEl);
          }
        });
      }
      itemInfo = {
        itemId: thisItem_a._id,
        quantity: quanSel,
        spiceLevel: thisItem.hasSpiceLevel ? getSpiceText(spiceLevel) : null,
        modifiers: modsArr,
        image: thisItem_a.image,
        name: thisItem_a.name,
        isCooked: false,
        price:
          !orderData.isAdminCreated && thisItem_a.hasDiscount
            ? thisItem_a.discountedPrice
            : thisItem_a.price,
        actualPrice:
          !orderData.isAdminCreated && thisItem_a.hasDiscount
            ? thisItem_a.discountedPrice
            : thisItem_a.price,
        hasDiscount: thisItem_a.hasDiscount,
        discountedPrice: thisItem_a.discountedPrice,
        hasSpiceLevel: thisItem_a.hasSpiceLevel,
      };
      if (itemExists) {
        itemInfo.identifierId = uuidv4();
      }
      if (!itemExists) {
        itemInfo.identifierId = null;
      }
      send_data = {
        ...send_data,
        itemInfo,
      };
      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, send_data.itemInfo],
      }));
      closeModal();
    } else {
      //combo

      thisItem_a = allCombos.filter((x) => x._id === item);
      if (thisItem_a.length === 0) return;
      thisItem_a = thisItem_a[0];
      const productExists = itemsArr.some(
        (item) => item.comboId === thisItem_a._id,
      );
      if (productExists) {
        if (
          !item.modifiers &&
          thisItem_a?.modifiers?.length === 0 &&
          thisItem_a?.hasSpiceLevel === false
        ) {
          setValues((x) => ({ ...x, add_item_loading: true }));
          closeModal();
          return;
        }
        comboExists = true;
      }
      comboInfo = {
        comboId: thisItem_a._id,
        quantity: quanSel,
        spiceLevel: getSpiceText(spiceLevel),
        image: thisItem_a.image,
        name: thisItem_a.name,
        isCooked: false,
        price:
          !orderData.isAdminCreated && thisItem_a.hasDiscount
            ? thisItem_a.discountedPrice
            : thisItem_a.price,
        actualPrice:
          !orderData.isAdminCreated && thisItem_a.hasDiscount
            ? thisItem_a.discountedPrice
            : thisItem_a.price,
        hasDiscount: thisItem_a.hasDiscount,
        hasSpiceLevel: thisItem_a.hasSpiceLevel,
        discountedPrice: thisItem_a.discountedPrice,
      };
      if (comboExists) {
        comboInfo.identifierId = uuidv4();
      }
      if (!comboExists) {
        comboInfo.identifierId = null;
      }
      send_data = {
        ...send_data,
        comboInfo,
      };
      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, send_data.comboInfo],
      }));
      closeModal();
    }
  };

  // edit clicked in menu
  const editItemFn = (prod) => {
    if (prod.itemId) {
      var modTestArr = [];
      prod.allModifiers.forEach((element, ind) => {
        var selected = null;
        prod.modifiers.filter((x) => {
          if (x.modifierId === element.modifierId) {
            selected = x;
          }
          return selected;
        });
        if (selected) {
          //has modifier selected
          selected.options.forEach((op, i) => {
            var index = element.options.findIndex(
              (obj) => obj.name === op.name,
            );
            op.sel = true;
            element.options[index] = op;
          });
          modTestArr.push(element);
        } else modTestArr.push(element);
      });
      prod.modifiers = modTestArr;
      setValues((prev) => ({
        ...prev,
        item: prod.itemId,
        thisItem: prod,
        allModifiers: prod.allModifiers,
        modifiersSel: prod.modifiers,
        editItemModal: true,
        errorPosition: "",
        errorText: "",
        spiceLevel: prod.spiceLevel ? getSpiceValue(prod.spiceLevel) : null,
      }));
    } else if (prod.comboId) {
      setValues((prev) => ({
        ...prev,
        item: prod.comboId,
        thisCombo: prod,
        editItemModal: true,
        errorPosition: "",
        errorText: "",
        spiceLevel: prod.spiceLevel ? getSpiceValue(prod.spiceLevel) : null,
        quanSel: prod.quantity,
      }));
    } else return;
  };

  const updateFnAPI = () => {
    if (currentItem.itemId && !currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.itemId === currentItem.itemId && !item.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
              modifiers: modifiersSel?.length > 0 ? modifiersSel : [],
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr, modifiersSel: null }));
    }

    if (currentItem.itemId && currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.itemId === currentItem.itemId &&
        item.identifierId === currentItem.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
              modifiers: modifiersSel?.length > 0 ? modifiersSel : [],
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr, modifiersSel: null }));
    }

    if (currentItem.comboId && !currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.comboId === currentItem.comboId && !item.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr }));
    }

    if (currentItem.comboId && currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.comboId === currentItem.comboId &&
        item.identifierId === currentItem.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr }));
    }

    setShowEditModifiersModal(false);
    setValues((x) => ({ ...x, spiceLevel: 28 }));
  };

  const createFn = async () => {
    if (
      orderData.status === ORDER_STATUS.NOT_ASSIGNED ||
      orderData.status === ORDER_STATUS.PENDING ||
      orderData.status === ORDER_STATUS.PICKED_UP ||
      orderData.status === ORDER_STATUS.COMPLETED ||
      orderData.status === ORDER_STATUS.CANCELED ||
      orderData.status === ORDER_STATUS.FAILED
    ) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: "Can't Edit this order",
      }));
      return;
    }

    if (orderData?.isAdminCreated) {
      if (!orderTypeId) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide a order type",
        }));
        return;
      }
    }

    if (paidAmount) {
      if (parseInt(paidAmount) > parseInt(calculatedTotalPrice)) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Paid amount can't be more than total price.",
        }));
        return;
      }
      if (parseInt(paidAmount) < 0) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Paid amount can't be less than 0.",
        }));
        return;
      }
    }

    if (itemsArr.length === 0) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: "Please provide an item",
      }));
      return;
    }

    if (orderData?.isAdminCreated) {
      if (selectedPartner) {
        const phonePattern = /^[9][6-9]\d{8}$/;

        if (phone === "") {
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: "Please provide phone number",
          }));
          return;
        }
        if (!phonePattern.test(phone)) {
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: "Please provide a valid phone number",
          }));
          return;
        }
      }
    } else {
      const phonePattern = /^[9][6-9]\d{8}$/;

      if (phone === "") {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide phone number",
        }));
        return;
      }
      if (!phonePattern.test(phone)) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide a valid phone number",
        }));
        return;
      }
    }

    // reomve sel proprty and also remove those with sel prperty
    const updatedItemsArr = itemsArr.map((item) => {
      if (item.modifiers && item.modifiers.length > 0) {
        // Filter out options with sel set to false and remove the sel property
        const updatedModifiers = item.modifiers.map((modifier) => ({
          ...modifier,
          options: modifier.options
            .filter((option) => option.sel)
            .map(({ sel, ...rest }) => rest),
        }));
        // Remove modifiers with no selected options
        const filteredModifiers = updatedModifiers.filter(
          (modifier) => modifier.options.length > 0,
        );
        return {
          ...item,
          modifiers: filteredModifiers,
        };
      }
      return item;
    });

    const itemsInfo = [];
    const combosInfo = [];
    const customItemsInfo = updatedItemsArr
      .filter((item) => item?.customId)
      .map((item) => {
        const { spiceLevel, hasSpiceLevel, modifiers, ...rest } = item;
        return { ...rest };
      });

    updatedItemsArr.forEach((item) => {
      if (!item?.customId) {
        if (item.itemId) {
          itemsInfo.push(item);
        } else if (item.comboId) {
          combosInfo.push(item);
        }
      }
    });

    setValues((x) => ({ ...x, loading: true }));
    let data = !orderData?.isAdminCreated
      ? {
          name,
          phone,
          email,
          address: add_address,
          latitude: add_lat,
          longitude: add_lng,
          mapUrl,
          locationStatus,
          needCutlery,
          note,
          itemsInfo,
          combosInfo,
          customItemsInfo,
          paidAmount: parseFloat(paidAmount),
          customDeliveryCharge: Math.ceil(parseFloat(customDeliveryCharge)),
        }
      : selectedPartner
        ? {
            orderTypeId,
            name,
            phone,
            email,
            address: add_address,
            latitude: add_lat,
            longitude: add_lng,
            mapUrl,
            locationStatus,
            needCutlery,
            note,
            itemsInfo,
            combosInfo,
            customItemsInfo,
            paidAmount: parseFloat(paidAmount),
            customDeliveryCharge: Math.ceil(parseFloat(customDeliveryCharge)),
          }
        : {
            orderTypeId,
            note,
            itemsInfo,
            combosInfo,
            customItemsInfo,
            paidAmount: parseFloat(paidAmount),
            customDeliveryCharge: Math.ceil(parseFloat(customDeliveryCharge)),
          };

    if (!isTextEmpty(orderId)) {
      //edit
      let edit_data = { ...data, orderId };
      POSTAPI_ADMIN("/order/admin/edit", edit_data).then((res) => {
        if (res.success) {
          navigate(`/orders/created`);
          setValues((x) => ({ ...x, loading: false }));
        } else if (res.error) {
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: res.error,
            loading: false,
          }));
        }
        closeModal();
      });
    } else {
      setValues((x) => ({ ...x, loading: false }));
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModifiersModal(false);
    setValues((x) => ({
      ...x,
      currentItem: null,
      spiceLevel: null,
      modifiersSel: null,
    }));
  };

  const handleCloseDiscountModal = () => {
    setShowDiscountModal(false);
    setValues((x) => ({ ...x, currentItem: null }));
    setValues((x) => ({
      ...x,
      customDiscount: { discountType: "percent", discountValue: null },
      errorPosition: "",
      errorText: "",
    }));
  };

  const form = (
    <div>
      {LabelComp("Name", "name")}
      {InputComp(name, "name", setValues, "")}
      {LabelComp("Phone", "phone")}
      {InputComp(phone, "phone", setValues, "")}
      {LabelComp("Email (optional)", "email", errorPosition, errorText)}
      {InputComp(email, "email", setValues, "")}
      {/* <div className='mb-2 pickMapDiv'>
            <Test setMapCoordinates={setMapCoordinates}
                mapCenter={mapCenter} //[add_lat 27,add_lng 85]
                markCoord={isTextEmpty(add_lng) ? null : [add_lat, add_lng]}
                markAdd={add_address}
                type={isTextEmpty(add_id) ? 'Add Address' : 'Edit Address'} />
        </div> */}
      {/* <MapBox lnglat={[add_lng, add_lat]} setLngLat={setMapCoordinatesMapBox} /> */}
      {LabelComp("Address co-ordinates", "coordinates")}
      {InputComp(
        coordinates.data,
        "data",
        setCoordinates,
        "",
        "27.683206, 85.313344",
      )}
      <p className="">{LabelComp("Address URL", "mapUrl")}</p>
      {InputComp(mapUrl, "mapUrl", setValues, "")}

      <p className="">{LabelComp("Location Status", "locationStatus")}</p>
      <RadioGroup
        aria-label="location-status"
        name="location-status"
        value={locationStatus}
        onChange={(e) => {
          setValues((x) => ({ ...x, locationStatus: e.target.value }));
        }}
        row={true}
      >
        <FormControlLabel
          value="Verified"
          control={<Radio color="error" />}
          label="Verified"
        />
        <FormControlLabel
          value="Hold"
          control={<Radio color="error" />}
          label="Hold"
        />
        <FormControlLabel
          value="Not Verified"
          control={<Radio color="error" />}
          label="Not Verified"
        />
      </RadioGroup>
      <p className="s2 w3 ">Additional Order Details</p>
      <div className="d-flex mb-3">
        <ForkKnife size={26} color="#8943EF" />
        <div className="ml-3 w-100">
          <div className="d-flex justify-content-between">
            <span>Do you need cutlery?</span>
            <RedSwitch
              checked={needCutlery}
              onChange={() =>
                setValues((x) => ({
                  ...x,
                  needCutlery: !needCutlery,
                }))
              }
            />
          </div>
          <span className="infoText">
            Consider using your own cutlery to reduce plastic use
          </span>
        </div>
      </div>
    </div>
  );

  const subTotalWithTax = useMemo(() => {
    return taxes.reduce((subtotal, item) => {
      if (item.type === "percent") {
        const taxAmount =
          (parseFloat(item.amount) / 100) * parseFloat(subTotalPrice);
        return parseFloat(subtotal + taxAmount);
      }
      if (item.type === "value") {
        const taxAmount = parseFloat(item.amount);
        return parseFloat(subtotal + taxAmount);
      }
      return subtotal;
    }, parseFloat(subTotalPrice));
  }, [subTotalPrice, taxes]);

  const calculatedTotalPrice = useMemo(() => {
    let totalPrice = 0;

    if (taxes && taxes.length > 0) {
      totalPrice =
        customDeliveryCharge > 0 && customDeliveryCharge !== null
          ? parseFloat(
              parseFloat(subTotalWithTax) +
                parseFloat(customDeliveryCharge) -
                parseFloat(promoAmount),
            )
          : parseFloat(parseFloat(subTotalWithTax) - parseFloat(promoAmount));
    } else {
      totalPrice =
        customDeliveryCharge > 0
          ? parseFloat(
              parseFloat(subTotalPrice) +
                parseFloat(customDeliveryCharge) -
                parseFloat(promoAmount),
            )
          : parseFloat(parseFloat(subTotalPrice) - parseFloat(promoAmount));
    }

    return totalPrice.toFixed(2);
  }, [
    taxes,
    customDeliveryCharge,
    subTotalWithTax,
    subTotalPrice,
    promoAmount,
  ]);

  if (data_loading) {
    return (
      <>
        <div className="w-100 h-100 d-flex justify-content-center align-items-centet">
          <CircularProgress />
        </div>
        {AlertError(errorPosition, errorText, setValues)}
      </>
    );
  }

  if (id && Object.keys(orderData).length === 0) {
    return (
      <>
        <div className="w-100 h-100 d-flex justify-content-center align-items-centet">
          No order details found
        </div>
        {AlertError(errorPosition, errorText, setValues)}
      </>
    );
  }

  return (
    <>
      <ThemeProvider theme={modalTheme}>
        <Button
          className="category_top_add"
          onClick={() => {
            orderData.isAdminCreated
              ? navigate("/orders/created")
              : navigate("/orders/onlineorders");
          }}
        >
          <ArrowLeft size={30} />
          <span className="ml-2">Edit Order</span>
        </Button>

        <div className="ado_create_div">
          <div className="adoc_left">
            {orderData?.isAdminCreated && (
              <div className="mb-4">
                <p className="s2 w3">Order Type</p>
                {LabelComp(
                  "Order Type",
                  "orderTypeId",
                  errorPosition,
                  errorText,
                )}
                <select
                  name="orderTypeId"
                  id="orderTypeId"
                  className="adocr_select"
                  value={orderTypeId}
                  onChange={(e) =>
                    setValues((x) => ({
                      ...x,
                      orderTypeId: e.target.value,
                    }))
                  }
                >
                  <option className="adocr_select_op" disabled value="">
                    Select an option
                  </option>

                  {deliveryPartners.map(({ _id, name }) => {
                    return (
                      <option key={_id} value={_id} className="adocr_select_op">
                        {name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            <p className="s2 w3">
              {orderTypeId && selectedPartner ? "Customer" : "Order"} Details
            </p>

            <p className="s2 w3">
              {orderTypeId === ""
                ? form
                : orderTypeId.length > 0 && selectedPartner && form}
            </p>

            <h5>
              <b>Note</b>
            </h5>
            {InputCompTextarea(note, "note", setValues, "order_note")}
          </div>

          <div className="adoc_left">
            {itemsArr && itemsArr.length > 0 && (
              <div className="adocr_right_items_div">
                {itemsArr.map((prod, ind) => {
                  let totalCostModifiers = 0;

                  if (prod?.modifiers) {
                    for (const modifier of prod.modifiers) {
                      for (const option of modifier.options) {
                        if (option.sel) {
                          totalCostModifiers += option.price;
                        } else {
                          totalCostModifiers += 0;
                        }
                      }
                    }
                  }

                  const percentPrice =
                    prod.discount && prod.discount.value
                      ? Math.ceil(parseFloat(prod.actualPrice)) -
                        (Math.ceil(parseFloat(prod.discount.value)) / 100) *
                          prod.actualPrice
                      : 0;
                  const percentPriceWithModifiers =
                    percentPrice +
                    (prod?.modifiers?.length > 0
                      ? Math.ceil(parseFloat(totalCostModifiers))
                      : 0);
                  const valuePrice = prod?.discount?.value
                    ? Math.ceil(parseFloat(prod?.actualPrice)) -
                      Math.ceil(parseFloat(prod?.discount?.value))
                    : 0;
                  const valuePriceWithModifiers =
                    valuePrice +
                    (prod?.modifiers?.length > 0
                      ? Math.ceil(parseFloat(totalCostModifiers))
                      : 0);

                  return (
                    <div className="eachCart_div" key={ind}>
                      <div className="mb-2 d-flex" key={ind}>
                        <Avatar
                          src={prod.image}
                          className="eachCart_image"
                          variant="rounded"
                        />
                        <div className="d-flex flex-column w-100 justify-content-center">
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="eachCart_name">{prod.name}</span>
                            {AdOrderEditDeleteMenu(
                              prod,
                              editItemFn,
                              add_edit_visible_ind,
                              Set_add_edit_visible,
                              ind,
                              itemsArr,
                              setValues,
                              setShowDiscountModal,
                              setShowEditModifiersModal,
                              orderData,
                            )}
                          </div>
                          <div className="d-flex w-100 justify-content-between mt-2">
                            {/* <span>Qty : {prod.quantity}</span> */}
                            {CartQuantityAdminArr(
                              prod,
                              itemsArr,
                              setValues,
                              editing,
                            )}
                            <span className="eachCart_price">
                              {prod.discount &&
                              prod.discount.type === "percent" &&
                              prod.discount.value !== null &&
                              prod.discount.value > 0 ? (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      Math.ceil(
                                        parseFloat(percentPriceWithModifiers),
                                      ) * prod.quantity,
                                    ),
                                  )}
                                  <del
                                    className="text-muted ml-1"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Rs.{" "}
                                    {Math.ceil(
                                      parseFloat(
                                        (Math.ceil(
                                          parseFloat(prod.actualPrice),
                                        ) +
                                          Math.ceil(
                                            parseFloat(totalCostModifiers),
                                          )) *
                                          prod.quantity,
                                      ),
                                    )}
                                  </del>
                                </>
                              ) : prod.discount &&
                                prod.discount.type === "value" &&
                                prod.discount.value !== null &&
                                prod.discount.value > 0 ? (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      Math.ceil(
                                        parseFloat(valuePriceWithModifiers),
                                      ) * prod.quantity,
                                    ),
                                  )}
                                  <del
                                    className="text-muted ml-1"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Rs.{" "}
                                    {Math.ceil(
                                      parseFloat(
                                        (Math.ceil(
                                          parseFloat(prod.actualPrice),
                                        ) +
                                          Math.ceil(
                                            parseFloat(totalCostModifiers),
                                          )) *
                                          prod.quantity,
                                      ),
                                    )}
                                  </del>
                                </>
                              ) : (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      (Math.ceil(parseFloat(prod.actualPrice)) +
                                        Math.ceil(
                                          parseFloat(totalCostModifiers),
                                        )) *
                                        prod.quantity,
                                    ),
                                  )}{" "}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* customizations */}
                      {((prod.modifiers &&
                        prod.modifiers
                          .map((item) => ({
                            ...item,
                            options: item.options.filter(
                              (option) => option.sel,
                            ),
                          }))
                          .filter((item) => item.options.length > 0).length >
                          0) ||
                        prod.hasSpiceLevel === true) && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={
                              <CaretDown weight="bold" className="ml-2" />
                            }
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            Customizations
                          </AccordionSummary>
                          <AccordionDetails>
                            {prod.modifiers && prod.modifiers.length > 0 && (
                              <>
                                {prod.modifiers
                                  .map((item) => ({
                                    ...item,
                                    options: item.options.filter(
                                      (option) => option.sel,
                                    ),
                                  }))
                                  .filter((item) => item.options.length > 0)
                                  .map((each, i) => (
                                    <div key={i}>
                                      <span className="c3 w3 s2">
                                        {each.name}
                                      </span>
                                      <div className="d-flex flex-column mt-2">
                                        {each.options &&
                                          each.options.map((eachOP, ind) => {
                                            return (
                                              <div
                                                key={ind}
                                                className="
                                                                  d-flex justify-content-between
                                                                   align-items-center mb-2"
                                              >
                                                <span>{eachOP.name}</span>
                                                <span>Rs. {eachOP.price}</span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  ))}
                              </>
                            )}
                            {prod.hasSpiceLevel && prod.spiceLevel !== null && (
                              <div className={"d-flex flex-column"}>
                                <span className="c3 w3 s2 mb-1">
                                  Spice Level
                                </span>
                                <span className="tile_mod_text">
                                  {prod.spiceLevel}
                                </span>
                              </div>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                  );
                })}

                <div className="mt-4 mb-1 border-top" />
                <div className="d-flex flex-column pl-3 pr-3 w-100 justify-content-between align-items-center mt-4">
                  <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                    <div>Subtotal</div>
                    <div className="fs-6 fw-semibold ">
                      Rs. {Math.ceil(parseFloat(subTotalPrice))}
                    </div>
                  </div>
                  {taxes &&
                    taxes.length > 0 &&
                    taxes.map((item) => {
                      return (
                        <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                          <div>{item.name}</div>
                          <div className="fs-6 fw-semibold ">
                            {item.type === "value" && `Rs. ${item.amount}`}
                            {item.type === "percent" &&
                              `Rs. ${parseFloat(
                                (parseFloat(item.amount) / 100) * subTotalPrice,
                              ).toFixed(2)} (${item.amount} %)`}
                          </div>
                        </div>
                      );
                    })}

                  {orderData?.payment?.hasOwnProperty("promoDiscount") &&
                  orderData?.payment?.promoDiscount &&
                  orderData?.payment?.promoDiscount > 0 ? (
                    <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                      <div>Promo discount</div>
                      <div className="fs-6 fw-semibold ">
                        Rs. {parseFloat(orderData.payment?.promoDiscount)}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {orderData.isAdminCreated ? (
                    selectedPartner ? (
                      <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                        <div>Delivery charge </div>
                        <div className="fs-6  fw-semibold d-flex flex-row justify-content-center align-items-center">
                          <div className="mr-2">Rs.{"   "}</div>
                          <TextField
                            type="number"
                            color="error"
                            placeholder="Amount..."
                            value={customDeliveryCharge}
                            onChange={(e) => {
                              setValues((x) => ({
                                ...x,
                                customDeliveryCharge: e.target.value,
                              }));
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "15px",
                                borderColor: "#8943EF",
                                width: "100px",
                                height: "40px",
                                "&:focus": {
                                  outlineColor: "#black",
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                      <div>Delivery charge </div>
                      <div className="fs-6  fw-semibold d-flex flex-row justify-content-center align-items-center">
                        <div className="mr-2">Rs.{"   "}</div>
                        <div>{customDeliveryCharge}</div>
                      </div>
                    </div>
                  )}

                  <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                    <div>Paid Amount </div>
                    <div className="fs-6  fw-semibold d-flex flex-row justify-content-center align-items-center">
                      <div className="mr-2">Rs.{"   "}</div>
                      <TextField
                        type="number"
                        value={paidAmount === 0 ? "" : paidAmount}
                        onChange={(e) => {
                          setValues((x) => ({
                            ...x,
                            paidAmount: e.target.value,
                          }));
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "15px",
                            borderColor: "#8943EF",
                            width: "100px",
                            height: "40px",
                            "&:focus": {
                              outlineColor: "#black",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="d-flex  flex-row w-100 mt-3 justify-content-between align-items-center">
                    <div>Total</div>
                    <div className="fs-3 fw-bold ">
                      Rs. {calculatedTotalPrice}
                    </div>
                  </div>
                  {paidAmount && parseFloat(paidAmount) > 0 ? (
                    <>
                      <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                        <div>Remaining Amount </div>
                        <div className="fs-3 fw-bold ">
                          Rs.{" "}
                          {parseFloat(
                            parseInt(calculatedTotalPrice) -
                              parseFloat(paidAmount),
                          ).toFixed(2)}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}

            <Button
              className="adocr_btn mt-3"
              onClick={() => handleAddItemBtn()}
            >
              + Add Item
            </Button>

            {itemsArr && itemsArr.length > 0 && (
              <div className="mt-auto">
                {HalfRightBtnComp("Update Order", createFn, loading)}
              </div>
            )}
          </div>
        </div>

        <Modal
          open={itemModalVisible}
          onClose={() => closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* top */}
            <div className="d-flex justify-content-between align-items-center">
              <span className="ad_body_title">Add Item</span>
              <IconButton onClick={() => closeModal()}>
                <X color="#000" />
              </IconButton>
            </div>

            {/* body */}
            {LabelComp("Type", "type", errorPosition, errorText)}
            <select
              name="items"
              id="items"
              className="adocr_select"
              value={type}
              onChange={(e) =>
                setValues((x) => ({
                  ...x,
                  type: e.target.value,
                  item: null,
                  thisItem: null,
                }))
              }
            >
              <option className="adocr_select_op" value="">
                Select an option
              </option>
              <option className="adocr_select_op" value="Item">
                Item
              </option>
              <option className="adocr_select_op" value="Combo Item">
                Combo Item
              </option>
              <option className="adocr_select_op" value="Custom Item">
                Custom Item
              </option>
            </select>

            {/* <div
              className="adocr_select_container"
              style={{
                borderRadius: "15px",
                border: "1px solid rgba(0, 0, 0, 0.2)",
                padding: "10px 25px 10px 20px ",
                fontSize: "20px",
                marginBottom: "15px",
              }}
            >
              <div className="adocr_select_option d-flex align-items-center mb-1">
                <label htmlFor="item" className="adocr_select_label ">
                  Item
                </label>
                <Radio
                  color="error"
                  id="item"
                  checked={type === "Item"}
                  className="adocr_select_radio ms-auto"
                  onChange={() =>
                    setValues((x) => ({
                      ...x,
                      type: "Item",
                      item: null,
                      thisItem: null,
                    }))
                  }
                />
              </div>

              <div className="adocr_select_option d-flex align-items-center mb-1">
                <label htmlFor="combo-item" className="adocr_select_label">
                  Combo Item
                </label>

                <Radio
                  color="error"
                  id="combo-item"
                  checked={type === "Combo Item"}
                  className="adocr_select_radio ms-auto"
                  onChange={() =>
                    setValues((x) => ({
                      ...x,
                      type: "Combo Item",
                      item: null,
                      thisItem: null,
                    }))
                  }
                />
              </div>

              <div className="adocr_select_option d-flex align-items-center ">
                <label htmlFor="custom-item" className="adocr_select_label">
                  Custom Item
                </label>
                <Radio
                  color="error"
                  id="custom-item"
                  checked={type === "Custom Item"}
                  className="adocr_select_radio ms-auto"
                  onChange={() =>
                    setValues((x) => ({
                      ...x,
                      type: "Custom Item",
                      item: null,
                      thisItem: null,
                    }))
                  }
                />
              </div>
            </div> */}

            {/* item */}
            {type === "Item" && (
              <div>
                {/* item */}
                {LabelComp("Item", "item", errorPosition, errorText)}
                <Select
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  maxMenuHeight={"200px"}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  options={allItems.map((item) =>
                    item._id ? { label: item.name, value: item._id } : item,
                  )}
                  value={allItems
                    .filter((option) => option._id === item)
                    .map((item) => ({ label: item.name, value: item._id }))}
                  name={"item"}
                  onChange={(e) => ItemSelectWithDetails(e)}
                  className="mb-2"
                />

                {thisItem && (
                  <>
                    <p className="mt-3 mb-2">
                      Price: Rs.{" "}
                      {!orderData.isAdminCreated && thisItem.hasDiscount
                        ? thisItem.discountedPrice
                        : thisItem.price}
                    </p>
                    <p>Quantity</p>
                    {CartQuantityAdmin(quanSel, setValues)}
                  </>
                )}

                {thisItem &&
                  thisItem.modifiers &&
                  thisItem.modifiers.length > 0 && (
                    <>
                      <FormGroup className="mt-3">
                        {thisItem.modifiers.map((each, i) => (
                          <div key={i}>
                            <p className="c3 w3 s2">{each.name}</p>
                            <div className="d-flex flex-column">
                              {each.options &&
                                each.options.map((eachOP, ind) => {
                                  return (
                                    <div
                                      key={ind}
                                      className="
                                                            d-flex justify-content-between
                                                             align-items-center"
                                    >
                                      <FormControlLabel
                                        key={ind}
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: "#8943EF",
                                              "&.Mui-checked": {
                                                color: "#8943EF",
                                              },
                                            }}
                                            // checked={checkedOrNot(ind, thisItem.modifiersArr_Selected[i])}
                                            value={each.sel}
                                            onClick={() =>
                                              modifierSelected(i, ind, thisItem)
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        }
                                        label={eachOP.name}
                                      />
                                      <span>Rs. {eachOP.price}</span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ))}
                      </FormGroup>
                    </>
                  )}

                {/* spice level */}
                {thisItem && thisItem.hasSpiceLevel && (
                  <>
                    {" "}
                    <p className="spiceLevel_text ml-auto mr-auto mt-4">
                      {getSpiceText(spiceLevel)}
                    </p>
                    <SliderMUI
                      defaultValue={[20, 40]}
                      value={spiceLevel}
                      color="error"
                      aria-label="modifier"
                      step={null}
                      onChange={handleChangeSlide}
                      valueLabelDisplay="off"
                      marks={[0, 28, 66, 100].map((each) => ({
                        value: each,
                        label: "",
                      }))}
                    />
                  </>
                )}
              </div>
            )}

            {/* combo item */}
            {type === "Combo Item" && (
              <div className="d-flex flex-column mt-2">
                {/* item */}
                {LabelComp("Item", "item", errorPosition, errorText)}
                {/* {ReactSelectDrop(item, 'item', setValues, allCombos)} */}
                <Select
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  maxMenuHeight={"200px"}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  options={allCombos.map((item) =>
                    item._id ? { label: item.name, value: item._id } : item,
                  )}
                  value={allCombos
                    .filter((option) => option._id === item)
                    .map((item) => ({ label: item.name, value: item._id }))}
                  name={"item"}
                  onChange={(e) => ComboSelected(e)}
                  className="mb-2"
                />

                {thisCombo && (
                  <>
                    <p className="mt-3 mb-2">
                      Price: Rs.{" "}
                      {!orderData.isAdminCreated && thisCombo.hasDiscount
                        ? thisCombo.discountedPrice
                        : thisCombo.price}
                    </p>
                    <p>Quantity</p>
                    {CartQuantityAdmin(quanSel, setValues)}
                  </>
                )}

                {/* spice level */}
                {thisCombo && thisCombo.hasSpiceLevel && (
                  <>
                    {" "}
                    <p className="spiceLevel_text ml-auto mr-auto mt-4">
                      {getSpiceText(spiceLevel)}
                    </p>
                    <SliderMUI
                      defaultValue={[20, 40]}
                      value={spiceLevel}
                      color="error"
                      aria-label="modifier"
                      step={null}
                      onChange={handleChangeSlide}
                      valueLabelDisplay="off"
                      marks={[0, 28, 66, 100].map((each) => ({
                        value: each,
                        label: "",
                      }))}
                    />{" "}
                  </>
                )}
              </div>
            )}

            {/* Custom Item */}
            {type === "Custom Item" && (
              <div className="d-flex flex-column mt-2">
                {LabelComp("Name", "name", errorPosition, errorText)}
                <TextField
                  type="text"
                  value={thisCustomItem?.name || ""}
                  onChange={(e) => {
                    setValues((x) => ({
                      ...x,
                      thisCustomItem: {
                        ...x.thisCustomItem,
                        name: e.target.value,
                      },
                    }));
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
                <div className="mt-2" />
                {LabelComp("Price", "price", errorPosition, errorText)}
                <TextField
                  type="number"
                  value={thisCustomItem?.price || null}
                  onChange={(e) => {
                    setValues((x) => ({
                      ...x,
                      thisCustomItem: {
                        ...x.thisCustomItem,
                        price: e.target.value,
                      },
                    }));
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />

                <div className="mt-2" />

                {thisCustomItem && thisCustomItem.name && (
                  <>
                    <p>Quantity</p>
                    {CartQuantityAdmin(quanSel, setValues)}
                  </>
                )}
              </div>
            )}

            {/* end */}
            <div className="d-flex justify-content-end mt-3">
              <Button className="adocr_cancel" onClick={() => closeModal()}>
                {" "}
                Close
              </Button>
              <Button className="adocr_add_btn" onClick={() => addItem()}>
                {add_item_loading ? (
                  <CircularProgress style={{ color: "#fff" }} />
                ) : (
                  "Add Item"
                )}
              </Button>
            </div>
          </Box>
        </Modal>

        {/* Discount Item Modal */}
        <Modal
          open={showDiscountModal}
          onClose={() => handleCloseDiscountModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-between align-items-center">
              <span className="ad_body_title">Add Discount</span>
              <IconButton onClick={() => handleCloseDiscountModal()}>
                <X color="#000" />
              </IconButton>
            </div>

            <FormGroup className="mt-3">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="discount-type"
                  name="discount-type"
                  value={customDiscount.discountType}
                  onChange={handleDiscountTypeChange}
                >
                  <FormControlLabel
                    value="percent"
                    control={<Radio color="error" />}
                    label="Percent"
                  />
                  <FormControlLabel
                    value="value"
                    control={<Radio color="error" />}
                    label="Value"
                  />
                </RadioGroup>
              </FormControl>

              <TextField
                type="number"
                color="error"
                value={customDiscount.discountValue}
                onChange={handleDiscountValueChange}
                InputProps={{
                  endAdornment:
                    customDiscount.discountType === "percent" ? "%" : "Rs",
                  style: {
                    borderRadius: "20px",
                    borderColor: "#8943EF",
                    "&:focus": {
                      outlineColor: "#8943EF",
                    },
                  },
                }}
              />
            </FormGroup>

            <div className="d-flex justify-content-end mt-3">
              <Button
                className="adocr_cancel"
                onClick={() => handleCloseDiscountModal()}
              >
                {" "}
                Cancel
              </Button>
              <Button
                className="adocr_update_btn"
                onClick={() => applyDiscount()}
              >
                Apply
              </Button>
            </div>
          </Box>
        </Modal>

        {/* Edit Item Modal */}
        <Modal
          open={showEditModifiersModal}
          onClose={() => handleCloseEditModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* top */}
            <div className="d-flex justify-content-between align-items-center">
              <span className="ad_body_title">Edit Customizations</span>
              <IconButton onClick={() => handleCloseEditModal()}>
                <X color="#000" />
              </IconButton>
            </div>

            {currentItem &&
              currentItem?.modifiers &&
              currentItem?.modifiers?.length > 0 && (
                <>
                  <FormGroup className="mt-3">
                    {currentItem &&
                      currentItem.modifiers.map((each, i) => {
                        return (
                          <div key={i}>
                            <p className="c3 w3 s2">{each.name}</p>
                            <div className="d-flex flex-column">
                              {each.options &&
                                each.options.map((item, ind) => {
                                  return (
                                    <div
                                      key={ind}
                                      className="
                                                          d-flex justify-content-between
                                                           align-items-center"
                                    >
                                      <FormControlLabel
                                        key={ind}
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: "#8943EF",
                                              "&.Mui-checked": {
                                                color: "#8943EF",
                                              },
                                            }}
                                            defaultChecked={item.sel}
                                            value={item.sel}
                                            onChange={() =>
                                              modifierSelectedEdit(
                                                i,
                                                ind,
                                                currentItem,
                                              )
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        }
                                        label={item.name}
                                      />
                                      <span>Rs. {item.price}</span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </FormGroup>
                </>
              )}

            {/* spice level */}
            {currentItem &&
              currentItem.hasSpiceLevel === true &&
              !isTextEmpty(currentItem.spiceLevel) && (
                <>
                  {" "}
                  <p className="spiceLevel_text ml-auto mr-auto mt-4">
                    {getSpiceText(spiceLevel)}
                  </p>
                  <SliderMUI
                    defaultValue={[20, 40]}
                    value={spiceLevel}
                    color="error"
                    aria-label="modifier"
                    step={null}
                    onChange={handleChangeSlide}
                    valueLabelDisplay="off"
                    marks={[0, 28, 66, 100].map((each) => ({
                      value: each,
                      label: "",
                    }))}
                  />
                </>
              )}

            {/* end */}
            <div className="d-flex justify-content-end mt-3">
              <Button
                className="adocr_cancel"
                onClick={() => handleCloseEditModal()}
              >
                {" "}
                Cancel
              </Button>
              <Button
                className="adocr_update_btn"
                onClick={() => updateFnAPI()}
              >
                Update
              </Button>
            </div>
          </Box>
        </Modal>
      </ThemeProvider>
      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};
