import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
// import Obstructed from "./Pages/Obstructed";
import { AllRoutes } from "./Routes";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { store } from "./app/store";

if (window.location.protocol !== "http:")
  Sentry.init({
    dsn: "https://1796b5cb026b0372ed13f6621bd3f947@o4506250912989184.ingest.sentry.io/4506822711312384",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AllRoutes />
  </Provider>,
);
reportWebVitals();
