import { IconButton, InputBase, Paper } from "@mui/material";
// import MUIDataTable from 'mui-datatables'
import { MagnifyingGlass } from "@phosphor-icons/react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAPIAdmin } from "../../../../Auth/Index";
import { AlertError, CircularProgressComp } from "../../../../Components/Common";
import { ad_dashFrom, ad_dashTo, setDashDate } from "../../../../slices/novaSlice";
import { rangePresets, rangePresetsNew } from "../../Dashbaord/AdminDashboard";
import { RiderStatsDetails } from "./RiderStatsDetail";
const { RangePicker } = DatePicker;

export const RiderStats = () => {
  const dispatch = useDispatch()
  const [values, setValues] = useState({
    from: useSelector(ad_dashFrom),
    to: useSelector(ad_dashTo),
    selectedIndex : 0,
    data: [],
    searchedData: [],
    first: true,
    loading : true,
    errorPosition: "",
    errorText: "",
    riderId :null
  });
  const {
    from, 
    to,
    selectedIndex,
    searchedData,
    first,
    loading,
    errorPosition,
    errorText,
    riderId
  } = values;
  const dateFormat = "MMMM D, YYYY h:mm A";
  const dateNow = new Date().getHours();
  const query = `dateFilterType=custom&from=${new Date(from).toISOString()}&to=${new Date(to).toISOString()}`;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/delivery/admin/dashboard?"+query).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            data: res.stats,
            searchedData: res.stats,
            first: false,
            loading: false,
            riderId : res.stats?.[0]?._id
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first, query]);

  const handleRiderClick = (rider,i) => {
    setValues(x=>({...x, 
      selectedIndex : i, 
      riderId : rider?._id, 
      rider : rider, 
    }))
  }

  const onChange = (date) => {
    setValues((x) => ({
      ...x,
      from: date[0],
      to: date[1],
      first: true,
      loading: true,
      selectedIndex : 0,
      riderId : searchedData?.[0]?._id
    }));
    dispatch(
      setDashDate({
        dashFrom: date[0],
        dashTo: date[1],
      }),
    );
  };
  return (
    <div className="category_body">
      <div className="d-flex gap-3">
        <div style={{width:'400px', height:'fit-content', borderRadius:5}} 
          className="d-flex flex-column gap-2 p-3">
         <RangePicker
          presets={dateNow >= 12 ? rangePresetsNew : rangePresets}
          showTime
          format={"YYYY-MM-DD HH:mm"}
          style={{ width: "330px" }}
          className="ml-auto s1 mb-3"
          size="large"
          value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
          onChange={onChange}
        />
        {leftTable(searchedData, handleRiderClick, selectedIndex, loading)}
        {loading && <div className="p-5 d-flex justify-content-center">
          <CircularProgressComp />
          </div>}
        {!loading && searchedData.length===0 && 
        <div className="d-flex p-5 justify-content-center">
          No deliveries today!
          </div>}
      </div>
      {RiderStatsDetails(riderId)}
    </div>
      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const SearchDiv = (searchText, data, setValues, width, mb) => {
  const handleChangeSearch = (e) => {
    const text = e?.target?.value;
    const filteredData = data?.filter((x) =>
      x.name?.toLowerCase()?.includes(text),
    );
    setValues((x) => ({ ...x, searchText: text, searchedData: filteredData }));
  };
  return (
    <Paper
      component="form"
      sx={{
        p: "0px 16px",
        display: "flex",
        border: "1px solid #E9ECFF",
        borderRadius: "25px",
        width: width ?? "400px",
        boxShadow: "none",
        mb: mb ?? "16px",
        padding: "0 16px",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        onChange={handleChangeSearch}
        value={searchText}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <MagnifyingGlass color="#8943EF" />
      </IconButton>
    </Paper>
  );
};

const leftTable = (riders,handleRiderClick, selectedIndex, loading) => {
  return (
    <table class="table-auto">
      <thead>
        <tr>
          <th>Name</th>
          <th>Deliveries</th>
          <th>Avg. Time</th>
          <th>Avg. Km</th>
        </tr>
      </thead>
      <tbody>
        {
          riders.map((each,i)=>(
            <tr className="cursor_pointer" 
            style={{
              backgroundColor: (selectedIndex===i) ? '#F2F2F2' : '',
              padding : '8px 16px',
              color : (selectedIndex===i) ? '#8943EF' : '#222227',
              fontWeight : (selectedIndex===i) ? 600 : 400,
              height:50
            }}
            onClick={e=>handleRiderClick(each,i)}> 
              <td>{each.name}</td>
              <td>{each.deliveryCount}</td>
              <td>{each.averageDeliveryTime}</td>
              <td>{each.averageDeliveryDistance}</td>
            </tr>
          ))
        }
  </tbody>
    </table>
  )
}
