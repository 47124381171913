import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { isTextEmpty } from "../Components/Validate_Admin";
import { Skeleton } from "@mui/material";

export const MapBox = ({ lnglat, setLngLat }) => {
  // export const MapBox = () => {
  // const [lnglat, setLngLat] = useState([null, null])
  const [map, setmap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [letsearch, setletsearch] = useState(false);
  useEffect(() => {
    if (!map) {
      if (navigator.geolocation && isTextEmpty(lnglat[0])) {
        navigator.geolocation.getCurrentPosition(
          function showLocation(position) {
            var latitude = position.coords.latitude;
            var longitude = position.coords.longitude;
            setLngLat([longitude, latitude]);
          },
          function errorHandler(err) {
            if (err.code === 1) {
              setletsearch(true);
              setLngLat([85.3398184, 27.6946846]);
              // alert("Error: Please enable location access for map!");
            } else if (err.code === 2) {
              alert("Error: Position is unavailable!");
            }
          },
        );
      }

      if (!isTextEmpty(lnglat[0]) || letsearch) {
        mapboxgl.accessToken =
          "pk.eyJ1IjoiZnNsb2dpdGVjaCIsImEiOiJjbDlpMmoxeTAwazN1M29vMGNvdmJwM3AzIn0.zgSikY5_F_AI21UQBVb3dQ";
        // if (!mapboxgl.supported()) { //not supported
        // }
        // else { //supported
        // }
        const map = new mapboxgl.Map({
          container: "map", // container ID
          // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
          style: "mapbox://styles/mapbox/streets-v11", // style URL
          center: lnglat, // starting position [lng, lat]
          zoom: 15, // starting zoom
          projection: "globe", // display the map as a 3D globe
        });

        map.on("load", function () {
          map.flyTo({
            center: lnglat,
            essential: true, // this animation is considered essential with respect to prefers-reduced-motion
          });
        });

        map.on("style.load", () => {
          map.setFog({}); // Set the default atmosphere style
        });

        // Set marker options.
        const markerData = new mapboxgl.Marker({
          draggable: true,
        })
          .setLngLat(lnglat)
          .addTo(map);

        setMarker(markerData);
        function onDrag(event) {
          setLngLat([event.target._lngLat.lng, event.target._lngLat.lat]);
        }
        markerData.on("drag", onDrag);

        // Add the control to the map.
        const geocoderData = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          countries: "np",
          mapboxgl: mapboxgl,
          marker: false,
          getItemValue: function (item) {
            markerData.setLngLat(item.center);
            setMarker(markerData);
            setLngLat(item.center);
            return item.place_name;
          },
        });
        map.addControl(geocoderData);

        setmap(map);
      }
    } else {
      function add_marker(event) {
        var coordinates = event.lngLat;
        var lnglat_new = [coordinates.lng, coordinates.lat];
        setLngLat(lnglat_new);
        marker.setLngLat([coordinates.lng, coordinates.lat]);
        setMarker(marker);
        map.flyTo({
          center: lnglat_new,
        });
      }
      map.on("click", add_marker);
    }
  }, [lnglat, map, marker, setLngLat, letsearch]);

  return (
    <div className="mapBox_maindiv">
      {!isTextEmpty(lnglat[0]) || letsearch ? (
        <div id="map" className="mapBox_main"></div>
      ) : (
        <Skeleton variant="rectangular" width={"100%"} height={250} />
      )}
    </div>
  );
};
