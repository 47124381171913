import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AuthenticateNovaKDS,
  isAuthenticatedKDS,
  isRemKDS,
  POSTAPI,
  RememberMe,
  RememberMeRemove,
} from "../../Auth/Index";
import {
  AlertError,
  FullBtnComp,
  InputComp,
  InputComp_Eye,
  LabelComp,
} from "../../Components/Common";
import { isTextEmpty, ValidateLogin } from "../../Components/Validate_Admin";
import "../../css/Admin/admin_login.css";

export const KDSLogin = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState({
    email: isRemKDS() && isRemKDS().email ? isRemKDS().email : "",
    password: "",
    rememberMe:
      isRemKDS() && isRemKDS().email && !isTextEmpty(isRemKDS().email)
        ? true
        : false,
    errorPosition: "",
    errorText: "",
    loading: false,
  });
  const { email, password, rememberMe, errorPosition, errorText, loading } =
    values;

  useEffect(() => {
    if (isAuthenticatedKDS()) navigate("/kds/home");
  }, [navigate]);

  const login = async () => {
    if (loading) return;
    var validated = await ValidateLogin(email, password, setValues);
    if (validated) {
      setValues((prev) => ({ ...prev, loading: true }));
      var send_data = {
        contact: email,
        password,
      };
      POSTAPI("/employee/kdsLogin", send_data).then((res) => {
        if (res.success) {
          if (rememberMe) RememberMe("_nova_foods_kds_rem", { email });
          else RememberMeRemove("_nova_foods_kds_rem");
          AuthenticateNovaKDS(res.data);
          navigate("/kds/home");
        }
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  };

  const rememberMeFn = () => {
    setValues((val) => ({
      ...val,
      rememberMe: !rememberMe,
    }));
  };

  return (
    <div className="admin_auth">
      <div className="admin_auth_left">
        <img
          src="https://nova-foods.s3.ap-south-1.amazonaws.com/loginpage.png"
          onLoad={() => setLoaded(true)}
          style={loaded ? { width: "100%" } : { display: "none", width: "0px" }}
          alt=""
          className="admin_auth_img"
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
          sx={{ bgcolor: "#d6d5d5" }}
          style={
            loaded
              ? { display: "none" }
              : { position: "absolute", width: "50%" }
          }
        />
      </div>

      <div className="admin_auth_right">
        <div className="admin_input_div">
          <p className="admin_auth_title">Login</p>

          {LabelComp("Email", "email", errorPosition, errorText)}
          {InputComp(email, "email", setValues, "admin_input")}

          {LabelComp("Password", "password", errorPosition, errorText)}
          {InputComp_Eye(password, "password", setValues)}

          <div className="d-flex justify-content-between">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    sx={{
                      "&.Mui-checked": {
                        color: "#8943EF",
                      },
                    }}
                    onChange={() => rememberMeFn()}
                  />
                }
                label="Remember me"
              />
            </FormGroup>
            <Button
              className="ad_login_forgot_btn"
              onClick={() => navigate("/admin/reset")}
            >
              Forgot password
            </Button>
          </div>

          {FullBtnComp("Login", login, loading)}
        </div>
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};
