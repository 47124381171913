export const isTextEmpty = (text) => {
  if (text === "" || text === undefined || text === null) {
    return true;
  } else return false;
};

export const notNumber = (text) => {
  if (isTextEmpty(text) || isNaN(text)) {
    return true;
  } else return false;
};

export const ValidateModifier = (modifier_name, options, setValues) => {
  if (isTextEmpty(modifier_name)) {
    setValues((val) => ({
      ...val,
      errorPosition: "modifier_name",
      errorText: "Invalid name",
    }));
    return false;
  } else {
    var bool = true;
    for (let index = 0; index < options.length; index++) {
      const element = options[index];
      if (isTextEmpty(element.name)) {
        setValues((val) => ({
          ...val,
          errorPosition: "options_name",
          errorText: "Invalid value",
          error_optionsIndex: (" " + index).slice(1),
        }));
        bool = false;
        break;
      } else if (isTextEmpty(element.price)) {
        setValues((val) => ({
          ...val,
          errorPosition: "options_price",
          errorText: "Invalid value",
          error_optionsIndex: (" " + index).slice(1),
        }));
        bool = false;
        break;
      }
    }
    return bool;
  }
};

export const ValidateSingleItem = (
  name,
  category,
  brand,
  division,
  image,
  price,
  estimatedTime,
  setValues,
) => {
  if (isTextEmpty(name)) {
    setValues((val) => ({
      ...val,
      errorPosition: "name",
      errorText: "Invalid name",
    }));
    return false;
  } else if (isTextEmpty(category)) {
    setValues((val) => ({
      ...val,
      errorPosition: "category",
      errorText: "Invalid value",
    }));
    return false;
  } else if (isTextEmpty(brand)) {
    setValues((val) => ({
      ...val,
      errorPosition: "brand",
      errorText: "Invalid value",
    }));
    return false;
  } else if (isTextEmpty(division)) {
    setValues((val) => ({
      ...val,
      errorPosition: "division",
      errorText: "Invalid value",
    }));
    return false;
  } else if (isTextEmpty(price)) {
    setValues((val) => ({
      ...val,
      errorPosition: "price",
      errorText: "Invalid value",
    }));
    return false;
  } else if (isTextEmpty(image)) {
    setValues((val) => ({
      ...val,
      errorPosition: "image",
      errorText: "Invalid image",
    }));
    return false;
  } else if (isTextEmpty(estimatedTime)) {
    setValues((val) => ({
      ...val,
      errorPosition: "estimatedTime",
      errorText: "Invalid value",
    }));
    return false;
  } else {
    return true;
  }
};

export const ValidateComboMeals = (
  name,
  image,
  price,
  estimatedTime,
  items,
  setValues,
) => {
  if (isTextEmpty(name)) {
    setValues((val) => ({
      ...val,
      errorPosition: "name",
      errorText: "Invalid name",
    }));
    return false;
  } else if (isTextEmpty(image)) {
    setValues((val) => ({
      ...val,
      errorPosition: "image",
      errorText: "Invalid image",
    }));
    return false;
  } else if (isTextEmpty(price)) {
    setValues((val) => ({
      ...val,
      errorPosition: "price",
      errorText: "Invalid value",
    }));
    return false;
  } else if (isTextEmpty(estimatedTime)) {
    setValues((val) => ({
      ...val,
      errorPosition: "estimatedTime",
      errorText: "Invalid value",
    }));
    return false;
  } else {
    var bool = true;
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (isTextEmpty(element.itemId)) {
        setValues((val) => ({
          ...val,
          errorPosition: "item_name",
          errorText: "Invalid value",
          error_itemIndex: (" " + index).slice(1),
        }));
        bool = false;
        break;
      } else if (isTextEmpty(element.quantity)) {
        setValues((val) => ({
          ...val,
          errorPosition: "item_quantity",
          errorText: "Invalid value",
          error_itemIndex: (" " + index).slice(1),
        }));
        bool = false;
        break;
      }
    }
    return bool;
  }
};

export const ValidateBrands = (
  name,
  description,
  logo,
  coverImage,
  divisions,
  divisionImages,
  setValues,
) => {
  if (isTextEmpty(name)) {
    setValues((val) => ({
      ...val,
      errorPosition: "name",
      errorText: "Invalid name",
    }));
    return false;
  } else if (isTextEmpty(description)) {
    setValues((val) => ({
      ...val,
      errorPosition: "description",
      errorText: "Invalid description",
    }));
    return false;
  } else if (isTextEmpty(logo)) {
    setValues((val) => ({
      ...val,
      errorPosition: "image",
      errorText: "Invalid image",
    }));
    return false;
  } else if (isTextEmpty(coverImage)) {
    setValues((val) => ({
      ...val,
      errorPosition: "coverImage",
      errorText: "Invalid image",
    }));
    return false;
  } else {
    var bool = true;
    for (let index = 0; index < divisions.length; index++) {
      const element = divisions[index];
      if (isTextEmpty(element)) {
        setValues((val) => ({
          ...val,
          errorPosition: "division",
          errorText: "Invalid value",
          error_optionsIndex: (" " + index).slice(1),
        }));
        bool = false;
        break;
      } else if (isTextEmpty(divisionImages[index])) {
        setValues((val) => ({
          ...val,
          errorPosition: "divisionImages",
          errorText: "Invalid value",
          error_optionsIndex: (" " + index).slice(1),
        }));
        bool = false;
        break;
      }
    }
    return bool;
  }
};

export const ValidateOffers = (
  name,
  type,
  promoCode,
  amount,
  promoLimit,
  from,
  to,
  group,
  groupValues,
  groupValuesDiv,
  image,
  setValues,
) => {
  if (isTextEmpty(name)) {
    setValues((val) => ({
      ...val,
      errorPosition: "name",
      errorText: "Invalid name",
    }));
    return false;
  } else if (isTextEmpty(type)) {
    setValues((val) => ({
      ...val,
      errorPosition: "type",
      errorText: "Invalid type",
    }));
    return false;
  } else if (type === "Promo" && isTextEmpty(promoCode)) {
    setValues((val) => ({
      ...val,
      errorPosition: "promoCode",
      errorText: "Invalid code",
    }));
    return false;
  } else if (isNaN(amount) || isTextEmpty(amount)) {
    setValues((val) => ({
      ...val,
      errorPosition: "amount",
      errorText: "Invalid amount",
    }));
    return false;
  } else if (type === "Promo" && isTextEmpty(promoLimit)) {
    setValues((val) => ({
      ...val,
      errorPosition: "promoLimit",
      errorText: "Invalid value",
    }));
    return false;
  } else if (!Date.parse(from) || !Date.parse(to)) {
    setValues((val) => ({
      ...val,
      errorPosition: "validity",
      errorText: "Invalid date",
    }));
    return false;
  } else if (type === "Offer" && isTextEmpty(image)) {
    setValues((val) => ({
      ...val,
      errorPosition: "image",
      errorText: "Invalid image",
    }));
    return false;
  } else if (isTextEmpty(group)) {
    setValues((val) => ({
      ...val,
      errorPosition: "group",
      errorText: "Invalid value",
    }));
    return false;
  } else if (group !== "All" && groupValues.length === 0) {
    setValues((val) => ({
      ...val,
      errorPosition: "groupValues",
      errorText: "Invalid value",
    }));
    return false;
  } else if (group === "Division" && groupValuesDiv.length === 0) {
    setValues((val) => ({
      ...val,
      errorPosition: "groupValuesDiv",
      errorText: "Invalid value",
    }));
    return false;
  } else {
    var bool = true;
    setValues((val) => ({ ...val, errorPosition: "", errorText: "" }));
    return bool;
  }
};

export const ValidateEmployees = (
  name,
  email,
  phone,
  designationId,
  profileImage,
  docImages,
  setValues,
) => {
  if (isTextEmpty(name)) {
    setValues((val) => ({
      ...val,
      errorPosition: "name",
      errorText: "Invalid name",
    }));
    return false;
  } else if (isTextEmpty(email)) {
    setValues((val) => ({
      ...val,
      errorPosition: "email",
      errorText: "Invalid value",
    }));
    return false;
  } else if (isTextEmpty(phone)) {
    setValues((val) => ({
      ...val,
      errorPosition: "phone",
      errorText: "Invalid phone number",
    }));
    return false;
  } else if (isTextEmpty(designationId)) {
    setValues((val) => ({
      ...val,
      errorPosition: "designationId",
      errorText: "Invalid designation",
    }));
    return false;
  } else if (isTextEmpty(profileImage)) {
    setValues((val) => ({
      ...val,
      errorPosition: "profileImage",
      errorText: "Invalid image",
    }));
    return false;
  } else if (docImages.length === 0) {
    setValues((val) => ({
      ...val,
      errorPosition: "docImages",
      errorText: "Invalid images",
    }));
    return false;
  } else {
    return true;
  }
};

export const ValidateLetAddItemBtn = (
  orderTypeId,
  name,
  phone,
  add_lat,
  add_lng,
  setValues,
  dontValidate,
) => {
  if (dontValidate) {
    return true;
  } else if (isTextEmpty(name)) {
    setValues((val) => ({
      ...val,
      errorPosition: "name",
      errorText: "Invalid name",
    }));
    return false;
  } else if (isTextEmpty(phone)) {
    setValues((val) => ({
      ...val,
      errorPosition: "phone",
      errorText: "Provide phone number",
    }));
    return false;
  } else if (isTextEmpty(orderTypeId)) {
    setValues((val) => ({
      ...val,
      errorPosition: "orderTypeId",
      errorText: "Select order Type",
    }));
    return false;
  } else if (isTextEmpty(add_lat) || isTextEmpty(add_lng)) {
    setValues((val) => ({
      ...val,
      errorPosition: "address",
      errorText: "Invalid address",
    }));
    return false;
  } else {
    return true;
  }
};

// market

export const ValidateOTPInput = (
  name,
  email,
  password,
  bool,
  otp,
  setValues,
) => {
  if (isTextEmpty(name)) {
    setValues((val) => ({
      ...val,
      errorPosition: "name",
      errorText: "Invalid name",
    }));
    return false;
  } else if (isTextEmpty(email)) {
    setValues((val) => ({
      ...val,
      errorPosition: "email",
      errorText: "Invalid value",
    }));
    return false;
  } else if (isTextEmpty(password)) {
    setValues((val) => ({
      ...val,
      errorPosition: "password",
      errorText: "Invalid password",
    }));
    return false;
  } else {
    if (bool && otp.length !== 6) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Invalid otp",
      }));
      return false;
    }
    return true;
  }
};

export const ValidateForgotOTP = (email, otp, setValues) => {
  if (isTextEmpty(email)) {
    setValues((val) => ({
      ...val,
      errorPosition: "email",
      errorText: "Invalid email or user",
    }));
    return false;
  } else {
    if (otp.length !== 6) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Invalid otp",
      }));
      return false;
    }
    return true;
  }
};

export const ValidateLogin = (email, password, setValues) => {
  if (isTextEmpty(email)) {
    setValues((val) => ({
      ...val,
      errorPosition: "email",
      errorText: "Invalid email",
    }));
    return false;
  } else if (isTextEmpty(password)) {
    setValues((val) => ({
      ...val,
      errorPosition: "password",
      errorText: "Invalid value",
    }));
    return false;
  } else return true;
};

export const ValidateAddress = (
  add_title,
  add_name,
  add_phone,
  add_lat,
  add_lng,
  setValues,
) => {
  const regex = new RegExp("^[9][0-9]{9}$");
  const validatePhone = regex.test(add_phone);
  if (isTextEmpty(add_title)) {
    setValues((val) => ({
      ...val,
      errorPosition: "add_title",
      errorText: "Invalid value",
    }));
    return false;
  }
  // else
  if (isTextEmpty(add_name)) {
    setValues((val) => ({
      ...val,
      errorPosition: "add_name",
      errorText: "Invalid value",
    }));
    return false;
  }
  // else if (isTextEmpty(add_email)) {
  //     setValues(val => ({ ...val, errorPosition: 'add_email', errorText: 'Invalid value' }))
  //     return false
  // }
  else if (isTextEmpty(add_phone) || !validatePhone) {
    setValues((val) => ({
      ...val,
      errorPosition: "add_phone",
      errorText: "Invalid value",
    }));
    return false;
  } else if (isTextEmpty(add_lat) || isTextEmpty(add_lng)) {
    setValues((val) => ({
      ...val,
      errorPosition: "add_address",
      errorText: "Invalid value",
    }));
    return false;
  }
  // else if (isTextEmpty(add_address)) {
  //     setValues(val => ({ ...val, errorPosition: 'add_address', errorText: 'Invalid value' }))
  //     return false
  // }
  else return true;
};
