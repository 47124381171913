import { IconButton } from "@mui/material";
import { ArrowClockwise, ArrowCounterClockwise } from "@phosphor-icons/react";
import React, { useCallback, useState } from "react";
import ReactCrop from "react-easy-crop";

const CropImage = ({ image, cropAsp, onChange, handleModal }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [cropPreview, setCropPreview] = useState(null);

  const onCropChange = useCallback((newCrop) => {
    setCrop(newCrop);
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropPreview(croppedAreaPixels);
  }, []);

  const onZoomChange = useCallback((newZoom) => {
    setZoom(newZoom);
  }, []);

  // eslint-disable-next-line
  const getCroppedImg = async (image, crop, rotation = 0) => {
    const img = await createImage(image);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      img.width,
      img.height,
      rotation,
    );

    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    ctx.fillStyle = "#FFFFFF"; // Set background color to white
    ctx.fillRect(0, 0, bBoxWidth, bBoxHeight); // Fill the canvas with white

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.translate(-img.width / 2, -img.height / 2);

    ctx.drawImage(img, 0, 0);

    const croppedCanvas = document.createElement("canvas");

    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      return null;
    }

    croppedCanvas.width = crop.width;
    croppedCanvas.height = crop.height;

    croppedCtx.drawImage(
      canvas,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height,
    );
    const convertImage = (quality) => {
      const base64 = croppedCanvas.toDataURL("image/jpeg", quality);
      const imgSize = atob(base64.split(",")[1]).length / 1024 / 1024;
      if (Math.round(imgSize) > 2) {
        return convertImage(quality - 0.05);
      } else {
        return base64;
      }
    };
    return convertImage(1);
  };

  const fileNull = null;
  const handleCropCancel = useCallback(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setCropPreview(null);
    onChange(fileNull);
    handleModal();
    // eslint-disable-next-line
  }, [onChange, handleModal, getCroppedImg]);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
  };

  const rotateSize = (width, height, rotation) => {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  };

  const rotateClockwise = () => {
    setRotation(rotation + 45);
  };

  const rotateAntiClockwise = () => {
    setRotation(rotation - 45);
  };
  const handleCropSave = useCallback(
    async (event) => {
      if (cropPreview) {
        try {
          const croppedImageBlob = await getCroppedImg(
            image,
            cropPreview,
            rotation,
          );
          onChange(croppedImageBlob);
          setCropPreview(null);
        } catch (error) {
          console.error("Error saving cropped image:", error);
        }
      }
    },
    [cropPreview, image, onChange, getCroppedImg, rotation],
  );
  return (
    <div className="d-flex flex-column gap-1">
      <div
        style={{
          position: "relative",
          height: "255px",
          maxWidth: "340px",
        }}
      >
        <ReactCrop
          image={image}
          aspect={cropAsp ? cropAsp : 1 / 1}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
        />
      </div>
      <div className="d-flex justify-content-between">
        <IconButton onClick={rotateAntiClockwise}>
          <ArrowCounterClockwise />
        </IconButton>
        <IconButton onClick={rotateClockwise}>
          <ArrowClockwise />
        </IconButton>
      </div>
      <div className="">
        <input
          type="range"
          min="1"
          step="0.01"
          aria-labelledby="Zoom"
          onInput={(event) => {
            setZoom(parseFloat(event.target.value));
          }}
          value={zoom}
          max="3"
          className="w-100"
        />
      </div>

      <div className="d-flex justify-content-end gap-2">
        <button type="button" onClick={handleCropCancel} className="">
          Cancel
        </button>
        <button type="button" onClick={handleCropSave}>
          Upload
        </button>
      </div>
    </div>
  );
};

export default React.memo(CropImage);
