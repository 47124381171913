export const searchOrdersById = (searchQuery, data) => {
  if (!searchQuery) {
    return data;
  }

  searchQuery = searchQuery.toLowerCase();

  const filteredData = data.filter((item) =>
    item.trackingNumber?.toLowerCase()?.includes(searchQuery),
  );

  return filteredData;
};
