import { Avatar, Button, IconButton, Skeleton } from "@mui/material";
import { CaretCircleLeft, CaretCircleRight } from "@phosphor-icons/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../../css/Market/alice.css";
import "./../a.css";

export const CarouselCategory = ({ data, itemsArr, loading }) => {
  const navigate = useNavigate();
  const [sliderRefCat, setSliderRefCat] = useState(null);
  const settings = {
    className: "slider variable-width",
    dots: false,
    arrows: "false",
    infinite: true,
    variableWidth: true,
  };
  return (
    <div>
      {/* bigger screen */}
      <div className="displayNoneMbCat p-2">
        <IconButton className="sc_cat_left" onClick={sliderRefCat?.slickPrev}>
          <CaretCircleLeft />
        </IconButton>
        <div
          style={{ width: "calc(100% - 100px) !important", overflow: "hidden" }}
        >
          <Slider
            ref={setSliderRefCat}
            {...settings}
            style={{
              position: "relative",
              width: "calc(100% - 100px) !important",
            }}
          >
            {loading &&
              [1, 2, 3, 4, 5].map((card, ind) => (
                <Skeleton
                  key={ind}
                  variant="rectangular"
                  className="sc_cat_each_skel"
                />
              ))}

            {!loading &&
              itemsArr?.map((card, ind) => (
                <div className="sc_cat" key={ind}>
                  <Button
                    className="sc_cat_each"
                    key={ind}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    onClick={() =>
                      navigate(`/filter?cid=${card.categoryId}&&name=${card.name}`, {
                        state: {
                          catId: card.categoryId,
                          offerId: null,
                          name: card.name,
                        },
                      })
                    }
                  >
                    <span
                      style={{
                        width: "calc(100% - 55px)",
                        display: "flex",
                        alignItems: "flex-start",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {card.name}
                    </span>
                    <Avatar className="sc_cat_icon" src={card.image} />
                  </Button>
                </div>
              ))}
          </Slider>
        </div>
        <IconButton className="sc_cat_left" onClick={sliderRefCat?.slickNext}>
          <CaretCircleRight />
        </IconButton>
      </div>
    </div>
  );
};
