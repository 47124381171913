import { Box } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../css/Market/layout.css";
import { useGetBannerImagesQuery } from "../../slices/apiSlice";
import './index.css'

const BannerCarousel = () => {
  const { data : bannerImagesData } = useGetBannerImagesQuery()
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div className="slider-container">
      {/* for bigger screens */}
      <Box display={{ xs: "none", md: "block" }}>
        <Slider {...settings}>
          {
            bannerImagesData?.webBannerImages?.map((each,ind)=>(
              <div>
              <img
                src={each}
                alt=""
                className="banner_lg"
              />
            </div>
            ))
          }
        </Slider>
      </Box>
      {/* for mobile */}
      <Box display={{ md: "none" }}>
        <Slider {...settings}>
        {
            bannerImagesData?.webMobileViewBannerImages?.map((each,ind)=>(
              <div>
              <img
                src={each}
                alt=""
                style={{ width: "calc(100vw - 40px)" }}
              />
            </div>
            ))
          }
        </Slider>
      </Box>
    </div>
  );
};

export default BannerCarousel;
