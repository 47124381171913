import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Collapse,
  createTheme,
  IconButton,
  Paper,
  Slider as SliderMUI,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
} from "@mui/material";
import moment from "moment";
import {
  CaretDown,
  CaretUp,
  MinusCircle,
  TrashSimple,
} from "@phosphor-icons/react";
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { POSTAPI_USER } from "../Auth/Index";
import { CartQuantitySide, getSpiceText, statusButtonClass } from "./Common";
import { getS3Image } from "./s3";
import { ORDER_STATUS } from "./staticData";

export const getMuiTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: "Outfit",
            fontWeight: "400px",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#2E2E2E",
          },
          head: {
            fontFamily: "Outfit",
            fontWeight: "600px",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#2E2E2E",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            // borderRadius: '12px 12px 0 0',
            // boxShadow: 'none'
          },
        },
      },
    },
  });

export const getMuiThemeCsr = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: "Blender Pro thin",
            fontWeight: "400",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: "white",
            backgroundColor: "black",
          },
          head: {
            fontFamily: "Blender Pro thin",
            fontWeight: "400",
            fontSize: "1.125rem",
            lineHeight: "normal",
            color: "white",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "1px",
            // borderRadius: '12px 12px 0 0',
            // boxShadow: 'none'
          },
        },
      },
    },
  });

export const OrderViewTable = (data, foods) => {
  const TableColumns = ["Items", "Qty", "Price", "Total Price"];

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" key="table_a">
          <TableHead key="thead_a">
            <TableRow key="trow_a">
              {TableColumns.map((eachCol, i) => (
                <TableCell key={i}>{eachCol}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key="tbody_a">
            {foods &&
              foods.map((row, i) => {
                return <Row key={i} row={row} />;
              })}
          </TableBody>
        </Table>
        {data.payment && (
          <div
            className="d-flex justify-content-end w-100"
            style={{
              padding: "30px",
              paddingRight: "10%",
            }}
          >
            <div className="d-flex flex-column" style={{ width: "300px" }}>
              <div className="d-flex justify-content-between">
                <p>Subtotal</p>
                <p>
                  <b>Rs. {parseFloat(data.payment.subTotal).toFixed(2)}</b>
                </p>
              </div>
              {data.payment &&
                data.payment.taxes &&
                data.payment.taxes.map((each, i) => (
                  <div>
                    <div className="d-flex justify-content-between">
                      <p>{each.name}</p>
                      <p>
                        <b>Rs. {each.taxableAmount}</b>
                      </p>
                    </div>
                  </div>
                ))}
              {/* {data.payment.discount > 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Discount</p>
                  <p className="c1">
                    <b>- Rs. {data.payment.discount}</b>
                  </p>
                </div>
              )} */}
              {!data.isAdminCreated && data.payment.promoDiscount > 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Promo Discount</p>
                  <p className="c1">
                    <b>- Rs. {data.payment.promoDiscount}</b>
                  </p>
                </div>
              )}
              {data.payment.deliveryCharge > 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Delivery charge</p>
                  <p>
                    <b>Rs. {data.payment.deliveryCharge}</b>
                  </p>
                </div>
              )}
              {data.payment?.paidAmount && data.payment?.paidAmount > 0 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Paid Amount</p>
                  <p>
                    <b>Rs. {data.payment?.paidAmount}</b>
                  </p>
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-between">
                <p>Total</p>
                <p>
                  <b>Rs. {parseFloat(data.payment.total).toFixed(2)}</b>
                </p>
              </div>
              {data.payment?.remainingAmount &&
              data.payment?.remainingAmount > 0 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Remaining Amount</p>
                  <p>
                    <b>Rs. {data.payment?.remainingAmount}</b>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

export const ShiftsViewTable = ({ orders }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const TableColumns = [
    "Order No",
    "Bill Total",
    "Delivery",
    "Total",
    "Remaining",
    "Qr",
    "Cash",
    "Hold",
    "",
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" key="table_a">
          <TableHead key="thead_a">
            <TableRow key="trow_a">
              {TableColumns.map((eachCol, i) => (
                <TableCell key={i}>{eachCol}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          {orders.length === 0 ? (
            <></>
          ) : (
            <>
              {" "}
              <TableBody key="tbody_a">
                {orders &&
                  orders
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return <ShiftsRow key={i} row={row} />;
                    })}
              </TableBody>
            </>
          )}
        </Table>
        {orders.length === 0 ? (
          <>
            <h5 className="d-flex justify-content-center align-items-center mt-5 mb-5 w-100">
              No orders found
            </h5>
          </>
        ) : (
          ""
        )}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={orders ? orders.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </ThemeProvider>
  );
};

export const ReviewsViewTable = ({ reviews }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const TableColumns = ["Order ID", "Order Date", "Customer ID", "Rating"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" key="table_a">
          <TableHead key="thead_a">
            <TableRow key="trow_a">
              {TableColumns.map((eachCol, i) => (
                <TableCell key={i}>{eachCol}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          {reviews.length === 0 ? (
            <></>
          ) : (
            <>
              {" "}
              <TableBody key="tbody_a">
                {reviews &&
                  reviews
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      return <ReviewsRow key={i} row={row} />;
                    })}
              </TableBody>
            </>
          )}
        </Table>
        {reviews.length === 0 ? (
          <>
            <h5 className="d-flex justify-content-center align-items-center mt-5 mb-5 w-100">
              No Reviews
            </h5>
          </>
        ) : (
          ""
        )}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={reviews ? reviews.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </ThemeProvider>
  );
};

export const OrderViewTableCsr = (data, foods) => {
  const TableColumns = ["Items", "Qty", "Price", "Total Price"];
  return (
    <ThemeProvider theme={getMuiThemeCsr()}>
      <TableContainer
        component={Paper}
        className="OrderViewTableCsr"
        sx={{ border: "1px solid #545356" }}
      >
        <Table aria-label="collapsible table" key="table_a">
          <TableHead key="thead_a">
            <TableRow key="trow_a">
              {TableColumns.map((eachCol, i) => (
                <TableCell key={i}>{eachCol}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            key="tbody_a"
            style={{
              backgroundColor: "black",
            }}
          >
            {foods &&
              foods.map((row, i) => {
                return <RowCsr key={i} row={row} />;
              })}
          </TableBody>
        </Table>
        {data.payment && (
          <div
            className="d-flex justify-content-end w-100"
            style={{
              padding: "30px",
              paddingRight: "10%",
              backgroundColor: "black",
              color: "white",
            }}
          >
            <div className="d-flex flex-column" style={{ width: "300px" }}>
              <div className="d-flex justify-content-between">
                <p>Subtotal</p>
                <p>
                  <b>Rs. {parseFloat(data.payment.subTotal).toFixed(2)}</b>
                </p>
              </div>
              {data.payment &&
                data.payment.taxes &&
                data.payment.taxes.map((each, i) => (
                  <div>
                    <div className="d-flex justify-content-between">
                      <p>{each.name}</p>
                      <p>
                        <b>Rs. {each.taxableAmount}</b>
                      </p>
                    </div>
                  </div>
                ))}
              {/* {data.payment.discount > 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Discount</p>
                  <p className="c1">
                    <b>- Rs. {data.payment.discount}</b>
                  </p>
                </div>
              )} */}
              {!data.isAdminCreated && data.payment.promoDiscount > 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Promo Discount</p>
                  <p className="c1">
                    <b>- Rs. {data.payment.promoDiscount}</b>
                  </p>
                </div>
              )}
              {data.payment.deliveryCharge > 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Delivery charge</p>
                  <p>
                    <b>Rs. {data.payment.deliveryCharge}</b>
                  </p>
                </div>
              )}
              {data.payment.tipAmount > 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Rider Tip</p>
                  <p>
                    <b>Rs. {data.payment.tipAmount}</b>
                  </p>
                </div>
              )}
              {data.payment?.paidAmount && data.payment?.paidAmount > 0 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Paid Amount</p>
                  <p>
                    <b>Rs. {data.payment?.paidAmount}</b>
                  </p>
                </div>
              ) : (
                ""
              )}
                {typeof data.payment.subscriptionAmount === 'number' && !isNaN(data.payment.subscriptionAmount) && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Subscription Charge</p>
                  <p>
                    <b>Rs. {data.payment.subscriptionAmount}</b>
                  </p>
                </div>
              )}
                {typeof data.payment.subscriptionDiscount === 'number' && !isNaN(data.payment.subscriptionDiscount) && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Subscription Discount</p>
                  <p>
                    <b>- Rs. {data.payment.subscriptionDiscount}</b>
                  </p>
                </div>
              )}
              <div className="d-flex justify-content-between">
                <p>Total</p>
                <p>
                  <b>Rs. {parseFloat(data.payment.total).toFixed(2)}</b>
                </p>
              </div>
              {data.payment?.remainingAmount &&
              data.payment?.remainingAmount > 0 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p>Remaining Amount</p>
                  <p>
                    <b>Rs. {data.payment?.remainingAmount}</b>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} key="tableRow_a">
        <TableCell
          align="left"
          className="d-flex align-items-center"
          key="tableCell_a"
        >
          <Avatar style={{ borderRadius: "12px" }} src={row.image} />
          <span className="ml-2 mr-1">{row.name}</span>
          {
            // row.comboId ||
            (row.spiceLevel !== null ||
              (row.modifiers && row.modifiers.length > 0)) && (
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <CaretUp /> : <CaretDown />}
              </IconButton>
            )
          }
        </TableCell>
        <TableCell align="left">x{row.quantity}</TableCell>
        <TableCell align="left">
          Rs. {parseFloat(row.unitPrice).toFixed(2)}
        </TableCell>
        <TableCell align="left">
          Rs. {parseFloat(row.price).toFixed(2)}
        </TableCell>
      </TableRow>

      {
        // row.comboId ||
        (row.spiceLevel !== null ||
          (row.modifiers && row.modifiers.length > 0)) && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1, mt: 3, mb: 3 }}>
                  {/* mod and spice */}
                  <div className="d-flex">
                    {/* mods  */}
                    {row.modifiers && row.modifiers.length > 0 && (
                      <div style={{ marginRight: "200px" }}>
                        {/* modifiers */}
                        <p>
                          <b>Modifier</b>
                        </p>
                        {/* {
                                                row.modifiers.map(eachMod => (
                                                    <p key={eachMod.modifierId}>{eachMod.name}</p>
                                                ))
                                            } */}
                        {row.modifiers.map((eachMod, ind) => (
                          <div key={ind}>
                            {eachMod &&
                              eachMod.options.map((eachOp, i) => (
                                <div
                                  className="d-flex justify-content-between"
                                  key={i}
                                >
                                  <p className="mr-5">{eachOp.name}</p>
                                  <p className="ml-5">Rs.{eachOp.price}</p>
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    )}

                    {/* spices */}
                    <div>
                      {row.spiceLevel !== null && (
                        <>
                          <p>
                            <b>Spice Level</b>
                          </p>
                          <p>{row.spiceLevel}</p>
                        </>
                      )}
                    </div>
                  </div>

                  {/* {
                                    row.comboId &&
                                    <div>
                                        here are combo items
                                    </div>
                                } */}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )
      }
    </React.Fragment>
  );
}

function ShiftsRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  let qrAmount = "";
  let cashAmount = "";
  let a = row?.payment?.deliveryCharge || 0;
  let billAmountPrice = parseFloat(row?.payment?.total) - parseFloat(a);

  if (row.paymentInfo) {
    // Check for QR payment method
    const qrPayment = row.paymentInfo.find((info) => info.method === "Qr");
    if (qrPayment && qrPayment.amount > 0) {
      qrAmount = `Rs.${qrPayment.amount.toLocaleString("ne-NP")}`;
    }

    // Check for Cash payment method
    const cashPayment = row.paymentInfo.find((info) => info.method === "Cash");
    if (cashPayment && cashPayment.amount > 0) {
      cashAmount = `Rs.${cashPayment.amount.toLocaleString("ne-NP")}`;
    }
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
        }}
        key="tableRow_a"
      >
        <TableCell
          align="left"
          //   className="d-flex align-items-center"
          //   style={{ paddingRight: "0px" }}
          //   key="tableCell_a"
        >
          #ORD-{row.trackingNumber}
          {
            // row.comboId ||
            row.status === ORDER_STATUS.FAILED && (
              <IconButton
                size="small"
                onClick={() => setOpen(!open)}
                style={{ transform: "translateX(15px)" }}
              >
                {open ? <CaretUp /> : <CaretDown />}
              </IconButton>
            )
          }
        </TableCell>
        <TableCell align="left">
          {row?.payment?.total
            ? `Rs.${billAmountPrice.toLocaleString("ne-NP")}`
            : ""}
        </TableCell>
        <TableCell align="left">
          {row?.payment?.deliveryCharge
            ? `Rs.${row?.payment?.deliveryCharge.toLocaleString("ne-NP")}`
            : ""}
        </TableCell>
        <TableCell align="left">
          {row?.payment?.total
            ? `Rs.${row?.payment?.total.toLocaleString("ne-NP")}`
            : ""}
        </TableCell>
        <TableCell align="left">
          {row?.payment?.remainingAmount
            ? `Rs.${row?.payment?.remainingAmount.toLocaleString("ne-NP")}`
            : ""}
        </TableCell>
        <TableCell align="left">{qrAmount}</TableCell>
        <TableCell align="left">{cashAmount}</TableCell>
        <TableCell align="left">
          {row?.riderInfo &&
          row?.riderInfo?.status === "Hold" &&
          row?.riderInfo?.holdAmount > 0
            ? `Rs.${row?.riderInfo?.holdAmount.toLocaleString("ne-NP")}`
            : ""}
        </TableCell>
        <TableCell align="left">
          <Button
            style={{ width: "100px", paddingInline: "15px" }}
            className={statusButtonClass(row.status)[0]}
          >
            {statusButtonClass(row.status)[1]}
          </Button>
        </TableCell>
      </TableRow>

      {
        // row.comboId ||
        row.status === ORDER_STATUS.FAILED && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1, mt: 2, mb: 2 }} className="d-flex ">
                  {row?.failedInfo?.phone ? (
                    <div className="d-flex flex-column  gap-2">
                      <div
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        Phone number
                      </div>
                      <div>{row?.failedInfo?.phone}</div>
                    </div>
                  ) : null}
                  {row?.failedInfo?.designation || row?.failedInfo?.name ? (
                    <div className="d-flex flex-column ml-4">
                      <div
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        {row?.failedInfo?.designation}
                      </div>
                      <div className="d-flex flex-row gap-2 align-items-center">
                        <Avatar
                          style={{ borderRadius: "12px" }}
                          src={row.failedInfo?.image}
                        />
                        <div>{row?.failedInfo?.name}</div>
                      </div>
                    </div>
                  ) : null}
                  {row?.failedInfo?.failedReason ? (
                    <div
                      className="d-flex flex-column gap-2 ml-auto"
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      <div>Reason for Cancellation</div>
                      <div
                        style={{
                          color: "#818090",
                          fontWeight: "400",
                          width: "300px",
                          wordWrap: "break-word",
                        }}
                      >
                        {row?.failedInfo?.failedReason}
                      </div>
                    </div>
                  ) : null}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )
      }
    </React.Fragment>
  );
}

function ReviewsRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
        }}
        key="tableRow_a"
      >
        <TableCell align="left">ORD-{row?.trackingNumber}</TableCell>
        <TableCell align="left">
          {moment(row?.createdAt).format("MMM D, YYYY, h:mm A")}
        </TableCell>
        <TableCell align="left">{row?.phone}</TableCell>
        <TableCell align="left">
          <img
            src={
              row?.rating?.mood.toLowerCase() === "terrible"
                ? getS3Image("Terrible.svg")
                : row?.rating?.mood.toLowerCase() === "bad"
                  ? getS3Image("Bad.svg")
                  : row?.rating?.mood.toLowerCase() === "meh"
                    ? getS3Image("Meh.svg")
                    : row?.rating?.mood.toLowerCase() === "good"
                      ? getS3Image("Good.svg")
                      : row?.rating?.mood.toLowerCase() === "great"
                        ? getS3Image("Great.svg")
                        : ""
            }
            alt="emoji"
            style={{ width: "24px", height: "24px", marginRight: "8px" }}
          />
          {row?.rating?.mood}{" "}
          {row?.rating?.comment && (
            <IconButton
              size="small"
              onClick={() => setOpen(!open)}
              style={{ transform: "translateX(15px)" }}
            >
              {open ? <CaretUp /> : <CaretDown />}
            </IconButton>
          )}{" "}
        </TableCell>
      </TableRow>

      {row.rating.comment && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className="d-flex ">
                <div
                  className="d-flex flex-column gap-2 "
                  style={{
                    fontWeight: "600",
                    width: "100%",
                    // backgroundColor: "#F9F9F9",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                >
                  <div>Comment</div>
                  <div
                    style={{
                      color: "#818090",
                      fontWeight: "400",
                      width: "100%",
                      wordWrap: "break-word",
                    }}
                  >
                    {row?.rating?.comment}
                  </div>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

function RowCsr(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} key="tableRow_a">
        <TableCell
          align="left"
          className="d-flex align-items-center"
          key="tableCell_a"
          style={{ height: "5rem" }}
        >
          {/* <Avatar style={{ borderRadius: "12px" }} src={row.image} /> */}
          <p className="ml-2 mr-1">{row.name}</p>
          {
            // row.comboId ||
            (row.spiceLevel !== null ||
              (row.modifiers && row.modifiers.length > 0)) &&
              !row.customId && (
                <IconButton
                  size="small"
                  onClick={() => setOpen(!open)}
                  sx={{ color: "#FFF" }}
                >
                  {open ? (
                    <CaretUp sx={{ color: "#FFF" }} />
                  ) : (
                    <CaretDown sx={{ color: "#FFF" }} />
                  )}
                </IconButton>
              )
          }
        </TableCell>
        <TableCell align="left">x{row.quantity}</TableCell>
        <TableCell align="left">
          Rs. {parseFloat(row.unitPrice).toFixed(2)}
        </TableCell>
        <TableCell align="left">
          Rs. {parseFloat(row.price).toFixed(2)}
        </TableCell>
      </TableRow>

      {
        // row.comboId ||
        (row.spiceLevel !== null ||
          (row.modifiers && row.modifiers.length > 0)) && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1, mt: 3, mb: 3 }}>
                  {/* mod and spice */}
                  <div className="d-flex">
                    {/* mods  */}
                    {row.modifiers && row.modifiers.length > 0 && (
                      <div style={{ marginRight: "200px" }}>
                        {/* modifiers */}
                        <p>
                          <b>Modifier</b>
                        </p>
                        {/* {
                                                row.modifiers.map(eachMod => (
                                                    <p key={eachMod.modifierId}>{eachMod.name}</p>
                                                ))
                                            } */}
                        {row.modifiers.map((eachMod, ind) => (
                          <div key={ind}>
                            {eachMod &&
                              eachMod.options.map((eachOp, i) => (
                                <div
                                  className="d-flex justify-content-between"
                                  key={i}
                                >
                                  <p className="mr-5">{eachOp.name}</p>
                                  <p className="ml-5">Rs.{eachOp.price}</p>
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    )}

                    {/* spices */}
                    <div>
                      {row.spiceLevel !== null && (
                        <>
                          <p>
                            <b>Spice Level</b>
                          </p>
                          <p>{row.spiceLevel}</p>
                        </>
                      )}
                    </div>
                  </div>

                  {/* {
                                    row.comboId &&
                                    <div>
                                        here are combo items
                                    </div>
                                } */}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )
      }
    </React.Fragment>
  );
}
// my cart Table

export const MyCartTable = ({
  data,
  foods,
  userId,
  spiceLevels,
  setSpiceLevels,
  setValues,
}) => {
  const TableColumns = ["Items", "Price", "Qty", "SubTotal", ""];

  const setCart = (cart) => {
    setValues((x) => ({ ...x, cart: cart }));
  };
  return (
    <ThemeProvider theme={getMuiTheme()}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" key="table_b">
          <TableHead key="thead_b">
            <TableRow key="trow_b">
              {TableColumns.map((eachCol, i) => (
                <TableCell key={i}>{eachCol}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key="tbody_b">
            {foods &&
              foods.map((row, i) => {
                return (
                  <CartRow
                    key={i}
                    row={row}
                    ind={i}
                    spiceLevels={spiceLevels}
                    setSpiceLevels={setSpiceLevels}
                    setCart={setCart}
                    userId={userId}
                    setValues={setValues}
                  />
                );
              })}
          </TableBody>
        </Table>
        {data.payment && (
          <div
            className="d-flex justify-content-end w-100"
            style={{
              padding: "30px",
              paddingRight: "10%",
            }}
          >
            <div className="d-flex flex-column" style={{ width: "300px" }}>
              <div className="d-flex justify-content-between">
                <p>Subtotal</p>
                <p>
                  <b>Rs. {parseFloat(data.payment.subTotal).toFixed(2)}</b>
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Delivery Charge</p>
                <p>
                  <b>Rs. {data.delivery.charge}</b>
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Total</p>
                <p>
                  <b>Rs. {parseFloat(data.payment.total).toFixed(2)}</b>
                </p>
              </div>
            </div>
          </div>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

const theme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          width: "50px",
          height: "50px",
          backgroundColor: "transparent",
          backgroundImage: `url("https://nova-foods.s3.amazonaws.com/miscellaneous/emojione-fire.png")`,
          boxShadow: "none",
          transition: "none",
          border: "none",
          "::before": {
            boxShadow: "none",
            transition: "none",
          },
          ":hover": {
            boxShadow: "none",
            transition: "none",
          },
          "&.Mui-active": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

function CartRow(props) {
  const outletContext = useOutletContext();
  const { row, setValues } = props;
  const [open, setOpen] = useState(false);
  const [loadingItem, setLoadingItem] = useState(null);
  const setnumberOfItemsFn = outletContext ? outletContext[6] : null;

  const edit_one = (val, quantity) => {
    if (loadingItem) return;
    var send_data = {
      userId: props.userId,
      itemId: val.itemId,
      identifierId: val.identifierId ?? null,
      comboId: val.comboId,
      quantity: quantity,
      modifiers: val.modifiers ? val.modifiers : [],
    };
    setLoadingItem(val.itemId);
    POSTAPI_USER("/cart/edit", send_data).then((res) => {
      setLoadingItem(null);
      if (res.success) {
        props.setCart(res.cart);
        setnumberOfItemsFn(res.numberOfItems);
      } else if (res.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
    });
  };

  const removeModifier = (prod, i) => {
    var nowMods = prod.modifiers;
    nowMods.splice(i, 1);
    var send_data = {
      userId: props.userId,
      itemId: prod.itemId,
      identifierId: prod.identifierId ?? null,
      comboId: prod.comboId,
      quantity: 0,
      modifiers: nowMods ? nowMods : [],
    };
    POSTAPI_USER("/cart/edit", send_data).then((res) => {
      if (res.success) {
        props.setCart(res.cart);
      }
    });
  };

  const removeOption = (prod, i, ind) => {
    var nowMods = prod.modifiers;
    var nowOp = nowMods[i].options;
    nowOp.splice(ind, 1);
    if (nowMods[i].options.length === 0) nowMods.splice(i, 1);
    var send_data = {
      userId: props.userId,
      itemId: prod.itemId,
      identifierId: prod.identifierId ?? null,
      comboId: prod.comboId,
      quantity: 0,
      modifiers: nowMods ? nowMods : [],
    };
    POSTAPI_USER("/cart/edit", send_data).then((res) => {
      if (res.success) {
        props.setCart(res.cart);
      }
    });
  };
  // for spicy level customize
  const handleChangeSlide = (e, newVal, prod, ind) => {
    var nowSpiceLevels = [...props.spiceLevels];
    nowSpiceLevels[ind] = newVal;
    props.setSpiceLevels(nowSpiceLevels);
    if (newVal - props.spiceLevels[ind] !== 0) {
      var send_data = {
        userId: props.userId,
        itemId: prod.itemId,
        identifierId: prod.identifierId ?? null,
        comboId: prod.comboId,
        modifiers: prod.modifiers,
        quantity: 0,
        spiceLevel: getSpiceText(newVal),
      };
      POSTAPI_USER("/cart/edit", send_data).then((res) => {
        if (res.success) {
          props.setCart(res.cart);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} key="tableRow_b">
        <TableCell align="left" style={{ border: 0 }} key="tableCell_b">
          <div className="d-flex align-items-center">
            <Avatar style={{ borderRadius: "12px" }} src={row.image} />
            <span className="ml-2 mr-1">{row.name}</span>
            {(row.spiceLevel !== null ||
              (row.modifiers && row.modifiers.length > 0)) && (
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <CaretUp /> : <CaretDown />}
              </IconButton>
            )}
          </div>
        </TableCell>
        <TableCell align="left" style={{ border: 0 }}>
          <span>Rs. {parseFloat(row.unitPrice).toFixed(2)}</span>
        </TableCell>
        <TableCell align="left" style={{ border: 0 }}>
          {row.itemId === loadingItem ? (
            <div className="d-flex align-items-center">
              <div className="div-disabled mr-2">
                {CartQuantitySide(row, edit_one, 0)}
              </div>
              <CircularProgress
                style={{
                  color: "#8943EF",
                  width: "25px",
                  height: "25px",
                }}
              />
            </div>
          ) : (
            CartQuantitySide(row, edit_one, 0)
          )}
        </TableCell>
        <TableCell align="left" style={{ border: 0 }} className="c1 w3 f2">
          <span>Rs. {parseFloat(row.price).toFixed(2)}</span>
        </TableCell>
        <TableCell align="left" style={{ border: 0 }}>
          <IconButton
            disabled={row.itemId === loadingItem}
            onClick={() => edit_one(row, -row.quantity)}
          >
            <TrashSimple size={25} />
          </IconButton>
        </TableCell>
      </TableRow>

      {(row.spiceLevel !== null ||
        (row.modifiers && row.modifiers.length > 0)) && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1, mt: 3, mb: 3 }}>
                {/* mod and spice */}
                <div className="d-flex">
                  {/* mods  */}
                  {row.modifiers && row.modifiers.length > 0 && (
                    <div className="col-6 mr-4">
                      {/* modifiers */}
                      <p>
                        <b>Modifier</b>
                      </p>
                      {row.modifiers.map((each, i) => (
                        <div key={i}>
                          <span className="c3 w3 s2">
                            <IconButton
                              className="ml-0"
                              onClick={() => removeModifier(row, i)}
                            >
                              <MinusCircle
                                color="#818090"
                                weight="fill"
                                className="cursor_pointer mod_icon"
                              />
                            </IconButton>
                            {each.name}
                          </span>
                          <div className="d-flex flex-column ">
                            {each.options &&
                              each.options.map((eachOP, ind) => {
                                return (
                                  <div
                                    key={ind}
                                    className="d-flex align-items-center"
                                  >
                                    <IconButton
                                      className="ml-0"
                                      onClick={() => removeOption(row, i, ind)}
                                    >
                                      <MinusCircle className="cursor_pointer mod_icon" />
                                    </IconButton>
                                    <span>{eachOP.name}</span>
                                    <span className="ml-auto">
                                      Rs. {parseFloat(eachOP.price).toFixed(2)}
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* spices */}
                  <div className="col-5">
                    {row.spiceLevel !== null && (
                      <>
                        <p className="c3 w3 s2 ml-0 mt-2">Spicy Level</p>
                        <div className="spicy_slider_div mb-3">
                          <p className="spiceLevel_text">
                            {getSpiceText(props.spiceLevels[props.ind])}
                          </p>
                          {props.spiceLevels.length > 0 && (
                            <ThemeProvider theme={theme}>
                              <SliderMUI
                                defaultValue={28}
                                value={props.spiceLevels[props.ind]}
                                color="error"
                                step={null}
                                onChange={(e, newVal) =>
                                  handleChangeSlide(e, newVal, row, props.ind)
                                }
                                valueLabelDisplay="off"
                                marks={[0, 28, 66, 100].map((each) => ({
                                  value: each,
                                  label: "",
                                }))}
                              />
                            </ThemeProvider>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
