import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  Container,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Menu as NotifMenu,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
  styled,
  useScrollTrigger,
} from "@mui/material";
import {
  ArrowSquareOut,
  Bell,
  BellZ,
  CaretDown,
  CaretUp,
  IconContext,
  List as ListIcon,
  Package,
  ShoppingBag,
} from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { API } from "../../Auth/Config";
import {
  LogoutAdmin,
  POSTAPI_ADMIN,
  isAuthenticatedAdmin,
} from "../../Auth/Index";
import { AlertError, formatDateTimeNext } from "../../Components/Common";
import { drawerData } from "../../Components/Sidebar";
import ting from "../../Components/Ting.mp3";
import { HeaderTitle } from "../../Components/header-title";
import { getS3Image } from "../../Components/s3";
import { ENTITY_TYPE } from "../../Components/staticData";
import "../../css/Admin/admin_layout.css";

const drawerWidth = 240;

const MySubListItem = styled(ListItem)(({ theme }) => ({
  marginLeft: "50px",
  color: "#969BA0",
  "&.Mui-selected": {
    width: "220px",
    backgroundColor: "#FFF",
    borderRadius: "12px",
    color: "var(---text)",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#FFF",
    color: "var(---text)",
  },
  "&:hover": {
    color: "var(---text)",
    backgroundColor: "#FFFFFF",
  },
}));

const MyListItem = styled(ListItem)(({ theme }) => ({
  marginLeft: "10px",
  width: "calc(100% - 10px)",
  "&.Mui-selected": {
    width: "220px",
    backgroundColor: "#F2F2F2",
    borderRadius: "12px",
    color: "#8943EF",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#F2F2F2",
    color: "#8943EF",
  },
  "&:hover": {
    color: "#8943EF",
    backgroundColor: "#FFFFFF",
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const theme = createTheme({
  root: {
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: `1px solid slategrey`,
    },
  },
  typography: {
    fontFamily: "Outfit",
    button: {
      textTransform: "none !important",
      fontFamily: "Outfit",
    },
  },
});

const AdminLayout = (props) => {
  const navigate = useNavigate();
  const [audio] = useState(new Audio(ting));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [unread, setUnread] = useState(0);
  const [expandSub, setExpandSub] = useState(
    Array(drawerData.length).fill(false),
  );
  const [expandNestedSub, setExpandNestedSub] = useState(
    Array(drawerData.length).fill(false),
  );
  const userId =
    isAuthenticatedAdmin() && isAuthenticatedAdmin().employeeId
      ? isAuthenticatedAdmin().employeeId
      : null;
  const name =
    isAuthenticatedAdmin() && isAuthenticatedAdmin().name
      ? isAuthenticatedAdmin().name
      : null;
  const [values, setValues] = useState({
    socket: null,
    notfFirst: true,
    first: true,
    newOrder: null,
    anchorElUser: null,
    unreadCount: null,
    anchorElNotif: null,
    errorPosition: "",
    errorText: "",
    socketFirst: true,
  });
  const {
    socket,
    notfFirst,
    first,
    newOrder,
    anchorElUser,
    unreadCount,
    anchorElNotif,
    errorPosition,
    errorText,
    socketFirst,
  } = values;
  const [notifications, setNotifications] = useState([]);
  const openUser = Boolean(anchorElUser);
  const openNotif = Boolean(anchorElNotif);

  // API call for notifications
  useEffect(() => {
    if (notfFirst)
      POSTAPI_ADMIN("/notification/systemUser", { userId }).then((res) => {
        if (res.success) {
          setValues((x) => ({
            ...x,
            unreadCount: res.unReadCount,
            notfFirst: false,
          }));
        }
        if (res.error)
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: res.error,
            notfFirst: false,
          }));
      });
  }, [userId, notfFirst]);

  // if not user, navigate to login
  useEffect(() => {
    if (!userId) navigate("/admin/login");
  }, [userId, navigate]);
  // socket
  useEffect(() => {
    if (!socket && userId && socketFirst) {
      var soc = io(API, {
        reconnection: true,
        reconnectionAttempts: Infinity,
      });
      setValues((x) => ({
        ...x,
        socket: soc,
        socketFirst: false,
      }));
      soc.emit("onConnection", {
        userId,
      });
      soc.on("onConnectionResponse", (res) => {});
    }
    if (socket) {
      socket.on("notification", (res) => {
        setValues((x) => ({
          ...x,
          newOrder: res.newOrder,
          unreadCount: res.unReadCount,
        }));
        audio.play();
      });

      socket.on("disconnect", function (res) {
        setValues((x) => ({ ...x, socket: null }));
      });
    }
  }, [userId, socket, first, audio, unreadCount, socketFirst]);

  const handleClick = (name, event) => {
    setValues((x) => ({ ...x, [name]: event.currentTarget }));
  };
  // read notification
  const handleClickNotif = (name, event) => {
    setValues((x) => ({ ...x, anchorElNotif: event.currentTarget }));
    POSTAPI_ADMIN("/notification/read", { employeeId: userId }).then((res) => {
      if (res.success) {
        setValues((x) => ({ ...x, unreadCount: 0 }));
        setNotifications(res.notifications);
      }
    });
  };
  const handleClose = (name) => {
    setValues((x) => ({ ...x, [name]: null }));
  };
  const handleMenuItem = (item) => {
    setValues((x) => ({ ...x, anchorElNotif: null }));
    switch (item.entity) {
      case ENTITY_TYPE.ORDER:
        POSTAPI_ADMIN("/order/one", { orderId: item.entityId }).then((resp) => {
          if (resp.success) {
            // var stateData = {
            //     ...resp.order,
            //     riders: resp.riders
            // }
            // navigate('/orders/created/view', { state: stateData })
          } else if (resp.error)
            setValues((x) => ({
              ...x,
              errorPosition: "apiErr",
              errorText: "Something went wrong!",
            }));
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      POSTAPI_ADMIN("/order/countDraftOrders").then((res) => {
        if (res.success) {
          setUnread(res.countDraftOrders);
          if (res.countDraftOrders > 0) {
            audio.play();
          }
        } else if (res) console.log(res.error);
      });
    }, 200000);

    return () => clearInterval(intervalId);
  }, [audio, unread]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (unread > 0) {
        audio.play();
      }
    }, 1500);

    return () => clearInterval(intervalId);
  }, [audio, unread]);

  useEffect(() => {
    POSTAPI_ADMIN("/order/countDraftOrders").then((res) => {
      if (res.success) {
        setUnread(res.countDraftOrders);
      } else if (res) console.log(res.error);
    });
  }, []);

  const CircleWithNumber = ({ number }) => {
    if (number === 0 || !number) {
      return <></>;
    }

    return (
      <div
        style={{
          backgroundColor: "red",
          width: "18px",
          height: "18px",
          color: "white",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "16px",
          fontSize: "14px",
        }}
      >
        <div>{number}</div>
      </div>
    );
  };

  // remove socket before tab closed
  // useEffect(() => {
  //     const handleTabClose = event => {
  //         event.preventDefault();
  //         if (socket && userId) {
  //             socket.emit('onDisconnection', {
  //                 userId
  //             })
  //         }
  //         return
  //     };

  //     window.addEventListener('beforeunload', handleTabClose);

  //     return () => {
  //         window.removeEventListener('beforeunload', handleTabClose);
  //     };
  // }, [socket, userId]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigateFn = (each, index) => {
    if (!each.sub) {
      navigate(each.path);
    } else {
      var newArr = [...expandSub];
      newArr[index] = !newArr[index];
      setExpandSub(newArr);
    }
  };

  const navigateNestedFn = (each, index) => {
    if (!each.sub) {
      navigate(each.path);
    } else {
      var newArr = [...expandNestedSub];
      newArr[index] = !newArr[index];
      setExpandNestedSub(newArr);
    }
  };

  const drawer = (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Toolbar>
        {/* <span className='nova_title_text'>Nova</span> */}

        <div
          style={{
            width: "240px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={getS3Image("Logo.svg")}
            alt=""
            onClick={() => navigate("/dashboard")}
            className="nova_title_logo_img cursor_pointer"
          />
        </div>
      </Toolbar>
      <List>
        {drawerData.map((each, index) => (
          <div key={index}>
            <MyListItem
              button
              // className={styles.root}
              selected={
                window ? window.location.pathname.includes(each.path) : false
              }
              onClick={() => navigateFn(each, index)}
            >
              <IconContext.Provider
                value={
                  (
                    window
                      ? window.location.pathname.includes(each.path)
                      : false
                  )
                    ? {
                        size: 28,
                        mirrored: false,
                      }
                    : {
                        size: 28,
                        mirrored: false,
                      }
                }
              >
                <div className="mr-3">{each.icon}</div>
              </IconContext.Provider>
              <ListItemText primary={each.name} />
              {each.sub && (
                <>
                  {expandSub[index] ? (
                    <CaretUp className="mr-3" />
                  ) : (
                    <CaretDown className="mr-3" />
                  )}
                </>
              )}
            </MyListItem>
            {each.sub && (
              <Collapse in={expandSub[index]} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  disablePadding
                  key="list"
                  className="mt-1"
                >
                  {each.sub.map((subnavEach, subIndex) => {
                    return (
                      <div key={subIndex}>
                        <MySubListItem
                          key={subIndex}
                          button
                          // className={styles.root}
                          selected={window.location.pathname.includes(
                            subnavEach.path,
                          )}
                          onClick={() => {
                            if (!subnavEach.sub) {
                              navigate(subnavEach.path);
                            } else {
                              navigateNestedFn(subnavEach, subIndex);
                            }
                          }}
                        >
                          {subnavEach.name}
                          {subnavEach.name === "Online Orders" && (
                            <CircleWithNumber number={unread} />
                          )}
                          {subnavEach.sub && (
                            <>
                              {expandNestedSub[subIndex] ? (
                                <CaretUp
                                  className="mr-3"
                                  style={{ marginLeft: "30px" }}
                                />
                              ) : (
                                <CaretDown
                                  className="mr-3"
                                  style={{ marginLeft: "30px" }}
                                />
                              )}
                            </>
                          )}
                        </MySubListItem>
                        {subnavEach.sub && (
                          <Collapse
                            in={expandNestedSub[subIndex]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List
                              component="div"
                              disablePadding
                              key="list"
                              className="mt-1 ml-2"
                            >
                              {subnavEach.sub.map(
                                (nestedEach, nestedSubIndex) => {
                                  return (
                                    <MySubListItem
                                      key={nestedSubIndex}
                                      // className={styles.root}
                                      selected={window.location.pathname.includes(
                                        nestedEach.path,
                                      )}
                                      onClick={() => {
                                        navigate(nestedEach.path);
                                      }}
                                    >
                                      {nestedEach.name}
                                    </MySubListItem>
                                  );
                                },
                              )}
                            </List>
                          </Collapse>
                        )}
                      </div>
                    );
                  })}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
      {/* <Button onClick={() => navigate('/')}>Dis</Button> */}
      <Button
        className="admin_drawer_kds"
        onClick={() => window.open("/kds/home", "_blank")}
      >
        <Avatar
          style={{ position: "absolute", left: "20px" }}
          variant="square"
          src="https://nova-foods.s3.ap-south-1.amazonaws.com/kds_btn_icon.svg"
        />
        <span className="ml-4">KDS</span>
        <ArrowSquareOut className="ml-2" weight="bold" size={20} />
      </Button>
    </div>
  );

  const NotificationBody = (option) => {
    switch (option.entity) {
      case ENTITY_TYPE.ORDER:
        return (
          <div className="d-flex">
            <div style={{ width: "40px" }}>
              <ShoppingBag size={32} color="#8943EF" />
            </div>
            <div
              className="d-flex flex-column ml-2"
              style={{
                width: "calc(100% - 50px)",
                whiteSpace: "initial",
              }}
            >
              <span className="ad_notif_break">{option.body}</span>
              <span className="c7 mt-2">
                {formatDateTimeNext(option.createdAt)}
              </span>
            </div>
          </div>
        );
      case ENTITY_TYPE.INVENTORY:
        return (
          <div className="d-flex">
            <div style={{ width: "40px" }}>
              <Package size={32} color="#8943EF" weight="fill" />
            </div>
            <div
              className="d-flex flex-column ml-2"
              style={{
                width: "calc(100% - 50px)",
                whiteSpace: "initial",
              }}
            >
              <span className="ad_notif_break">{option.body}</span>
              <span className="c7 mt-2">{option.createdAt}</span>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const AdminNotification = () => (
    <div className="d-flex align-items-center ml-auto">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={openNotif ? "long-menu" : undefined}
        aria-expanded={openNotif ? "true" : undefined}
        aria-haspopup="true"
        onClick={(e) => handleClickNotif("anchorElNotif", e)}
      >
        <Badge
          badgeContent={unreadCount}
          sx={{
            "& .MuiBadge-badge": {
              color: "#F7F4DB",
              backgroundColor: "#8943EF",
            },
          }}
        >
          <Bell color="#2E2E2E" />
        </Badge>
      </IconButton>
      <NotifMenu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorElNotif}
        open={openNotif}
        onClose={() => handleClose("anchorElNotif")}
        PaperProps={{
          style: {
            maxHeight: "500px",
            width: "400px",
            scrollbarWidth: "thin",
          },
        }}
      >
        {notifications.length === 0 ? (
          <div className="ad_no_notif">
            <BellZ size={100} weight="fill" color="#8943EF" />
            <span className="mt-2 s2">No notifications yet</span>
          </div>
        ) : (
          <div className="d-flex align-items-center mt-3 justify-content-between">
            <p className="ml-3 w3 s3">Notifications</p>
            <p
              className="mr-3 c9 w3 cursor_pointer"
              onClick={() => {
                handleClose("anchorElNotif");
                navigate("/admin/notifications", { state: notifications });
              }}
            >
              View All
            </p>
          </div>
        )}
        {notifications.map((option, ind) => (
          <MenuItem
            key={ind}
            // onClick={() => handleClose('anchorEl')}>
            onClick={() => handleMenuItem(option)}
          >
            <div className="notifi_each">{NotificationBody(option)}</div>
          </MenuItem>
        ))}
      </NotifMenu>
    </div>
  );

  const Appbar_Comp = () => (
    <ElevationScroll {...props}>
      <AppBar className="admin_layout_appbar" sx={{ left: 0 }}>
        <Toolbar>
          <Toolbar sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <ListIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Drawer
            </Typography>
          </Toolbar>

          <Toolbar
            sx={{ mr: 2, display: { xs: "none", sm: "flex" }, color: "#000" }}
          >
            <div className="admin_layout_appbar_div">
              {HeaderTitle()}
              {AdminNotification()}
              <div>
                <Button
                  className="avatar_name"
                  aria-label="user"
                  id="user-button"
                  aria-controls={openUser ? "user-menu" : undefined}
                  aria-expanded={openUser ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => handleClick("anchorElUser", e)}
                >
                  <Avatar />
                  <span className="ml-2 mr-2">{name}</span>
                  <CaretDown />
                </Button>
                <NotifMenu
                  id="user-menu"
                  MenuListProps={{
                    "aria-labelledby": "user-button",
                  }}
                  anchorEl={anchorElUser}
                  open={openUser}
                  onClose={() => handleClose("anchorElUser")}
                  PaperProps={{
                    style: {
                      width: "200px",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      LogoutAdmin();
                      navigate("/admin/login");
                    }}
                  >
                    Logout
                  </MenuItem>
                </NotifMenu>
              </div>
            </div>
          </Toolbar>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );

  const Drawer_Comp = () => (
    <Box
      className="admin_drawer"
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        onClick={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" }, //for mobile
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" }, //for larger devices
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );

  const Body = () => (
    <Container style={{ height: "100%" }}>
      <Toolbar />
      <Box
        className="admin_body"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "100%",
        }}
      >
        {userId && <Outlet context={[newOrder, setValues]} />}
      </Box>
    </Container>
  );

  return (
    <Box className="admin_layout_main">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {Appbar_Comp()}
        {Drawer_Comp()}
        <div id="scroll_here_id" />
        {Body()}
        {AlertError(errorPosition, errorText, setValues)}
      </ThemeProvider>
    </Box>
  );
};

AdminLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default AdminLayout;
