import { Avatar, Box, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function Quote({ quote, index, selected, setState }) {
  const handleSelect = (sel) => {
    setState((prevState) => {
      const selectedQuotes = prevState.selectedQuotes.slice(); // Create a shallow copy of the selected array
      const index = selectedQuotes.indexOf(sel.categoryId ?? sel.itemId);

      if (index !== -1) {
        // If sel.id is in the array, remove it
        selectedQuotes.splice(index, 1);
      } else {
        // If sel.id is not in categoryId array, add it
        selectedQuotes.push(sel.categoryId ?? sel.itemId);
      }

      return { ...prevState, selectedQuotes };
    });
  };
  return (
    <Draggable draggableId={quote.id} index={index}>
      {(provided) => (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            borderBottom: "1px solid #E9ECFF",
            backgroundColor: "#fff",
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={`d-flex w-100 align-items-center`}
            style={{
              padding: "12px",
              gap: "12px",
              backgroundColor: selected ? "rgba(0, 71, 255, 0.25)" : "#fff",
            }}
            onClick={(e) => handleSelect(quote)}
          >
            <Avatar src={quote.image} />
            <span className="c3 s-12">{quote.name}</span>
          </div>
        </div>
      )}
    </Draggable>
  );
}

const QuoteList = React.memo(function QuoteList({
  quotes,
  selectedQuotes,
  setState,
}) {
  return quotes.map((quote, index) => (
    <Quote
      quote={quote}
      index={index}
      key={quote.id}
      selected={
        selectedQuotes?.find((x) => x === (quote.categoryId || quote.itemId))
          ? true
          : false
      }
      setState={setState}
    />
  ));
});

export const DraggableCards = (
  prop,
  handleUpdated,
  varName,
  values,
  setValues,
) => {
  const { loadingSection, loadingClearRemove } = values;

  const [state, setState] = useState({
    quotes: [],
    selectedQuotes: [],
  });
  useEffect(() => {
    setState((x) => ({
      ...x,
      quotes: prop.map((x, ind) => ({ ...x, id: `id-${ind}` })),
    }));
  }, [prop]);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(
      state.quotes,
      result.source.index,
      result.destination.index,
    );

    setState((x) => ({ ...x, quotes }));
    handleUpdated(
      quotes.map((obj) => obj.categoryId ?? obj.itemId),
      setValues,
      varName,
    );
  }
  const handleRemoveOrClear = async () => {
    // check if already loading
    if (loadingSection === varName && loadingClearRemove) return;
    else if (state.selectedQuotes?.length > 0) {
      //remove, since selected array is not empty
      setValues((x) => ({
        ...x,
        loadingSection: varName,
        loadingClearRemove: true,
      }));
      const objectsToKeep = state.quotes?.filter(
        (obj) => !state.selectedQuotes.includes(obj.categoryId ?? obj.itemId),
      );
      const ids = objectsToKeep.map((obj) => obj.categoryId ?? obj.itemId);
      await handleUpdated(ids, setValues, varName);
      setState((x) => ({ ...x, selectedQuotes: [] }));
    } else {
      //clear
      setValues((x) => ({
        ...x,
        loadingSection: varName,
        loadingClearRemove: true,
      }));
      handleUpdated([], setValues, varName);
    }
  };
  return (
    <Box
      className="d-flex flex-column bg-white col"
      gap={"16px"}
      padding={"24px 24px 0 24px"}
      borderRight={"1px solid #E9ECFF"}
    >
      <div className="d-flex justify-content-between">
        <span className="s2 c3">Selected Categories</span>
        <Button
          className="s-12 c7"
          onClick={(e) => handleRemoveOrClear()}
          style={{
            height: "35px",
          }}
        >
          {loadingSection === varName && loadingClearRemove ? (
            <CircularProgress size={12} className="c1" />
          ) : (
            <>{state.selectedQuotes?.length > 0 ? "Remove" : "Clear all"}</>
          )}
        </Button>
      </div>
      {/* list */}
      <div
        style={{ height: "280px", overflowY: "auto", scrollbarWidth: "thin" }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <QuoteList
                  quotes={state.quotes}
                  selectedQuotes={state.selectedQuotes}
                  setState={setState}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Box>
  );
};
