import { Button, IconButton } from "@mui/material"
import { FileArrowDown, Plus, XCircle } from "@phosphor-icons/react"
import { useEffect, useState } from "react"
import { CircularProgressCompSec, LabelComp } from "../../../Components/Common"
import { useGetBannerImagesQuery, useGetPopupImagesQuery, useUploadMarketplaceImagesMutation } from "../../../slices/apiSlice"

export const Marketplace = () => {
    const [values, setValues] = useState({ 
        popupImages : [],
        webPopupImages  : [],
        bannerImages : [],
        webMobileViewBannerImages : [],
        webBannerImages : [],
        removeIds : [],
        errorPosition : '', 
        errorText : ''
    })
    const { popupImages, webPopupImages,
        bannerImages, webBannerImages, webMobileViewBannerImages,
        removeIds, errorPosition, errorText } = values
    const { data : bannerImagesData } = useGetBannerImagesQuery()
    const { data : popupImagesData } = useGetPopupImagesQuery()
    const [ uploadMarketplaceImages, { isLoading }] = useUploadMarketplaceImagesMutation()
    const handleUpload = async () => {
        try {
            const send = {
                popupImages,
                webPopupImages,
                webMobileViewPopupImages : webPopupImages,
                bannerImages,
                webBannerImages,
                webMobileViewBannerImages
            } 
            await uploadMarketplaceImages(send)
            // uploadMarketplaceImages()
        } catch (error) {
            
        }
    }
    useEffect(()=>{
        setValues(x=>({...x, 
            bannerImages : bannerImagesData?.bannerImages ?? [],
            webMobileViewBannerImages : bannerImagesData?.webMobileViewBannerImages ?? [],
            webBannerImages : bannerImagesData?.webBannerImages ?? []
        }))
    },[bannerImagesData])
    useEffect(()=>{
        setValues(x=>({...x, popupImages : popupImagesData?.popupImages ?? null, webPopupImages : popupImagesData?.webPopupImages ?? null}))
    },[popupImagesData])
    return (
        <div className="d-flex flex-column bg-white" style={{ padding:24, gap:24, borderRadius:12}}>
            <span className="s2 w3 d-flex">Popup Image</span>
           <div className="d-flex gap-3">
                <div className="w-100">
                    {LabelComp('App Popup Image', 'popupImages', errorPosition, errorText)}
                    {SelectImagePopup(popupImages,'popupImages',setValues)}
                </div>
                <div className="w-100">
                    {LabelComp('Mobile / Web Popup Image', 'webPopupImages', errorPosition, errorText)}
                    {SelectImagePopup(webPopupImages,'webPopupImages',setValues)}
                </div>
           </div>
            <span className="s2 w3 d-flex mt-3">Banner Image</span>
            <div className="d-flex gap-3">
                <div className="d-flex flex-column w-100">
                    {LabelComp('App Banner Images', 'bannerImages', errorPosition, errorText)}
                    {SelectImageMultipleRatio(bannerImages, 'bannerImages',setValues, removeIds, 328, 169)}
                </div>
                <div className="d-flex flex-column w-100">
                    {LabelComp('Mobile Banner Images', 'webMobileViewBannerImages', errorPosition, errorText)}
                    {SelectImageMultipleRatio(webMobileViewBannerImages, 'webMobileViewBannerImages',setValues, removeIds, 328, 169)}
                </div>
            </div>
            <div className="d-flex flex-column w-50">
                {LabelComp('Web Banner Images', 'webBannerImages', errorPosition, errorText)}
                {SelectImageMultipleRatio(webBannerImages, 'webBannerImages',setValues, removeIds, 1210, 267)}
            </div>
            <Button className="primary_btn ml-auto" style={{ width:200}}
            onClick={handleUpload}
            >{isLoading ? <CircularProgressCompSec /> : 'Save changes'}</Button>
        </div>
    )
}

export const SelectImagePopup = (images, name, setValues) => {
    const image = images?.[0] ?? null
    const handleImage = (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          setValues((val) => ({ ...val, [name]: [reader.result] }))
        };
        reader.onerror = function (error) {
        }
    }
    return(
    <div className="add_category_image_box">
      {!image ? (
        <div className="upload-btn-wrapper">
          <IconButton>
            <FileArrowDown color="#0C72DE" style={{ fontSize: 80 }} />
          </IconButton>
          <p className="browseText">
            Drop your file here or
            <span className="browseTextBold"> Browse</span>
          </p>
          <input
            type="file"
            onChange={(event) =>
                handleImage(event.target.files[0])
            //   setValues((val) => ({ ...val, [name]: event.target.files[0] }))
            }
          />
        </div>
      ) : (
        <>
          {typeof image === "object" ? (
            <div className="upload-btn-wrapper-show">
              <div className="add_category_image_div">
                <img
                  src={URL.createObjectURL(image)}
                  alt=""
                  className="add_category_image"
                />
                 <XCircle
                    className="prodEachImageCancel"
                    style={{
                      top: 5,
                      right: 5,
                      width : 20,
                      height : 20
                    }}
                    onClick={(event) => setValues((val) => ({ ...val, [name]: [] }))
                  }
                  />
              </div>
              <input
                type="file"
                onChange={(event) =>
                    handleImage(event.target.files[0])
                //   setValues((val) => ({ ...val, [name]: event.target.files[0] }))
                }
              />
            </div>
          ) : (
            <div className="upload-btn-wrapper-show">
              <div className="add_category_image_div">
              <XCircle
                    className="prodEachImageCancel"
                    style={{
                      top: 5,
                      right: 5,
                      width : 20,
                      height : 20
                    }}
                    onClick={(event) => setValues((val) => ({ ...val, [name]: [] }))
                  }
                  />
                <img src={image} alt="" className="add_category_image" />
              </div>
              <input
                type="file"
                onChange={(event) =>
                    handleImage(event.target.files[0])
                //   setValues((val) => ({ ...val, [name]: event.target.files[0] }))
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export const SelectImageMultipleRatio = (images, name, setFn, removeIds, ratioX, ratioY) => {
    const handleDeleteImage = (ind) => {
      var editImages = [...images];
  
      var nowDelete = editImages[ind];
      editImages.splice(ind, 1);
  
      var deleted = [...removeIds];
      if (typeof nowDelete === "string") deleted.push(nowDelete);
      setFn((val) => ({
        ...val,
        [name]: editImages,
        removeIds: deleted,
      }));
    };
    const handleImage = (e) => {
      
      var img  = document.createElement("img");
      img.onload = function (event) {
        const requiredRatio = parseFloat(ratioX/ratioY).toFixed(2)
        const ratio = parseFloat((event.target.naturalWidth)/(event.target.naturalHeight)).toFixed(2)
        if(ratio === requiredRatio) {
          var newImages = [...images];
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = function () {
            newImages.push(reader.result);
            return setFn((val) => ({ ...val, [name]: newImages, errorPosition : '', errorText : '' }))
          };
        }
        else return setFn(x=>({...x, errorPosition : name, errorText : 'Image resolution should be '+ ratioX + ":"+ ratioY}))
        };
        img.src = URL.createObjectURL(e.target.files[0]);
    }
   
    return (
      <div className="add_category_image_box">
        {images.length === 0 ? (
          <div className="upload-btn-wrapper">
            <IconButton>
              <FileArrowDown color="#0C72DE" style={{ fontSize: 80 }} />
            </IconButton>
            <p className="browseText">
              Drop your file here or
              <span className="browseTextBold"> Browse</span>
            </p>
            <input
              type="file"
              onChange={(event) => handleImage(event) }
            />
          </div>
        ) : (
          <div className="upload-btn-wrapper-show">
            <div className="prodEachImageDiv">
              {images.map((eachImg, indexEachImg) => {
                var urla =
                  typeof eachImg === "object"
                    ? URL.createObjectURL(eachImg)
                    : eachImg;
                return (
                  <div className="prodEachImageInside" key={indexEachImg}>
                    <img src={urla} alt="" className="prodEachImage" />
                    <XCircle
                      className="prodEachImageCancel"
                      onClick={(event) => handleDeleteImage(indexEachImg)}
                    />
                  </div>
                );
              })}
              <div className="addAnotherImgDiv">
                <Plus className="addAnotherImg" />
              </div>
            </div>
              <input
                type="file"
                onChange={(event) => handleImage(event) }
              />
          </div>
        )}
      </div>
    );
};
