import { X } from "@phosphor-icons/react";
import React from "react";

const PreviewImage = ({ file, onClick, label, hideCross = false }) => {
  return (
    <>
      <label className="text-text-black font-normal text-sm mb-2 block mt-2">
        {label}
      </label>
      <div class="d-flex align-items-center justify-content-center position-relative bg-white shadow-sm rounded-xl p-4 mb-2">
        <img
          src={file}
          alt="preview"
          style={{
            width: "200px",
            height: "200px",
          }}
        />
        {!hideCross && (
          <X
            className="position-absolute top-0 end-0 bg-primary rounded-circle"
            size={20}
            color="#FFFFFF"
            onClick={onClick}
          />
        )}
      </div>
    </>
  );
};

export default PreviewImage;
