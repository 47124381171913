import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { AlertError, CircularProgressComp } from "../../Components/Common";
import { useGetHomeQuery } from "../../slices/apiSlice";
import { AddToCartModal, OpenAddToCartModal } from "./AddToCartFn";
import { Item } from "./ReactSlick";
import "./a.css";

export const ViewCombos = () => {
  const [values, setValues] = useState({
    combos: [],
    errorPosition: "",
    errorText: "",
    error_optionsIndex: "",
    modalItem: null,
    itemModalVisible: false,
    modifiersSel: [],
  });
  const {
    combos,
    errorPosition,
    errorText,
    //  error_optionsIndex,
    modalItem,
    itemModalVisible,
    modifiersSel,
  } = values;
  const {isLoading, data:res} = useGetHomeQuery()

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      combos: res?.combos ?? [],
    }));
  }, [res]);
  return (
    <div className="">
      <p className="w3 s3 mt-5 mb-4">Combo Meals</p>

      <div className="d-flex w-100 justify-content-center">
        {isLoading && <CircularProgressComp />}
      </div>

      <Grid
        container
        mb={"30px"}
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {combos &&
          combos.map((card, ind) => (
            <Grid key={ind} item xs={6} sm={4} md={3} lg={3}>
              <Item style={{ height: "100%" }}>
                <div
                  className="sc_combo_div_all"
                  onClick={(e) => {
                    const send = {...card}
                    send.isCombo = true;
                    OpenAddToCartModal(e, send, setValues);
                  }}
                >
                  <div
                    className="hp_each_popular_image_combo_search mb-3"
                    style={{
                      background: `url(${card.image})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  ></div>
                  <p className="c3 w3 s2">{card.name}</p>
                  {/* <p className="c7 s-12">{card.estimatedTime} min approx.</p> */}
                  <p className="sc_cm_item_div">
                    {card.items &&
                      card.items.map((eachItem, ind) => (
                        <span key={ind} className="sc_cm_item">
                          {eachItem.name}
                          {eachItem.quantity > 1 && ` X${eachItem.quantity}`}
                        </span>
                      ))}
                  </p>
                  <div className="d-flex mt-2 mt-sm-0 align-items-center">
                    <span className="s5 c1 w3">Rs. {card.price}</span>
                  </div>
                </div>
              </Item>
            </Grid>
          ))}
      </Grid>

      {AddToCartModal(itemModalVisible, modalItem, modifiersSel, setValues)}

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};
