import { Box, Modal } from "@mui/material";
import { DownloadSimple, X } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import CropImage from "../image-crop";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  border: "none",
  boxShadow: 24,
  p: 4,
  height: 500,
};

const UploadImage = ({
  thumbModal,
  setThumbModal,
  name,
  onChange,
  label,
  required,
  cropAsp,
  SetImageName,
}) => {
  const [image, setImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const fileRef = useRef(null);

  const inputElement = document.querySelector('input[name="image"]');

  const handleModal = () => {
    setShowModal(!showModal);
    // setFieldValue && setFieldValue(name, null);
    setImage(null);
    if (inputElement) {
      inputElement.value = "";
    }
  };

  const handleFileChange = (event) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // Check if the file size is within the limit (5MB)
      if (file.size > 5 * 1024 * 1024) {
        // Handle the case where the file exceeds the limit (e.g., show an alert)
        setUploadError(" File size exceeds the limit of 5MB");
        if (inputElement) {
          inputElement.value = "";
        }
        return;
      } else {
        SetImageName && SetImageName(file.name);
        setUploadError("");
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    image && setShowModal(true);
  }, [image]);
  return (
    <div className="mb-2 z-[9999] mt-2">
      <label className="text-text-black font-normal text-sm mb-2 block">
        {label}
      </label>
      {showModal ? (
        <>
          {/* <Modal title={'Crop Image'} onClick={handleModal} hideCross={true}> */}
          {/* <Modal
                open={true}
                onClose={() => setThumbModal(false)}
            >
             <Box className='bg-white'>
              <CropImage
                  image={image}
                  onChange={onChange}
                  handleModal={handleModal}
                  cropAsp={cropAsp}
                />
             </Box>
            </Modal> */}
          <Modal open={true} onClose={() => setThumbModal(false)}>
            <Box sx={style}>
              <div
                className="d-flex flex-column h-100"
                style={{ position: "relative" }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="">Crop Image</h5>
                  <X onClick={handleModal} size={25} />
                </div>
                <div className="my-3">
                  <CropImage
                    image={image}
                    onChange={onChange}
                    handleModal={handleModal}
                    cropAsp={cropAsp}
                  />
                </div>
              </div>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          <div
            className="upload-btn-wrapper-show d-flex flex-row align-items-center justify-content-center gap-1"
            onClick={() => {
              fileRef?.current?.click();
            }}
          >
            <DownloadSimple size={20} />
            <p className="">Upload</p>
          </div>
          {uploadError && <p className="text-warning text-sm">{uploadError}</p>}
        </>
      )}
      <input
        type="file"
        name={name}
        id=""
        hidden
        required={required}
        ref={fileRef}
        onChange={handleFileChange}
        accept="image/*"
        multiple
      />
    </div>
  );
};

export default React.memo(UploadImage);
