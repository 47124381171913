export default function isTimeInRange(currentTime) {
   // Convert current time to UTC
   let utcTime = new Date(currentTime.getTime() + currentTime.getTimezoneOffset() * 60000);

   // Convert UTC time to Nepali time (UTC+5:45)
   let nepaliTimeOffset = 5.75 * 60 * 60000; // 5 hours 45 minutes in milliseconds
   let nepaliTime = new Date(utcTime.getTime() + nepaliTimeOffset);
   console.log(nepaliTime);

   // Extract hours and minutes from Nepali time
   let hours = nepaliTime.getHours();
   let minutes = nepaliTime.getMinutes();

    // Define the time ranges
    // Range 1: 12 PM (12:00) to 11:59 PM (23:59)
    let range1StartHours = 12, range1StartMinutes = 0;
    let range1EndHours = 23, range1EndMinutes = 59;

    // Range 2: 12 AM (0:00) to 4 AM (4:00)
    let range2StartHours = 0, range2StartMinutes = 0;
    let range2EndHours = 4, range2EndMinutes = 0;

    // Check if current time is within range 1
    let inRange1 = (hours > range1StartHours || (hours === range1StartHours && minutes >= range1StartMinutes)) &&
                   (hours < range1EndHours || (hours === range1EndHours && minutes <= range1EndMinutes));

    // Check if current time is within range 2
    let inRange2 = (hours > range2StartHours || (hours === range2StartHours && minutes >= range2StartMinutes)) &&
                   (hours < range2EndHours || (hours === range2EndHours && minutes <= range2EndMinutes));

    return inRange1 || inRange2;
}