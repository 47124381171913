import { Avatar, IconButton, ThemeProvider } from "@mui/material";
// import MUIDataTable from 'mui-datatables'
import { PlusCircle, TrashSimple } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DELETEAPIAdmin,
  GetAPIAdmin,
  POSTAPI_CreateInfoAdmin,
  POSTAPI_formAdmin,
  PUT_API_formAdmin,
} from "../../Auth/Index";
import {
  AlertError,
  DataGridNova,
  getMuiTheme,
  HalfRightBtnComp,
  InputComp,
  InputCompTextarea,
  LabelComp,
  LabelComp_Index,
  SelectImage,
  SelectImage_Small,
  SelectImage_SmallIndex,
  TableEditDelete,
  TopAddComp,
  TopBackComp,
} from "../../Components/Common";
import { ValidateBrands } from "../../Components/Validate_Admin";
import "../../css/Admin/brand.css";

export const Brand = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    brands: [],
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    edAnchor: null,
    edClicked: null,
    pageSize: 10,
  });
  const {
    brands,
    first,
    loading,
    errorPosition,
    errorText,
    edAnchor,
    edClicked,
    pageSize,
  } = values;

  useEffect(() => {
    if (first) {
      POSTAPI_CreateInfoAdmin().then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            brands: res.brands,
            first: false,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first]);

  // const columns = [
  //     {
  //         name: '_id',
  //         label: '',
  //         options: {
  //             display: false
  //         }
  //     },
  //     {
  //         name: 'image',
  //         label: 'Image',
  //         options: {
  //             display: false
  //         }
  //     },
  //     {
  //         name: "name",
  //         label: "Brand Name",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 var index = tableMeta.rowIndex
  //                 return (
  //                     <div className="d-flex align-items-center">
  //                         <Avatar style={{ borderRadius: '12px' }}
  //                             src={tableMeta.currentTableData[index].data[1]} />
  //                         <span className="ml-3">{value}</span>
  //                     </div>
  //                 )
  //             }
  //         }
  //     },
  //     {
  //         name: "divisions",
  //         label: "No. of divisions",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <span className="d-flex justify-content-center">{value ? value.length : ''}</span>
  //                 )
  //             }
  //         }
  //     },
  //     {
  //         name: "",
  //         label: "",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <TableEditDelete onClick={(e) => e.stopPropagation()} id={tableMeta.rowData[0]}
  //                         EditFn={EditFn} DeleteFn={DeleteFn}
  //                         edAnchor={edAnchor} setValues={setValues} edClicked={edClicked} />
  //                 )
  //             }
  //         }
  //     }
  // ];

  const columnsData = [
    {
      field: "name",
      headerName: "Brand Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <Avatar style={{ borderRadius: "12px" }} src={params.row.image} />
            <span className="ml-3">{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "divisions",
      headerName: "No. of divisions",
      width: 150,
      renderCell: (params) => {
        return (
          <span className="d-flex justify-content-center">
            {params.value ? params.value.length : ""}
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: "",
      description: "This column is sortable",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <TableEditDelete
            onClick={(e) => e.stopPropagation()}
            id={params.row._id}
            EditFn={EditFn}
            DeleteFn={DeleteFn}
            edAnchor={edAnchor}
            setValues={setValues}
            edClicked={edClicked}
          />
        );
      },
    },
  ];

  const EditFn = () => {
    var item = brands.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      navigate("/brands/edit", { state: item });
    }
  };

  const DeleteFn = () => {
    var item = brands.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      DELETEAPIAdmin("/brand/remove", { brandId: edClicked }).then((res) => {
        if (res.success) {
          setValues((x) => ({
            ...x,
            errorPosition: "success",
            errorText: `Brand ${item.name} deleted`,
            brands: res.brands,
          }));
        } else if (res.error)
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    }
  };

  const handleRowClick = (item) => {
    navigate("/brands/edit", { state: item });
  };

  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No brand yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  // };

  return (
    <div className="category_body">
      {TopAddComp("Brand", "/brands/add", "Add Brand")}

      <ThemeProvider theme={getMuiTheme()}>
        {/* <MUIDataTable
                    data={brands}
                    columns={columns}
                    options={options}
                /> */}
      </ThemeProvider>

      <div style={{ height: "65vh", width: "600px" }}>
        <DataGridNova
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={brands}
          columns={columnsData}
          loading={loading}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const AddBrand = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    description: "",
    image: null,
    coverImage: null,
    divisions: [""],
    divisionImages: [null],
    first: true,
    loading: false,
    errorPosition: "",
    errorText: "",
    error_optionsIndex: "",
  });
  const {
    name,
    description,
    image,
    coverImage,
    divisions,
    divisionImages,
    loading,
    first,
    errorPosition,
    errorText,
    error_optionsIndex,
  } = values;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/item/").then((res) => {
        if (res.success) {
          setValues((prev) => ({ ...prev, allItems: res.items, first: false }));
        }
      });
    }
  }, [first]);

  const handleCreate = async () => {
    if (loading) return;
    var validated = await ValidateBrands(
      name,
      description,
      image,
      coverImage,
      divisions,
      divisionImages,
      setValues,
    );
    if (validated) {
      var formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("logo", image);
      formData.append("coverImage", image);
      formData.append("divisions", JSON.stringify(divisions));
      divisionImages.forEach((element) => {
        formData.append("divisionImages", element);
      });
      setValues((prev) => ({ ...prev, loading: true }));
      POSTAPI_formAdmin("/brand/create", formData).then((res) => {
        if (res.success) navigate("/brands");
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  };

  const handleDeleteOption = (ind) => {
    var nowDivisions = [...divisions];
    var nowImages = [...divisionImages];
    if (divisions.length === 1) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Atleast one division needed",
      }));
      return;
    }
    nowDivisions.splice(ind, 1);
    nowImages.splice(ind, 1);
    setValues((val) => ({
      ...val,
      divisions: nowDivisions,
      divisionImages: nowImages,
    }));
  };

  const handleChangeOption = (e, ind) => {
    var nowDivisions = [...divisions];
    nowDivisions[ind] = e.target.value;
    setValues((val) => ({ ...val, divisions: nowDivisions }));
  };

  const addDivision = (index) => {
    var nowDivisions = [...divisions];
    nowDivisions[index] = "";
    setValues((val) => ({ ...val, divisions: nowDivisions }));
  };

  return (
    <>
      {TopBackComp("Add Brand", "/brands")}

      <div className="add_brand_body">
        <div className="add_brand_body_top">
          <div className="brand_left">
            {LabelComp("Brand name", "name", errorPosition, errorText)}
            {InputComp(name, "name", setValues, "mod_input")}

            {LabelComp("Description", "description", errorPosition, errorText)}
            {InputCompTextarea(
              description,
              "description",
              setValues,
              "novaTextarea",
            )}

            {LabelComp("Logo", "image", errorPosition, errorText)}
            {SelectImage_Small(image, "image", setValues)}

            {LabelComp("Cover Image", "coverImage", errorPosition, errorText)}
            {SelectImage(coverImage, "coverImage", setValues)}
          </div>

          <div className="brand_left">
            {divisions.map((eachDivision, index) => (
              <div key={index}>
                <div className="each_option">
                  <div className="d-flex">
                    {LabelComp_Index(
                      "Division",
                      "division",
                      index,
                      errorPosition,
                      errorText,
                      error_optionsIndex,
                    )}
                    <IconButton
                      className="d-flex ml-auto"
                      onClick={() => handleDeleteOption(index)}
                    >
                      <TrashSimple />
                    </IconButton>
                  </div>
                  <input
                    type="text"
                    name="name"
                    value={eachDivision}
                    className="mod_input"
                    onChange={(e) => handleChangeOption(e, index)}
                  />

                  {LabelComp_Index(
                    "Image",
                    "divisionImages",
                    index,
                    errorPosition,
                    errorText,
                    error_optionsIndex,
                  )}
                  {SelectImage_SmallIndex(
                    divisionImages,
                    divisionImages[index],
                    "divisionImages",
                    index,
                    setValues,
                    [],
                  )}
                </div>

                {index === divisions.length - 1 && (
                  <PlusCircle
                    className="plusCircle_option"
                    onClick={() => addDivision(index + 1)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>

        {HalfRightBtnComp("Add Brand", handleCreate, loading)}
        {AlertError(errorPosition, errorText, setValues)}
      </div>
    </>
  );
};

export const EditBrand = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (!location.state) navigate("/brands");

  const [values, setValues] = useState({
    _id: location.state ? location.state._id : "",
    name: location.state ? location.state.name : "",
    description: location.state ? location.state.description : "",
    image: location.state ? location.state.image : "",
    coverImage: location.state ? location.state.coverImage : "",
    divisionIds: location.state
      ? location.state.divisions.map((a) => a.divisionId)
      : "",
    divisions: location.state
      ? location.state.divisions.map((a) => a.name)
      : "",
    divisionImages: location.state
      ? location.state.divisions.map((a) => a.image)
      : "",
    removeIds: location.state
      ? Array(location.state.divisions.length).fill(false)
      : [],
    first: true,
    loading: false,
    errorPosition: "",
    errorText: "",
  });
  const {
    _id,
    name,
    description,
    image,
    coverImage,
    divisionIds,
    divisions,
    divisionImages,
    removeIds,
    loading,
    first,
    errorPosition,
    errorText,
    error_optionsIndex,
  } = values;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/item/").then((res) => {
        if (res.success) {
          setValues((prev) => ({ ...prev, allItems: res.items, first: false }));
        }
      });
    }
  }, [first]);

  const handleUpdate = async () => {
    if (loading) return;
    var validated = await ValidateBrands(
      name,
      description,
      image,
      coverImage,
      divisions,
      divisionImages,
      setValues,
    );
    if (validated) {
      var formData = new FormData();
      formData.append("brandId", _id);
      formData.append("name", name);
      formData.append("description", description);
      formData.append("logo", image);
      formData.append("coverImage", coverImage);
      var divArr = [];
      divisions.forEach((element, index) => {
        divArr.push({
          divisionId: divisionIds[index],
          name: element,
        });
      });
      formData.append("divisions", JSON.stringify(divArr));
      divisionImages.forEach((element, imgIndex) => {
        if (typeof element === "object")
          formData.append(`divisionImages[${imgIndex}]`, element);
      });
      setValues((prev) => ({ ...prev, loading: true }));
      PUT_API_formAdmin("/brand/edit", formData).then((res) => {
        if (res.success) navigate("/brands");
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  };

  const handleDeleteOption = (ind) => {
    var nowDivisions = [...divisions];
    var nowDivisionIds = [...divisionIds];
    var nowImages = [...divisionImages];
    if (divisions.length === 1) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Atleast one division needed",
      }));
      return;
    }
    nowDivisions.splice(ind, 1);
    nowDivisionIds.splice(ind, 1);
    nowImages.splice(ind, 1);
    setValues((val) => ({
      ...val,
      divisions: nowDivisions,
      divisionImages: nowImages,
      divisionIds: nowDivisionIds,
    }));
  };

  const handleChangeOption = (e, ind) => {
    var nowDivisions = [...divisions];
    nowDivisions[ind] = e.target.value;
    setValues((val) => ({ ...val, divisions: nowDivisions }));
  };

  const addDivision = (index) => {
    var nowDivisions = [...divisions];
    var nowDivisionIds = [...divisionIds];
    nowDivisions[index] = "";
    nowDivisionIds[index] = null;
    setValues((val) => ({
      ...val,
      divisions: nowDivisions,
      divisionIds: nowDivisionIds,
    }));
  };

  return (
    <>
      {TopBackComp("Edit Brand", "/brands")}

      <div className="add_brand_body">
        <div className="add_brand_body_top">
          <div className="brand_left">
            {LabelComp("Brand name", "name", errorPosition, errorText)}
            {InputComp(name, "name", setValues, "mod_input")}

            {LabelComp("Description", "description", errorPosition, errorText)}
            {InputCompTextarea(
              description,
              "description",
              setValues,
              "novaTextarea",
            )}

            {LabelComp("Logo", "image", errorPosition, errorText)}
            {SelectImage_Small(image, "image", setValues)}

            {LabelComp("Cover Image", "coverImage", errorPosition, errorText)}
            {SelectImage(coverImage, "coverImage", setValues)}
          </div>

          <div className="brand_left">
            {divisions.map((eachDivision, index) => (
              <div key={index}>
                <div className="each_option">
                  <div className="d-flex">
                    {LabelComp_Index(
                      "Division",
                      "division",
                      index,
                      errorPosition,
                      errorText,
                      error_optionsIndex,
                    )}
                    <IconButton
                      className="d-flex ml-auto"
                      onClick={() => handleDeleteOption(index)}
                    >
                      <TrashSimple />
                    </IconButton>
                  </div>
                  <input
                    type="text"
                    name="name"
                    value={eachDivision}
                    className="mod_input"
                    onChange={(e) => handleChangeOption(e, index)}
                  />

                  {LabelComp_Index(
                    "Image",
                    "divisionImages",
                    index,
                    errorPosition,
                    errorText,
                    error_optionsIndex,
                  )}
                  {SelectImage_SmallIndex(
                    divisionImages,
                    divisionImages[index],
                    "divisionImages",
                    index,
                    setValues,
                    removeIds,
                  )}
                </div>

                {index === divisions.length - 1 && (
                  <PlusCircle
                    className="plusCircle_option"
                    onClick={() => addDivision(index + 1)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>

        {HalfRightBtnComp("Update Brand", handleUpdate, loading)}
        {AlertError(errorPosition, errorText, setValues)}
      </div>
    </>
  );
};
