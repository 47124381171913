import { Avatar } from "@mui/material";
import { Input, Spin } from "antd";
// import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from "react";
import { GetAPIAdmin, POSTAPI_ADMIN } from "../../Auth/Index";
import { AlertError, DataGridNova } from "../../Components/Common";
import "../../Components/common.css";
import { isTextEmpty } from "../../Components/Validate_Admin";

export const Inventory = () => {
  const [values, setValues] = useState({
    _id: "",
    cats: [],
    changed: false,
    first: true,
    loading: true,
    loadingInd: "",
    loadingColumn: "",
    errorPosition: "",
    errorText: "",
    pageSize: 10,
  });
  const {
    _id,
    cats,
    changed,
    first,
    loading,
    loadingInd,
    loadingColumn,
    errorPosition,
    errorText,
    pageSize,
  } = values;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/inventory/").then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            _id: res.inventoryId,
            cats: res.inventoryItems,
            first: false,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first]);

  const valueChanged = (name, value, params) => {
    if (isTextEmpty(value) || isNaN(value)) {
      setValues((prev) => ({
        ...prev,
        errorPosition: "apiErr",
        errorText: "Invalid value",
        first: true,
      }));
      return;
    }
    if (!changed) return;
    setValues((prev) => ({
      ...prev,
      loadingInd: params.id,
      loadingColumn: name,
    }));
    var sendData = {
      inventoryId: _id,
      itemId: params.row.itemId,
      estimatedStock: name === "estimatedStock" ? value : null,
      lowStock: name === "lowStock" ? value : null,
    };
    POSTAPI_ADMIN("/inventory/edit", sendData).then((res) => {
      if (res.success)
        setValues((prev) => ({
          ...prev,
          loadingInd: "",
          loadingColumn: "",
          changed: false,
        }));
      else if (res.error)
        setValues((prev) => ({
          ...prev,
          first: false,
          loading: false,
          errorPosition: "apiErr",
          errorText: res.error,
          loadingInd: "",
          loadingColumn: "",
          changed: false,
        }));
    });
  };

  // const handleChange = (name, e, tableMeta) => {
  //     var nowIndex = []
  //     tableMeta.currentTableData.map(x => nowIndex.push(x.index))
  //     var newCats = []
  //     nowIndex.forEach((element, index) => {
  //         newCats[index] = cats[element]
  //     });
  //     newCats[tableMeta.rowIndex][name] = e.target.value
  //     setValues(prev => ({ ...prev, cats: newCats, changed: true }))
  // }

  const handleChange = (name, e, params) => {
    var index = cats.findIndex((obj) => obj.itemId === params.row.itemId);
    var newCats = [...cats];
    newCats[index][name] = e.target.value;
    setValues((prev) => ({ ...prev, cats: newCats, changed: true }));
  };

  const columns = [
    {
      field: "name",
      headerName: "Item Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <Avatar style={{ borderRadius: "12px" }} src={params.row.image} />
            <span className="ml-3">{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "estimatedStock",
      headerName: "Quantity",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <Input
              onBlur={() =>
                valueChanged("estimatedStock", params.value, params)
              }
              value={params.value}
              disabled={
                loadingInd === params.id && loadingColumn === "estimatedStock"
              }
              onChange={(e) => handleChange("estimatedStock", e, params)}
              className="inventory_input"
              bordered={false}
            />
            {loadingInd === params.id && loadingColumn === "estimatedStock" && (
              <Spin />
            )}
          </div>
        );
      },
    },
    {
      field: "lowStock",
      headerName: "Low Stock",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <Input
              onBlur={() => valueChanged("lowStock", params.value, params)}
              value={params.value}
              disabled={
                loadingInd === params.id && loadingColumn === "lowStock"
              }
              onChange={(e) => handleChange("lowStock", e, params)}
              className="inventory_input"
              bordered={false}
            />
            {loadingInd === params.id && loadingColumn === "lowStock" && (
              <Spin />
            )}
          </div>
        );
      },
    },
  ];

  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No items yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  //     search: true,
  //     tableBodyMaxHeight: 'calc(100vh - 305px)',
  // };

  return (
    <div className="category_body">
      <div className="category_top_inventory">
        <span className="category_top_title">Inventory</span>
      </div>

      {/* <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    data={cats}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider> */}

      <div style={{ height: "65vh", width: "500px" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={cats}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => (row.itemId ? row.itemId : Math.random())}
          disableSelectionOnClick
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};
