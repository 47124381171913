import { useEffect } from "react";
import { useState } from "react";
import { GetAPIAdmin } from "../../Auth/Index";

import { ReviewsViewTable } from "../../Components/MuiTable";

export const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    GetAPIAdmin("/order/orderRatings").then((res) => {
      if (res.success) {
        setReviews(res.orders);
      } else if (res.error) {
      }
    });
  }, []);

  return (
    <>
      <p className="w3 s3">Reviews</p>

      <div style={{ height: "75vh", width: "645px" }}>
        <ReviewsViewTable reviews={reviews} />
      </div>
    </>
  );
};
