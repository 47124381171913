import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  createTheme,
  IconButton,
  Slider as SliderMUI,
  ThemeProvider,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import { CaretDown, MinusCircle, TrashSimple, X } from "@phosphor-icons/react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, POSTAPI_USER } from "../../Auth/Index";
import {
  CartQuantitySide,
  getSpiceText,
  getSpiceValue,
} from "../../Components/Common";
import { getS3Image } from "../../Components/s3";
import "../../css/Market/cart.css";
import isTimeInRange from "../../Components/check-time-range";

const theme = createTheme({
  components: {
    // Name of the component
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid #E9ECFF",
          borderRadius: "5px",
          backgroundColor: "#FFF",
          padding: "12px",
          "&.Mui-expanded": {
            margin: "0px",
          },
          "&::before": {
            opactiy: "0",
            height: "0px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0",
          minHeight: "0px",
          "&.Mui-expanded": {
            minHeight: "0px",
          },
        },
        content: {
          margin: "0px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
        expandIconWrapper: {
          color: "#2E2E2E",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
          color: "#818091",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          width: "50px",
          height: "50px",
          backgroundColor: "transparent",
          backgroundImage: `url("https://nova-foods.s3.amazonaws.com/miscellaneous/emojione-fire.png")`,
          boxShadow: "none",
          transition: "none",
          border: "none",
          "::before": {
            boxShadow: "none",
            transition: "none",
          },
          ":hover": {
            boxShadow: "none",
            transition: "none",
          },
          "&.Mui-active": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

export const SidenavCart = ({
  open,
  close,
  cart,
  setCart,
  cartLoading,
  setValues,
  setNumberOfItemsFn,
  setCartUpdatedForMycart,
  businessHours,
}) => {
  const anchor = "right";
  const navigate = useNavigate();
  const userId = isAuthenticated()?.userId;
  // const { userId } = isAuthenticated()
  // const [first, setFirst] = useState(true)
  const [spiceLevels, setSpiceLevels] = useState([]);
  const [loadingItem, setLoadingItem] = useState(null);
  useEffect(() => {
    var spice = [];
    cart &&
      cart.foods &&
      cart.foods.forEach((element) => {
        spice.push(getSpiceValue(element.spiceLevel));
      });
    setSpiceLevels(spice);
    // setFirst(false)
  }, [cart]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    close(open);
    setValues((x) => ({ ...x, cartLoading: true }));
  };

  // called when quantity changed or deleted
  const edit_one = (val, quantity, loadingInd) => {
    if (loadingItem) return;
    var send_data = {
      userId,
      itemId: val.itemId,
      identifierId: val.identifierId ?? null,
      comboId: val.comboId,
      quantity: quantity,
      modifiers: val.modifiers ? val.modifiers : [],
    };
    // setLoadingItem(val.itemId)
    setLoadingItem(loadingInd);
    POSTAPI_USER("/cart/edit", send_data).then((res) => {
      setLoadingItem(null);
      if (res.success) {
        setCart(res.cart);
        setNumberOfItemsFn && setNumberOfItemsFn(res.cart.numberOfItems);
        setCartUpdatedForMycart(true);
      } else if (res.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
    });
  };

  const top = () => (
    <div className="topDiv">
      <span className="c3 w3 s3">My Cart</span>
      <IconButton className="topDivClear" onClick={toggleDrawer(false)}>
        <X weight="bold" size={20} color="#2E2E2E" />
      </IconButton>
    </div>
  );

  const removeModifier = (prod, i) => {
    var nowMods = prod.modifiers;
    nowMods.splice(i, 1);
    var send_data = {
      userId,
      itemId: prod.itemId,
      identifierId: prod.identifierId ?? null,
      comboId: prod.comboId,
      quantity: 0,
      modifiers: nowMods ? nowMods : [],
    };
    POSTAPI_USER("/cart/edit", send_data).then((res) => {
      if (res.success) {
        setCart(res.cart);
      }
    });
  };

  const removeOption = (prod, i, ind) => {
    var nowMods = prod.modifiers;
    var nowOp = nowMods[i].options;
    nowOp.splice(ind, 1);
    if (nowMods[i].options.length === 0) nowMods.splice(i, 1);
    var send_data = {
      userId,
      itemId: prod.itemId,
      identifierId: prod.identifierId ?? null,
      comboId: prod.comboId,
      quantity: 0,
      modifiers: nowMods ? nowMods : [],
    };
    POSTAPI_USER("/cart/edit", send_data).then((res) => {
      if (res.success) {
        setCart(res.cart);
      }
    });
  };

  // for spicy level customize
  const handleChangeSlide = (e, newVal, prod, ind) => {
    var nowSpiceLevels = [...spiceLevels];
    nowSpiceLevels[ind] = newVal;
    setSpiceLevels(nowSpiceLevels);
    if (newVal - spiceLevels[ind] !== 0) {
      var send_data = {
        userId,
        itemId: prod.itemId,
        identifierId: prod.identifierId ?? null,
        comboId: prod.comboId,
        modifiers: prod.modifiers,
        quantity: 0,
        spiceLevel: getSpiceText(newVal),
      };
      POSTAPI_USER("/cart/edit", send_data).then((res) => {
        if (res.success) {
          setCart(res.cart);
        }
      });
    }
  };

  const list = () => (
    <ThemeProvider theme={theme}>
      <Box role="presentation">
        {cart &&
          cart.foods &&
          cart.foods.length > 0 &&
          cart.foods.map((prod, ind) => {
            return (
              <div className="eachCart_div" key={ind}>
                <div className="mb-2 d-flex" key={ind}>
                  <Avatar
                    src={prod.image}
                    className="eachCart_image"
                    variant="rounded"
                  />
                  <div className="d-flex flex-column w-100 justify-content-between">
                    <div className="d-flex justify-content-between">
                      <span
                        className="eachCart_name"
                        style={{ textAlign: "left" }}
                      >
                        {prod.name}
                      </span>
                      <IconButton
                        // disabled={prod.itemId === loadingItem}
                        disabled={ind === loadingItem}
                        onClick={() => edit_one(prod, -prod.quantity, ind)}
                      >
                        <TrashSimple size={25} />
                      </IconButton>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                      {/* {prod.itemId === loadingItem ? */}
                      {ind === loadingItem ? (
                        <div className="d-flex align-items-center">
                          <div className="div-disabled mr-2">
                            {CartQuantitySide(prod, edit_one, ind)}
                          </div>
                          <CircularProgress
                            style={{
                              color: "#8943EF",
                              width: "25px",
                              height: "25px",
                            }}
                          />
                        </div>
                      ) : (
                        CartQuantitySide(prod, edit_one, ind)
                      )}
                      <span className="eachCart_price">
                        Rs. {parseFloat(prod.price).toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>

                {/* customizations */}
                {((prod.modifiers && prod.modifiers.length > 0) ||
                  prod.spiceLevel !== null) && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<CaretDown weight="bold" className="ml-2" />}
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      Customizations
                    </AccordionSummary>
                    <AccordionDetails>
                      {prod.modifiers && prod.modifiers.length > 0 && (
                        <>
                          {prod.modifiers.map((each, i) => (
                            <div key={i}>
                              <span className="c3 w3 s2">
                                <IconButton
                                  className="ml-0"
                                  onClick={() => removeModifier(prod, i)}
                                >
                                  <MinusCircle
                                    color="#818090"
                                    weight="fill"
                                    className="cursor_pointer mod_icon"
                                  />
                                </IconButton>
                                {each.name}
                              </span>
                              <div className="d-flex flex-column ">
                                {each.options &&
                                  each.options.map((eachOP, ind) => {
                                    return (
                                      <div
                                        key={ind}
                                        className="d-flex align-items-center"
                                      >
                                        <IconButton
                                          className="ml-0"
                                          onClick={() =>
                                            removeOption(prod, i, ind)
                                          }
                                        >
                                          <MinusCircle className="cursor_pointer mod_icon" />
                                        </IconButton>
                                        <span>{eachOP.name}</span>
                                        <span className="ml-auto">
                                          Rs. {eachOP.price}
                                        </span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      {prod.spiceLevel !== null && (
                        <>
                          <p className="c3 w3 s2 ml-0 mt-2">Spicy Level</p>
                          <div className="spicy_slider_div mb-3">
                            <p className="spiceLevel_text">
                              {getSpiceText(spiceLevels[ind])}
                            </p>
                            {spiceLevels.length > 0 && (
                              <SliderMUI
                                defaultValue={28}
                                value={spiceLevels[ind]}
                                color="error"
                                step={null}
                                onChange={(e, newVal) =>
                                  handleChangeSlide(e, newVal, prod, ind)
                                }
                                valueLabelDisplay="off"
                                marks={[0, 28, 66, 100].map((each) => ({
                                  value: each,
                                  label: "",
                                }))}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>
            );
          })}
      </Box>
    </ThemeProvider>
  );

  const bottom = () => (
    <div id="bottomDiv" className="bottomDiv">
      {/* sub total */}
      <div className="subTotalDiv">
        <span>Subtotal</span>
        <span className="subTotalAmt">
          Rs. {parseFloat(cart.subTotal).toFixed(2)}
        </span>
      </div>
      <Button
        className="proceedBtn"
        onClick={() => {
          if(isTimeInRange(new Date())){
            close(false);
            setValues((x) => ({ ...x, cartLoading: true }));
            cart.spiceLevels = spiceLevels;
            navigate("/myCart", { state: cart });
            return
          }
          if (!businessHours?.isOpen) {
            setValues((x) => ({
              ...x,
              errorPosition: "apiErr",
              errorText: `We are currently closed but we encourage you to come back and visit 
        us from ${businessHours?.openingTime} to ${businessHours?.closingTime}. Thank you for understanding.`,
            }));
            return;
          }
          close(false);
          setValues((x) => ({ ...x, cartLoading: true }));
          cart.spiceLevels = spiceLevels;
          navigate("/myCart", { state: cart });
        }}
      >
        Proceed to Checkout
      </Button>
    </div>
  );

  const EmptyView = () => (
    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
      <img
        src={getS3Image("empty_cart.png")}
        className="empty_cart_img"
        alt=""
      />
      <p className="empty_cart_title">Cart is empty</p>
      <p className="empty_cart_text">Add items to get started</p>
    </div>
  );

  return (
    <div>
      <Fragment key={anchor}>
        <Drawer
          style={{ overflow: "hidden" }}
          anchor={anchor}
          open={open}
          onClose={toggleDrawer(false)}
        >
          <>
            {cartLoading ? (
              <Box
                style={{ height: "100%" }}
                className="sidenavCartMain d-flex 
                                    justify-content-center align-items-center"
                onKeyDown={toggleDrawer(false)}
              >
                <CircularProgress sx={{ color: "#8943EF" }} />
              </Box>
            ) : (
              <>
                {(cart && cart.foods) || !userId ? (
                  <>
                    {!cart.foods || cart.foods.length === 0 ? (
                      <Box
                        style={{ height: "100%" }}
                        className="sidenavCartMain"
                        onKeyDown={toggleDrawer(false)}
                      >
                        {top()}
                        {EmptyView()}
                      </Box>
                    ) : (
                      <Box
                        style={{ height: "100%", marginBottom: "120px" }}
                        className="sidenavCartMain"
                        onKeyDown={toggleDrawer(false)}
                      >
                        {top()}
                        {list()}
                      </Box>
                    )}
                  </>
                ) : (
                  <Box
                    style={{ height: "calc(100% - 110px)" }}
                    className="sidenavCartMain"
                    onKeyDown={toggleDrawer(false)}
                  >
                    {top()}
                    {EmptyView()}
                  </Box>
                )}
              </>
            )}
          </>
          {cart &&
            cart.foods &&
            cart.foods.length > 0 &&
            !cartLoading &&
            bottom()}
        </Drawer>
      </Fragment>
    </div>
  );
};
