import { Avatar, IconButton, InputBase, Paper } from "@mui/material";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGridNova } from "../../../Components/Common";
import { useGetDashboardItemsQuery } from "../../../slices/apiSlice";
import { ad_dashFrom, ad_dashOrigin, ad_dashTo, setDashDate } from "../../../slices/novaSlice";
import { rangePresets, rangePresetsNew } from "./AdminDashboard";
const { RangePicker } = DatePicker;

export const AdminDashboardItems = () => {
  const dispatch = useDispatch();
  const origin = useSelector(ad_dashOrigin)
  const [values, setValues] = useState({
    from: useSelector(ad_dashFrom),
    to: useSelector(ad_dashTo),
    searchedData: [],
    pageSize: 10,
    searchText: "",
    // filterDate : new Date(),
  });
  const {
    from,
    to,
    searchedData,
    pageSize,
    searchText,
    // filterDate
  } = values;

  const dateFormat = "MMMM D, YYYY h:mm A";
  const query = `from=${new Date(from).toISOString()}&to=${new Date(to).toISOString()}&origin=${origin}`;
  const { data, isLoading } = useGetDashboardItemsQuery(query);

  useEffect(() => {
    setValues((x) => ({ ...x, searchedData: data ?? [] }));
  }, [data]);
  const columnsData = [
    {
      field: "name",
      headerName: "Item",
      description: "This column is sortable",
      sortable: false,
      width: 340,
      renderCell: (params) => {
        return (
          <div className="d-flex gap-2 align-items-center">
            <Avatar src={params.row.image?.url} />
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "categoryName",
      headerName: "Category",
      description: "This column is sortable",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return <span className="c9">{params.value}</span>;
      },
    },
    {
      field: "soldUnits",
      headerName: "Qty Sold",
      description: "This column is sortable",
      sortable: false,
      width: 110,
    },
  ];
  const handleRowClick = () => {};
  const onChange = (date) => {
    setValues((x) => ({
      ...x,
      from: date[0],
      to: date[1],
      first: true,
      loading: true,
    }));
    dispatch(
      setDashDate({
        dashFrom: date[0],
        dashTo: date[1],
      }),
    );
  };

  const dateNow = new Date().getHours();

  return (
    <div>
      <div
        className="d-flex justify-content-between gap-2 "
        style={{ width: "657px" }}
      >
        {SearchDiv(searchText, data, setValues)}
        <Space direction="vertical" size={12} className="ml-auto">
          <RangePicker
            presets={dateNow >= 12 ? rangePresetsNew : rangePresets}
            showTime
            format={"YYYY-MM-DD HH:mm"}
            style={{ width: "auto" }}
            className="ml-auto s1"
            size="large"
            value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
            onChange={onChange}
          />
        </Space>
      </div>
      <div style={{ height: "570px", width: "657px" }}>
        <DataGridNova
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={searchedData}
          columns={columnsData}
          loading={isLoading}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>
    </div>
  );
};

const SearchDiv = (searchText, data, setValues) => {
  const handleChangeSearch = (e) => {
    const text = e?.target?.value;
    const filteredData = data?.filter((x) =>
      x.name?.toLowerCase()?.includes(text),
    );
    setValues((x) => ({ ...x, searchText: text, searchedData: filteredData }));
  };
  return (
    <Paper
      component="form"
      sx={{
        p: "0px 16px",
        display: "flex",
        border: "1px solid #E9ECFF",
        borderRadius: "25px",
        width: "400px",
        boxShadow: "none",
        mb: "16px",
        padding: "0 16px",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        onChange={handleChangeSearch}
        value={searchText}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <MagnifyingGlass color="#8943EF" />
      </IconButton>
    </Paper>
  );
};
