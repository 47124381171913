import { Box, Button, Container } from "@mui/material";
import { Avatar } from "antd";
import novaverse from "./Novaverse.png";
import glare from "./glare.png";
import loyBg from "./loy-bg.png";
import mini from "./mini.png";
import wide from "./wide.png";
import cheeseSand from "./cheese_sand.webp";
import { useState } from "react";

const howItWorks = [
  {
    title: "Earn points on every order",
    image: null,
    url: null,
  },
  {
    title: "Use earned points",
    image: null,
    url: null,
  },
  {
    title: "Claim FREE benefits",
    image: null,
    url: null,
  },
];

const info = [
  {
    name: "10 points",
    image: cheeseSand,
    title: "Cottage cheese in a creamy tomato",
    subtitle:
      "Authentic Dilli style Rajma x2 + Dal makhni/Chole/Rajma x1 + Paratha/Rice x3 + Salad + Chutney + Dessert.",
    title2: "Cheese pizza",
    subtitle2:
      "This is a pizza reimagining of the classic Roman pasta dish cacio e pepe, pasta served with only cheese and black pepper It came to The Times from the ...",
  },
  {
    name: "20 points",
    image: cheeseSand,
    title: "Cottage cheese in a creamy tomato",
    subtitle:
      "Authentic Dilli style Rajma x2 + Dal makhni/Chole/Rajma x1 + Paratha/Rice x3 + Salad + Chutney + Dessert.",
    title2: "Cheese pizza",
    subtitle2:
      "This is a pizza reimagining of the classic Roman pasta dish cacio e pepe, pasta served with only cheese and black pepper It came to The Times from the ...",
  },
  {
    name: "40 points",
    image: cheeseSand,
    title: "Cottage cheese in a creamy tomato",
    subtitle:
      "Authentic Dilli style Rajma x2 + Dal makhni/Chole/Rajma x1 + Paratha/Rice x3 + Salad + Chutney + Dessert.",
    title2: "Cheese pizza",
    subtitle2:
      "This is a pizza reimagining of the classic Roman pasta dish cacio e pepe, pasta served with only cheese and black pepper It came to The Times from the ...",
  },
];

export const Loyalty = () => {
  const [tab, setTab] = useState(0);

  return (
    <Container
      className="d-flex justify-content-center"
      style={{
        marginTop: "32px",
        marginBottom: "32px",
      }}
    >
      <Box
        width={{
          xs: "100%",
          md: "900px",
        }}
        sx={{
          bgcolor: "#111111",
          borderRadius: "5px",
          color: "#fff",
          overflow: "hidden",
        }}
      >
        <Box
          width={{
            xs: "100%",
            md: "900px",
          }}
          minHeight={{
            md: "1172px",
          }}
          sx={{
            backgroundImage: `url(${loyBg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* heading */}
          <Box
            width={"100%"}
            sx={{
              position: "absolute",
              width: "900px",
              borderRadius: "5px",
              backgroundImage: `url(${mini})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "306px",
            }}
          >
            <img
              src={glare}
              alt=""
              style={{
                position: "absolute",
                marginTop: "120px",
                marginLeft: "50px",
                width: "40px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                pt: "32px",
                alignItems: "center",
              }}
            >
              <img src={wide} alt="" width={"60px"} />
              <img src={novaverse} alt="" />
              <span className="s1 mt-2">Nova Deliver’s Loyalty Program</span>
            </Box>
          </Box>
          {/* how it works */}
          <Box
            width={{
              xs: "100%",
              md: "703px",
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
              marginInline: "auto",
              marginTop: "250px",
              zIndex: 9999,
            }}
          >
            <span className="c4 s-28 w3 mx-auto">How it works?</span>
            <div class="row justify-content-between">
              {howItWorks.map((each, i) => (
                <div
                  class="col-3"
                  key={"how_" + i}
                  style={{
                    width: "200px",
                    background: "#fff",
                    padding: "50px 32px",
                    borderRadius: "32px",
                    color: "#000",
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Avatar style={{ width: 100, height: 100 }} />
                  <span className="s-20 w3">{each.title}</span>
                </div>
              ))}
            </div>
            <span className="w3 s2 mx-auto">1 order = 10 Points</span>
          </Box>
          {/* tabs */}
          <Box
            className="d-flex"
            sx={{
              m: "40px",
              border: "1px solid #fff",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            {/* left */}
            <div
              className="d-flex flex-column"
              style={{
                width: "238px",
                borderRight: "1px solid #fff",
              }}
            >
              {info?.map((each, i) => (
                <Button
                  key={"how_btn_left_" + i}
                  onClick={(e) => setTab(i)}
                  className="s-20 c4"
                  style={{
                    borderBottom: "1px solid #fff",
                    backgroundColor: i === tab ? "#8066E980" : "",
                    padding: "24px 0",
                  }}
                >
                  {each.name}
                </Button>
              ))}
            </div>
            {/* right */}
            <div
              className="d-flex flex-column"
              style={{
                padding: "24px",
                gap: "24px",
              }}
            >
              <span className="w3 s-20 mx-auto">
                Earn {info[tab]?.name} and get
              </span>
              <div className="d-flex" style={{ gap: "16px" }}>
                <div
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(83, 75, 156, 0) 0%, #534B9C 100%), url(${cheeseSand})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    width: "239px",
                    height: "329px",
                    borderRadius: "12px",
                  }}
                >
                  <div
                    className="d-flex flex-column mt-auto"
                    style={{ gap: "4px", padding: "14px" }}
                  >
                    <span className="s2 w3">{info[tab].title}</span>
                    <span className="s1">{info[tab].subtitle}</span>
                  </div>
                </div>
                <span className="my-auto">Or</span>
                <div
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(83, 75, 156, 0) 0%, #534B9C 100%), url(${cheeseSand})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    width: "239px",
                    height: "329px",
                    borderRadius: "12px",
                  }}
                >
                  <div
                    className="d-flex flex-column mt-auto"
                    style={{ gap: "4px", padding: "14px" }}
                  >
                    <span className="s2 w3">{info[tab].title2}</span>
                    <span className="s1">{info[tab].subtitle2}</span>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
