import { Box, Typography } from "@mui/material";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { Check, X } from "@phosphor-icons/react";
import * as React from "react";
import { useEffect } from "react";
import loadingGif from "../../../assets/loading-gif.gif";
import { ORDER_STATUS_TYPE } from "../../../Components/constants";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "#D3FDBF",
  zIndex: 1,
  color: "#222227",
  width: 32,
  height: 32,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));

const formatTime = (dateVal) => {
  const date = new Date(dateVal);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const customFormattedDate = date.toLocaleString("en-US", options);
  return customFormattedDate;
};

function ColorlibStepIcon(props, activeStep, failed) {
  const { active, completed, className, icon } = props;
  const myStep = icon - 1;
  const loadingImage = (
    <img src={loadingGif} alt="" style={{ width: "24px", height: "24px" }} />
  );
  const checkImage = <Check />;
  const crossImage = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "32px",
        height: "32px",
        backgroundColor: "#F2F2F2",
        borderRadius: "50%",
      }}
    >
      <X color="#E22B41" />
    </div>
  );
  const notreached = myStep > activeStep;
  let iconVal = notreached ? (
    <div
      style={{
        width: "32px",
        height: "32px",
        backgroundColor: "#F2F2F2",
        borderRadius: "50%",
      }}
    />
  ) : active ? (
    loadingImage
  ) : (
    checkImage
  );
  if (failed && myStep === activeStep) iconVal = crossImage;

  const icons = {
    1: iconVal,
    2: iconVal,
    3: iconVal,
    4: iconVal,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const referenceArray_status = [
  "Draft",
  "Queue",
  "Cooking",
  "Not Assigned",
  "Pending",
  "Picked Up",
  "Completed",
  "Failed",
  "Canceled",
];

function findFirstMissingStatus(arrayA) {
  const statusValuesA = new Set(arrayA?.map((obj) => obj.status)) ?? [];
  for (let i = 0; i < referenceArray_status.length; i++) {
    const status = referenceArray_status[i];
    if (!statusValuesA.has(status)) {
      if (status === "Queue") return 0;
      if (status === "Cooking") return 0;
      if (status === "Not Assigned") return 1;
      if (status === "Pending") return 2;
      if (status === "Picked Up") return 2;
      if (status === "Completed") return 3;
      else return 5;
    }
  }

  return null; // Return null if no missing status is found
}

const steps = [
  { init: "Processing your order", final: "Accepted" },
  { init: "Cooking", final: "Your order has been cooked" },
  { init: "Awaiting delivery", final: "You order is on the way" },
  { init: "Delivery", final: "Delivered" },
];

export default function CustomizedSteppers({ order }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [failed, setFailed] = React.useState(false);
  const [failedIndex, setFailedIndex] = React.useState(false);
  const [time, setTime] = React.useState(0);
  useEffect(() => {
    if (Array.isArray(order?.statusInfo)) {
      const time1 =
        order?.statusInfo?.find((x) => x.status === "Cooking")?.changedAt ??
        null;
      const time2 =
        order?.statusInfo?.find((x) => x.status === "Not Assigned")
          ?.changedAt ?? null;
      const time3 =
        order?.statusInfo?.find((x) => x.status === "Picked Up")?.changedAt ??
        null;
      const time4 =
        order?.statusInfo?.find((x) => x.status === "Completed")?.changedAt ??
        null;
      const time_failed =
        order?.statusInfo?.find(
          (x) => x.status === "Failed" || x.status === "Canceled",
        )?.changedAt ?? null;
      setTime([time1, time2, time3, time4, time_failed]);
    }
    switch (order?.status) {
      case "Queue":
        setActiveStep(0);
        break;
      case "Draft":
        setActiveStep(0);
        break;
      case "Cooking":
        setActiveStep(1);
        break;
      case "Not Assigned":
        setActiveStep(2);
        break;
      case ORDER_STATUS_TYPE.PENDING:
        setActiveStep(2);
        break;
      case ORDER_STATUS_TYPE.PICKED_UP:
        setActiveStep(3);
        break;
      case "Completed":
        setActiveStep(4);
        break;
      default:
        break;
    }
  }, [order]);
  useEffect(() => {
    switch (order?.status) {
      case "Failed":
        setFailed(true);
        if (Array.isArray(order?.statusInfo)) {
          const missing = findFirstMissingStatus(order?.statusInfo ?? []);
          setFailedIndex(missing);
          setActiveStep(missing ?? 4);
        }
        break;
      case "Canceled":
        setFailed(true);
        break;
      default:
        break;
    }
  }, [order]);
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={(e) => ColorlibStepIcon(e, activeStep, failed)}
              optional={
                <Typography>
                  {(activeStep > index || (failed && activeStep >= index)) &&
                    formatTime(
                      failedIndex === index
                        ? time[4]
                        : time[index] ?? new Date(),
                    )}
                </Typography>
              }
            >
              <span className="w3">
                {index >= activeStep ? step.init : step.final}
              </span>
            </StepLabel>
            <StepContent></StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
