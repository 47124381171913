import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  createTheme,
  Grid,
  Tab,
  Tabs,
  ThemeProvider,
} from "@mui/material";
import { Checkbox, Dropdown, Menu } from "antd";
import { CaretDown } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { POSTAPI_USER } from "../../Auth/Index";
import { AlertError } from "../../Components/Common";
import "../../css/Market/searchPage.css";
import { AddToCartModal, OpenAddToCartModal } from "./AddToCartFn";
import { Item } from "./ReactSlick";

const CheckboxGroup = Checkbox.Group;

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          textTransform: "none",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#818091",
          "&.Mui-selected": {
            color: "#8943EF",
          },
        },
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const SearchPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const stateData = location.state;
  const outletContext = useOutletContext();
  var body_sc = document.getElementById("s_leftDiv");
  const [values, setValues] = useState({
    scrollForCart: null,
    searchOptions: {},
    allOptions: {},
    allOffers: [],
    allOffersOptions: [],
    checkAll_Offer: false,
    checkedList_Offer: [],
    numbers: [0, 0, 0],
    indeterminate_Offer: false,
    minPrice: "",
    maxPrice: "",
    sortValue: "Default",
    modalItem: null,
    itemModalVisible: false,
    modifiersSel: [],
    errorPosition: "",
    errorText: "",
    value: 0,
    setValuesFn: null,
    searchSortLoad: false,
  });
  const {
    scrollForCart,
    searchOptions,
    //  allOptions, allOffers,
    allOffersOptions,
    checkAll_Offer,
    checkedList_Offer,
    numbers,
    indeterminate_Offer,
    minPrice,
    maxPrice,
    sortValue,
    modalItem,
    itemModalVisible,
    modifiersSel,
    value,
    errorPosition,
    errorText,
    setValuesFn,
    searchSortLoad,
  } = values;
  useEffect(() => {
    if (!stateData) navigate("/");
    setValues((x) => ({
      ...x,
      // searchOptions: stateData.searchOptions,
      allOptions: stateData.searchOptions,
      allOffers: stateData.data.offers,
      allOffersOptions: stateData.data.offers.map((x) => x.name),
      checkedList_Offer: stateData.offerId
        ? Array(1).fill(
            stateData.data.offers.filter((x) => x._id === stateData.offerId)[0]
              .name,
          )
        : [],
      numbers: [stateData.num0, stateData.num1, stateData.num2],
      value: stateData.value,
    }));
  }, [stateData, navigate]);
  useEffect(() => {
    const searchOps =
      outletContext.length > 0 && outletContext[4] !== null
        ? outletContext[4]
        : null;
    const setValuesFn =
      outletContext.length > 0 && outletContext[4] !== null
        ? outletContext[5]
        : null;
    const searchSortLoadVal =
      outletContext.length > 0 && outletContext[10] !== null
        ? outletContext[10]
        : null;
    setValues((x) => ({
      ...x,
      searchOptions: searchOps,
      setValuesFn,
      searchSortLoad: searchSortLoadVal,
    }));
  }, [outletContext]);
  //handle tab change
  const handleChange = (event, newValue) => {
    setValues((x) => ({ ...x, value: newValue }));
    setValuesFn &&
      setValuesFn((x) => ({ ...x, swipeableEdgeDrawerIndex: newValue }));
  };
  const [y, setY] = useState(window.scrollY);
  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        if (body_sc) body_sc.style.top = "80px";
      } else if (y < window.scrollY) {
        if (body_sc) body_sc.style.top = "20px";
      }
      setY(window.scrollY);
    },
    [y, body_sc],
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  //handle checkbox offers all checked
  const onCheckAllChange = async (e, type, options, all, indeterminate_val) => {
    setValues((x) => ({
      ...x,
      [type]: e.target.checked ? options : [],
      [indeterminate_val]: false,
      [all]: e.target.checked,
    }));
    handleFIlter(
      minPrice,
      maxPrice,
      sortValue,
      e.target.checked ? options : [],
    );
  };

  //handle checkbox offers
  const onChange = async (list, type, options, all, indeterminate_val) => {
    setValues((x) => ({
      ...x,
      [type]: list,
      [indeterminate_val]: !!list.length && list.length < options.length,
      [all]: list.length === options.length,
    }));
    handleFIlter(minPrice, maxPrice, sortValue, list);
  };

  // handle price change
  const handlePriceChanged = async (min, max) => {
    if ((isNaN(min) && min !== "") || (isNaN(max) && max !== "")) {
      return;
    }
    setValues((x) => ({ ...x, minPrice: min, maxPrice: max }));
    handleFIlter(min, max, sortValue, checkedList_Offer);
  };

  //handle sort
  const sortChanged = async (val) => {
    setValues((x) => ({ ...x, sortValue: val, searchSortLoad: true }));
    handleFIlter(minPrice, maxPrice, val, checkedList_Offer);
  };

  // handle filter API
  const handleFIlter = async (minVal, maxVal, sortVal, offersVal) => {
    var send_data = {
      queryText: stateData.searchText,
      minPrice: isNaN(parseInt(minVal)) ? null : parseInt(minVal),
      maxPrice: isNaN(parseInt(maxVal)) ? null : parseInt(maxVal),
      requestSortQuery: sortVal,
    };
    var offerArr = [];
    offersVal.forEach((element) => {
      var found = stateData.data.offers.find((x) => x.name === element);
      if (found) {
        offerArr.push(found._id);
      }
    });
    send_data.offerIds = offerArr;
    POSTAPI_USER("/homepage/search", send_data).then((resp) => {
      if (resp.success) {
        setValues((x) => ({
          ...x,
          searchOptions: resp,
          searchSortLoad: false,
        }));
      }
    });
  };

  const sort_menu = (
    <Menu
      style={{ zIndex: "9999" }}
      items={[
        {
          label: (
            <Button
              className="acc_dropdownBtn"
              onClick={() => sortChanged("Default")}
            >
              Default
            </Button>
          ),
          key: "0",
        },
        {
          label: (
            <Button
              className="acc_dropdownBtn"
              onClick={() => sortChanged("High to Low")}
            >
              High to Low
            </Button>
          ),
          key: "1",
        },
        {
          label: (
            <Button
              className="acc_dropdownBtn"
              onClick={() => sortChanged("Low to High")}
            >
              Low to High
            </Button>
          ),
          key: "2",
        },
        {
          label: (
            <Button
              className="acc_dropdownBtn"
              onClick={() => sortChanged("Popularity")}
            >
              Popularity
            </Button>
          ),
          key: "3",
        },
        // {
        //     label: <Button className='acc_dropdownBtn'
        //         onClick={() => sortChanged('Fastest Delivery')}>
        //         Fastest Delivery
        //     </Button>,
        //     key: '4',
        // },
      ]}
    />
  );

  const ItemsAndCombosBody = () => (
    <Grid
      container
      mb={"30px"}
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 1, md: 1 }}
      marginTop={{ xs: "1px", sm: "0" }}
    >
      {searchSortLoad ? (
        <div className="d-flex justify-content-center w-100">
          <CircularProgress sx={{ color: "#8943EF" }} />
        </div>
      ) : (
        <>
          {searchOptions &&
            searchOptions["itemsAndCombos"] &&
            searchOptions["itemsAndCombos"].map((card, ind) => (
              <Grid key={ind} item xs={6} sm={6} md={4} lg={4}>
                <Item
                  className="h-100"
                  onClick={(e) => OpenAddToCartModal(e, card, setValues)}
                >
                  <div className="sc_combo_div">
                    <div
                      className="hp_each_popular_image_combo_search mb-2"
                      style={{
                        backgroundImage: `url(${card.image})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                      }}
                    ></div>
                    <span className="c3 w3 s2">{card.name}</span>
                    <div className="sc_cm_item_div mb-auto mt-2">
                      {card.items &&
                        card.items.map((eachItem, ind) => (
                          <span key={ind} className="sc_cm_item">
                            {eachItem.name}
                            {eachItem.quantity > 1 && ` X${eachItem.quantity}`}
                          </span>
                        ))}
                    </div>
                    {/* <p className="c1 s3 w3 mt-auto">Rs. {card.price}</p> */}
                    <div className="d-flex mt-2 mt-sm-0 align-items-center">
                      {card.hasDiscount && (
                        <span className="c7 w3 s2 mr-2 strikethrough">
                          Rs. {card.price}
                        </span>
                      )}
                      <span className="s5 c1 w3">
                        Rs. {card.discountedPrice}
                      </span>
                    </div>
                  </div>
                </Item>
              </Grid>
            ))}
          {searchOptions &&
            searchOptions["itemsAndCombos"] &&
            searchOptions["itemsAndCombos"].length === 0 && (
              <p className="ml-3">No items here!</p>
            )}
        </>
      )}
    </Grid>
  );

  const CategoriesBody = () => (
    <Grid
      container
      mb={"30px"}
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      marginTop={{ xs: "1px", sm: "0" }}
    >
      {searchOptions &&
        searchOptions["category"] &&
        searchOptions["category"].map((card, ind) => (
          <Grid
            item
            key={ind}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            paddingTop={{ xs: "2", sm: "24px" }}
          >
            <div className="sc_cat_search" key={ind}>
              <Button
                className="sc_cat_each_search"
                key={ind}
                onClick={() =>
                  navigate(`/filter?cid=${card._id}`, {
                    state: {
                      catId: card._id,
                      offerId: null,
                    },
                  })
                }
              >
                <p className="spr_each_text">
                  <span>{card.name}</span>
                  <span className="ml-1 d-sm-none">
                    &#40;{card.itemsCount}&#41;
                  </span>
                </p>
                <Avatar
                  className="d-none d-sm-block sc_cat_icon_search"
                  src={card.image}
                />
              </Button>
            </div>
          </Grid>
        ))}
      {searchOptions &&
        searchOptions["category"] &&
        searchOptions["category"].length === 0 && (
          <p className="ml-3">No category here!</p>
        )}
    </Grid>
  );

  return (
    <div>
      {stateData && (
        <ThemeProvider theme={theme}>
          {/* tabs */}
          <div className="search_tab_div mb-4">
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
            >
              <Tabs
                TabIndicatorProps={{
                  style: {
                    color: "#8943EF",
                    backgroundColor: "#8943EF",
                    height: "3px",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Items and Combos" {...a11yProps(0)} />
                {/* <Tab label="Brands" {...a11yProps(1)} /> */}
                <Tab label="Categories" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </div>

          <div className="d-flex position-relative mb-5">
            {/* left */}
            <Grid
              container
              id="s_leftDiv"
              visibility={{ xs: "hidden", md: "visible" }}
              width={{ xs: "0px", md: "275px" }}
              className="sp_left"
            >
              <div className="sp_left_first">
                <p className="w3 s3">"{stateData.searchText}"</p>
                <span className="c7">
                  {numbers[value]} results for "{stateData.searchText}"
                </span>
              </div>

              <div className="divider" />
              <div className="sp_left_first">
                {/* offer */}
                {allOffersOptions && allOffersOptions.length > 0 && (
                  <div className="mb-3">
                    <Checkbox
                      indeterminate={indeterminate_Offer}
                      onChange={(e) =>
                        onCheckAllChange(
                          e,
                          "checkedList_Offer",
                          allOffersOptions,
                          "checkAll_Offer",
                          indeterminate_Offer,
                        )
                      }
                      checked={checkAll_Offer}
                    >
                      <span className="cb_main">Offers</span>
                    </Checkbox>
                    <div className="divider" />
                    <CheckboxGroup
                      options={allOffersOptions}
                      value={checkedList_Offer}
                      onChange={(list) =>
                        onChange(
                          list,
                          "checkedList_Offer",
                          allOffersOptions,
                          "checkAll_Offer",
                          indeterminate_Offer,
                        )
                      }
                    />
                  </div>
                )}

                {/* price */}
                {value === 0 && (
                  <>
                    <span className="cb_main">Price (Rs.)</span>
                    <div className="d-flex justify-content-between mt-2">
                      <input
                        type="text"
                        className="fm_left_price"
                        value={minPrice}
                        name="minPrice"
                        onChange={(e) =>
                          handlePriceChanged(e.target.value, maxPrice)
                        }
                      />
                      <span>_</span>
                      <input
                        type="text"
                        className="fm_left_price"
                        value={maxPrice}
                        name="maxPrice"
                        onChange={(e) =>
                          handlePriceChanged(minPrice, e.target.value)
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </Grid>

            {/* right */}
            <Grid
              container
              width={{ xs: "100%", md: "calc(100% - 275px)" }}
              className="sp_right"
              marginLeft={{ xs: "0", md: "25px" }}
            >
              <div className="d-flex flex-column w-100">
                {value === 0 && (
                  <div className="d-none d-md-flex fm_right_sort_div ml-auto mb-4">
                    <span>Sort by: </span>
                    <Dropdown overlay={sort_menu} trigger={["click"]}>
                      <div onClick={(e) => e.preventDefault()}>
                        <Button className="fm_right_sort_btn">
                          <span>{sortValue}</span>
                          <CaretDown className="ml-auto" />
                        </Button>
                      </div>
                    </Dropdown>
                  </div>
                )}
                <TabPanel value={value} index={0}>
                  {ItemsAndCombosBody()}
                </TabPanel>
                {/* brand */}
                {/* <TabPanel value={value} index={1}>
                  {BrandBody()}
                </TabPanel> */}
                <TabPanel value={value} index={1}>
                  {CategoriesBody()}
                </TabPanel>
              </div>
            </Grid>
          </div>
          {AddToCartModal(
            itemModalVisible,
            modalItem,
            modifiersSel,
            setValues,
            scrollForCart,
          )}

          {AlertError(errorPosition, errorText, setValues)}
        </ThemeProvider>
      )}
    </div>
  );
};
