import { Avatar, ThemeProvider } from "@mui/material";
// import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DELETEAPIAdmin,
  GetAPIAdmin,
  POSTAPI_formAdmin,
  PUT_API_formAdmin,
} from "../../Auth/Index";
import {
  AlertError,
  DataGridNova,
  HalfRightBtnComp,
  InputComp,
  LabelComp,
  ReactSelectDrop,
  SelectImage_Small,
  TableEditDelete,
  TopAddComp,
  TopBackComp,
  getMuiTheme,
} from "../../Components/Common";
import "../../css/Admin/category.css";

export const Category = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    cats: [],
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    edAnchor: null,
    edClicked: null,
    pageSize: 10,
  });
  const {
    cats,
    first,
    loading,
    errorPosition,
    errorText,
    edAnchor,
    edClicked,
    pageSize,
  } = values;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/category/").then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            cats: res.categories,
            first: false,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first]);

  // const columns = [
  //     {
  //         name: '_id',
  //         label: '',
  //         options: {
  //             display: false
  //         }
  //     },
  //     {
  //         name: 'image',
  //         label: 'Image',
  //         options: {
  //             display: false
  //         }
  //     },
  //     {
  //         name: "name",
  //         label: "Category",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 var index = tableMeta.rowIndex
  //                 return (
  //                     <div className="d-flex align-items-center">
  //                         <Avatar style={{ borderRadius: '12px' }} src={tableMeta.currentTableData[index].data[1]} />
  //                         <span className="ml-3">{value}</span>
  //                     </div>
  //                 )
  //             }
  //         }
  //     },
  //     {
  //         name: "",
  //         label: "",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <TableEditDelete onClick={(e) => e.stopPropagation()} id={tableMeta.rowData[0]}
  //                         EditFn={EditFn} DeleteFn={DeleteFn}
  //                         edAnchor={edAnchor} setValues={setValues} />
  //                 )
  //             }
  //         }
  //     }
  // ];
  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No category yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  // };

  const EditFn = () => {
    var item = cats.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      navigate("/categories/edit", { state: item });
    }
  };

  const DeleteFn = () => {
    var item = cats.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      DELETEAPIAdmin("/category/remove", { categoryId: edClicked }).then(
        (res) => {
          if (res.success) {
            setValues((x) => ({
              ...x,
              errorPosition: "success",
              errorText: `Category ${item.name} deleted`,
              cats: res.categories,
            }));
          } else if (res.error)
            setValues((x) => ({
              ...x,
              errorPosition: "apiErr",
              errorText: res.error,
            }));
        },
      );
    }
  };

  const handleRowClick = (item) => {
    // var item = cats[rowMeta.dataIndex]
    navigate("/categories/edit", { state: item });
  };

  // mui
  const columnsData = [
    {
      field: "name",
      headerName: "Category",
      description: "This column is sortable",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <Avatar style={{ borderRadius: "12px" }} src={params.row.image} />
            <span className="ml-3">{params.row.name}</span>
          </div>
        );
      },
    },
    {
      field: "option",
      headerName: "",
      description: "Click for edit delete options",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <TableEditDelete
              onClick={(e) => e.stopPropagation()}
              id={params.row._id}
              EditFn={EditFn}
              DeleteFn={DeleteFn}
              edAnchor={edAnchor}
              setValues={setValues}
              edClicked={edClicked}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="category_body">
      {TopAddComp("Category", "/categories/add", "Add Category")}

      <ThemeProvider theme={getMuiTheme()}>
        {/* <MUIDataTable
                    data={cats}
                    columns={columns}
                    options={options}
                /> */}
      </ThemeProvider>

      <div style={{ height: "55vh", width: "450px" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={cats}
          columns={columnsData}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const AddCategory = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    cat: "",
    image: null,
    displayIn: "Group",
    loading: false,
    errorPosition: "",
    errorText: "",
  });
  const { cat, image, displayIn, loading, errorPosition, errorText } = values;

  const handleCreate = () => {
    if (loading) return;
    var formData = new FormData();
    formData.append("name", cat);
    formData.append("image", image);
    formData.append("displayIn", displayIn);
    setValues((prev) => ({ ...prev, loading: true }));
    POSTAPI_formAdmin("/category/create", formData).then((res) => {
      if (res.success) navigate("/categories");
      if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          first: false,
          errorText: res.error,
          loading: false,
        }));
    });
  };

  return (
    <>
      {TopBackComp("Add Category", "/categories")}

      <div className="add_category_body">
        {LabelComp("Category Name", cat, errorPosition, errorText)}
        {InputComp(cat, "cat", setValues, "add_category_input")}

        {LabelComp("Image", image, errorPosition, errorText)}
        {SelectImage_Small(image, "image", setValues)}

        {LabelComp("Display In", "displayIn", errorPosition, errorText, "mt-3")}
        {ReactSelectDrop(displayIn, "displayIn", setValues, [
          { _id: "Single", name: "Single" },
          { _id: "Group", name: "Group" },
        ])}

        {HalfRightBtnComp("Add Category", handleCreate, loading)}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

export const EditCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!location.state) navigate("/categories");
  }, [location, navigate]);
  const [values, setValues] = useState({
    _id: location.state._id,
    cat: location.state.name,
    image: location.state.image,
    displayIn: location.state.displayIn ?? "Group",
    errorPosition: "",
    errorText: "",
    loading: false,
  });
  const { _id, cat, image, displayIn, errorPosition, errorText, loading } =
    values;

  const handleUpdate = () => {
    if (loading) return;
    var formData = new FormData();
    formData.append("categoryId", _id);
    formData.append("name", cat);
    formData.append("displayIn", displayIn);
    if (typeof image === "object") formData.append("image", image);
    setValues((prev) => ({ ...prev, loading: true }));
    PUT_API_formAdmin("/category/edit", formData).then((res) => {
      if (res.success) navigate("/categories");
      if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          first: false,
          errorText: res.error,
          loading: false,
        }));
    });
  };

  return (
    <>
      {TopBackComp("Edit Category", "/categories")}

      <div className="add_category_body">
        {LabelComp("Category Name", cat, errorPosition, errorText)}
        {InputComp(cat, "cat", setValues, "add_category_input")}

        {LabelComp("Image", image, errorPosition, errorText)}
        {SelectImage_Small(image, "image", setValues)}

        {LabelComp("Display In", "displayIn", errorPosition, errorText, "mt-3")}
        {ReactSelectDrop(displayIn, "displayIn", setValues, [
          { _id: "Single", name: "Single" },
          { _id: "Group", name: "Group" },
        ])}

        {HalfRightBtnComp("Edit Category", handleUpdate, loading)}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};
