import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  createTheme,
  CssBaseline,
  Divider,
  Paper,
  styled,
  Tab,
  Tabs,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import { ForkKnife } from "@phosphor-icons/react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { API } from "../../Auth/Config";
import {
  GetAPIAdmin,
  GetAPIKDS,
  isAuthenticatedAdmin,
  isAuthenticatedKDS,
  POSTAPI,
} from "../../Auth/Index";
import {
  admin_kdsId,
  AlertError,
  formatDate,
  formatDateTimeKDS,
  formatTime,
  HomeMenu,
  NotSupported,
} from "../../Components/Common";
import { getS3Image } from "../../Components/s3";
import ting from "../../Components/Ting.mp3";
import "../../css/KDS/home.css";
import "../../css/KDS/layout.css";
import "../../css/Market/layout.css";
import { HideOnScroll } from "../Market/ScrollTest";

const theme = createTheme({
  components: {
    // Name of the component
    MuiAppBar: {
      styleOverrides: {
        root: {
          left: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#8943EF",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#818091",
          textTransform: "none",
          "&.Mui-selected": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#2E2E2E",
            textTransform: "none",
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "100vw !important",
        },
      },
    },
  },
});

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#2E2E2E",
  display: "flex",
  flexDirection: "column",
  boxShadow: "none",
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const KDSHome = (props) => {
  const navigate = useNavigate();
  const userId = admin_kdsId();
  const [audio] = useState(new Audio(ting));
  const [values, setValues] = useState({
    index: 0,
    first: true,
    socketFirst: true,
    orders: {},
    errorPosition: "",
    errorText: "",
    socket: null,
    isKitchenBusy: false,
    loadInd: null,
    toPrint: null,
  });
  const {
    index,
    first,
    socketFirst,
    orders,
    errorPosition,
    errorText,
    socket,
    isKitchenBusy,
    loadInd,
    toPrint,
  } = values;
  const [date, setDate] = useState(new Date());

  // authentication checked
  useEffect(() => {
    if (!isAuthenticatedKDS() && !isAuthenticatedAdmin())
      navigate("/kds/login");
  }, [navigate]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setValues((x) => ({ ...x, first: true }));
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  // get all orders API call
  useEffect(() => {
    if (first) {
      POSTAPI("/order/kds").then((res) => {
        if (res.success) {
          setValues((x) => ({
            ...x,
            orders: res.orders,
            first: false,
            isKitchenBusy: res.isKitchenBusy,
          }));
        }
      });
    }
  }, [first]);

  // intervals for time
  useEffect(() => {
    setInterval(() => setDate(new Date()), 30000);
  }, []);

  // socket
  useEffect(() => {
    if (!socket && userId && socketFirst) {
      var thisSocket = io(API, {
        reconnection: true,
        reconnectionAttempts: Infinity,
      });
      setValues((x) => ({
        ...x,
        socket: thisSocket,
        socketFirst: false,
      }));
      thisSocket.emit("onConnection", {
        userId,
      });
      thisSocket.on("onConnectionResponse", (res) => {});
    }
    if (socket) {
      socket.on("newOrder", (res) => {
        audio.play();
        setValues((x) => ({ ...x, orders: res.allOrders }));
      });
    }
  }, [userId, socket, socketFirst, first, orders, audio]);

  // // remove socket before tab closed
  // useEffect(() => {
  //     const handleTabClose = event => {
  //         if (socket) {
  //             socket.emit('onDisconnection', {
  //                 userId
  //             })
  //         }
  //         return
  //     };

  //     window.addEventListener('beforeunload', handleTabClose);

  //     return () => {
  //         window.removeEventListener('beforeunload', handleTabClose);
  //     };
  // }, [socket, userId]);

  // // disconnect
  // useEffect(() => {
  //     return () => {
  //         if (socket) {
  //             socket.emit('onDisconnection', {
  //                 userId
  //             })
  //         }
  //     }
  // }, [socket, userId])

  const handleChangeIndex = (e, newValue) => {
    setValues((prev) => ({ ...prev, index: newValue, first: true }));
  };

  function TabPanel(props) {
    const { children, value, index, data, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className="w-100"
        {...other}
      >
        {value === index && (
          <>
            {data && (
              <App
                data={data}
                toPrint={toPrint}
                loadInd={loadInd}
                setValues={setValues}
              />
            )}
          </>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const tabCustomLabel = (text, data, step) => (
    <span className="d-flex">
      {text}
      {data && data.length > 0 && (
        <span className={step === index ? "kds_sel_num" : "kds_unsel_num"}>
          {data && data.length > 0 && data.length}
        </span>
      )}
    </span>
  );

  const handleBusy = () => {
    const hasAdKey = localStorage.getItem("_nova_foods_ad");
    const hasKdsKey = localStorage.getItem("_nova_foods_kds");

    if (hasAdKey && hasKdsKey) {
      GetAPIAdmin("/settings/kitchenStatus").then((res) => {
        setValues((x) => ({ ...x, isKitchenBusy: !isKitchenBusy }));
      });
    } else if (hasAdKey) {
      GetAPIAdmin("/settings/kitchenStatus").then((res) => {
        setValues((x) => ({ ...x, isKitchenBusy: !isKitchenBusy }));
      });
    } else if (hasKdsKey) {
      GetAPIKDS("/settings/kitchenStatus").then((res) => {
        setValues((x) => ({ ...x, isKitchenBusy: !isKitchenBusy }));
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="testOk">
        <div className="kds_layout">
          <div className="kds_layout_main">
            <CssBaseline />
            <HideOnScroll {...props}>
              <AppBar className="bg-white market_appbar">
                <Toolbar className="bg-white kds_width">
                  <div className="ml_top w-100">
                    <span className="kds_date">{formatDateTimeKDS(date)}</span>
                    <Box sx={{ width: "100%", position: "absolute" }}>
                      <Tabs value={index} centered onChange={handleChangeIndex}>
                        <Tab
                          label={tabCustomLabel("All", orders.All, 0)}
                          {...a11yProps(0)}
                        />
                        <Tab
                          label={tabCustomLabel("Queue", orders.Queue, 1)}
                          {...a11yProps(1)}
                        />
                        <Tab
                          label={tabCustomLabel("Cooking", orders.Cooking, 2)}
                          {...a11yProps(2)}
                        />
                      </Tabs>
                    </Box>

                    {isKitchenBusy ? (
                      <Button
                        className="ml-auto primary_btn"
                        onClick={() => handleBusy()}
                      >
                        Back to normal
                      </Button>
                    ) : (
                      <Button
                        className="ml-auto secondary_btn"
                        onClick={() => handleBusy()}
                      >
                        Mark as busy
                      </Button>
                    )}
                    <span className="kds_three_dots mr-5">{HomeMenu()}</span>
                  </div>
                </Toolbar>
              </AppBar>
            </HideOnScroll>

            <Toolbar />

            <Container style={{ width: "1200px" }}>
              <Box sx={{ my: 2 }}>
                <div className="kds_width_main">
                  <div className="kds_width pt-4">
                    <TabPanel value={index} index={0} data={orders.All}>
                      All
                    </TabPanel>
                    <TabPanel value={index} index={1} data={orders.Queue}>
                      Queue
                    </TabPanel>
                    <TabPanel value={index} index={2} data={orders.Cooking}>
                      Cooking
                    </TabPanel>
                  </div>
                </div>
              </Box>
            </Container>
          </div>
          <div className="kds_layout_nope">{NotSupported()}</div>
        </div>
      </div>
      {AlertError(errorPosition, errorText, setValues)}
    </ThemeProvider>
  );
};

let brakePoints = [350, 500, 750];

export const App = ({ data, loadInd, toPrint, setValues }) => {
  return (
    <div>
      {data && data.length === 0 ? (
        <div className="masonry-container-empty">
          <img className="mce_img" src={getS3Image("kds_none.svg")} alt="" />
          <p className="mce_text">No orders right now</p>
        </div>
      ) : (
        <div className="masonry-container">
          <Masonry brakePoints={brakePoints}>
            {data.map((thisOne, id) => {
              return (
                <Tile
                  key={id}
                  loadInd={loadInd}
                  toPrint={toPrint}
                  data={thisOne}
                  Orderindex={id + 1}
                  setValues={setValues}
                />
              );
            })}
          </Masonry>
        </div>
      )}
    </div>
  );
};

const Tile = ({ data, loadInd, toPrint, Orderindex, setValues }) => {
  const statusChange = (thisOrder, status) => {
    if (loadInd === null) {
      // setValues(x => ({ ...x, loadInd: thisOrder.orderId }))
      if (status === "Finished") {
        setValues((x) => ({ ...x, toPrint: data }));
      }
      if (status === "Cooking") {
        setValues((x) => ({ ...x, toPrint: data }));
      }
      var send_data = {
        orderId: thisOrder.orderId,
        newStatus: status,
      };
      POSTAPI("/order/status/change", send_data).then((res) => {
        if (res.success) {
          if (status === "Finished") {
            setValues((prev) => ({
              ...prev,
              orders: res.orders,
              errorPosition: "success",
              errorText: "Cooking Completed.",
              loadInd: null,
            }));
            handlePrintED(thisOrder);
          } else if (status === "Cooking") {
            setValues((prev) => ({
              ...prev,
              orders: res.orders,
              loadInd: null,
            }));
            handlePrintStart(thisOrder);
          } else {
            setValues((prev) => ({
              ...prev,
              orders: res.orders,
              // errorPosition: 'success', errorText: 'Cooking Started.'
            }));
            setValues((x) => ({ ...x, loadInd: null }));
          }
        }
      });
    }
  };

  const statusChangeEachItem = (data, each) => {
    if (data.status === "Queue") return;
    var send_data = {};
    if (each.itemId)
      send_data = {
        orderId: data.orderId,
        itemId: each.itemId,
        identifierId: each.identifierId || null,
      };
    else if (each.comboId)
      send_data = {
        orderId: data.orderId,
        comboId: each.comboId,
      };
    else if (each.customId)
      send_data = {
        orderId: data.orderId,
        customId: each.customId,
      };
    else send_data = {};
    var isCookedTillNow = 1;
    data.foods.forEach((element) => {
      if (element.isCooked && element.itemId !== each.itemId) isCookedTillNow++;
    });
    var print = isCookedTillNow === data.foods.length;
    if (print) setValues((x) => ({ ...x, toPrint: data }));
    POSTAPI("/order/status/change", send_data).then((res) => {
      if (res.success) {
        if (print) handlePrintED(data);
        setValues((prev) => ({
          ...prev,
          orders: res.orders,
        }));
      }
    });
  };

  const FinishCookingPrint = (props) => {
    return (
      <>
        <div>
          <div
            id={`printId${props.i}`}
            className="printNeo"
            style={{ fontFamily: "Arial" }}
          >
            <div
              className="printNeoBody d-flex flex-column"
              style={{ fontFamily: "Arial", fontWeight: "400" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "18px",
                    marginBottom: "5px",
                  }}
                >
                  NOVA FOODS PVT. LTD
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "5px",
                  }}
                >
                  BAKHUNDOLE, LALITPUR
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "5px",
                  }}
                >
                  PAN NO. 610284707
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "5px",
                  }}
                >
                  ABBREVIATED TAX INVOICE
                </span>
              </div>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "12px",
                  lineHeight: "14px",
                  marginBottom: "5px",
                }}
              >
                <span>Order No. : #{props.data.trackingNumber}</span>
                <span> {formatDate(props.data.createdAt)}</span>
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "12px",
                  lineHeight: "14px",
                  marginBottom: "5px",
                }}
              >
                <span>
                  Bill To :{" "}
                  {props.data?.isAdminCreated &&
                  !props.data?.delivery?.deliveryPartner?.isDefaultPartner
                    ? props.data?.delivery?.deliveryPartner?.name
                    : ""}
                  {props.data?.isAdminCreated &&
                  props.data?.delivery?.location?.name
                    ? props.data?.delivery?.location?.name
                    : ""}
                  {!props.data?.isAdminCreated &&
                  props.data?.delivery?.location?.name
                    ? props.data?.delivery?.location?.name
                    : ""}
                </span>
                <span>{formatTime(props.data.createdAt)}</span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {props.data?.delivery?.location?.phone ? (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        lineHeight: "14px",
                      }}
                    >
                      Phone number : {props.data?.delivery?.location?.phone}
                    </span>
                  </>
                ) : (
                  ""
                )}
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                  }}
                >
                  Payment Method : {props.data.payment.method}
                </span>
              </div>
              <div
                style={{
                  margin: "10px 0",
                  borderTop: "2px solid #000",
                }}
              />
              <div>
                {props &&
                  props.data &&
                  props.data.foods &&
                  props.data.foods.length > 0 &&
                  props.data.foods.map((each, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "15px",
                        }}
                      >
                        <div>
                          <span>{each.quantity}</span>
                          <span style={{ marginLeft: "5px" }}>
                            {each.name}{" "}
                          </span>
                          {each?.modifiersInfo &&
                          each.modifiersInfo.length > 0 ? (
                            <span
                              style={{
                                width: "fit-content",
                                marginLeft: "auto",
                                float: "right",
                              }}
                            >
                              Rs.{each?.unitPrice}
                            </span>
                          ) : (
                            ""
                          )}

                          {!each?.modifiersInfo ||
                          each?.modifiersInfo.length === 0 ? (
                            <span
                              style={{
                                width: "fit-content",
                                marginLeft: "auto",
                                float: "right",
                              }}
                            >
                              Rs.{each.price}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        {each?.modifiersInfo &&
                        each?.modifiersInfo.length > 0 ? (
                          <>
                            {each?.modifiersInfo.map((item, i) =>
                              item.options.map((x, a) => (
                                <div
                                  key={a}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginLeft: "10px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <span>{x.name}</span>
                                  <span>Rs. {x.price}</span>
                                </div>
                              )),
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {each?.spiceLevel && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginLeft: "10px",
                              marginTop: "5px",
                            }}
                          >
                            <span>Spice level</span>
                            <span>{each.spiceLevel}</span>
                          </div>
                        )}

                        {each?.modifiersInfo &&
                        each?.modifiersInfo.length > 0 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "5px",
                            }}
                          >
                            <span>Rs. {each.price}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
              </div>
              <div
                style={{
                  margin: "10px 0",
                  borderTop: "2px solid #000",
                }}
              />
              {props.data &&
                props.data.payment &&
                props.data.payment.subTotal && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                      lineHeight: "14px",
                      marginBottom: "3px",
                    }}
                  >
                    <span>Subtotal</span>
                    <span>Rs. {props.data.payment.subTotal}</span>
                  </div>
                )}
              {props.data &&
              props.data.payment &&
              props.data.payment?.deliveryCharge &&
              props.data.payment?.deliveryCharge > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Delivery Charge</span>
                  <span>Rs. {props.data.payment.deliveryCharge}</span>
                </div>
              ) : (
                ""
              )}
              {/* {props.data &&
              props.data.payment &&
              props.data.payment.discount ? (
                <>
                  {props.data.payment.discount > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        lineHeight: "14px",
                        marginBottom: "3px",
                      }}
                    >
                      <span>Discount</span>
                      <span>Rs. {props.data.payment.discount}</span>
                    </div>
                  )}
                </>
              ) : null} */}
              {props.data &&
              props.data?.payment &&
              props.data.payment?.promoDiscount ? (
                <>
                  {props.data.payment?.promoDiscount > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        lineHeight: "14px",
                        marginBottom: "3px",
                      }}
                    >
                      <span>Promo Discount</span>
                      <span>- Rs. {props.data.payment?.promoDiscount}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              {props.data &&
              props.data.payment &&
              props.data.payment.taxes &&
              props.data.payment.taxes.length > 0
                ? props.data.payment.taxes.map((each, i) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        lineHeight: "14px",
                        marginBottom: "3px",
                      }}
                      key={i}
                    >
                      <span>{each.name}</span>
                      <span>
                        {each.type === "value" && "Rs."} {each.amount}{" "}
                        {each.type === "percent" && "%"}
                      </span>
                    </div>
                  ))
                : ""}
              {props.data?.payment?.tipAmount > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Rider Tip</span>
                  <span>Rs. {props.data.payment.tipAmount}</span>
                </div>
              )}
              {props.data &&
              props.data.payment &&
              props.data.payment?.paidAmount &&
              props.data.payment?.paidAmount > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Paid Amount</span>
                  <span>Rs. {props.data.payment?.paidAmount}</span>
                </div>
              ) : (
                ""
              )}
              {props.data && props.data.payment && props.data.payment.total && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Total</span>
                  <span>Rs. {props.data.payment.total}</span>
                </div>
              )}
              {props.data &&
              props.data.payment &&
              props.data.payment.remainingAmount &&
              props.data.payment?.remainingAmount > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Remaining Amount</span>
                  <span>Rs. {props.data.payment?.remainingAmount}</span>
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  margin: "10px 0",
                  borderTop: "1px solid #000",
                }}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={{ fontSize: "10px", lineHeight: "12px" }}>
                  THANK YOU FOR YOUR ORDER
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const StartCookingPrint = (props) => {
    return (
      <>
        <div>
          <div
            id={`startprintId${props.i}`}
            className="printNeo"
            style={{ fontFamily: "Arial" }}
          >
            <div
              className="printNeoBody"
              style={{
                display: "flex",
                flexDirection: "column",
                fontFamily: "Arial",
                fontWeight: "400",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "18px",
                    marginBottom: "5px",
                  }}
                >
                  KOT
                </span>
              </div>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "12px",
                  lineHeight: "14px",
                  marginBottom: "5px",
                }}
              >
                <span> Order No. : #{props.data.trackingNumber}</span>
                <span> {formatDate(props.data.createdAt)}</span>
              </span>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "12px",
                  lineHeight: "14px",
                  marginBottom: "5px",
                }}
              >
                <span>
                  Customer :{" "}
                  {props.data?.isAdminCreated &&
                  !props.data?.delivery?.deliveryPartner?.isDefaultPartner
                    ? props.data?.delivery?.deliveryPartner?.name
                    : ""}
                  {props.data?.isAdminCreated &&
                  props.data?.delivery?.location?.name
                    ? props.data?.delivery?.location?.name
                    : ""}
                  {!props.data?.isAdminCreated &&
                  props.data?.delivery?.location?.name
                    ? props.data?.delivery?.location?.name
                    : ""}
                </span>
                <span>{formatTime(props.data.createdAt)}</span>
              </div>
              <div
                style={{
                  margin: "10px 0",
                  borderTop: "2px solid #000",
                }}
              />
              <div>
                {props &&
                  props.data &&
                  props.data.foods &&
                  props.data.foods.length > 0 &&
                  props.data.foods.map((each, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "15px",
                        }}
                      >
                        <div>
                          <span>{each.quantity}</span>
                          <span style={{ marginLeft: "5px" }}>
                            {each.name}{" "}
                          </span>
                        </div>
                        {each.modifiers && each.modifiers.length > 0 ? (
                          <>
                            {each.modifiers.map((item, i) => (
                              <div
                                key={i}
                                style={{
                                  marginLeft: "10px",
                                  marginTop: "5px",
                                }}
                              >
                                <span>{item}</span>
                              </div>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                        {each.spiceLevel && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginLeft: "10px",
                              marginTop: "5px",
                            }}
                          >
                            <span>Spice level</span>
                            <span>{each.spiceLevel}</span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                {props.data && props.data.note && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#6D6D6D",
                      }}
                    >
                      Note :
                    </p>
                    <span
                      style={{
                        fontStyle: "italic",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "18px",
                        color: "#6D6D6D",
                      }}
                    >
                      {props.data.note}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const handlePrintED = (props) => {
    var prtContent = document.getElementById(`printId${props.trackingNumber}`);
    if (!prtContent) return;
    var printWindow = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0",
    );
    printWindow.document.write(prtContent.innerHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const handlePrintStart = (props) => {
    var prtContent = document.getElementById(
      `startprintId${props.trackingNumber}`,
    );
    if (!prtContent) return;
    var printWindow = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0",
    );
    printWindow.document.write(prtContent.innerHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };
  return (
    <div className="tile">
      {data &&
        data.foods &&
        data.foods.length > 0 &&
        data.foods.map((each, i) => {
          return (
            <div key={i}>
              {i === 0 && (
                <>
                  <div className="d-flex justify-content-between  ">
                    <p className="tile_number">#{data.trackingNumber}</p>
                    <p>{formatTime(data.createdAt)}</p>
                  </div>
                  <Divider />

                  <div className="d-flex justify-content-between mt-3 mb-2">
                    <div>
                      <ForkKnife size={26} color="#8943EF" />
                      <b>
                        <span className="ml-1">Cutlery</span>
                      </b>
                    </div>
                    {data.needCutlery ? "Yes" : "No"}
                  </div>

                  {data.note ? (
                    <div
                      className="tile_note mb-2"
                      style={{ wordWrap: "break-word" }}
                    >
                      {data.note}
                    </div>
                  ) : null}
                </>
              )}

              <Button
                className="tile_each_btn"
                onClick={() => statusChangeEachItem(data, each)}
              >
                {/* item name */}
                <div
                  className={
                    each.isCooked
                      ? "tile_each_btn_title line_through_text"
                      : "tile_each_btn_title"
                  }
                >
                  <span>{each.name}</span>
                  <span>x{each.quantity}</span>
                </div>

                {/* modifiers */}
                <div
                  className={
                    each.modifiers && each.modifiers.length > 0
                      ? "p-2 d-flex flex-column align-items-start w-100"
                      : "align-items-start w-100"
                  }
                >
                  {each.modifiers &&
                    each.modifiers.length > 0 &&
                    each.modifiers.map((mod, ind) => {
                      return (
                        <p
                          key={ind}
                          className={
                            each.isCooked
                              ? "tile_mod_text line_through_text"
                              : "tile_mod_text"
                          }
                        >
                          {mod}
                        </p>
                      );
                    })}
                  {each.spiceLevel && (
                    <div
                      className={
                        each.isCooked
                          ? "tile_mod_spice_div line_through_text"
                          : "tile_mod_spice_div"
                      }
                    >
                      <span className="tile_mod_text">Spice Level</span>
                      <span className="tile_mod_text">{each.spiceLevel}</span>
                    </div>
                  )}
                </div>
              </Button>

              {i === data.foods.length - 1 && (
                <>
                  {data.status === "Queue" && (
                    <Button
                      className="start_cook_btn mt-2"
                      onClick={() => statusChange(data, "Cooking")}
                    >
                      <>
                        {loadInd === data.orderId ? (
                          <CircularProgress sx={{ color: "#fff" }} />
                        ) : (
                          "Start Cooking"
                        )}
                      </>
                    </Button>
                  )}
                  {data.status === "Cooking" && (
                    <Button
                      className="finish_cook_btn mt-2"
                      onClick={() => statusChange(data, "Finished")}
                    >
                      <>
                        {loadInd === data.orderId ? (
                          <CircularProgress sx={{ color: "#fff" }} />
                        ) : (
                          "Finish Cooking"
                        )}
                      </>
                    </Button>
                  )}
                  {/* {
                                        data.status === 'Cooking' &&
                                        < ComponentToPrint ref={componentRef} data={data} toPrint={toPrint} />} */}
                  {data.status === "Queue" && (
                    <StartCookingPrint
                      i={data.trackingNumber}
                      data={data}
                      toPrint={toPrint}
                    />
                  )}

                  {data.status === "Cooking" && (
                    <FinishCookingPrint
                      i={data.trackingNumber}
                      data={data}
                      toPrint={toPrint}
                    />
                  )}
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};

class Masonry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { columns: 1 };
    this.onResize = this.onResize.bind(this);
    this.gridRef = React.createRef();
  }
  componentDidMount() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  }

  getColumns(w) {
    return (
      this.props.brakePoints.reduceRight((p, c, i) => {
        return c < w ? p : i;
      }, this.props.brakePoints.length) + 1
    );
  }

  onResize() {
    const columns =
      this.gridRef.current && this.getColumns(this.gridRef.current.offsetWidth);
    if (columns !== this.state.columns) {
      this.setState({ columns: columns });
    }
  }

  mapChildren() {
    let col = [];
    const numC = this.state.columns;
    for (let i = 0; i < numC; i++) {
      col.push([]);
    }
    return this.props.children.reduce((p, c, i) => {
      p[i % numC].push(c);
      return p;
    }, col);
  }

  render() {
    return (
      <div
        className="masonry"
        ref={this.gridRef}
        // ref="Masonry"
      >
        {this.mapChildren().map((col, ci) => {
          return (
            <div className="column" key={ci}>
              {col.map((child, i) => {
                return <div key={i}>{child}</div>;
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

// const ComponentToPrint = React.forwardRef((props, ref) => {
//     return (
//         <div ref={ref} className='printNeo'>
//             <div className='printNeoBody d-flex flex-column'>
//                 <div className='d-flex flex-column align-items-center'>
//                     <span style={{ fontSize: '0.7in' }}>NOVA FOODS PVT. LTD</span>
//                     <span style={{ fontSize: '0.45in' }}>SUKEDHARA, KATHMANDU</span>
//                     <span style={{ fontSize: '0.45in' }}>PAN NO. 610284707</span>
//                     <span style={{ fontSize: '0.45in' }}>ABBREVIATED TAX INVOICE</span>
//                 </div>
//                 <div className='d-flex justify-content-between mt-5'>
//                     <span>Bill To : Anna Sharma</span>
//                     <span>10:23 AM</span>
//                 </div>
//                 <span>Payment Method : Credit Card</span>
//                 <div className='dividerNeo' />
//                 <div>
//                     {
//                         props && props.data && props.data.foods &&
//                         props.data.foods.length > 0 &&
//                         props.data.foods.map((each, i) => {
//                             return (
//                                 <div key={i} className='d-flex'>
//                                     <span>{each.quantity}</span>
//                                     <span className='ml-4'>{each.name}</span>
//                                     <span className='ml-auto neoPrice bg-info'>Rs.{each.price}</span>
//                                 </div>
//                             )
//                         })
//                     }
//                 </div>
//                 <div className='dividerNeo' />
//                 {props.data && props.data.payment && props.data.payment.subTotal &&
//                     <div className='d-flex justify-content-between'>
//                         <span>Subtotal</span>
//                         <span>Rs. {props.data.payment.subTotal}</span>
//                     </div>
//                 }
//                 {(props.data && props.data.payment && props.data.payment.discount
//                 ) ?
//                     <>
//                         {props.data.payment.discount > 0 && <div className='d-flex justify-content-between'>
//                             <span>Discount</span>
//                             <span>Rs. {props.data.payment.discount}</span>
//                         </div>}
//                     </>
//                     :
//                     null
//                 }
//                 {props.data &&
//                     props.data.payment
//                     && props.data.payment.taxes
//                     && props.data.payment.taxes.length > 0
//                     && props.data.payment.taxes.map((each, i) => (
//                         <div className='d-flex justify-content-between' key={i}>
//                             <span>{each.name}</span>
//                             <span>{each.type === 'value' && 'Rs.'} {each.amount} {each.type === 'percent' && '%'}</span>
//                         </div>
//                     ))
//                 }
//                 {props.data && props.data.payment && props.data.payment.total &&
//                     <div className='d-flex justify-content-between'>
//                         <span>Total</span>
//                         <span>Rs. {props.data.payment.total}</span>
//                     </div>
//                 }
//                 <div className='dividerNeo' />
//                 <div className='d-flex flex-column align-items-center'>
//                     <span style={{ fontSize: '70px' }}>THANK YOU!</span>
//                     <span style={{ fontSize: '70px' }}>SEE YOU AGAIN</span>
//                 </div>
//             </div>
//         </div>
//     );
// });
