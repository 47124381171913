import { Box, Button, Container, Grid } from "@mui/material";
import {
  FacebookLogo,
  InstagramLogo,
  TwitterLogo,
} from "@phosphor-icons/react";
import { useLocation, useNavigate } from "react-router-dom";
import { openInNewTab } from "../../Components/Common";
import { getS3Image } from "../../Components/s3";
import "../../css/Market/footer.css";

const locations = [
  "/",
  "/allOffers",
  "/allCombos",
  "/privacy",
  "/terms",
  "/whoweare",
  "/helpAndSupport",
  "/myAccount",
  "/notifications",
  "/delivery-charges",
];

export const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const openInNewTabFn = (url) => {
    openInNewTab(url);
  };
  // const handleOpenAppStore = () =>{
  //     const playStoreUrl = 'https://play.google.com/store/apps/details?id=studio.fantasticstory.developer.novaapp&pcampaignid=web_share';
  //     window.open(playStoreUrl, '_blank');
  //   };
  if (locations.includes(location.pathname)) {
    return (
      <div
        id="footerNova"
        className="footerMain"
        style={{ flexDirection: "column" }}
      >
        <div
          className="market_width footerMain_body"
          style={{ borderBottom: "1px solid #9D9CA3", paddingBottom: "50px" }}
        >
          <Grid container marginLeft={{ xs: "0", lg: "20px" }} spacing={4}>
            <Grid
              item
              xs={12}
              sm={6}
              lg={2}
              flexDirection="column"
              className="footer_each footer_each_first"
            >
              <Button className="footer_each_title">About NOVA</Button>
              <Button
                className="footer_each_title_btn"
                onClick={() => navigate("/whoweare")}
              >
                Who we are
              </Button>
              <Button
                className="footer_each_title_btn"
                onClick={() => navigate("/delivery-charges")}
              >
                Delivery Charges
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={2.5}
              flexDirection="column"
              className="footer_each"
            >
              <Button className="footer_each_title">Learn More</Button>
              <Button
                className="footer_each_title_btn"
                onClick={() => navigate("/terms")}
              >
                Terms & Conditions
              </Button>
              <Button
                className="footer_each_title_btn"
                onClick={() => navigate("/privacy")}
              >
                Privacy Policy
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={2.5}
              flexDirection="column"
              className="footer_each"
            >
              <Button className="footer_each_title">Get Help</Button>
              <Button
                className="footer_each_title_btn"
                onClick={() => navigate("/helpAndSupport")}
              >
                Help & Support
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={2.5}
              flexDirection="column"
              className="footer_each"
            >
              <Button className="footer_each_title">Contact Us</Button>
              <Button className="footer_each_title_btn">
                Bakhundole, Lalitpur
              </Button>
              <Button className="footer_each_title_btn">9863594648</Button>
              <div>
                <TwitterLogo
                  onClick={() =>
                    openInNewTabFn("https://twitter.com/novadelivers")
                  }
                  size={28}
                  color="#9D9CA3"
                  weight="fill"
                  className="mr-2"
                />
                <FacebookLogo
                  onClick={() => {
                    openInNewTabFn(
                      "https://www.facebook.com/profile.php?id=100086008584076",
                    );
                  }}
                  size={28}
                  color="#9D9CA3"
                  weight="fill"
                  className="mr-2"
                />
                <InstagramLogo
                  onClick={() =>
                    openInNewTabFn("https://www.instagram.com/novadelivers/")
                  }
                  size={28}
                  color="#9D9CA3"
                  weight="fill"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={2.5}
              flexDirection="column"
              className="footer_each"
            >
              <p className="footer_each_title mt-3">We accept</p>
              <div className="flex gap-2">
                <img
                  src={getS3Image("fonepay.svg")}
                  alt=""
                  style={{ marginBottom: "12px", width: "103px" }}
                />
                <img
                  src={getS3Image("cod.png")}
                  alt=""
                  style={{
                    width: "75px",
                    marginLeft: "12px",
                    marginRight: "12px",
                    marginBottom: "12px",
                  }}
                />
              </div>

              <p className="footer_each_title mt-3">Download App</p>
              <div className="flex gap-2">
                <img
                  src={getS3Image("google_play.svg")}
                  onClick={(e) =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=studio.fantasticstory.developer.novaapp",
                      "_blank",
                    )
                  }
                  alt=""
                  style={{ marginBottom: "12px", width: "103px" }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            marginLeft={{ xs: "0", lg: "20px" }}
            gap={4}
            style={{ alignItems: "center" }}
          >
            <span className="footer_each_title">Service Hour</span>
            <span className="footer_each_title_btn">
              12:00 PM to 4:00 AM (NST)
            </span>
          </Grid>
        </div>
        <div>
          <Container
            container
            sx={{
              mt: "24px",
              display: "flex",
            }}
          >
            <Box
              justifyContent={{ xs: "center", md: "flex-end" }}
              className="d-flex w-100"
            >
              <span className="c7 s1">
                2024 Nova Delivers. All Rights Reserved.
              </span>
            </Box>
          </Container>
        </div>
      </div>
    );
  }
};
