import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import { MagnifyingGlass } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { POSTAPI_ADMIN } from "../../Auth/Index";
import { AlertError } from "../../Components/Common";
import { DraggableCards } from "../../Components/DragCards";

function AdminApp() {
  const [values, setValues] = useState({
    data: [],
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    loadingSection: "",
    loadingId: null,
    loadingClearRemove: false,
  });
  const { data, first, errorPosition, errorText } = values;

  useEffect(() => {
    if (first) {
      POSTAPI_ADMIN("/homepage/admin/mobile").then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            data: res,
            first: false,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first]);

  return (
    <div>
      <div className="d-flex flex-column" style={{ gap: "26px" }}>
        {AllCategories(
          data.allCategories ?? [],
          data.categories ?? [],
          values,
          setValues,
        )}
        {FeaturedCategories(
          data.allCategories ?? [],
          data.featuredCategories ?? [],
          values,
          setValues,
        )}
        {FeaturedItems(
          data.allItems ?? [],
          data.specialItems?.items ?? [],
          data.specialItems?.name,
          values,
          setValues,
        )}
      </div>
      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
}

export default AdminApp;

// left side, all categories section
//add one to selected side
const handleUpdateAddCategory = (dataSelected, each, setValues, varName) => {
  setValues((x) => ({ ...x, loadingSection: varName, loadingId: each._id }));
  dataSelected = dataSelected.map((x) => x.categoryId ?? x.itemId);
  dataSelected.unshift(each._id);
  var send = {};
  if (varName === "categories" || varName === "featuredCategories") {
    send = {
      [varName]: dataSelected,
    };
  } else {
    send = {
      specialItems: {
        name: varName,
        items: dataSelected,
      },
    };
  }
  POSTAPI_ADMIN("/homepage/mobile/edit", send).then((res) => {
    if (res.success) {
      setValues((prev) => ({
        ...prev,
        data: res,
        first: false,
        loading: false,
        loadingSection: "",
        loadingId: null,
      }));
    } else if (res.error)
      setValues((prev) => ({
        ...prev,
        errorPosition: "apiErr",
        first: false,
        errorText: res.error,
        loading: false,
        loadingSection: "",
        loadingId: null,
      }));
  });
};

// handle remove or clear from right side
const updateAllCategories = (updated, setValues, varName) => {
  var send = {};
  if (varName === "categories" || varName === "featuredCategories") {
    send = {
      [varName]: updated,
    };
  } else {
    send = {
      specialItems: {
        name: varName,
        items: updated,
      },
    };
  }
  POSTAPI_ADMIN("/homepage/mobile/edit", send).then((res) => {
    if (res.success) {
      setValues((prev) => ({
        ...prev,
        data: res,
        first: false,
        loading: false,
        loadingSection: "",
        loadingClearRemove: false,
      }));
      return true;
    } else if (res.error)
      setValues((prev) => ({
        ...prev,
        errorPosition: "apiErr",
        errorText: res.error,
        loadingSection: "",
        loadingClearRemove: false,
      }));
    return false;
  });
};

// first section
const AllCategories = (data, dataSelected, values, setValues) => {
  const allCategoriesFiltered = data.filter(
    (obj) =>
      !dataSelected.some((filterObj) => filterObj.categoryId === obj._id),
  );
  return CategoriesData(
    allCategoriesFiltered,
    dataSelected,
    "Categories",
    "categories",
    values,
    setValues,
  );
};

// second section
const FeaturedCategories = (data, dataSelected, values, setValues) => {
  const featuredCategoriesFiltered = data.filter(
    (obj) =>
      !dataSelected.some((filterObj) => filterObj.categoryId === obj._id),
  );
  return CategoriesData(
    featuredCategoriesFiltered,
    dataSelected,
    "Featured Categories",
    "featuredCategories",
    values,
    setValues,
  );
};

const CategoriesData = (
  data,
  dataSelected,
  title1,
  varNameToUpdate,
  values,
  setValues,
) => {
  const { loadingSection, loadingId } = values;
  return (
    <div className="d-flex flex-column" style={{ gap: "16px" }}>
      <span className="s2 w3">{title1}</span>
      <div className="d-flex" style={{ borderRadius: "12px", width: "90%" }}>
        {/* left */}
        <Box
          className="d-flex flex-column bg-white col"
          gap={"16px"}
          padding={"24px 24px 0 24px"}
          borderRight={"1px solid #E9ECFF"}
        >
          <span className="s2 c3">All Categories</span>
          <Box className="d-flex flex-column" gap={"8px"}>
            <Paper
              component="form"
              sx={{
                p: "0px 16px",
                display: "flex",
                border: "1px solid #E9ECFF",
                borderRadius: "25px",
                alignItems: "center",
                width: "100%",
                boxShadow: "none",
                height: "40px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <MagnifyingGlass />
              </IconButton>
            </Paper>
            {/* list */}
            <div
              className="d-flex flex-column"
              style={{
                height: "280px",
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
            >
              {data?.map((each, i) => (
                <Button
                  key={"admin_app_categories_" + i}
                  className="d-flex justify-content-start c3 s-12"
                  style={{
                    gap: "12px",
                    padding: "12px",
                    borderBottom: "1px solid #E9ECFF",
                  }}
                  onClick={(e) =>
                    handleUpdateAddCategory(
                      dataSelected,
                      each,
                      setValues,
                      varNameToUpdate,
                    )
                  }
                >
                  <Avatar src={each.image} />
                  <span>{each.name}</span>
                  {loadingSection === varNameToUpdate &&
                    loadingId === each._id && (
                      <CircularProgress size={16} className="c1 ml-auto" />
                    )}
                </Button>
              ))}
            </div>
          </Box>
        </Box>
        {/* right */}
        {DraggableCards(
          dataSelected,
          updateAllCategories,
          varNameToUpdate,
          values,
          setValues,
        )}
      </div>
    </div>
  );
};

const FeaturedItems = (
  data,
  dataSelected,
  varNameToUpdate,
  values,
  setValues,
) => {
  const { loadingSection, loadingId } = values;
  const allItemsFiltered = data.filter(
    (obj) => !dataSelected.some((filterObj) => filterObj.itemId === obj._id),
  );
  return (
    <div className="d-flex flex-column" style={{ gap: "16px" }}>
      <span className="s2 w3">{varNameToUpdate}</span>
      <div className="d-flex" style={{ borderRadius: "12px", width: "90%" }}>
        {/* left */}
        <Box
          className="d-flex flex-column bg-white col"
          gap={"16px"}
          padding={"24px 24px 0 24px"}
          borderRight={"1px solid #E9ECFF"}
        >
          <span className="s2 c3">All Items</span>
          <Box className="d-flex flex-column" gap={"8px"}>
            <Paper
              component="form"
              sx={{
                p: "0px 16px",
                display: "flex",
                border: "1px solid #E9ECFF",
                borderRadius: "25px",
                alignItems: "center",
                width: "100%",
                boxShadow: "none",
                height: "40px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <MagnifyingGlass />
              </IconButton>
            </Paper>
            {/* list */}
            <div
              className="d-flex flex-column"
              style={{
                height: "280px",
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
            >
              {allItemsFiltered?.map((each, i) => (
                <Button
                  key={"admin_app_categories_" + i}
                  className="d-flex justify-content-start c3 s-12"
                  style={{
                    gap: "12px",
                    padding: "12px",
                    borderBottom: "1px solid #E9ECFF",
                  }}
                  onClick={(e) =>
                    handleUpdateAddCategory(
                      dataSelected,
                      each,
                      setValues,
                      varNameToUpdate,
                    )
                  }
                >
                  <Avatar src={each.image} />
                  <span>{each.name}</span>
                  {loadingSection === varNameToUpdate &&
                    loadingId === each._id && (
                      <CircularProgress size={16} className="c1 ml-auto" />
                    )}
                </Button>
              ))}
            </div>
          </Box>
        </Box>
        {/* right */}
        {DraggableCards(
          dataSelected,
          updateAllCategories,
          varNameToUpdate,
          values,
          setValues,
        )}

        {/* <Box className='d-flex flex-column bg-white col'
                    gap={'16px'} padding={'24px 24px 0 24px'} borderRight={'1px solid #E9ECFF'}>
                    <div className='d-flex justify-content-between'>
                        <span className='s2 c3'>Selected Items</span>
                        <Button className='s-12 c7'>Clear all</Button>
                    </div>
                    <div className='d-flex flex-column'
                        style={{ height: '280px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                        {
                            dataSelected?.items?.map((each, i) => (
                                <Button key={'admin_app_categories_' + i}
                                    className='d-flex justify-content-start c3 s-12'
                                    style={{ gap: '12px', padding: '12px', borderBottom: '1px solid #E9ECFF' }}
                                >
                                    <Avatar src={each.image} />
                                    <span>{each.name}</span>
                                </Button>
                            ))
                        }
                    </div>
                </Box> */}
      </div>
    </div>
  );
};
