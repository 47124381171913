import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  MenuItem,
  Menu as NotifMenu,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import {
  Bell,
  BellZ,
  CaretDown,
  Package,
  ShoppingBag,
} from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { API } from "../../Auth/Config";
import {
  LogoutCsrAdmin,
  POSTAPI_CSR_ADMIN,
  isAuthenticatedCsrAdmin,
} from "../../Auth/Index";
import { formatDateTimeNext } from "../../Components/Common";
import { AlertErrorCsr } from "../../Components/CommonCsr";
import ting from "../../Components/Ting.mp3";
import { getS3Image } from "../../Components/s3";
import { ENTITY_TYPE } from "../../Components/staticData";
import "../../css/Admin/admin_layout.css";

const theme = createTheme({
  root: {
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      outline: `1px solid slategrey`,
    },
  },
  typography: {
    button: {
      textTransform: "none !important",
    },
  },
});

export const SvgButtonCSR = ({ text }) => {
  return (
    <svg width="164" height="40" viewBox="0 0 164 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 1V0.5H0V1H0.5ZM163.5 1H164V0.5H163.5V1ZM163.5 21.8219L163.903 22.1185L164 21.9862V21.8219H163.5ZM150.844 39V39.5H151.096L151.246 39.2966L150.844 39ZM0.5 39H0V39.5H0.5V39ZM0.5 21.7733V21.2733H0V21.7733H0.5ZM10.5809 21.7733V22.2733H11.0809V21.7733H10.5809ZM10.5809 19.24H11.0809V18.74H10.5809V19.24ZM0.5 19.24H0V19.74H0.5V19.24ZM134.735 0.5H0.5V1.5H134.735V0.5ZM163.5 0.5H134.735V1.5H163.5V0.5ZM164 21.8219V1H163V21.8219H164ZM151.246 39.2966L163.903 22.1185L163.097 21.5253L150.441 38.7034L151.246 39.2966ZM134.735 39.5H150.844V38.5H134.735V39.5ZM0.5 39.5H134.735V38.5H0.5V39.5ZM0 21.7733V39H1V21.7733H0ZM10.5809 21.2733H0.5V22.2733H10.5809V21.2733ZM10.0809 19.24V21.7733H11.0809V19.24H10.0809ZM0.5 19.74H10.5809V18.74H0.5V19.74ZM0 1V19.24H1V1H0Z" fill="#00F0FF"/>
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="16" fill="#00F0FF">
        {text}
      </text>
    </svg>
  );
};

const CsrLayout = () => {
  const navigate = useNavigate();
  const [audio] = useState(new Audio(ting));

  const userId =
    isAuthenticatedCsrAdmin() && isAuthenticatedCsrAdmin().employeeId
      ? isAuthenticatedCsrAdmin().employeeId
      : null;
  const name =
    isAuthenticatedCsrAdmin() && isAuthenticatedCsrAdmin().name
      ? isAuthenticatedCsrAdmin().name
      : null;
  const image =
    isAuthenticatedCsrAdmin() && isAuthenticatedCsrAdmin().image
      ? isAuthenticatedCsrAdmin().image
      : null;
  const [values, setValues] = useState({
    socket: null,
    notfFirst: true,
    first: true,
    newOrder: null,
    anchorElUser: null,
    unreadCount: null,
    anchorElNotif: null,
    errorPosition: "",
    errorText: "",
    socketFirst: true,
  });
  const {
    socket,
    notfFirst,
    first,
    newOrder,
    anchorElUser,
    unreadCount,
    anchorElNotif,
    errorPosition,
    errorText,
    socketFirst,
  } = values;
  const [notifications, setNotifications] = useState([]);
  const openUser = Boolean(anchorElUser);
  const openNotif = Boolean(anchorElNotif);

  // API call for notifications
  useEffect(() => {
    if (notfFirst)
      POSTAPI_CSR_ADMIN("/notification/systemUser", { userId }).then((res) => {
        if (res.success) {
          setValues((x) => ({
            ...x,
            unreadCount: res.unReadCount,
            notfFirst: false,
          }));
        }
        if (res.error)
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: res.error,
            notfFirst: false,
          }));
      });
  }, [userId, notfFirst]);

  // if not user, navigate to login
  useEffect(() => {
    if (!userId) navigate("/csr/login");
  }, [userId, navigate]);
  // socket
  useEffect(() => {
    if (!socket && userId && socketFirst) {
      var soc = io(API, {
        reconnection: true,
        reconnectionAttempts: Infinity,
      });
      setValues((x) => ({
        ...x,
        socket: soc,
        socketFirst: false,
      }));
      soc.emit("onConnection", {
        userId,
      });
      soc.on("onConnectionResponse", (res) => {});
    }
    if (socket) {
      socket.on("notification", (res) => {
        setValues((x) => ({
          ...x,
          newOrder: res.newOrder,
          unreadCount: res.unReadCount,
        }));
        audio.play();
      });

      socket.on("disconnect", function (res) {
        setValues((x) => ({ ...x, socket: null }));
      });
    }
  }, [userId, socket, first, audio, unreadCount, socketFirst]);

  const handleClick = (name, event) => {
    setValues((x) => ({ ...x, [name]: event.currentTarget }));
  };
  // read notification
  const handleClickNotif = (name, event) => {
    setValues((x) => ({ ...x, anchorElNotif: event.currentTarget }));
    POSTAPI_CSR_ADMIN("/notification/read", { employeeId: userId }).then(
      (res) => {
        if (res.success) {
          setValues((x) => ({ ...x, unreadCount: 0 }));
          setNotifications(res.notifications);
        }
      },
    );
  };
  const handleClose = (name) => {
    setValues((x) => ({ ...x, [name]: null }));
  };
  const handleMenuItem = (item) => {
    setValues((x) => ({ ...x, anchorElNotif: null }));
    switch (item.entity) {
      case ENTITY_TYPE.ORDER:
        POSTAPI_CSR_ADMIN("/order/one", { orderId: item.entityId }).then(
          (resp) => {
            if (resp.success) {
              // var stateData = {
              //     ...resp.order,
              //     riders: resp.riders
              // }
              // navigate('/orders/created/view', { state: stateData })
            } else if (resp.error)
              setValues((x) => ({
                ...x,
                errorPosition: "apiErr",
                errorText: "Something went wrong!",
              }));
          },
        );
        break;
      default:
        break;
    }
  };

  const NotificationBody = (option) => {
    switch (option.entity) {
      case ENTITY_TYPE.ORDER:
        return (
          <div className="d-flex">
            <div style={{ width: "40px" }}>
              <ShoppingBag size={32} color="#8943EF" />
            </div>
            <div
              className="d-flex flex-column ml-2"
              style={{
                width: "calc(100% - 50px)",
                whiteSpace: "initial",
              }}
            >
              <span className="ad_notif_break">{option.body}</span>
              <span className="c7 mt-2">
                {formatDateTimeNext(option.createdAt)}
              </span>
            </div>
          </div>
        );
      case ENTITY_TYPE.INVENTORY:
        return (
          <div className="d-flex">
            <div style={{ width: "40px" }}>
              <Package size={32} color="#8943EF" weight="fill" />
            </div>
            <div
              className="d-flex flex-column ml-2"
              style={{
                width: "calc(100% - 50px)",
                whiteSpace: "initial",
              }}
            >
              <span className="ad_notif_break">{option.body}</span>
              <span className="c7 mt-2">{option.createdAt}</span>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const AdminNotification = () => (
    <div className="d-flex align-items-center">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={openNotif ? "long-menu" : undefined}
        aria-expanded={openNotif ? "true" : undefined}
        aria-haspopup="true"
        onClick={(e) => handleClickNotif("anchorElNotif", e)}
        sx={{
          "& .MuiBadge-badge": {
            color: "#FFFFFF",
            backgroundColor: "#8943EF ",
            width: "15px",
            height: "15px",
            fontSize: "9px",
          },
        }}
      >
        <Badge
          badgeContent={unreadCount}
          sx={{
            "& .MuiBadge-badge": {
              color: "#FFFFFF",
              backgroundColor: "#8943EF",
              border: "none",
            },
          }}
          size={10}
        >
          <Bell color="#FFFFFF" size={20} />
        </Badge>
      </IconButton>
      <NotifMenu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorElNotif}
        open={openNotif}
        onClose={() => handleClose("anchorElNotif")}
        PaperProps={{
          style: {
            maxHeight: "500px",
            width: "400px",
            scrollbarWidth: "thin",
            backgroundColor: "black",
            border: "1px solid #545356",
            borderRadius: "0px",
            color: "white",
          },
        }}
      >
        {notifications.length === 0 ? (
          <div className="ad_no_notif">
            <BellZ size={100} weight="fill" color="#8943EF" />
            <span className="mt-2 s2">No notifications yet</span>
          </div>
        ) : (
          <div className="d-flex align-items-center mt-3 justify-content-between">
            <p className="ml-3 w3 s3 csr_create_order_header_titles">
              Notifications
            </p>
            {/* <p
              className="mr-3 c9 w3 cursor_pointer"
              onClick={() => {
                handleClose("anchorElNotif");
                navigate("/admin/notifications", { state: notifications });
              }}
              style={{ color: "white" }}
            >
              View All
            </p> */}
          </div>
        )}
        {notifications.map((option, ind) => (
          <MenuItem
            key={ind}
            // onClick={() => handleClose('anchorEl')}>
            onClick={() => handleMenuItem(option)}
          >
            <div className="notifi_each_csr">{NotificationBody(option)}</div>
          </MenuItem>
        ))}
      </NotifMenu>
    </div>
  );

  const Appbar_Comp = () => (
    <AppBar className="csr_layout_appbar">
      <div className="csr_header_logo">
        <img src={getS3Image("csrLogo.svg")} alt="logo" className="logo" />
      </div>
      <div className="csr_header_moto">
        <span>管制センター</span>
      </div>

      <div className="csr_layout_appbar_div">
        {AdminNotification()}

        <div>
          <Button
            className="avatar_name"
            aria-label="user"
            id="user-button"
            aria-controls={openUser ? "user-menu" : undefined}
            aria-expanded={openUser ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => handleClick("anchorElUser", e)}
            sx={{
              minWidth: "10rem",
              width: "fit-content",
              height: "2.25rem",
              display: "flex",
              justifyContent: " center",
              alignItems: "center",
              gap: "1rem",
              padding: "0px",
            }}
          >
            <Avatar
              src={image}
              sx={{ width: "2.25rem", height: "2.25rem" }}
              variant="square"
            />
            <span className="csr_user">
              <p
                style={{
                  marginRight: "8px",
                }}
              >
                {name}
              </p>
              <CaretDown size={16} />
            </span>
          </Button>
          <NotifMenu
            id="user-menu"
            MenuListProps={{
              "aria-labelledby": "user-button",
            }}
            anchorEl={anchorElUser}
            open={openUser}
            onClose={() => handleClose("anchorElUser")}
            PaperProps={{
              style: {
                width: "200px",
                backgroundColor: "black",
                border: "1px solid #545356",
                color: "white",
                borderRadius: "0px",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                LogoutCsrAdmin();
                navigate("/csr/login");
              }}
              sx={{ fontFamily: "Blender Pro thin", fontWeight: "400" }}
            >
              Logout
            </MenuItem>
          </NotifMenu>
        </div>
      </div>
    </AppBar>
  );

  const Body = () => (
    <Box className="csr_body">
      {userId && <Outlet context={[newOrder, setValues]} />}
    </Box>
  );

  return (
    <Box className="csr_layout_main">
      <ThemeProvider theme={theme}>
        {Appbar_Comp()}
        {Body()}
        {AlertErrorCsr(errorPosition, errorText, setValues)}
      </ThemeProvider>
    </Box>
  );
};

export default CsrLayout;
