// import MUIDataTable from 'mui-datatables'
import { Avatar, IconButton } from "@mui/material";
import { MapPin, X } from "@phosphor-icons/react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetAPIAdmin } from "../../../../Auth/Index";
import {
  AlertError,
  DataGridNova,
  convertMinutesToHoursAndMinutes,
  formatDateNext,
  formatTime,
} from "../../../../Components/Common";
import {
  ad_dashFrom,
  ad_dashTo,
  setDashDate,
} from "../../../../slices/novaSlice";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { RangePicker } = DatePicker;

export const CookingStats = () => {
  const dispatch = useDispatch();
  const dateFormat = "MMMM D, YYYY h:mm A";
  const { id } = useParams();
  const [values, setValues] = useState({
    from: useSelector(ad_dashFrom),
    to: useSelector(ad_dashTo),
    data: [],
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    pageSize: 10,
    searchText: "",
    order: null,
    openOrder: false,
  });
  const {
    from,
    to,
    data,
    first,
    loading,
    errorPosition,
    errorText,
    pageSize,
    order,
    openOrder,
  } = values;
  const query = `from=${new Date(from).toISOString()}&to=${new Date(to).toISOString()}`;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/delivery/admin/dashboard/kitchen?" + query).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            data: res.stats,
            first: false,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first, id, query]);

  const columnsData = [
    {
      field: "trackingNumber",
      headerName: "Order ID",
      flex: 200,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center overflow-hidden">
            <span className="ml-3">{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "statusInfo",
      headerName: "Order Date",
      width: 150,
      renderCell: (params) => {
        const started = params?.row?.statusInfo?.find(
          (x) => x.status === "Draft",
        );
        if (!started) return "-";
        return <span>{formatDateNext(started?.changedAt)}</span>;
      },
    },
    {
      field: "statusInfos",
      headerName: "Order Time",
      width: 150,
      renderCell: (params) => {
        const started = params?.row?.statusInfo?.find(
          (x) => x.status === "Draft",
        );
        if (!started) return "-";
        return <span>{formatTime(started?.changedAt)}</span>;
      },
    },
    {
      field: "offer",
      headerName: "Cooking Start",
      width: 200,
      renderCell: (params) => {
        const started = params?.row?.statusInfo?.find(
          (x) => x.status === "Cooking",
        );
        if (!started) return "-";
        return <span>{formatTime(started?.changedAt)}</span>;
      },
    },
    {
      field: "needCutlery",
      headerName: "Cooking End",
      width: 200,
      renderCell: (params) => {
        const completed = params?.row?.statusInfo?.find(
          (x) => x.status === "Not Assigned",
        );
        if (!completed) return "-";
        return <span>{formatTime(completed?.changedAt)}</span>;
      },
    },
    {
      field: "cookingTime",
      headerName: "Cooking Time",
      width: 150,
      renderCell: (params) => {
        return (
          <span className="d-flex justify-content-center">
            {convertMinutesToHoursAndMinutes(params.value)}
          </span>
        );
      },
    },
  ];

  const handleRowClick = (item) => {
    setValues((x) => ({ ...x, order: item, openOrder: true }));
  };

  const onChange = (date) => {
    setValues((x) => ({
      ...x,
      from: date[0],
      to: date[1],
      first: true,
      loading: true,
    }));
    dispatch(
      setDashDate({
        from: date[0],
        to: date[1],
      }),
    );
  };

  return (
    <div className="category_body">
      <div className="d-flex justify-content-between items-align-center mb-4">
        <RangePicker
          showTime={{ format: "h:mm A" }}
          format="YYYY-MM-DD h:mm A"
          style={{ width: "400px" }}
          className="ml-auto"
          size="large"
          value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
          onChange={onChange}
        />
      </div>

      <div style={{ height: "65vh", width: "950px" }}>
        <DataGridNova
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={data ?? []}
          columns={columnsData}
          loading={loading}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
          getRowClassName={(params) => `super-app-theme--${params.row.status}`}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
      {OrderDetail(order, openOrder, setValues)}
    </div>
  );
};

const OrderDetail = (order, openOrder, setValues) => {
  const orderedAt = order?.statusInfo?.find((x) => x.status === "Draft");
  const started = order?.statusInfo?.find((x) => x.status === "Cooking");
  const completed = order?.statusInfo?.find((x) => x.status === "Not Assigned");

  const divRef = useRef(null);

  useEffect(() => {
    // Function to handle click event
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setValues((x) => ({ ...x, openOrder: false }));
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef, setValues]);
  if (!openOrder) return;
  return (
    <div
      ref={divRef}
      className="d-flex flex-column position-fixed bg-white"
      style={{
        overflowY: "auto",
        width: "376px",
        height: "calc(100vh - 90px)",
        right: 0,
        padding: "24px",
      }}
    >
      <div
        className="d-flex flex-column position-relative w-full"
        style={{ gap: "24px" }}
      >
        {/* header */}
        <div className="d-flex justify-content-between align-items-center">
          <span className="s5 w3">ORD-{order?.trackingNumber}</span>
          <IconButton
            onClick={(e) => setValues((x) => ({ ...x, openOrder: false }))}
          >
            <X />
          </IconButton>
        </div>
        {/* body */}
        <div className="d-flex flex-column s-12 c7" style={{ gap: "12px" }}>
          <span>{formatDateNext(started?.changedAt)}</span>
          {/* user info */}
          <div
            className="d-flex flex-column bor"
            style={{ borderRadius: "5px", padding: "12px", gap: "8px" }}
          >
            <div className="s-12">
              Ordered at:{" "}
              <span className="w3">{formatTime(orderedAt?.changedAt)}</span>
            </div>
            <div className="d-flex gap-2">
              <Avatar src={order?.userInfo?.image} />
              <div className="d-flex flex-column">
                <span className="c3 s1">{order?.userInfo?.name}</span>
                <span className="s-12">{order?.userInfo?.phone}</span>
              </div>
            </div>
            {order?.delivery?.location?.address && (
              <div className="d-flex gap-1 align-items-center c3 s1">
                <MapPin />
                <span>{order?.delivery?.location?.address}</span>
              </div>
            )}
            <div className="d-flex justify-content-between">
              {started?.changedAt && (
                <div className="s-12">
                  Cooking Start:{" "}
                  <span className="w3">{formatTime(started?.changedAt)}</span>
                </div>
              )}
              {completed?.changedAt && (
                <div className="s-12">
                  Cooking End:{" "}
                  <span className="w3">{formatTime(completed?.changedAt)}</span>
                </div>
              )}
            </div>
          </div>
          {/* items info */}
          <div className="d-flex flex-column s2 c3 w3" style={{ gap: "8px" }}>
            <span>Items</span>
            {order?.foods?.map((food) => (
              <div className="d-flex" style={{ gap: "12px" }}>
                <div
                  style={{
                    width: "68px",
                    height: "68px",
                    backgroundImage: `url(${food?.image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    borderRadius: "5px",
                    backgroundColor: !food.image && "#F2F2F2",
                  }}
                ></div>
                <div className="d-flex flex-column" style={{ gap: "8px" }}>
                  <span className="c3 s2 w1">{food?.name}</span>
                  <span className="s-2 w3">Rs. {food?.price}</span>
                </div>
              </div>
            ))}
          </div>
          {/* bill info */}
          <div
            className="d-flex flex-column"
            style={{
              border: "1px, 0px, 0px, 0px",
              gap: "8px",
            }}
          >
            <span className="s2 c3 w3">Bill Details</span>
            {order?.payment?.subTotal > 0 && (
              <div className="d-flex justify-content-between">
                <span className="c-secondary s1">Subtotal</span>
                <span className="c3 s1">Rs. {order?.payment?.subTotal}</span>
              </div>
            )}
            {order?.payment?.promoDiscount > 0 && (
              <div className="d-flex justify-content-between">
                <span className="c-secondary s1">Promocode</span>
                <span className="c3 s1">
                  Rs. {order?.payment?.promoDiscount}
                </span>
              </div>
            )}
            {order?.payment?.deliveryCharge > 0 && (
              <div className="d-flex justify-content-between">
                <span className="c-secondary s1">Delivery fee</span>
                <span className="c3 s1">
                  Rs. {order?.payment?.deliveryCharge}
                </span>
              </div>
            )}
            {order?.payment?.tipAmount > 0 && (
              <div className="d-flex justify-content-between">
                <span className="c-secondary s1">Rider Tip</span>
                <span className="c3 s1">Rs. {order?.payment?.tipAmount}</span>
              </div>
            )}
            {order?.payment?.total > 0 && (
              <div className="d-flex justify-content-between">
                <span className="c-secondary s1">Total</span>
                <span className="c3 s1">Rs. {order?.payment?.total}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
