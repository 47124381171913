import { DeleteUser } from "./Index";

export const handleUserRemove = async (
  setValuesFn,
  navigate,
  setNoOfItemsFn,
  setValues,
) => {
  try {
    setValues((x) => ({ ...x, isLoadingRemoveUser: true }));
    await DeleteUser("/user").then((res) => {
      if (res.error) {
        setValues((x) => ({ ...x, isLoadingRemoveUser: false }));
        return;
      } else if (res.success) {
        setValuesFn((prev) => ({
          ...prev,
          errorPosition: "success",
          errorText: "Your account has been deleted.",
          userId: null,
          data: { ...prev.data, pastOrders: [] },
          unreadCount: 0,
        }));
        setNoOfItemsFn(0);
        navigate("/");
      }
    });
  } catch (error) {
    console.log(error);
  }
};
