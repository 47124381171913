import React, { useEffect, useState } from 'react';
import { Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, payload, name, value } = props;
  const fill = pie_colors.find(x => x.name === name)?.fill ?? '#000';
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={pie_colors.find(x => x.name === name)?.fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${name}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${value}`}
      </text>
    </g>
  );
};


export const pie_colors = [
  { name: 'Admin',  fill: '#b9b9b9' },
  { name: 'App', fill: '#8943ef' },
  { name: 'Day Sales', fill: '#3ed' },
  { name: 'Night Sales', fill: '#000' },
  { name: 'Website', fill: '#e7302a' },
]

const PieChartComp = (props) => {
  const {active, data} = props
  const [activeIndex, setActiveIndex] = useState(active ?? 0);
  const testMeasurements = [
    {
      data: data?.map(x => ({ ...x,
        value : x.numberOfOrders,
        fill : pie_colors.find(y=>y.name===x.name)?.fill ?? '#fff' })) 
      ?? []
    },
  ];

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  useEffect(()=>{
    setActiveIndex(active)
  },[active])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        {testMeasurements.map((s, i) => (
          <Pie
            key={i}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            dataKey="value"
            isAnimationActive={false}
            data={s.data}
            cx="50%"
            cy="50%"
            outerRadius={60}
            innerRadius={45}
            fill="#fff"
            onMouseEnter={onPieEnter}
          />
        ))}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComp;
