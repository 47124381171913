export function gtag_report_conversion(userId) {
  const callback = () => {
  };
  if(window.location.hostname==='localhost') return
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11244183706/uAn2CJW75LwZEJrB0vEp',
    'event_callback': callback
  });
}

export function purchase_gtag_report_conversion(data) {
  const callback = () => {
  };
  if(window.location.hostname==='localhost') return
  window.gtag('event', 'conversion', {
    'send_to': 'AW-11244183706/l4emCNCC_r0ZEJrB0vEp',
    'event_callback': callback,
    'transaction_id': data?.order?.trackingNumber,
  });
}

