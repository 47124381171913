import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const dateNow = new Date().getHours();

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    errorPosition: "",
    errorText: "",
    dashFrom:
      dateNow >= 12
        ? dayjs(new Date(new Date().getTime()).setHours(12, 0, 0, 0))
        : dayjs(
            new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(
              12,
              0,
              0,
              0,
            ),
          ),
    dashTo:
      dateNow >= 12
        ? dayjs(
            new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setHours(
              4,
              0,
              0,
              0,
            ),
          )
        : dayjs(new Date(new Date()).setHours(4, 0, 0, 0)),
      riderDetailsFrom:
      dateNow >= 12
        ? dayjs(new Date(new Date().getTime()).setHours(12, 0, 0, 0))
        : dayjs(
            new Date(new Date().getTime() - 24 * 60 * 60 * 1000).setHours(
              12,
              0,
              0,
              0,
            ),
          ),
      riderDetailsTo:
      dateNow >= 12
        ? dayjs(
            new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setHours(
              4,
              0,
              0,
              0,
            ),
          )
        : dayjs(new Date(new Date()).setHours(4, 0, 0, 0)),
    dashAttendanceFrom: dayjs().format('YYYY-MM-DD'),
    dashAttendanceTo: dayjs().format('YYYY-MM-DD'),
    dashOrigin : 'all',
  },
  reducers: {
    setError: (state, action) => {
      const { errorPosition, errorText } = action.payload;
      state.errorPosition = errorPosition;
      state.errorText = errorText;
    },
    clearError: (state, action) => {
      state.errorPosition = "";
      state.errorText = "";
    },
    setDashDate: (state, action) => {
      const { dashFrom, dashTo } = action.payload;
      state.dashFrom = dashFrom;
      state.dashTo = dashTo;
    },
    setRiderDetailsDate: (state, action) => {
      const { from, to } = action.payload;
      state.riderDetailsFrom = from;
      state.riderDetailsTo = to;
    },
    setDashAttendance: (state, action) => {
      const { from, to } = action.payload;
      state.dashAttendanceFrom = from;
      state.dashAttendanceTo = to;
    },
    setDashOrigin : (state, action) => {
      state.dashOrigin = action.payload.origin
    }
  },
});

export const {
  setError,
  clearError,
  setRiderDate,
  setCookingDate,
  setDashDate,
  setRiderDetailsDate,
  setDashAttendance,
  setDashOrigin
} = counterSlice.actions;

export const ad_errorPosition = (state) => state.counter.errorPosition;
export const ad_errorText = (state) => state.counter.errorText;
export const ad_dashFrom = (state) => state.counter.dashFrom;
export const ad_dashTo = (state) => state.counter.dashTo;
export const ad_dashAttendanceFrom = (state) => state.counter.dashAttendanceFrom;
export const ad_dashAttendanceTo = (state) => state.counter.dashAttendanceTo;
export const ad_riderDetailsFrom = (state) => state.counter.riderDetailsFrom;
export const ad_riderDetailsTo = (state) => state.counter.riderDetailsTo;
export const ad_dashOrigin = (state) => state.counter.dashOrigin;

export default counterSlice.reducer;
