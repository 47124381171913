import { Button, IconButton } from "@mui/material";
import { ArrowLeft } from "@phosphor-icons/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { POSTAPI } from "../../Auth/Index";
import {
  AlertError,
  FullBtnComp,
  InputComp,
  LabelComp,
} from "../../Components/Common";
import { isTextEmpty } from "../../Components/Validate_Admin";
import "../../css/Admin/admin_login.css";

export const AdminReset = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    errorPosition: "",
    errorText: "",
    loading: false,
    sent: false,
  });
  const { email, errorPosition, errorText, loading, sent } = values;

  const sendMail = () => {
    if (isTextEmpty(email)) {
      setValues((x) => ({
        ...x,
        loading: false,
        errorPosition: "apiErr",
        errorText: "Email is invalid",
      }));
      return;
    }
    setValues((x) => ({ ...x, loading: true }));
    POSTAPI("/employee/sendOTPForgotPassword", { email: email }).then(
      (resp) => {
        if (resp.success) {
          setValues((x) => ({ ...x, loading: false, sent: true }));
        } else if (resp.error)
          setValues((x) => ({
            ...x,
            loading: false,
            errorPosition: "apiErr",
            errorText: resp.error,
          }));
      },
    );
  };

  return (
    <div className="admin_reset">
      <div>
        <IconButton onClick={() => navigate("/admin/login")}>
          <ArrowLeft color="#2E2E2E" size={30} />
        </IconButton>

        {sent ? (
          <div className="admin_reset_child_sent">
            <p className="admin_reset_title">We have sent you a link</p>
            <p className="admin_reset_email_text">
              Link is sent to
              <span className="admin_reset_email"> {email}</span>
            </p>
            <Button className="admin_reset_btn">
              Didn't receive the link?
              <span
                className="admin_reset_btn_resend"
                onClick={() => sendMail()}
              >
                {" "}
                Resend
              </span>
            </Button>
          </div>
        ) : (
          <div className="admin_reset_child_send">
            <p className="enter_your_email">Enter your email</p>
            {LabelComp("Email", "email", errorPosition, errorText)}
            {InputComp(email, "email", setValues, "add_category_input")}
            {FullBtnComp("Submit", sendMail, loading)}
          </div>
        )}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};
// 1. enter your email. User enters email.
// 2. NOVA sends otp to the user in mail
// 3. Let user enter otp. checkTokenValidity. OTP validation api.
// 4. change forgotten password. let user enter new password
