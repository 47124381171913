import { useState } from "react";
import { Outlet } from "react-router-dom";
import { AlertError } from "../../Components/Common";

export const KDSLayout = () => {
  const [values, setValues] = useState({
    errorPosition: "",
    errorText: "",
  });
  const { errorPosition, errorText } = values;
  return (
    <>
      <Outlet context={[setValues]} />
      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};
