import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  createTheme,
  Grid,
  IconButton,
  Modal,
  ThemeProvider,
} from "@mui/material";
import {
  ArrowLeft,
  CaretDown,
  CurrencyDollar,
  MapPin,
  Medal,
  PencilSimple,
  ShoppingBag,
  X,
  ForkKnife,
  Phone,
} from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  AuthenticateNova,
  isAuthenticated,
  POSTAPI_formUser,
  POSTAPI_USER,
} from "../../Auth/Index";
import { handleUserRemove } from "../../Auth/RemoveUser";
import {
  AccountSubTotalDiv,
  AlertError,
  CircularProgressComp,
  EditDeleteMenu,
  formatDateTimeNext,
  FullBtnComp,
  HalfRightBtnComp,
  InputComp,
  LabelComp,
  modalStyles,
  statusButtonClass,
} from "../../Components/Common";
import { PAYMENT_METHODS } from "../../Components/staticData";
import { isTextEmpty, ValidateAddress } from "../../Components/Validate_Admin";
import "../../css/Market/myAccount.css";
import "../../css/Market/shippingPage.css";
import { MapBox } from "../MapBox";
import CustomizedSteppers from "./Order/Track";
import { Item } from "./ReactSlick";

const style = {
  position: "absolute",
  width: "400px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFFFFF",
  borderColor: "#FFF",
  boxShadow: 24,
  maxHeight: "95vh",
  overflowX: "hidden",
  overflowY: "auto",
  scrollbarWidth: "thin",
  borderRadius: "12px",
  padding: 3,
};

export const MyAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const outletContext = useOutletContext();
  const setValuesFn = outletContext ? outletContext[5] : null;
  const setNoOfItemsFn = outletContext ? outletContext[6] : null;
  const { userId, email, phone } = isAuthenticated();
  const [values, setValues] = useState({
    image: null,
    imageLoad: false,
    name: isAuthenticated().name,
    email: "",
    ad_loading: false,
    ad_pass_loading: false,
    oldPass: "",
    newPass: "",
    data: {},
    step:
      location.state && location.state.goToStep ? location.state.goToStep : 0,
    bi_step: 0,
    add_Address_modal: false,
    // address book
    add_id: "",
    add_title: "",
    add_name: "",
    add_phone: "",
    add_email: "",
    add_address: null,
    add_landmark: "",
    add_lat: null,
    add_lng: null,
    ab_loading: false,
    errorPosition: "",
    errorText: "",
    esewaID: "",
    khaltiID: "",
    payment_load: false,
    first: true,
    loading: true,
    // map
    alt: "",
    str: "",
    initMapCenter: [27.7172, 85.324],
    mapCenter: [27.7172, 85.324],
    conDialog: false,
    cancelId: "",
    confirmRemove: false,
    isLoadingRemoveUser: false,
  });
  const {
    image,
    imageLoad,
    name,
    //  email,
    ad_loading,
    data,
    step,
    //  bi_step,
    add_Address_modal,
    add_id,
    add_title,
    add_name,
    add_phone,
    add_lat,
    add_lng,
    add_landmark,
    //  add_email,
    add_address,
    ab_loading,
    errorPosition,
    errorText,
    first,
    loading,
    // esewaID,
    // khaltiID,
    // payment_load,
    // map
    // alt, str,
    // mapCenter,
    initMapCenter,
    conDialog,
    cancelId,
    confirmRemove,
    isLoadingRemoveUser,
  } = values;
  const [add_edit_visible_ind, Set_add_edit_visible] = useState(false);

  useEffect(() => {
    if (first)
      POSTAPI_USER("/user/info", { userId }).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            first: false,
            loading: false,
            data: res.data,
            esewaID:
              res.data.paymentInfo &&
              res.data.paymentInfo.length > 0 &&
              res.data.paymentInfo.filter(
                (x) => x.name === PAYMENT_METHODS.ESEWA,
              )[0]
                ? res.data.paymentInfo.filter(
                    (x) => x.name === PAYMENT_METHODS.ESEWA,
                  )[0].id
                : "",
            khaltiID:
              res.data.paymentInfo &&
              res.data.paymentInfo.length > 0 &&
              res.data.paymentInfo.filter(
                (x) => x.name === PAYMENT_METHODS.KHALTI,
              )[0]
                ? res.data.paymentInfo.filter(
                    (x) => x.name === PAYMENT_METHODS.KHALTI,
                  )[0].id
                : "",
            image: res.data.image,
          }));
        }
      });
  }, [userId, first]);

  // for name and email
  const updateDetails = () => {
    if (isTextEmpty(name)) {
      setValues((x) => ({
        ...x,
        errorPosition: "name",
        errorText: "Invalid name",
      }));
      return;
    }
    setValues((x) => ({
      ...x,
      errorPosition: "",
      errorText: "",
      ad_loading: true,
    }));
    POSTAPI_USER("/user/info/update", { userId, name }).then((resp) => {
      if (resp.success) {
        setValues((x) => ({
          ...x,
          ad_loading: false,
          errorPosition: "success",
          errorText: "Successfully updated user info!",
        }));
        var nowAuth = isAuthenticated();
        nowAuth.name = name;
        AuthenticateNova(nowAuth);
      }
    });
  };

  // for image
  const updateDetailsImage = (e) => {
    if (imageLoad) return;
    setValues((x) => ({ ...x, imageLoad: true }));
    var formData = new FormData();
    formData.append("userId", userId);
    formData.append("image", e.target.files[0]);
    POSTAPI_formUser("/user/info/update", formData).then((resp) => {
      if (resp.success) {
        var nowUser = isAuthenticated();
        nowUser.image = resp.image;
        AuthenticateNova(nowUser);
        setValues((x) => ({
          ...x,
          ad_loading: false,
          errorPosition: "success",
          errorText: "Successfully updated user image!",
          image: nowUser.image,
          imageLoad: false,
        }));
      }
    });
  };
  // for password
  // const updatePassword = () => {
  //     if (isTextEmpty(oldPass) || isTextEmpty(newPass)) {
  //         setValues(x => ({ ...x, errorPosition: 'apiErr', errorText: 'Invalid credentials' }))
  //         return
  //     }
  //     setValues(x => ({ ...x, ad_pass_loading: true }))
  //     POSTAPI_USER('/user/changePassword', {
  //         userId,
  //         oldPassword: EncryptPasswordAES(oldPass),
  //         newPassword: EncryptPasswordAES(newPass)
  //     })
  //         .then(res => {
  //             if (res.success) {
  //                 setValues(prev => ({
  //                     ...prev, errorPosition: 'success', errorText: 'Password updated',
  //                     oldPass: '', newPass: '', ad_pass_loading: false
  //                 }))
  //             }
  //             else if (res.error) {
  //                 setValues(prev => ({
  //                     ...prev, errorPosition: 'apiErr', errorText: res.error, ad_pass_loading: false
  //                 }))
  //             }
  //         })
  // }
  const AccountDetails = () => (
    <div className="flexAcc">
      <div className="ad_left_ac">
        <p className="ad_title_ac">Personal Information</p>

        <div className="d-flex align-items-center">
          <div className="container_image mr-2">
            <label for="file-input">
              <Avatar
                style={{
                  width: "90px",
                  height: "90px",
                  color: "#2E2E2E",
                }}
                src={image}
                variant="rounded"
                className="user_image"
              />
              <div className="middle">
                <PencilSimple size={25} color="#FFF" />
              </div>
            </label>
            <input
              id="file-input"
              type="file"
              accept="image/*"
              onChange={(e) => updateDetailsImage(e)}
            />
          </div>
          {imageLoad && (
            <CircularProgress style={{ color: "#8943EF", padding: "10px" }} />
          )}
        </div>

        <Button className="cus_loyal_btn mt-2 mb-3">
          <Medal color="#8943EF" />
          <span className="cus_loyal_text">LP:</span>
          <span className="cus_loyal_value ml-1">{data.loyaltyPoints}XP</span>
        </Button>
        {LabelComp("Full Name", "name", errorPosition, errorText)}
        {InputComp(name, "name", setValues, "ab_input")}
        {email && (
          <>
            {LabelComp("Email", "email", errorPosition, errorText)}
            <input
              type="text"
              value={email}
              disabled
              style={{ marginBottom: "10px" }}
              className={"ab_input"}
            />
          </>
        )}
        {phone && (
          <>
            {LabelComp("Phone", "phone", errorPosition, errorText)}
            <input
              type="text"
              value={phone}
              disabled
              style={{ marginBottom: "10px" }}
              className={"ab_input"}
            />
          </>
        )}
        {FullBtnComp("Update Details", updateDetails, ad_loading)}
        <Button
          className="c9 s1 mt-5 w-100 mb-3 displayNoneLg"
          onClick={(e) => setValues((x) => ({ ...x, confirmRemove: true }))}
        >
          Remove my account
        </Button>
      </div>

      <Modal
        open={confirmRemove}
        onClose={() => setValues((x) => ({ ...x, confirmRemove: false }))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex flex-column" style={{ gap: "16px" }}>
            <div className="d-flex justify-content-between">
              <span className="s5 c3 w3">Remove Account</span>
              <IconButton
                onClick={() =>
                  setValues((x) => ({ ...x, confirmRemove: false }))
                }
              >
                <X size={24} />
              </IconButton>
            </div>
            <span className="s1 c3">
              Removing your account will delete all your information from our
              database. This action cannot be undone.
            </span>
            <Button
              className="primary_btn"
              onClick={() =>
                handleUserRemove(
                  setValuesFn,
                  navigate,
                  setNoOfItemsFn,
                  setValues,
                )
              }
            >
              {isLoadingRemoveUser ? <CircularProgress /> : "Remove Account"}
            </Button>
          </div>
        </Box>
      </Modal>

      {/* <div className='ad_right_ac'>
                <p className='ad_title_ac'>Change Password</p>
                {LabelComp('Old Password', 'oldPass', errorPosition, errorText)}
                <Input.Password className='ab_input mb-2'
                    value={oldPass}
                    onChange={(e) => setValues(prev => ({ ...prev, oldPass: e.target.value }))}
                    iconRender={visible => (visible ?
                        <Eye className="mr-2" />
                        : <EyeClosed className="mr-2" />)}
                />
                {LabelComp('New Password', 'newPass', errorPosition, errorText)}
                <Input.Password className='ab_input'
                    value={newPass}
                    onChange={(e) => setValues(prev => ({ ...prev, newPass: e.target.value }))}
                    iconRender={visible => (visible ?
                        <Eye className="mr-2" />
                        : <EyeClosed className="mr-2" />)}
                />
                {FullBtnComp('Update Password', updatePassword, ad_pass_loading)}
            </div> */}
    </div>
  );

  const AddAddressFn = async () => {
    if (ab_loading) return;
    var validated = await ValidateAddress(
      add_title,
      add_name,
      add_phone,
      add_lat,
      add_lng,
      setValues,
    );
    if (validated) {
      var send_data = {
        userId,
        title: add_title,
        name: add_name,
        phone: add_phone,
        address: add_address,
        longitude: add_lng,
        latitude: add_lat,
        closestLandmark: add_landmark,
      };
      setValues((prev) => ({
        ...prev,
        ab_loading: true,
        errorPosition: "",
        errorText: "",
      }));
      POSTAPI_USER("/user/address/add", send_data).then((res) => {
        if (res.success)
          setValues((prev) => ({
            ...prev,
            add_Address_modal: false,
            add_id: "",
            add_address: "",
            add_email: "",
            add_phone: "",
            add_title: "",
            add_name: "",
            add_landmark: "",
            first: true,
            ab_loading: false,
          }));
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            ab_loading: false,
          }));
      });
    }
  };

  const EditAddressFn = async () => {
    if (ab_loading) return;
    var validated = await ValidateAddress(
      add_title,
      add_name,
      add_phone,
      add_lat,
      add_lng,
      setValues,
    );
    if (validated) {
      var send_data = {
        userId,
        addressId: add_id,
        title: add_title,
        name: add_name,
        phone: add_phone,
        address: add_address,
        longitude: add_lng,
        latitude: add_lat,
        closestLandmark: add_landmark,
      };
      setValues((prev) => ({
        ...prev,
        ab_loading: true,
        errorPosition: "",
        errorText: "",
      }));
      POSTAPI_USER("/user/address/edit", send_data).then((res) => {
        if (res.success)
          setValues((prev) => ({
            ...prev,
            add_Address_modal: false,
            add_id: "",
            add_address: "",
            add_email: "",
            add_phone: "",
            add_title: "",
            add_name: "",
            add_landmark: "",
            first: true,
            ab_loading: false,
            errorPosition: "success",
            errorText: "Address updated",
          }));
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            ab_loading: false,
          }));
      });
    }
  };

  const removeAddressFn = async (id) => {
    var send_data = {
      userId,
      addressId: id,
    };
    POSTAPI_USER("/user/address/remove", send_data).then((res) => {
      if (res.success) {
        var index = "";
        data.addresses.filter((x, i) =>
          x.addressId === id ? (index = i) : null,
        );
        var nowAddresses = data.addresses;
        nowAddresses.splice(index, 1);
        data.addresses = nowAddresses;
        setValues((prev) => ({
          ...prev,
          data: data,
          errorPosition: "success",
          errorText: "Address deleted",
        }));
      } else if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
    });
  };

  const editFn = async (id) => {
    var toEdit = data.addresses.filter((x) => x.addressId === id);
    if (toEdit.length === 1) {
      toEdit = toEdit[0];
      setValues((val) => ({
        ...val,
        add_id: toEdit.addressId,
        add_title: toEdit.title,
        add_name: toEdit.name,
        add_phone: toEdit.phone,
        add_address: toEdit.address,
        mapCenter: [toEdit.longitude, toEdit.latitude],
        add_Address_modal: true,
        add_lng: toEdit.longitude,
        add_lat: toEdit.latitude,
        add_landmark: toEdit.closestLandmark,
      }));
    }
  };

  const closeModal = () => {
    setValues((val) => ({
      ...val,
      add_Address_modal: false,
      add_id: "",
      add_title: "",
      add_name: "",
      add_email: "",
      add_phone: "",
      add_address: "",
      add_landmark: "",
      add_lat: null,
      add_lng: null,
      mapCenter: initMapCenter,
    }));
  };

  const AddressBook = () => (
    <>
      {loading && data?.addresses?.length === 0 && <CircularProgressComp />}

      {!loading && data?.addresses?.length === 0 && (
        <div className="d-flex w-100">No address yet.</div>
      )}

      <Grid
        container
        mt={"2px"}
        mb={"30px"}
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
      >
        {data &&
          data.addresses &&
          data.addresses.map((card, ind) => (
            <Grid key={ind} item xs={12} sm={12} md={6} lg={6}>
              <Item className="each_shipping_address h-100">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="esa_title">{card.title}</p>
                  {EditDeleteMenu(
                    card.addressId,
                    removeAddressFn,
                    editFn,
                    add_edit_visible_ind,
                    Set_add_edit_visible,
                    ind,
                  )}
                </div>
                <p>{card.name}</p>
                {card.email && <p>{card.email}</p>}
                {card.phone && <p>{card.phone}</p>}
                <p>{card.address}</p>
              </Item>
            </Grid>
          ))}
      </Grid>

      <Modal open={add_Address_modal} onClose={() => closeModal()}>
        <Box style={modalStyles} width={{ xs: "95vw", md: "400px" }}>
          <div
            className="w-100 d-flex flex-column"
            //  onClick={() => closeModal()}
          >
            <IconButton className="ml-auto" onClick={() => closeModal()}>
              <X />
            </IconButton>
            {LabelComp("Address Title", "add_title", errorPosition, errorText)}
            {InputComp(
              add_title,
              "add_title",
              setValues,
              "ab_input",
              "Home/Office",
            )}

            {LabelComp("Full Name", "add_name", errorPosition, errorText)}
            {InputComp(add_name, "add_name", setValues, "ab_input")}

            {/* {LabelComp('Email', 'add_email', errorPosition, errorText)} */}
            {/* {InputComp(add_email, 'add_email', setValues, 'ab_input')} */}

            {LabelComp("Phone", "add_phone", errorPosition, errorText)}
            {InputComp(add_phone, "add_phone", setValues, "ab_input")}

            {LabelComp(
              "Closest landmark",
              "add_landmark",
              errorPosition,
              errorText,
            )}
            {InputComp(add_landmark, "add_landmark", setValues, "ab_input")}

            {LabelComp("Address", "add_address", errorPosition, errorText)}
            {/* {InputComp(add_address, 'add_address', setValues, 'ab_input')} */}

            {/* old openstreetmap code */}
            {/* <div className='mb-2 pickMapDiv'>
                            <Test setMapCoordinates={setMapCoordinates}
                                mapCenter={mapCenter}
                                markCoord={isTextEmpty(add_lng) ? null : [add_lng, add_lat]}
                                markAdd={add_address}
                                type={isTextEmpty(add_id) ? 'Add Address' : 'Edit Address'} />
                        </div> */}

            <MapBox
              lnglat={[add_lng, add_lat]}
              setLngLat={setMapCoordinatesMapBox}
            />

            {FullBtnComp(
              isTextEmpty(add_id) ? "Add Address" : "Edit Address",
              isTextEmpty(add_id) ? AddAddressFn : EditAddressFn,
              ab_loading,
            )}
          </div>
        </Box>
      </Modal>
    </>
  );

  // var bi_buttons = [
  // 'Esewa Details',
  // 'Khalti Details']

  const cancelFn = (cancelId) => {
    POSTAPI_USER("/order/cancel", { userId, orderId: cancelId }).then((res) => {
      if (res.success)
        setValues((prev) => ({
          ...prev,
          first: false,
          data: res.data,
          image: res.data.image,
          conDialog: false,
          errorPosition: "success",
          errorText: "Order cancelled",
        }));
      else if (res.error)
        setValues((prev) => ({
          ...prev,
          first: false,
          errorPosition: "apiErr",
          errorText: res.errorText,
          conDialog: false,
        }));
    });
  };
  const handleClickOpenConDialog = (cardVal) => {
    setValues((x) => ({ ...x, conDialog: true, cancelId: cardVal._id }));
  };

  const handleCloseConDialog = () => {
    setValues((x) => ({ ...x, conDialog: false }));
  };

  const OrderHistory = () => (
    <>
      {loading && (!data?.pastOrders || data?.pastOrders?.length === 0) && (
        <div
          className="d-flex justify-content-center"
          style={{ width: "100vw" }}
        >
          <CircularProgressComp />
        </div>
      )}
      {!loading && data.pastOrders && data.pastOrders?.length === 0 ? (
        "No orders yet"
      ) : (
        <Grid
          container
          mb={"30px"}
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 6 }}
        >
          {data.pastOrders &&
            data.pastOrders.map((card, ind) => (
              <Grid key={ind} item xs={12} sm={12} md={6} lg={6}>
                <Item
                  style={{ height: "100%" }}
                  className="each_order_history_div"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="c3 w3 s2"># {card.trackingNumber}</span>
                    <Button className={statusButtonClass(card.status)[0]}>
                      {statusButtonClass(card.status)[1]}
                    </Button>
                  </div>
                  <p className="c7 s1">{formatDateTimeNext(card.createdAt)}</p>
                  <div>
                    <MapPin className="oh_icon" color="#8943EF" size={20} />
                    <span className="oh_text">
                      {card.delivery &&
                        card.delivery.location &&
                        card.delivery.location.title}
                    </span>
                  </div>
                  <div className="elipsis_text mt-2">
                    <ShoppingBag
                      className="oh_icon"
                      color="#8943EF"
                      size={20}
                    />
                    <span className="oh_text">
                      {card.foodNames && card.foodNames.join(",")}
                    </span>
                  </div>
                  <div className="elipsis_text mt-2">
                    <CurrencyDollar
                      className="oh_icon"
                      color="#8943EF"
                      size={20}
                    />
                    <span className="oh_text">
                      Rs. {parseFloat(card.payment.total).toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <Button
                      className="oh_btn ml-auto"
                      onClick={() => {
                        navigate("/order/" + card._id, { state: card });
                      }}
                    >
                      View Details
                    </Button>
                    {
                      (card.status === "Queue" || card.status === "Draft") && (
                        <Button
                          variant="outlined"
                          className={"oh_btn mr-auto ml-auto"}
                          onClick={() => handleClickOpenConDialog(card)}
                        >
                          Cancel Order
                        </Button>
                      )
                      // ConfirmDialog('Cancel Order',
                      // 'Do want to cancel this order?', 'Cancel', 'Proceed',
                      // conDialog,
                      // setValues, 'oh_btn mr-auto ml-auto', cancelFn, card._id)
                    }

                    {/* {card.status === 'Picked Up' && <Button
                                            onClick={() => navigate(`/trackOrder/${card._id}`)}
                                            className='oh_btn_track mr-auto ml-auto'>Track Order</Button>} */}
                  </div>
                </Item>
              </Grid>
            ))}
        </Grid>
      )}
    </>
  );

  // const BillingInfo = () => (
  //     <div className='bi_info'>

  //         <div className='bi_left'>
  //             {
  //                 bi_buttons.map((eachBtn, i) => (
  //                     <Button key={i} className={bi_step === i ? 'bi_left_btn_sel' : 'bi_left_btn'}
  //                         onClick={() => setValues(prev => ({ ...prev, bi_step: i }))}
  //                     >
  //                         {eachBtn}
  //                     </Button>
  //                 ))
  //             }
  //         </div>

  //         <div className='bi_right'>
  //             {
  //                 bi_buttons.map((eachBtn, i) => (
  //                     bi_step === i &&
  //                     <div className='ac_right_each' key={i}>
  //                         <div className='d-flex align-items-center mb-3'>
  //                             <span className='bi_title'>{eachBtn}</span>
  //                         </div>
  //                         {biBody(i)}
  //                     </div>
  //                 ))
  //             }
  //         </div>
  //     </div>
  // )

  // const savePaymentInfo = (paymentMethod, id) => {
  //     if (isTextEmpty(id) || payment_load) {
  //         setValues(x => ({ ...x, errorPosition: paymentMethod, errorText: 'Invalid value', payment_load: true }))
  //         return
  //     }
  //     setValues(x => ({ ...x, errorPosition: '', errorText: '', payment_load: true }))
  //     POSTAPI_USER('/user/payment', { userId, paymentMethod, id })
  //         .then(res => {
  //             if (res.success) {
  //                 setValues(x => ({ ...x, errorPosition: 'success', errorText: 'Saved payment info', payment_load: false }))
  //             }
  //             else if (res.error) {
  //                 setValues(x => ({ ...x, errorPosition: 'apiErr', errorText: res.error, payment_load: false }))
  //             }
  //         })
  // }

  // const biBody = (i) => (
  //     <div className='acc_body'>
  //         {/* {i === 0 && <>
  //             <div className='d-flex flex-column'>
  //                 {LabelComp('Esewa ID', PAYMENT_METHODS.ESEWA, errorPosition, errorText)}
  //                 {InputComp(esewaID, 'esewaID', setValues, 'addside_input')}
  //                 <Button className='order_again_btn w-100'
  //                     onClick={() => savePaymentInfo(PAYMENT_METHODS.ESEWA, esewaID)}>
  //                     {payment_load ? <CircularProgress className="full_btn_comp_loading" /> : 'Save Details'}
  //                 </Button>
  //             </div>
  //         </>} */}
  //         {/* {i === 0 && <>
  //             <div className='d-flex flex-column'>
  //                 {LabelComp('Khalti ID', PAYMENT_METHODS.KHALTI, errorPosition, errorText)}
  //                 {InputComp(khaltiID, 'khaltiID', setValues, 'addside_input')}
  //                 <Button className='order_again_btn w-100'
  //                     onClick={() => savePaymentInfo(PAYMENT_METHODS.KHALTI, khaltiID)}>
  //                     {payment_load ? <CircularProgress className="full_btn_comp_loading" /> : 'Save Details'}
  //                 </Button>
  //             </div>
  //         </>} */}
  //     </div>
  // )

  // openstreetmap
  // const setMapCoordinates = (index, address, mapCenter) => {
  //     var split = index.split(',')
  //     setValues(prev => ({
  //         ...prev, alt: index, add_address: address,
  //         add_lng: split[0], add_lat: split[1], mapCenter: mapCenter
  //     }))
  // }

  const setMapCoordinatesMapBox = (newlnglat) => {
    // var split = index.split(',')
    setValues((prev) => ({
      ...prev,
      alt: `${newlnglat[0]},${newlnglat[1]}`,
      add_address: "",
      add_lng: newlnglat[0],
      add_lat: newlnglat[1],
      mapCenter: newlnglat,
    }));
  };

  const accBody = (i) => (
    <div className="acc_body">
      {i === 0 && AccountDetails()}
      {i === 1 && AddressBook()}
      {i === 2 && OrderHistory()}
      {/* {i === 3 && BillingInfo()} */}
    </div>
  );

  const tabSelected = (ind) => {
    setValues((prev) => ({ ...prev, step: ind, first: true, loading: true }));
  };

  var buttons = [
    "Account Details",
    "Address Book",
    "Order History",
    //  'Billing & Payment Info'
  ];
  return (
    <>
      <p className="myAcc mt-3 mb-4">My Account</p>

      <div className="myAccountMain">
        <div className="ac_left">
          {buttons.map((eachBtn, i) => (
            <Button
              key={i}
              className={step === i ? "ac_left_btn_sel" : "ac_left_btn"}
              onClick={() => tabSelected(i)}
            >
              {eachBtn}
            </Button>
          ))}
        </div>

        <div className="ac_right">
          {buttons.map(
            (eachBtn, i) =>
              step === i && (
                <div className="ac_right_each" key={i}>
                  <div className="flexAcc mb-3 mt-3">
                    <span className="w3 s3 c3">{eachBtn}</span>
                    {i === 0 && (
                      <Button
                        className="c9 s1 ml-auto displayOnLg"
                        onClick={(e) =>
                          setValues((x) => ({ ...x, confirmRemove: true }))
                        }
                      >
                        Remove my account
                      </Button>
                    )}
                    {i === 1 && (
                      <Button
                        className="ab_add_btn"
                        onClick={() =>
                          setValues((val) => ({
                            ...val,
                            add_id: "",
                            add_title: "",
                            add_name: "",
                            add_email: "",
                            add_phone: "",
                            add_address: "",
                            add_landmark: "",
                            mapCenter: initMapCenter,
                            add_Address_modal: true,
                          }))
                        }
                      >
                        Add Address
                      </Button>
                    )}
                  </div>
                  {accBody(i)}
                </div>
              ),
          )}
        </div>

        {/* confirm dialogue */}
        <Modal open={conDialog} onClose={() => handleCloseConDialog()}>
          <Box style={modalStyles} width={{ xs: "95vw", sm: "400px" }}>
            <div className="dialog_text">Do want to cancel this order?</div>
            <div className="d-flex justify-content-end mt-3">
              <Button
                autoFocus
                onClick={handleCloseConDialog}
                className="borderless_btn_black"
              >
                Cancel
              </Button>
              <Button
                onClick={() => cancelFn(cancelId)}
                autoFocus
                className="primary_btn"
              >
                Proceed
              </Button>
            </div>
          </Box>
        </Modal>
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

const theme = createTheme({
  components: {
    // Name of the component
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid #E9ECFF",
          borderRadius: "5px",
          backgroundColor: "inherit",
          padding: "12px",
          "&.Mui-expanded": {
            margin: "0px",
          },
          "&::before": {
            opactiy: "0",
            height: "0px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0",
          minHeight: "0px",
          "&.Mui-expanded": {
            minHeight: "0px",
          },
        },
        content: {
          margin: "0px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
        expandIconWrapper: {
          color: "#2E2E2E",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
          color: "#818091",
        },
      },
    },
  },
});
export const OneOrder = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const outletContext = useOutletContext();
  const orderStatusChange = outletContext ? outletContext[12] : null;
  const { userId } = isAuthenticated();
  const [order, setOrder] = useState(null);
  var cart = order ?? null;
  const [values, setValues] = useState({
    first: true,
    loading: true,
    orderAgainLoading: false,
    errorPosition: "",
    errorText: "",
  });
  const { first, loading, orderAgainLoading, errorPosition, errorText } =
    values;
  useEffect(() => {
    if (orderStatusChange?._id === orderId)
      setValues((x) => ({ ...x, first: true }));
  }, [orderStatusChange, orderId]);
  useEffect(() => {
    if (!orderId) navigate("/myAccount");
    if (first) {
      POSTAPI_USER("/order/one", { orderId: orderId }).then((res) => {
        if (res.success) {
          setOrder(res.order);
          setValues((prev) => ({
            ...prev,
            first: false,
            loading: false,
          }));
        } else if (res.error) {
          setValues((prev) => ({
            ...prev,
            first: false,
            loading: false,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
        }
      });
    }
  }, [navigate, first, orderId]);

  const OrderSummary = () => (
    <Box>
      <ThemeProvider theme={theme}>
        {cart &&
          cart.foods &&
          cart.foods.length > 0 &&
          cart.foods.map((prod, ind) => (
            <div className="eachCart_div_oneOrder" key={ind}>
              <div className="mb-2 d-flex" key={ind}>
                <Avatar
                  src={prod.image}
                  className="shipping_eachCart_image"
                  variant="rounded"
                />
                <div className="d-flex flex-column w-100 justify-content-between py-1">
                  <span className="w3 s2 c3">{prod.name}</span>
                  <div className="d-flex w-100 justify-content-between">
                    <span className="w1 s2 c3">
                      Qty : <span className="w3">{prod.quantity}</span>
                    </span>
                    <span className="w1 s2 c3">
                      Rs. {parseFloat(prod.price).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>

              {/* customizations */}
              {((prod.modifiers && prod.modifiers.length > 0) ||
                prod.spiceLevel !== null) && (
                <Accordion style={{ borderRadius: "5px" }}>
                  <AccordionSummary
                    expandIcon={<CaretDown weight="bold" className="ml-2" />}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    Customizations
                  </AccordionSummary>
                  <AccordionDetails>
                    {prod.modifiers && prod.modifiers.length > 0 && (
                      <>
                        {prod.modifiers.map((each, i) => (
                          <div key={i}>
                            <span className="c3 w3 s2">{each.name}</span>
                            <div className="d-flex flex-column mt-2">
                              {each.options &&
                                each.options.map((eachOP, ind) => {
                                  return (
                                    <div
                                      key={ind}
                                      className="
                                                            d-flex justify-content-between
                                                             align-items-center mb-2"
                                    >
                                      <span>{eachOP.name}</span>
                                      <span>
                                        Rs.{" "}
                                        {parseFloat(eachOP.price).toFixed(2)}
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    {prod.spiceLevel !== null && (
                      <div className={"d-flex flex-column"}>
                        <span className="c3 w3 s2 mb-1">Spice Level</span>
                        <span className="tile_mod_text">{prod.spiceLevel}</span>
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          ))}
      </ThemeProvider>
    </Box>
  );

  const orderAgain = () => {
    var send_data = { userId, orderId: orderId };
    setValues((x) => ({ ...x, orderAgainLoading: true }));
    POSTAPI_USER("/order/reorder", send_data).then((res) => {
      if (res.success) {
        navigate("/myCart");
      } else if (res.error) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          orderAgainLoading: false,
        }));
      }
    });
  };

  return (
    <>
      {AlertError(errorPosition, errorText, setValues)}
      {loading && (
        <div className="d-flex justify-content-center">
          <CircularProgressComp />
        </div>
      )}
      {order && (
        <>
          <div className="d-flex flex-column mb-3 od_main">
            <Box
              className="d-flex"
              flexDirection={{
                xs: "column",
                md: "row",
              }}
              alignItems={{ xs: "start", md: "center" }}
            >
              <Button
                className="d-flex gap-2"
                onClick={() =>
                  navigate("/myAccount", { state: { goToStep: 2 } })
                }
              >
                <ArrowLeft size={24} color="#000" />

                <div className="d-flex align-items-center">
                  <span className="c3 w3 s2 mr-3">
                    # ORD-{order.trackingNumber}
                  </span>
                </div>
                <Button className={statusButtonClass(order.status)[0]}>
                  {statusButtonClass(order.status)[1]}
                </Button>
              </Button>
              {order.status === "Completed" && (
                <div className="ml-2">
                  {HalfRightBtnComp(
                    "Order Again",
                    orderAgain,
                    orderAgainLoading,
                  )}
                </div>
              )}
            </Box>

            <div className="oneOrder_body mt-3">
              {((!loading && Array.isArray(order?.statusInfo)) ||
                cart?.delivery?.riderInfo) && (
                <div
                  className="od_left p-0"
                  style={{ height: "fit-content", border: "none" }}
                >
                  {!loading && Array.isArray(order?.statusInfo) && (
                    <CustomizedSteppers order={cart} />
                  )}
                  {cart?.delivery?.riderInfo && (
                    <div className="mb-2 od_left mt-4">
                      <p className="w3 s2">Delivered by</p>
                      <div className="d-flex align-items-center">
                        <Avatar src={cart.delivery.riderInfo?.image} />
                        <div className="d-flex justify-content-between w-100 align-items-center">
                          <div className="d-flex flex-column gap-1 ml-1">
                            <span className="ml-2 w3 s2">
                              {cart.delivery.riderInfo?.name}
                            </span>
                            <span className="ml-2 s1">
                              {cart.delivery.riderInfo?.phone}
                            </span>
                          </div>

                          <a
                            href={"tel:+" + cart.delivery.riderInfo?.phone}
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              width: "32px",
                              height: "33px",
                            }}
                          >
                            <Phone color="#000" size={20} />
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="od_right bor bg-white">
                <p className="w3 s3 c3 mt-2">Order Summary</p>
                {cart && OrderSummary()}
                {order.note && (
                  <div className="d-flex mt-3">
                    <span className="w3 c3">Note:</span>
                    <span className="ml-1">{order.note}</span>
                  </div>
                )}
                <div className="d-flex justify-content-between mb-3 mt-2">
                  <p className="w3 c3">
                    <ForkKnife size={24} color="#8943EF" /> Cutlery
                  </p>
                  <p className="">{order.needCutlery ? "Yes" : "No"}</p>
                </div>
                {cart && (
                  <div className="bottomDiv_ship_oneOrder">
                    {AccountSubTotalDiv(cart)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
