// import MUIDataTable from 'mui-datatables'
import { Box, IconButton, InputBase, Paper } from "@mui/material";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertError, DataGridNova } from "../../../../Components/Common";
import { useGetOrderStatsQuery } from "../../../../slices/apiSlice";
import {
  ad_dashFrom,
  ad_dashTo,
  setDashDate,
} from "../../../../slices/novaSlice";
import { rangePresets, rangePresetsNew } from "../../Dashbaord/AdminDashboard";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { RangePicker } = DatePicker;

export const OrderStats = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dateFormat = "MMMM D, YYYY h:mm A";
  const [values, setValues] = useState({
    errorPosition: "",
    errorText: "",
    pageSize: 25,
    searchText: "",
    searchedData: [],
    // order: null,
    // openOrder: false,
    from: useSelector(ad_dashFrom),
    to: useSelector(ad_dashTo),
  });
  const {
    errorPosition,
    errorText,
    pageSize,
    // order,
    // openOrder,
    searchText,
    searchedData,
    from,
    to,
  } = values;
  const query = `from=${new Date(from).toISOString()}&to=${new Date(to).toISOString()}`;
  const { isFetching: isLoading, data } = useGetOrderStatsQuery(query);

  useEffect(() => {
    setValues((x) => ({ ...x, searchedData: data?.responseOrders ?? [] }));
  }, [data]);

  const columnsData = [
    {
      field: "trackingNumber",
      headerName: "ID",
      width: 75,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "contact",
      headerName: "Contact",
      width: 140,
    },
    {
      field: "distance",
      headerName: "Km",
      width: 65,
    },
    {
      field: "rider",
      headerName: "Rider Name",
      width: 135,
      renderCell: (params) => {
        if (params.value) return <span>{params.value}</span>;
        else return "-";
      },
    },
    {
      field: "total",
      headerName: "Total",
      width: 120,
      renderCell: (params) => {
        if (params.value) return <span>Rs. {params.value}</span>;
        else return "-";
      },
    },
    {
      field: "discountAmount",
      headerName: "Discount",
      width: 100,
      renderCell: (params) => {
        if (params.value) return <span>Rs. {params.value}</span>;
        else return "-";
      },
    },
    {
      field: "unpaidAmount",
      headerName: "Unpiad",
      width: 100,
      renderCell: (params) => {
        if (params.value) return <span>Rs. {params.value}</span>;
        else return "-";
      },
    },
    {
      field: "cashAmount",
      headerName: "Cash",
      width: 105,
      renderCell: (params) => {
        if (params.value) return <span>Rs. {params.value}</span>;
        else return "-";
      },
    },
    {
      field: "qrAmount",
      headerName: "QR",
      width: 140,
      renderCell: (params) => {
        if (params.value) return <span>Rs. {params.value}</span>;
        else return "-";
      },
    },
    {
      field: "deliveryCharge",
      headerName: "Delivery",
      width: 90,
      renderCell: (params) => {
        if (params.value) return <span>Rs. {params.value}</span>;
        else return "-";
      },
    },
    {
      field: "tipAmount",
      headerName: "Tip",
      width: 130,
      renderCell: (params) => {
        if (params.value) return <span>Rs. {params.value}</span>;
        else return "-";
      },
    },
  ];

  const handleRowClick = (item) => {
    navigate(`/orders/draft/view/${item._id}`, {
      state: { returnUrl: "/stats/order-stats" },
    });
  };
  const onChange = (date) => {
    setValues((x) => ({
      ...x,
      from: date[0],
      to: date[1],
      first: true,
      loading: true,
    }));
    dispatch(
      setDashDate({
        dashFrom: date[0],
        dashTo: date[1],
      }),
    );
  };

  const dateNow = new Date().getHours();

  return (
    <div className="category_body" style={{ height: "100% !important" }}>
      <Box className="d-flex justify-content-between align-items-center mb-4">
        {SearchDiv(searchText, data, setValues)}
        <Space direction="vertical" size={12}>
          <Box marginLeft={{md:'50px', lg:'auto' }}>
            <RangePicker
              presets={dateNow >= 12 ? rangePresetsNew : rangePresets}
              showTime
              format={"YYYY-MM-DD HH:mm"}
              style={{ width: "auto" }}
              className="s1"
              size="large"
              value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
              onChange={onChange}
              />
            </Box>
        </Space>
      </Box>

      <Box width={{md:'70vw',lg:'1200px'}} style={{ height: "65vh" }}>
        <DataGridNova
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={searchedData ?? []}
          columns={columnsData}
          loading={isLoading}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
          getRowClassName={(params) => `super-app-theme--${params.row.status}`}
        />
        {data?.responseOrders && (
          <div
            className="d-flex bg-white s2 w3"
            style={{ borderTop: "1px solid #D9D9D9", padding: "12px 0" }}
          >
            <span style={{ paddingLeft: "32px" }}>Total</span>
            <span
              className="mr-1"
              style={{ marginLeft: "auto", width: "110px" }}
            >
              Rs. {FixedAmount(data?.allTotal)}
            </span>
            <span className="mr-1" style={{ width: "100px" }}>
              Rs. {FixedAmount(data?.discountTotal)}
            </span>
            <span className="mr-1" style={{ width: "90px" }}>
              Rs. {FixedAmount(data?.unpaidTotal)}
            </span>
            <span className="mr-1" style={{ width: "110px" }}>
              Rs. {FixedAmount(data?.cashTotal)}
            </span>
            <span className="mr-1" style={{ width: "140px" }}>
              Rs. {FixedAmount(data?.qrTotal)}
            </span>
            <span className="mr-1" style={{ width: "90px" }}>
              Rs. {FixedAmount(data?.deliveryChargeTotal)}
            </span>
            <span style={{ width: "110px" }}>
              Rs. {FixedAmount(data?.tipTotal)}
            </span>
          </div>
        )}
      </Box>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const SearchDiv = (searchText, data, setValues) => {
  const handleChangeSearch = (e) => {
    const text = e?.target?.value;
    const filteredData = data?.responseOrders?.filter((x) =>
      x.trackingNumber.includes(text),
    );
    setValues((x) => ({ ...x, searchText: text, searchedData: filteredData }));
  };
  return (
    <Paper
      component="form"
      sx={{
        p: "0px 16px",
        display: "flex",
        border: "1px solid #E9ECFF",
        borderRadius: "25px",
        width: "400px",
        boxShadow: "none",
        padding: "0 16px",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search by order ID"
        inputProps={{ "aria-label": "search" }}
        onChange={handleChangeSearch}
        value={searchText}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <MagnifyingGlass color="#8943EF" />
      </IconButton>
    </Paper>
  );
};

const FixedAmount = (val) => {
  return Math.ceil(val);
};
