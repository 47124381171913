import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  ThemeProvider,
} from "@mui/material";
// import MUIDataTable from 'mui-datatables'
import { PlusCircle, TrashSimple } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DELETEAPIAdmin,
  GetAPIAdmin,
  POSTAPI_ADMIN,
  PUTAPIAdmin,
} from "../../Auth/Index";
import {
  AlertError,
  DataGridNova,
  getMuiTheme,
  HalfRightBtnComp,
  InputComp,
  LabelComp,
  LabelComp_Index,
  ReactSelectDropMulti,
  RedSwitch,
  TableEditDelete,
  TopAddComp,
  TopBackComp,
} from "../../Components/Common";
import { ValidateModifier } from "../../Components/Validate_Admin";
import "../../css/Admin/modifier.css";

export const Modifier = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    modifiers: [],
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    edAnchor: null,
    edClicked: null,
    pageSize: 10,
  });
  const {
    modifiers,
    first,
    loading,
    errorPosition,
    errorText,
    edAnchor,
    edClicked,
    pageSize,
  } = values;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/modifier/").then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            modifiers: res.modifiers,
            first: false,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first]);

  // const columns = [
  //     {
  //         name: '_id',
  //         label: '',
  //         options: {
  //             display: false
  //         }
  //     },
  //     {
  //         name: "name",
  //         label: "Modifiers",
  //         options: {
  //             filter: true,
  //             sort: true
  //         }
  //     },
  //     {
  //         name: "options",
  //         label: "No. of options",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <span className="d-flex justify-content-center">{value ? value.length : ''}</span>
  //                 )
  //             }
  //         }
  //     },
  //     {
  //         name: "",
  //         label: "",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <TableEditDelete onClick={(e) => e.stopPropagation()} id={tableMeta.rowData[0]}
  //                         EditFn={EditFn} DeleteFn={DeleteFn}
  //                         edAnchor={edAnchor} setValues={setValues} edClicked={edClicked} />
  //                 )
  //             }
  //         }
  //     }
  // ];
  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No modifier yet.',
  //         },
  //     },
  //     tableBodyMaxHeight,
  //     ...tableDisabledOptions,
  //     responsive: 'vertical'
  // };
  // const tableBodyMaxHeight = "55vh";

  const EditFn = () => {
    var item = modifiers.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      navigate("/modifiers/edit", { state: item });
    }
  };

  const DeleteFn = () => {
    var item = modifiers.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      DELETEAPIAdmin("/modifier/remove", { modifierId: edClicked }).then(
        (res) => {
          if (res.success) {
            setValues((x) => ({
              ...x,
              errorPosition: "success",
              errorText: `Modifier ${item.name} deleted`,
              modifiers: res.modifiers,
            }));
          } else if (res.error)
            setValues((x) => ({
              ...x,
              errorPosition: "apiErr",
              errorText: res.error,
            }));
        },
      );
    }
  };

  // const handleRowClick = (rowData, rowMeta) => {
  const handleRowClick = (item) => {
    // var item = modifiers[rowMeta.dataIndex]
    navigate("/modifiers/edit", { state: item });
  };

  const columnsData = [
    {
      field: "name",
      headerName: "Modifiers",
      description: "This column is sortable",
      sortable: false,
      width: 175,
      renderCell: (params) => {
        return <span>{params.row.name}</span>;
      },
    },
    {
      field: "options",
      headerName: "No. of modifiers",
      description: "This column is sortable",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return <span>{params.row.options.length}</span>;
      },
    },
    {
      field: "icon",
      headerName: "",
      description: "Click for edit delete options",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <TableEditDelete
              onClick={(e) => e.stopPropagation()}
              id={params.row._id}
              EditFn={EditFn}
              DeleteFn={DeleteFn}
              edAnchor={edAnchor}
              setValues={setValues}
              edClicked={edClicked}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="category_body">
      {TopAddComp("Modifier", "/modifiers/add", "Add Modifier")}

      <ThemeProvider theme={getMuiTheme()}>
        {/* <MUIDataTable
                    data={modifiers}
                    columns={columns}
                    options={options}
                /> */}
      </ThemeProvider>

      <div style={{ height: "55vh", width: "450px" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={modifiers}
          columns={columnsData}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const AddModifier = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    modifier_name: "",
    items: [],
    tagName: "",
    maxOptionsLimit: "",
    isRequired: false,
    allItems: [],
    options: [
      {
        name: "",
        price: "",
        isDefault: true,
        isPopular: true,
      },
    ],
    errorPosition: "",
    errorText: "",
    error_optionsIndex: "",
    first: true,
    loading: false,
  });
  const {
    modifier_name,
    items,
    tagName,
    maxOptionsLimit,
    isRequired,
    allItems,
    options,
    errorPosition,
    errorText,
    error_optionsIndex,
    first,
    loading,
  } = values;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/item/").then((res) => {
        if (res.success) {
          setValues((prev) => ({ ...prev, allItems: res.items, first: false }));
        }
      });
    }
  }, [first]);

  const handleCreate = async () => {
    setValues((val) => ({
      ...val,
      loading: true,
      errorPosition: "",
      errorText: "",
      error_optionsIndex: "",
    }));
    var validated = await ValidateModifier(modifier_name, options, setValues);
    if (validated) {
      var itemsArr = [];
      items.map((each) => itemsArr.push(each._id));
      var sendData = {
        name: modifier_name,
        options,
        items: itemsArr,
        maxOptionsLimit,
        isRequired,
        tagName,
      };
      POSTAPI_ADMIN("/modifier/create", sendData).then((res) => {
        if (res.success) navigate("/modifiers");
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  };

  const addOption = (option_index) => {
    var nowOptions = [...options];
    nowOptions[option_index] = {
      name: "",
      price: "",
      isPopular: false,
      isDefault: false,
    };
    setValues((val) => ({ ...val, options: nowOptions }));
  };

  const handleChangeOption = (e, ind) => {
    var nowOptions = [...options];
    nowOptions[ind][e.target.name] = e.target.value;
    setValues((val) => ({ ...val, options: nowOptions }));
  };

  const handleDeleteOption = (ind) => {
    var nowOptions = [...options];
    if (options.length === 1) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Atleast one option needed",
      }));
      return;
    }
    nowOptions.splice(ind, 1);
    setValues((val) => ({ ...val, options: nowOptions }));
  };

  const focusOutPrice = () => {
    var changed = false;
    options.forEach((element) => {
      if (element.price === "") {
        element.price = 0;
        changed = true;
      }
    });
    if (changed) setValues((val) => ({ ...val, options: options }));
  };
  return (
    <>
      {TopBackComp("Add Modifier", "/modifiers")}

      <div className="add_modifier_body">
        <div className="add_modifier_body_top">
          <div className="mod_left">
            {LabelComp(
              "Modifier name",
              "modifier_name",
              errorPosition,
              errorText,
            )}
            {InputComp(modifier_name, "modifier_name", setValues, "mod_input")}

            {LabelComp("Assign to items", "items", errorPosition, errorText)}
            {ReactSelectDropMulti(items, "items", setValues, allItems)}

            {LabelComp("Tag Name", "tagName", errorPosition, errorText, "mt-3")}
            {InputComp(tagName, "tagName", setValues, "mod_input")}

            {LabelComp(
              "Max Options Limit",
              "maxOptionsLimit",
              errorPosition,
              errorText,
            )}
            {InputComp(
              maxOptionsLimit,
              "maxOptionsLimit",
              setValues,
              "mod_input",
            )}

            <div className="d-flex justify-content-between">
              <span>Required</span>
              <RedSwitch
                checked={isRequired}
                onChange={() =>
                  setValues((prev) => ({ ...prev, isRequired: !isRequired }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>

          <div className="mod_left">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="isPopular0"
              name="radio-buttons-group"
              value={options.findIndex((x) => x.isDefault === true) ?? 0}
              onChange={(e) =>
                setValues((x) => ({
                  ...x,
                  options: options?.map((option, index) =>
                    parseInt(index) === parseInt(e.target.value)
                      ? { ...option, isDefault: true }
                      : { ...option, isDefault: false },
                  ),
                }))
              }
            >
              {options.map((eachOption, option_index) => {
                return (
                  <div key={option_index}>
                    <>
                      <div className="each_option">
                        <div className="d-flex">
                          {LabelComp_Index(
                            "Option name",
                            "options_name",
                            option_index,
                            errorPosition,
                            errorText,
                            error_optionsIndex,
                          )}
                          <IconButton
                            className="d-flex ml-auto"
                            onClick={() => handleDeleteOption(option_index)}
                          >
                            <TrashSimple />
                          </IconButton>
                        </div>
                        <input
                          type="text"
                          name="name"
                          value={eachOption.name}
                          className="mod_input"
                          onChange={(e) => handleChangeOption(e, option_index)}
                        />

                        {LabelComp_Index(
                          "Option price",
                          "options_price",
                          option_index,
                          errorPosition,
                          errorText,
                          error_optionsIndex,
                        )}
                        <input
                          type="number"
                          name="price"
                          value={eachOption.price}
                          className="mod_input"
                          onBlur={() => focusOutPrice()}
                          onChange={(e) => handleChangeOption(e, option_index)}
                        />

                        {/* popular */}
                        <div className="d-flex justify-content-between mt-3">
                          <span>Popular</span>
                          <RedSwitch
                            checked={eachOption.isPopular}
                            onChange={(e) => {
                              const updatedArray = [...options];
                              updatedArray[option_index] = {
                                ...updatedArray[option_index],
                                isPopular: !eachOption.isPopular,
                              };
                              setValues((x) => ({
                                ...x,
                                options: updatedArray,
                              }));
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>

                        {/* default */}
                        <FormControlLabel
                          value={option_index}
                          control={<Radio />}
                          label={"Default"}
                        />
                      </div>
                    </>
                    {option_index === options.length - 1 && (
                      <PlusCircle
                        className="plusCircle_option"
                        onClick={() => addOption(option_index + 1)}
                      />
                    )}
                  </div>
                );
              })}
            </RadioGroup>
            {HalfRightBtnComp("Add Modifier", handleCreate, loading)}
          </div>
        </div>
      </div>
      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

export const EditModifier = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!location.state) {
      navigate("/modifiers", { replace: true });
    }
  }, [location.state, navigate]);

  const [values, setValues] = useState({
    modifier_name: location.state ? location.state.name : "",
    tagName: location.state.tagName ?? "",
    maxOptionsLimit: location.state.maxOptionsLimit ?? 1,
    isRequired: location.state.isRequired ?? false,
    options: location.state
      ? location.state.options
      : [
          {
            name: "",
            price: "",
            isDefault: true,
            isPopular: true,
          },
        ],
    items: location.state ? location.state.items : [],
    allItems: [],
    errorPosition: "",
    errorText: "",
    error_optionsIndex: "",
    first: true,
    loading: false,
  });
  const {
    modifier_name,
    options,
    tagName,
    isRequired,
    maxOptionsLimit,
    items,
    allItems,
    errorPosition,
    errorText,
    error_optionsIndex,
    first,
    loading,
  } = values;
  useEffect(() => {
    if (first) {
      GetAPIAdmin("/item/").then((res) => {
        if (res.success) {
          setValues((prev) => ({ ...prev, allItems: res.items, first: false }));
        }
      });
    }
  }, [first]);

  const handleUpdate = async () => {
    setValues((val) => ({
      ...val,
      loading: true,
      errorPosition: "",
      errorText: "",
      error_optionsIndex: "",
    }));
    var validated = await ValidateModifier(modifier_name, options, setValues);
    if (validated) {
      var itemsArr = [];
      items.map((each) => itemsArr.push(each._id));
      var sendData = {
        modifierId: location.state._id,
        name: modifier_name,
        options,
        items: itemsArr,
        maxOptionsLimit,
        isRequired,
        tagName,
      };
      PUTAPIAdmin("/modifier/edit", sendData).then((res) => {
        if (res.success) navigate("/modifiers");
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  };

  const addOption = (option_index) => {
    var nowOptions = [...options];
    nowOptions[option_index] = { name: "", price: "" };
    setValues((val) => ({ ...val, options: nowOptions }));
  };

  const handleChangeOption = (e, ind) => {
    var nowOptions = [...options];
    nowOptions[ind][e.target.name] = e.target.value;
    setValues((val) => ({ ...val, options: nowOptions }));
  };

  const handleDeleteOption = (ind) => {
    var nowOptions = [...options];
    if (options.length === 1) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Atleast one option needed",
      }));
      return;
    }
    nowOptions.splice(ind, 1);
    setValues((val) => ({ ...val, options: nowOptions }));
  };

  const focusOutPrice = () => {
    var changed = false;
    options.forEach((element) => {
      if (element.price === "") {
        element.price = 0;
        changed = true;
      }
    });
    if (changed) setValues((val) => ({ ...val, options: options }));
  };

  return (
    <>
      {TopBackComp("Edit Modifier", "/modifiers")}

      <div className="add_modifier_body">
        <div className="add_modifier_body_top">
          <div className="mod_left">
            {LabelComp(
              "Modifier name",
              "modifier_name",
              errorPosition,
              errorText,
            )}
            {InputComp(modifier_name, "modifier_name", setValues, "mod_input")}

            {LabelComp("Assign to items", items, errorPosition, errorText)}
            {ReactSelectDropMulti(items, "items", setValues, allItems)}

            {LabelComp("Tag Name", "tagName", errorPosition, errorText, "mt-3")}
            {InputComp(tagName, "tagName", setValues, "mod_input")}

            {LabelComp(
              "Max Options Limit",
              "maxOptionsLimit",
              errorPosition,
              errorText,
            )}
            {InputComp(
              maxOptionsLimit,
              "maxOptionsLimit",
              setValues,
              "mod_input",
            )}

            <div className="d-flex justify-content-between">
              <span>Required</span>
              <RedSwitch
                checked={isRequired}
                onChange={() =>
                  setValues((prev) => ({ ...prev, isRequired: !isRequired }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>

          <div className="mod_left">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="isPopular0"
              name="radio-buttons-group"
              value={options.findIndex((x) => x.isDefault === true) ?? 0}
              onChange={(e) =>
                setValues((x) => ({
                  ...x,
                  options: options?.map((option, index) =>
                    parseInt(index) === parseInt(e.target.value)
                      ? { ...option, isDefault: true }
                      : { ...option, isDefault: false },
                  ),
                }))
              }
            >
              {options.map((eachOption, option_index) => {
                return (
                  <div key={option_index}>
                    <>
                      <div className="each_option">
                        <div className="d-flex">
                          {LabelComp_Index(
                            "Option name",
                            "options_name",
                            option_index,
                            errorPosition,
                            errorText,
                            error_optionsIndex,
                          )}
                          <IconButton
                            className="d-flex ml-auto"
                            onClick={() => handleDeleteOption(option_index)}
                          >
                            <TrashSimple />
                          </IconButton>
                        </div>
                        <input
                          type="text"
                          name="name"
                          value={eachOption.name}
                          className="mod_input"
                          onChange={(e) => handleChangeOption(e, option_index)}
                        />

                        {LabelComp_Index(
                          "Option price",
                          "options_price",
                          option_index,
                          errorPosition,
                          errorText,
                          error_optionsIndex,
                        )}
                        <input
                          type="number"
                          name="price"
                          value={eachOption.price}
                          className="mod_input"
                          onBlur={() => focusOutPrice()}
                          onChange={(e) => handleChangeOption(e, option_index)}
                        />

                        {/* popular */}
                        <div className="d-flex justify-content-between mt-3">
                          <span>Popular</span>
                          <RedSwitch
                            checked={eachOption.isPopular}
                            onChange={(e) => {
                              const updatedArray = [...options];
                              updatedArray[option_index] = {
                                ...updatedArray[option_index],
                                isPopular: !eachOption.isPopular,
                              };
                              setValues((x) => ({
                                ...x,
                                options: updatedArray,
                              }));
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>

                        {/* default */}
                        <FormControlLabel
                          value={option_index}
                          control={<Radio />}
                          label={"Default"}
                        />
                      </div>
                    </>
                    {option_index === options.length - 1 && (
                      <PlusCircle
                        className="plusCircle_option"
                        onClick={() => addOption(option_index + 1)}
                      />
                    )}
                  </div>
                );
              })}
            </RadioGroup>
            {HalfRightBtnComp("Edit Modifier", handleUpdate, loading)}
          </div>
        </div>
      </div>
      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};
