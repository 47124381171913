import { Button } from "@mui/material";
// import MUIDataTable from 'mui-datatables'
import { DownloadSimple } from "@phosphor-icons/react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAPIAdmin } from "../../Auth/Index";
import { AlertError, DataGridNova } from "../../Components/Common";
import { jsonToCsv } from "../../Components/save-csv";
import { ad_dashAttendanceFrom, ad_dashAttendanceTo, setDashDate } from "../../slices/novaSlice";
const { RangePicker } = DatePicker;

export const rangePresets = [
  {
    label: "Today",
    value: [
      dayjs().startOf("day"),
      dayjs().endOf("day"),
    ],
  },
  {
    label: "Yesterday",
    value: [
      dayjs().subtract(1, "day").startOf("day"),
      dayjs().subtract(1, "day").endOf("day"),
    ],
  },
  {
    label: "This Week",
    value: [dayjs().startOf("week"), dayjs().endOf("week")],
  },
  {
    label: "Last Week",
    value: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week")
    ],
  },
  {
    label: "This Month",
    value: [
      dayjs().startOf("month"),
      dayjs().endOf("month"),
    ],
  },
  {
    label: "Last Month",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs()
        .subtract(1, "month")
        .endOf("month")
    ],
  },
  {
    label: "Last 7 Days",
    value: [
      dayjs().subtract(7, "day").startOf("day"),
      dayjs().endOf("day"),
    ],
  },
  {
    label: "Last 30 Days",
    value: [
      dayjs().subtract(30, "day").startOf("day"),
      dayjs().endOf("day"),
    ],
  },
];

export const Attendance = () => {
  const dispatch = useDispatch()
  const [values, setValues] = useState({
    riders : [],
    data: [],
    selectedIndex: 0,
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    pageSize: 10,
    searchText: "",
    riderId : null,
    rider : null,
    from: dayjs(useSelector(ad_dashAttendanceFrom)),
    to: dayjs(useSelector(ad_dashAttendanceTo)),
  });
  const {
    riders,
    data,
    first,
    selectedIndex,
    loading,
    errorPosition,
    errorText,
    pageSize,
    riderId,
    rider,
    from,
    to
  } = values;
  const dateFormat = "MMMM D, YYYY h:mm A";
  const query = `?riderId=${riderId}&from=${dayjs(from).format('YYYY-MM-DD')}&to=${dayjs(to).format('YYYY-MM-DD')}`;
  useEffect(()=>{
    fetchRiders()
  },[])
  const fetchRiders = async () => {
    const resp =await GetAPIAdmin("/employee/riders");
    setValues(x=>({...x, 
      riders : resp.riders, 
      riderId : resp.riders?.[0]?.riderId ?? null, 
      rider : resp.riders?.[0] 
    }))
  }
  useEffect(() => {
    if (first && riderId) {
      GetAPIAdmin("/attendance/range"+query).then((res) => {
        if (res.error)
          return setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
        setValues((prev) => ({
          ...prev,
          data: res,
          first: false,
          loading: false,
        }));
      });
    }
  }, [first, query, riderId]);
  const columnsData = [
    {
      field: "date",
      headerName: "Daye",
      flex:1,
      renderCell: (params) => {
        return params.value
      },
    },
    {
      field: "clockIn",
      headerName: "Clock In",
      width: 180,
      renderCell: (params) => <div className="flex flex-col">
        <div>{params.value ==='-' ? '' : dayjs(params.value).format('h:mm A')}</div>
      </div>
    },
    {
      field: "clockOut",
      headerName: "Clock Out",
      width: 130,
      renderCell: (params) => <div className="flex flex-col">
      <div>{params.value ==='-' ? '' : dayjs(params.value).format('h:mm A')}</div>
    </div>
    },
    {
      field: "totalWorkingTimeReadable",
      headerName: "Total Work",
      width: 130,
      renderCell: (params) => params.row?.attendanceInfo?.totalWorkingTimeReadable,
    },
  ];
  const handleRiderClick = (rider,i) => {
    setValues(x=>({...x, 
      selectedIndex : i, 
      riderId : rider?.riderId, 
      rider : rider, 
      first:true, 
      loading:true
    }))
  }
  const onChange = (date) => {
    setValues((x) => ({
      ...x,
      from: date[0],
      to: date[1],
      first: true,
      loading: true,
    }));
    dispatch(
      setDashDate({
        dashFrom: date[0],
        dashTo: date[1],
      }),
    );
  };
  const csvFilename = `rider-${rider?.name}-${from.format('YYYY-MM-DD')}-${to.format('YYYY-MM-DD')}`.replace(/\s/g, "")
  return (
    <div className="category_body">
      <div className="d-flex justify-content-between ml-auto gap-2 align-items-center">
        <Space direction="vertical" size={12}>
          <RangePicker
            presets={rangePresets}
            showTime
            format={"YYYY-MM-DD HH:mm"}
            style={{ width: "330px" }}
            className="ml-auto s1"
            size="large"
            value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
            onChange={onChange}
          />
        </Space>
        <Button
        onClick={e=>jsonToCsv(data, csvFilename)}
        className="bg-pri text-white gap-2" style={{borderRadius:25, padding:'10px 16px'}}>
          <DownloadSimple />
          <span>Download CSV </span>
        </Button>
      </div>

      <div className="d-flex gap-3 mt-3">
      <div style={{width:'223px'}} className="d-flex flex-column gap-2">
        {
          riders?.map((each,i) => (
            <Button onClick={e=>handleRiderClick(each,i)} 
             sx={{
              backgroundColor: (selectedIndex===i) ? '#F2F2F2' : '',
              padding : '8px 16px',
              color : (selectedIndex===i) ? '#8943EF' : '#222227'
            }} className={`d-flex justify-content-start s2 w1 ${selectedIndex===i ? 'w3' : 'w1'}`} key={each._id}>
              {each.name}
            </Button>
          ))
        }
      </div>
      <div style={{ height: "65vh", width: "650px" }}>
        <DataGridNova
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={data}
          columns={columnsData}
          loading={loading}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id ?? Math.random()}
          disableSelectionOnClick
          hideFooterSelectedRowCount={true}
        />
      </div>
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};