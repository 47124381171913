import { Button } from "@mui/material";
import { Package, Pizza, ShoppingBag } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated, POSTAPI_ADMIN } from "../../Auth/Index";
import { formatDateTimeNext, ReadNotification } from "../../Components/Common";
import { ENTITY_TYPE } from "../../Components/staticData";
import "../../css/Market/notifications.css";

export const Notifications = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    step: 0,
  });
  const { step } = values;
  const data = location.state;
  useEffect(() => {
    if (!data) navigate("/");
  }, [data, navigate]);

  return (
    <>
      <p className="ml-3 w3 s3">Notifications</p>
      <div className="d-flex nt">
        <div className="nt_left">
          {data &&
            data.map((each, i) => (
              <div
                key={i}
                className={i === step ? "nt_left__each_sel" : "nt_left__each"}
                onClick={() => setValues((x) => ({ ...x, step: i }))}
              >
                <p className="c7">{formatDateTimeNext(each.createdAt)}</p>
                <div className="d-flex align-items-center">
                  <Pizza color="#8943EF" size={30} />
                  <span className="ml-2 s2">{each.title}</span>
                </div>
              </div>
            ))}
        </div>

        <div className="nt_right">
          <p className="c7 s2">{formatDateTimeNext(data[step].createdAt)}</p>
          <Pizza
            color="#8943EF"
            size={60}
            className="d-flex align-self-center"
          />
          <p className="w3 s3 mt-4">{data[step].body}</p>
        </div>
      </div>
    </>
  );
};

export const AllAdminNotifs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = isAuthenticated();
  const [values, setValues] = useState({
    step: 0,
  });
  const { step } = values;
  const data = location.state;

  useEffect(() => {
    if (!data) navigate("/dashboard");
  }, [data, navigate]);

  const assignRider = (item) => {
    POSTAPI_ADMIN("/order/one", { orderId: item.entityId }).then((resp) => {
      if (resp.success) {
        var stateData = {
          ...resp.order,
          riders: resp.riders,
        };
        ReadNotification({ userId, notificationId: item._id });
        navigate("/orders/created/view", { state: stateData });
      } else if (resp.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Something went wrong!",
        }));
    });
  };

  const Body = () => {
    switch (data[step].entity) {
      case ENTITY_TYPE.ORDER:
        return (
          <>
            <p className="c7 s2">{data[step].createdAt}</p>
            <ShoppingBag color="#8943EF" size={60} />
            <p className="w3 s3 mt-4">{data[step].body}</p>
            <Button
              className="half_right_btn_comp"
              onClick={() => assignRider(data[step])}
            >
              Assign Rider
            </Button>
          </>
        );
      case ENTITY_TYPE.INVENTORY:
        return (
          <>
            <p className="c7 s2">{data[step].createdAt}</p>
            <Package size={32} color="#8943EF" weight="fill" />
            <p className="w3 s3 mt-4">{data[step].body}</p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <p className="ml-3 w3 s3">Notifications</p>
      <div className="d-flex nt">
        <div className="nt_left">
          {data &&
            data.map((each, i) => (
              <div
                className={
                  i === step ? "ad_nt_left__each_sel" : "nt_left__each"
                }
                onClick={() => setValues((x) => ({ ...x, step: i }))}
              >
                <p className="c7">{each.createdAt}</p>
                <div className="d-flex align-items-center">
                  <ShoppingBag color="#8943EF" size={30} />
                  <span className="ml-2 s2">{each.title}</span>
                </div>
              </div>
            ))}
        </div>

        <div className="nt_right">{Body()}</div>
      </div>
    </>
  );
};
