// import MUIDataTable from 'mui-datatables'
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetAPIAdmin, POSTAPI_ADMIN, PUTAPIAdmin } from "../../Auth/Index";
import {
  AlertError,
  DataGridNova,
  HalfRightBtnComp,
  InputComp,
  LabelComp,
  TopAddComp,
  TopBackComp,
} from "../../Components/Common";
import { isTextEmpty } from "../../Components/Validate_Admin";
import "../../css/Admin/category.css";

export const Location = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    cats: [],
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    pageSize: 10,
  });
  const { cats, first, loading, errorPosition, errorText, pageSize } = values;

  useEffect(() => {
    if (first) {
      GetAPIAdmin("/location/").then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            cats: res.locations,
            first: false,
            loading: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first]);

  // const columns = [
  //     {
  //         name: 'name',
  //         label: 'Location Name',
  //         options: {
  //             sort: true
  //         }
  //     },
  //     {
  //         name: 'address',
  //         label: 'Address',
  //         options: {
  //             sort: true
  //         }
  //     },
  //     {
  //         name: 'estimatedDeliveryTime',
  //         label: 'Delivery Time',
  //         options: {
  //             sort: true
  //         }
  //     }
  // ];
  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No location yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  // };

  const handleRowClick = (item) => {
    navigate("/locations/edit", { state: item });
  };

  const columnsData = [
    {
      field: "name",
      headerName: "Location Name",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      width: 150,
    },
    {
      field: "estimatedDeliveryTime",
      headerName: "Delivery Time",
      width: 120,
      renderCell: (params) => {
        return <span>Rs. {params.value}</span>;
      },
    },
  ];

  return (
    <div className="category_body">
      {TopAddComp("Locations", "/locations/add", "Add Location")}

      {/* <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    data={cats}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider> */}

      <div style={{ height: "65vh", width: "500px" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={cats}
          columns={columnsData}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const AddLocation = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    address: "",
    estimatedDeliveryTime: "",
    loading: false,
    errorPosition: "",
    errorText: "",
  });
  const {
    name,
    address,
    estimatedDeliveryTime,
    loading,
    errorPosition,
    errorText,
  } = values;

  useEffect(() => {}, [errorPosition]);

  const handleCreate = () => {
    if (loading) return;
    var sendData = {
      name: name,
      address: address,
      estimatedDeliveryTime: estimatedDeliveryTime,
    };
    setValues((prev) => ({ ...prev, loading: true }));
    POSTAPI_ADMIN("/location/create", sendData).then((res) => {
      if (res.success) navigate("/locations");
      if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          first: false,
          errorText: res.error,
          loading: false,
        }));
    });
  };

  return (
    <>
      {TopBackComp("Add Location", "/locations")}

      <div className="add_category_body">
        {LabelComp("Location Name", "name", errorPosition, errorText)}
        {InputComp(name, "name", setValues, "add_category_input")}

        {LabelComp("Address", "address", errorPosition, errorText)}
        {InputComp(address, "address", setValues, "add_category_input")}

        {LabelComp(
          "Delivery Time (min)",
          "estimatedDeliveryTime",
          errorPosition,
          errorText,
        )}
        {InputComp(
          estimatedDeliveryTime,
          "estimatedDeliveryTime",
          setValues,
          "add_category_input",
        )}

        {HalfRightBtnComp("Add Location", handleCreate, loading)}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

export const EditLocation = () => {
  const navigate = useNavigate();
  var location = useLocation();
  var stateData = location.state;
  const [values, setValues] = useState({
    name: stateData ? stateData.name : "",
    address: stateData ? stateData.address : "",
    estimatedDeliveryTime: stateData ? stateData.estimatedDeliveryTime : "",
    loading: false,
    errorPosition: "",
    errorText: "",
  });
  const {
    name,
    address,
    estimatedDeliveryTime,
    loading,
    errorPosition,
    errorText,
  } = values;

  useEffect(() => {}, [errorPosition]);

  const handleUpdate = () => {
    if (loading) return;
    var sendData = {
      locationId: stateData._id,
      name: name,
      address: address,
      estimatedDeliveryTime: estimatedDeliveryTime,
    };
    if (
      isTextEmpty(name) ||
      isTextEmpty(address) ||
      isTextEmpty(estimatedDeliveryTime)
    ) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Invalid values",
      }));
      return;
    }
    setValues((prev) => ({ ...prev, loading: true }));
    PUTAPIAdmin("/location/edit", sendData).then((res) => {
      if (res.success) navigate("/locations");
      if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          first: false,
          errorText: res.error,
          loading: false,
        }));
    });
  };

  return (
    <>
      {TopBackComp("Edit Location", "/locations")}

      <div className="add_category_body">
        {LabelComp("Location Name", "name", errorPosition, errorText)}
        {InputComp(name, "name", setValues, "add_category_input")}

        {LabelComp("Address", "address", errorPosition, errorText)}
        {InputComp(address, "address", setValues, "add_category_input")}

        {LabelComp(
          "Delivery Time (min)",
          "estimatedDeliveryTime",
          errorPosition,
          errorText,
        )}
        {InputComp(
          estimatedDeliveryTime,
          "estimatedDeliveryTime",
          setValues,
          "add_category_input",
        )}

        {HalfRightBtnComp("Update Location", handleUpdate, loading)}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};
