import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Slider as SliderMUI,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import { CaretDown, MinusCircle, TrashSimple } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { POSTAPI_USER, isAuthenticated } from "../../Auth/Index";
import {
  AlertError,
  BtnComp,
  CartQuantityChange,
  CartQuantitySide,
  CartSubTotalDiv,
  Fb_Con_data,
  FullBtnComp,
  HalfRightBtnComp,
  InputComp,
  getSpiceText,
  getSpiceValue,
} from "../../Components/Common";
import { MyCartTable } from "../../Components/MuiTable";
import { isTextEmpty } from "../../Components/Validate_Admin";
import "../../css/Market/myCart.css";
import { LoginModalView, OpenAddToCartModal } from "./AddToCartFn";
import { Item } from "./ReactSlick";
import { gtag_report_conversion } from "../../Components/Gtag/basketgtagConversion";
import isTimeInRange from "../../Components/check-time-range";

const theme = createTheme({
  components: {
    // Name of the component
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid #E9ECFF",
          borderRadius: "12px",
          backgroundColor: "#FFF",
          padding: "12px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0",
          minHeight: "0px",
          "&.Mui-expanded": {
            minHeight: "0px",
          },
        },
        content: {
          margin: "0px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
        expandIconWrapper: {
          color: "#2E2E2E",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
          color: "#818091",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          width: "50px",
          height: "50px",
          backgroundColor: "transparent",
          backgroundImage: `url("https://nova-foods.s3.amazonaws.com/miscellaneous/emojione-fire.png")`,
          boxShadow: "none",
          transition: "none",
          border: "none",
          "::before": {
            boxShadow: "none",
            transition: "none",
          },
          ":hover": {
            boxShadow: "none",
            transition: "none",
          },
          "&.Mui-active": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

export const MyCart = () => {
  const location = useLocation();
  const { userId } = isAuthenticated();
  const navigate = useNavigate();
  const [loadingItemmb, setLoadingItemmb] = useState(null);
  const [spiceLevels, setSpiceLevels] = useState(
    location && location.state && location.state.spiceLevels
      ? location.state.spiceLevels
      : [],
  );
  const [values, setValues] = useState({
    firstloading: false,
    cart:
      location && location.state && location.state.total ? location.state : {},
    promoCode: "",
    proceed_loading: false,
    promo_loading: false,
    errorPosition: "",
    errorText: "",
    // for modal add to cart
    modalItem: null,
    itemModalVisible: false,
    modifiersSel: [],
    likeItems:
      location && location.state && location.state.likeItems
        ? location.state.likeItems
        : [],
  });
  const {
    firstloading,
    cart,
    promoCode,
    proceed_loading,
    promo_loading,
    errorPosition,
    errorText,
    modalItem,
    itemModalVisible,
    modifiersSel,
    likeItems,
  } = values;
  // const fixedElement = document.getElementById('fixedElement')
  // var fixedElementHeight = 0
  // if (fixedElement) {
  //     fixedElementHeight = fixedElement.clientHeight
  // }
  const outletContext = useOutletContext();
  const setnumberOfItemsFn = outletContext ? outletContext[6] : null;
  const cartUpdatedForMycart = outletContext ? outletContext[7] : null;
  const setCartUpdatedForMycart = outletContext ? outletContext[8] : null;
  const businessHours = outletContext ? outletContext[11] : null;
  useEffect(() => {
    if (!location.state) {
      setValues((x) => ({ ...x, firstloading: true }));
      POSTAPI_USER("/cart/one", { userId }).then((cartVal) => {
        if (cartVal.success) {
          var spice = [];
          cartVal.cart &&
            cartVal.cart.foods &&
            cartVal.cart.foods.forEach((element) => {
              spice.push(getSpiceValue(element.spiceLevel));
            });
          setSpiceLevels(spice);
          setValues((x) => ({
            ...x,
            cart: cartVal.cart,
            firstloading: false,
            likeItems: cartVal.cart.likeItems,
          }));
        }
      });
    }
  }, [location.state, userId, promo_loading]);

  useEffect(() => {
    if (cartUpdatedForMycart) {
      POSTAPI_USER("/cart/one", { userId }).then((cartVal) => {
        if (cartVal.success) {
          var spice = [];
          cartVal.cart &&
            cartVal.cart.foods &&
            cartVal.cart.foods.forEach((element) => {
              spice.push(getSpiceValue(element.spiceLevel));
            });
          setSpiceLevels(spice);
          setValues((x) => ({
            ...x,
            cart: cartVal.cart,
            firstloading: false,
            likeItems: cartVal.cart.likeItems,
          }));
          setCartUpdatedForMycart(false);
        }
      });
    }
  }, [cartUpdatedForMycart, setCartUpdatedForMycart, userId]);

  // tip
  const tips = [20, 30, 40];
  const [tipType, setTipType] = useState("");
  const [customTip, setCustomTip] = useState(false);
  const handleChangeCustomTip = (e) => {
    const value = e?.target?.value;
    if (customTip) {
      setCustomTip(value);
      setTip(value);
    }
    if (isTextEmpty(value)) {
      setCustomTip(true);
      setTip(null);
    }
  };
  const [tip, setTip] = useState(null);
  // const tip = customTip && customTip?.length>0 ? customTip : !customTip && tipType > 0 ? tipType : null

  const proceedFn = () => {
    if(isTimeInRange(new Date())){
      Fb_Con_data("Initiate checkout");
      navigate("/delivery", { state: { tip: tip } });
      return
    }
    if (!businessHours?.isOpen) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: `We are currently closed but we encourage you to come back and visit 
        us from ${businessHours?.openingTime} to ${businessHours?.closingTime}. Thank you for understanding.`,
      }));
      return;
    }
    Fb_Con_data("Initiate checkout");
    navigate("/delivery", { state: { tip: tip } });
  };
  const applyPromo = () => {
    if (!promo_loading) {
      var send_data = {
        userId,
        promoCode,
      };
      setValues((prev) => ({ ...prev, promo_loading: true }));
      POSTAPI_USER("/cart/applyPromo", send_data).then((res) => {
        if (res.success) {
          var nowCart = { ...cart };
          nowCart.total = parseFloat(res.cart.total).toFixed(2);
          nowCart.promo = res.cart.promo;
          setValues((prev) => ({
            ...prev,
            promo_loading: false,
            cart: nowCart,
            errorPosition: "success",
            errorText: "Promo applied",
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            errorText: res.error,
            promo_loading: false,
          }));
      });
    }
  };
  const sliderSettings = {
    arrows: false,
    infinite: true,
    variableWidth: true,
    speed: 500,
    adaptiveHeight: false,
  };
  // for spicy level customize
  const handleChangeSlide = (e, newVal, prod, ind) => {
    var nowSpiceLevels = [...spiceLevels];
    nowSpiceLevels[ind] = newVal;
    setSpiceLevels(nowSpiceLevels);
    if (newVal - spiceLevels[ind] !== 0) {
      var send_data = {
        userId,
        itemId: prod.itemId,
        identifierId: prod.identifierId ?? null,
        comboId: prod.comboId,
        modifiers: prod.modifiers,
        quantity: 0,
        spiceLevel: getSpiceText(newVal),
      };
      POSTAPI_USER("/cart/edit", send_data).then((res) => {
        if (res.success) {
          setValues((x) => ({ ...x, cart: res.cart }));
        }
      });
    }
  };
  const removeOption = (prod, i, ind) => {
    var nowMods = prod.modifiers;
    var nowOp = nowMods[i].options;
    nowOp.splice(ind, 1);
    if (nowMods[i].options.length === 0) nowMods.splice(i, 1);
    var send_data = {
      userId,
      itemId: prod.itemId,
      identifierId: prod.identifierId ?? null,
      comboId: prod.comboId,
      quantity: 0,
      modifiers: nowMods ? nowMods : [],
    };
    POSTAPI_USER("/cart/edit", send_data).then((res) => {
      if (res.success) {
        setValues((x) => ({ ...x, cart: res.cart }));
      }
    });
  };
  const removeModifier = (prod, i) => {
    var nowMods = prod.modifiers;
    nowMods.splice(i, 1);
    var send_data = {
      userId,
      itemId: prod.itemId,
      identifierId: prod.identifierId ?? null,
      comboId: prod.comboId,
      quantity: 0,
      modifiers: nowMods ? nowMods : [],
    };
    POSTAPI_USER("/cart/edit", send_data).then((res) => {
      if (res.success) {
        setValues((x) => ({ ...x, cart: res.cart }));
      }
    });
  };
  // edit mobile
  const edit_one = (val, quantity) => {
    if (loadingItemmb) return;
    var send_data = {
      userId,
      itemId: val.itemId,
      identifierId: val.identifierId ?? null,
      comboId: val.comboId,
      quantity: quantity,
      modifiers: val.modifiers ? val.modifiers : [],
    };
    setLoadingItemmb(val.itemId);
    POSTAPI_USER("/cart/edit", send_data).then((res) => {
      setLoadingItemmb(null);
      if (res.success) {
        setValues((x) => ({ ...x, cart: res.cart }));
        setnumberOfItemsFn(res.numberOfItems);
      } else if (res.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
    });
  };
  const MobileCart = () => (
    <ThemeProvider theme={theme}>
      <div className="mycart_mobile">
        {cart &&
          cart.foods &&
          cart.foods.length > 0 &&
          cart.foods.map((prod, ind) => {
            return (
              <div className="eachCart_div p-0" key={ind}>
                <div className="mb-2 d-flex" key={ind}>
                  <Avatar
                    src={prod.image}
                    className="eachCart_image"
                    variant="rounded"
                  />
                  <div className="d-flex flex-column w-100">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="eachCart_name s2">{prod.name}</span>
                      <IconButton
                        disabled={prod.itemId === loadingItemmb}
                        onClick={() => edit_one(prod, -prod.quantity)}
                      >
                        <TrashSimple size={25} />
                      </IconButton>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                      {prod.itemId === loadingItemmb ? (
                        <div className="d-flex align-items-center">
                          <div className="div-disabled mr-2">
                            {CartQuantitySide(prod, edit_one)}
                          </div>
                          <CircularProgress
                            style={{
                              color: "#8943EF",
                              width: "25px",
                              height: "25px",
                            }}
                          />
                        </div>
                      ) : (
                        CartQuantitySide(prod, edit_one)
                      )}
                      <span className="eachCart_price c3">
                        Rs. {parseFloat(prod.price).toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>

                {/* customizations */}
                {((prod.modifiers && prod.modifiers.length > 0) ||
                  prod.spiceLevel !== null) && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<CaretDown weight="bold" className="ml-2" />}
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      Customizations
                    </AccordionSummary>
                    <AccordionDetails>
                      {prod.modifiers && prod.modifiers.length > 0 && (
                        <>
                          {prod.modifiers.map((each, i) => (
                            <div key={i}>
                              <span className="c3 w3 s2">
                                <IconButton
                                  className="ml-0"
                                  onClick={() => removeModifier(prod, i)}
                                >
                                  <MinusCircle
                                    color="#818090"
                                    weight="fill"
                                    className="cursor_pointer mod_icon"
                                  />
                                </IconButton>
                                {each.name}
                              </span>
                              <div className="d-flex flex-column ">
                                {each.options &&
                                  each.options.map((eachOP, ind) => {
                                    return (
                                      <div
                                        key={ind}
                                        className="d-flex align-items-center"
                                      >
                                        <IconButton
                                          className="ml-0"
                                          onClick={() =>
                                            removeOption(prod, i, ind)
                                          }
                                        >
                                          <MinusCircle className="cursor_pointer mod_icon" />
                                        </IconButton>
                                        <span>{eachOP.name}</span>
                                        <span className="ml-auto">
                                          Rs. {eachOP.price}
                                        </span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      {prod.spiceLevel !== null && (
                        <>
                          <p className="c3 w3 s2 ml-0 mt-2">Spicy Level</p>
                          <div className="spicy_slider_div mb-3">
                            <p className="spiceLevel_text">
                              {getSpiceText(spiceLevels[ind])}
                            </p>
                            {spiceLevels.length > 0 && (
                              <SliderMUI
                                defaultValue={28}
                                value={spiceLevels[ind]}
                                color="error"
                                step={null}
                                onChange={(e, newVal) =>
                                  handleChangeSlide(e, newVal, prod, ind)
                                }
                                valueLabelDisplay="off"
                                marks={[0, 28, 66, 100].map((each) => ({
                                  value: each,
                                  label: "",
                                }))}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>
            );
          })}
        <p className="w3 s3 mt-5">You may also like</p>
        {/* for mobile */}
        <div
          className="content mayAlsoLike_mb_div"
          style={{ marginBottom: "200px" }}
        >
          <Slider
            //  ref={setSliderRef}
            {...sliderSettings}
          >
            {likeItems &&
              likeItems.map((card, index) => (
                <div className="d-flex h-100" key={index}>
                  {CarBody(card)}
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </ThemeProvider>
  );
  const handleTip = (tipTypeVal, custom) => {
    setCustomTip(custom);
    setTipType(tipType === tipTypeVal ? null : tipTypeVal);
    setTip(tipType === tipTypeVal ? null : tipTypeVal);
  };
  const TipsBody = () => (
    <div className="d-flex flex-column gap-2 mb-4 mt-2">
      {/* text */}
      <div className="d-flex flex-column gap-1">
        <span className="s2 w3">Tip your rider</span>
        <span className="s1 c-secondary">
          The entire amount will be transferred to the rider
        </span>
      </div>
      {/* tip amount */}
      <div className="row gap-2">
        {tips?.map((each, i) => (
          <Button
            style={{
              height: i === 1 ? "62px" : "40px",
              borderRadius: "5px",
            }}
            key={"tip_" + i}
            onClick={(e) => handleTip(each, false)}
            className={`d-flex flex-column p-0 s2 col c3 px-0
                                    ${tipType === tips[i] ? "bor-primary" : "bor"}`}
          >
            <span className={`${i === 1 && "mt-auto"}`}>
              {each !== "Custom" && "Rs."} {each}
            </span>
            {i === 1 && (
              <div
                style={{ fontSize: "10px" }}
                className="bg-dark c4 mt-auto px-1 w-100"
              >
                Most Tipped
              </div>
            )}
          </Button>
        ))}
        <Button
          style={{ height: "40px", borderRadius: "5px" }}
          onClick={(e) => handleTip("", !customTip)}
          className={`px-0 s2 col c3 ${customTip ? "bor-primary" : "bor"}`}
        >
          Custom
        </Button>
      </div>
      {customTip && (
        <input
          type="number"
          inputMode="numeric"
          autoFocus
          value={customTip}
          onChange={handleChangeCustomTip}
          className="px-2 py-2 bor c3 s1"
          style={{ borderRadius: "5px", outline: "none" }}
        />
      )}
    </div>
  );
  const StickyDiv = () => (
    <div id="fixedElement" className="fixedElement">
      {/* tips */}
      {TipsBody()}
      <div className="d-flex justify-content-between mt-3">
        <p className="s2">Subtotal</p>
        <p className="w3 s2">Rs. {parseFloat(cart.subTotal).toFixed(2)}</p>
      </div>
      {tip && (
        <div className="d-flex justify-content-between">
          <p className="s2">Rider Tip</p>
          <p className="w3 s2">Rs. {tip}</p>
        </div>
      )}
      {cart.promo && (
        <div className="d-flex justify-content-between">
          <p className="s2">
            Promo{" "}
            <b>
              (<b style={{ color: "#192FA4" }}>{cart.promo.promoCode}</b>)
            </b>
          </p>
          <p className="w3 s2 c3">- Rs. {cart.promo.discount}</p>
        </div>
      )}
      {!cart.promo && (
        <div className="cart_promo mt-2">
          {InputComp(
            promoCode,
            "promoCode",
            setValues,
            "cart_promo__input bor_dark",
            "Enter promo code",
          )}
          {BtnComp("Apply", applyPromo, promo_loading)}
        </div>
      )}
      {FullBtnComp("Proceed to checkout", proceedFn, proceed_loading)}
    </div>
  );
  const CarBody = (card) => (
    <div
      className="mayAlsoLike_mb"
      onClick={(e) => OpenAddToCartModal(e, card, setValues)}
    >
      <div
        className="mayAlsoLike_mb_each"
        style={{
          backgroundImage: `url(${card.image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      ></div>
      <span className="c3 w3 s2">{card.name}</span>
      {/* <p className="c7 s-12">{card.estimatedTime} min approx.</p> */}
      <p className="sc_cm_item_div">
        {card.items &&
          card.items.map((eachItem, ind) => (
            <span key={ind} className="sc_cm_item">
              {eachItem.name}
              {eachItem.quantity > 1 && ` X${eachItem.quantity}`}
            </span>
          ))}
      </p>
      <div className="d-flex mt-auto align-items-center">
        {card.hasDiscount && (
          <span className="c7 w3 s2 mr-2 strikethrough">Rs. {card.price}</span>
        )}
        <span className="s5 c3 w3">Rs. {card.discountedPrice}</span>
      </div>
    </div>
  );

  return (
    <>
      {MyAddToCartModal(itemModalVisible, modalItem, modifiersSel, setValues)}

      {firstloading ? (
        <div className="d-flex w-100 justify-content-center mt-5">
          <CircularProgress
            className="d-flex align-self-center"
            sx={{ color: "#8943EF" }}
          />
        </div>
      ) : (
        <>
          {cart && !isTextEmpty(cart.subTotal) && cart.foods.length > 0 ? (
            <>
              <p className="w3 s3">My Cart</p>
              <div className="cart_table">
                <div className="cart_table__left">
                  <MyCartTable
                    data={cart}
                    foods={cart.foods}
                    userId={userId}
                    spiceLevels={spiceLevels}
                    setSpiceLevels={setSpiceLevels}
                    setValues={setValues}
                  />
                  {!cart.promo && (
                    <div className="cart_promo mt-4">
                      {InputComp(
                        promoCode,
                        "promoCode",
                        setValues,
                        "cart_promo__input bor_dark",
                        "Enter promo code",
                      )}
                      {BtnComp("Apply", applyPromo, promo_loading)}
                    </div>
                  )}
                </div>

                <div className="cart_table__right bor_rd_sm">
                  {TipsBody()}
                  {CartSubTotalDiv(cart, 0, tip)}
                  <div className="divider" />
                  <span className="c7">
                    *Our customer care center will call you for the delivery
                    charge.
                  </span>
                  {FullBtnComp(
                    "Proceed to checkout",
                    proceedFn,
                    proceed_loading,
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center mb-5 mt-5">
              <img
                src="https://nova-foods.s3.ap-south-1.amazonaws.com/emptycart.png"
                className="empty_cart_img"
                alt=""
              />
              <p className="empty_cart_title">Cart is empty</p>
              <p className="empty_cart_text">Add items to get started</p>
            </div>
          )}
        </>
      )}
      {/* for mobile */}
      {MobileCart()}
      {/* you my also like */}
      {likeItems && likeItems.length > 0 && (
        <>
          <p className="w3 s3 mt-5 mycart_large">You may also like</p>
          <Box sx={{ flexGrow: 1 }} className="ad_dash_top_cart">
            <Grid container spacing={2}>
              {likeItems &&
                likeItems.map((card, i) => (
                  <Grid key={i} item xs={12} sm={12} md={4} lg={3}>
                    {i < 4 && (
                      <Item
                        className="d-flex"
                        onClick={(e) => OpenAddToCartModal(e, card, setValues)}
                      >
                        <div
                          className="hp_each_popular_image"
                          style={{
                            backgroundImage: `url(${card.image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                          }}
                        >
                          {card.hasDiscount && (
                            <div className="mayAlsoLike_dis w2">
                              {card.offer &&
                                card.offer.discountType === "value" &&
                                "Rs."}
                              {card.offer.amount}
                              {card.offer &&
                                card.offer.discountType === "percent" &&
                                "%"}
                              <span> off </span>
                              {/* <span className='ml-1' /> */}
                              {/* <span>- {card.offer.name}</span> */}
                            </div>
                          )}
                        </div>
                        <span className="c3 w3 s2">{card.name}</span>
                        <div className="d-flex mt-2 mt-sm-0 align-items-center">
                          {card.hasDiscount && (
                            <span className="c7 w3 s2 mr-2 strikethrough">
                              Rs. {card.price}
                            </span>
                          )}
                          <span className="s5 c3 w3">
                            Rs. {card.discountedPrice}
                          </span>
                        </div>
                      </Item>
                    )}
                  </Grid>
                ))}
            </Grid>
          </Box>
        </>
      )}
      {StickyDiv()}
      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

const modalTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          width: "50px",
          height: "50px",
          backgroundColor: "transparent",
          backgroundImage: `url("https://nova-foods.s3.amazonaws.com/miscellaneous/emojione-fire.png")`,
          boxShadow: "none",
          transition: "none",
          border: "none",
          "::before": {
            boxShadow: "none",
            transition: "none",
          },
          ":hover": {
            boxShadow: "none",
            transition: "none",
          },
          "&.Mui-active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid #E9ECFF",
          borderRadius: "12px",
          backgroundColor: "#FFF",
          padding: "12px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0",
          minHeight: "0px",
          "&.Mui-expanded": {
            minHeight: "0px",
          },
        },
        content: {
          margin: "0px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
        expandIconWrapper: {
          color: "#2E2E2E",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
          color: "#818091",
        },
      },
    },
  },
});

const style = {
  position: "absolute",
  width: { xs: "95%", sm: "400px" },
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFFFFF",
  borderColor: "#FFF",
  boxShadow: 24,
  maxHeight: "95vh",
  overflow: "hidden",
};

export const MyAddToCartModal = (
  itemModalVisible,
  modalItem,
  modifiersSel,
  setFn,
) => {
  const [values, setValues] = useState({
    spiceLevel: 28,
    cartLoading: false,
    userData: isAuthenticated(),
    quantitySel: 1,
    loginModal: false,
    send_data_after_login: {},
  });
  const {
    spiceLevel,
    cartLoading,
    userData,
    quantitySel,
    loginModal,
    send_data_after_login,
  } = values;
  const OutletContext = useOutletContext();
  var setnumberOfItemsFn = OutletContext ? OutletContext[6] : null;

  const modifierSelected = (modInd, optionInd) => {
    var nowMods = [...modifiersSel];
    var selected = nowMods[modInd].options[optionInd];
    selected.sel = selected.sel ? false : true;
    setValues((prev) => ({ ...prev, modifiersSel: nowMods }));
  };
  const handleChangeSlide = (e, newVal) => {
    setValues((x) => ({ ...x, spiceLevel: newVal }));
  };

  const modalBody = () => (
    <ThemeProvider theme={modalTheme}>
      <div className="hp_modal_body">
        {/* {modalItem.hasDiscount &&
                    <div className='mayAlsoLike_dis eachitem_brand_abs d-flex w2 s2'>
                        {modalItem.offer && modalItem.offer.discountType === 'value' && 'Rs.'}
                        {modalItem.offer.amount}
                        {modalItem.offer && modalItem.offer.discountType === 'percent' && '%'}
                        <span className='ml-1' /> off
                        <span className='d-none d-sm-block ml-1'>- </span>
                        <span className='d-none d-sm-block ml-1'>{modalItem.offer.name}</span>
                    </div>} */}
        <img src={modalItem.image} alt="" className="hp_modal_image" />
        <div className="p-3">
          {modalItem.categoryImage && (
            <div className="d-flex mt-3 mb-3">
              {modalItem.categoryImage && (
                <Tooltip arrow title={modalItem.categoryName}>
                  <Avatar className="mr-2" src={modalItem.categoryImage} />
                </Tooltip>
              )}
              {modalItem.brandImage && (
                <Tooltip arrow title={modalItem.brandName}>
                  <Avatar className="mr-2" src={modalItem.brandImage} />
                </Tooltip>
              )}
            </div>
          )}
          <p className="c3 w3 s2">{modalItem.name}</p>
          {/* <p className="c7 s-12 mb-1">{modalItem.estimatedTime} min approx.</p> */}
          {modalItem.items &&
            modalItem.items.map((eachItem, ind) => (
              <p className="sc_cm_item_div">
                <span key={ind} className="sc_cm_item">
                  {eachItem.name}
                  {eachItem.quantity > 1 && ` X${eachItem.quantity}`}
                </span>
              </p>
            ))}
          <p className="c3 s3 w3">
            {modalItem.hasDiscount && (
              <span className="c7 w3 s3 mr-2 strikethrough">
                Rs. {modalItem.price}
              </span>
            )}
            <span className="s3">Rs. {modalItem.discountedPrice}</span>
          </p>
          <p className="mt-4 mb-3">{modalItem.description}</p>

          {modalItem.modifiers && modalItem.modifiers.length > 0 && (
            <>
              <FormGroup>
                {modalItem.modifiers.map((each, i) => (
                  <div key={i}>
                    <p className="c3 w3 s2">{each.name}</p>
                    <div className="d-flex flex-column">
                      {each.options &&
                        each.options.map((eachOP, ind) => {
                          return (
                            <div
                              key={ind}
                              className="
                                                            d-flex justify-content-between
                                                             align-items-center"
                            >
                              <FormControlLabel
                                key={ind}
                                control={
                                  <Checkbox
                                    sx={{
                                      color: "#8943EF",
                                      "&.Mui-checked": {
                                        color: "#8943EF",
                                      },
                                    }}
                                    value={each.sel}
                                    onClick={() => modifierSelected(i, ind)}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                                label={eachOP.name}
                              />
                              <span>Rs. {eachOP.price}</span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ))}
              </FormGroup>
            </>
          )}

          {modalItem.hasSpiceLevel && (
            <>
              <p className="c3 w3 s2">Spicy Level</p>
              <div className="spicy_slider_div mb-3">
                <p className="spiceLevel_text">{getSpiceText(spiceLevel)}</p>
                <SliderMUI
                  defaultValue={[20, 40]}
                  value={spiceLevel}
                  color="error"
                  step={null}
                  onChange={handleChangeSlide}
                  valueLabelDisplay="off"
                  marks={[0, 28, 66, 100].map((each) => ({
                    value: each,
                    label: "",
                  }))}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </ThemeProvider>
  );

  const addToCartFn = () => {
    var spiceLevelVal = "No";
    switch (spiceLevel) {
      case 0:
        spiceLevelVal = "No";
        break;
      case 28:
        spiceLevelVal = "Low";
        break;
      case 66:
        spiceLevelVal = "Medium";
        break;
      case 100:
        spiceLevelVal = "High";
        break;
      default:
        spiceLevelVal = "No";
        break;
    }
    var modArr = [];
    modifiersSel.forEach((element) => {
      var eachElement = {};
      var isSelected = element.options.filter((x) => x.sel);
      if (isSelected.length > 0) {
        eachElement.modifierId = element.modifierId;
        eachElement.name = element.name;
        var opArr = [];
        isSelected.forEach((eachOption) => {
          opArr.push({
            name: eachOption.name,
            price: eachOption.price,
          });
        });
        eachElement.options = opArr;
        modArr.push(eachElement);
      }
    });
    var card_send = {
      userId: userData.userId,
      itemId: modalItem.items
        ? null
        : modalItem._id
          ? modalItem._id
          : modalItem.itemId,
      comboId: modalItem.items ? modalItem._id : modalItem.comboId,
      modifiers: modArr,
      quantity: quantitySel,
      spiceLevel: spiceLevelVal,
    };
    if (userData && userData.userId) {
      setValues((x) => ({ ...x, cartLoading: true }));
      Fb_Con_data("Add to cart");
      gtag_report_conversion(isAuthenticated()?.userId)
      POSTAPI_USER("/cart/addTo", card_send).then((res) => {
        if (res.success) {
          setValues((val) => ({
            ...val,
            quantitySel: 1,
            cartLoading: false,
          }));
          setFn((x) => ({
            ...x,
            itemModalVisible: false,
            modalItem: null,
            modifiersSel: [],
            errorPosition: "success",
            errorText: "Added to cart",
            cart: res.cart,
          }));
          setnumberOfItemsFn && setnumberOfItemsFn(res.numberOfItems);
        } else if (res.error)
          setFn((val) => ({
            ...val,
            cartLoading: false,
            itemModalVisible: false,
            modalItem: null,
            modifiersSel: [],
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    } //ask to login
    else {
      setValues((val) => ({
        ...val,
        quantitySel: 1,
        loginModal: true,
        send_data_after_login: card_send,
      }));
      setFn((x) => ({
        ...x,
        itemModalVisible: false,
        modalItem: null,
        modifiersSel: [],
      }));
    }
  };

  return (
    <>
      {modalItem && (
        <Modal
          open={itemModalVisible}
          disableRestoreFocus
          disableScrollLock
          onClose={() => {
            setValues((val) => ({
              ...val,
              quantitySel: 1,
              send_data_after_login: {},
            }));
            setFn((x) => ({
              ...x,
              itemModalVisible: false,
              modalItem: null,
              modifiersSel: [],
            }));
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {modalBody(modalItem)}
            <div
              key="1"
              className="d-flex w-100 justify-content-between align-items-center cart_footer p-3"
            >
              {CartQuantityChange(quantitySel, "quantitySel", setValues)}
              {HalfRightBtnComp("Add to cart", addToCartFn, cartLoading)}
            </div>
          </Box>
        </Modal>
      )}

      <LoginModalView
        OutletContext={OutletContext}
        loginModal={loginModal}
        send_data_after_login={send_data_after_login}
        setFn={setValues}
      />
    </>
  );
};
