import {
  Avatar,
  Box,
  Checkbox,
  createTheme,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Slider as SliderMUI,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import jwt_decode from "jwt-decode";
import OTPInput from "otp-input-react";
import { X } from "@phosphor-icons/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { GOOGLE_CLIENT_ID } from "../../Auth/Config";
import {
  AuthenticateNova,
  isAuthenticated,
  POSTAPI,
  POSTAPI_USER,
  POSTAPI_USER_afterlogin,
} from "../../Auth/Index";
import {
  AlertError,
  CartQuantityChange,
  Fb_Con_data,
  FullBtnComp,
  getSpiceText,
  HalfRightBtnComp,
  LabelComp,
} from "../../Components/Common";
import { getS3Image } from "../../Components/s3";
import { isTextEmpty } from "../../Components/Validate_Admin";
import "../../css/Market/addTocart.css";
import { gtag_report_conversion } from "../../Components/Gtag/basketgtagConversion";

export const AddToCartModal = (
  itemModalVisible,
  modalItem,
  modifiersSel,
  setFn,
  isFromSearch
) => {
  const OutletContext = useOutletContext();
  const [values, setValues] = useState({
    spiceLevel: 28,
    cartLoading: false,
    quantitySel: 1,
    loginModal: false,
    send_data_after_login: {},
    setLoginModalFn : OutletContext ? OutletContext[5] : null
  });
  const { spiceLevel, cartLoading, quantitySel, setLoginModalFn } = values;
  var setnumberOfItemsFn = OutletContext ? OutletContext[6] : null;
  const modifierSelected = (modInd, optionInd) => {
    var nowMods = [...modifiersSel];
    var selected = nowMods[modInd].options[optionInd];
    selected.sel = selected.sel ? false : true;
    setValues((prev) => ({ ...prev, modifiersSel: nowMods }));
  };
  const handleChangeSlide = (e, newVal) => {
    setValues((x) => ({ ...x, spiceLevel: newVal }));
  };
  const modalBody = () => (
    <ThemeProvider theme={modalTheme}>
      <div className="hp_modal_body">
        <IconButton
          className="hp_modal_body_x"
          onClick={() => {
            setValues((val) => ({
              ...val,
              quantitySel: 1,
              send_data_after_login: {},
            }));
            setFn((x) => ({
              ...x,
              itemModalVisible: false,
              modalItem: null,
              modifiersSel: [],
            }));
          }}
        >
          <X size={20} weight="bold" />
        </IconButton>
        <img src={modalItem.image} alt="" className="hp_modal_image" />
        <div className="p-3">
          {modalItem.categoryImage && (
            <div className="d-flex mt-3 mb-3">
              {modalItem.categoryImage && (
                <Tooltip arrow title={modalItem.categoryName}>
                  <Avatar className="mr-2" src={modalItem.categoryImage} />
                </Tooltip>
              )}
              {modalItem.brandImage && (
                <Tooltip arrow title={modalItem.brandName}>
                  <Avatar className="mr-2" src={modalItem.brandImage} />
                </Tooltip>
              )}
            </div>
          )}
          <p className="c3 w3 s2">{modalItem.name}</p>
          <p className="">
            {modalItem.items &&
              modalItem.items.map((eachItem, ind) => (
                <span key={ind} className="sc_cm_item">
                  {eachItem.name}
                  {eachItem.quantity > 1 && ` X${eachItem.quantity}`}
                </span>
              ))}
          </p>
          {/* <p className="c1 s3 w3">Rs. {modalItem.price}</p> */}
          <p className="c3 s3 w3">
            {modalItem.hasDiscount && (
              <span className="c7 w3 s3 mr-2 strikethrough">
                Rs. {modalItem.price}
              </span>
            )}
            <span className="s3">
              Rs.{" "}
              {modalItem.isCombo ? modalItem.price : modalItem.discountedPrice}
            </span>
          </p>
          <p className="mt-4 mb-3">{modalItem.description}</p>

          {modalItem.modifiers && modalItem.modifiers.length > 0 && (
            <>
              <FormGroup>
                {modalItem.modifiers.map((each, i) => (
                  <div key={i}>
                    <p className="c3 w3 s2">{each.name}</p>
                    <div className="d-flex flex-column">
                      {each.options &&
                        each.options.map((eachOP, ind) => {
                          return (
                            <div
                              key={ind}
                              className="
                                                            d-flex justify-content-between
                                                             align-items-center"
                            >
                              <FormControlLabel
                                key={ind}
                                control={
                                  <Checkbox
                                    sx={{
                                      color: "#8943EF",
                                      "&.Mui-checked": {
                                        color: "#8943EF",
                                      },
                                    }}
                                    value={each.sel}
                                    onClick={() => modifierSelected(i, ind)}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                                label={eachOP.name}
                              />
                              <span>Rs. {eachOP.price}</span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ))}
              </FormGroup>
            </>
          )}

          {modalItem.hasSpiceLevel && (
            <>
              <p className="c3 w3 s2">Spicy Level</p>
              <div className="spicy_slider_div mb-3">
                <p className="spiceLevel_text">{getSpiceText(spiceLevel)}</p>
                <SliderMUI
                  defaultValue={[20, 40]}
                  value={spiceLevel}
                  color="error"
                  step={null}
                  onChange={handleChangeSlide}
                  valueLabelDisplay="off"
                  marks={[0, 28, 66, 100].map((each) => ({
                    value: each,
                    label: "",
                  }))}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
  const addToCartFn = async () => {
    Fb_Con_data("Add to cart");
    var spiceLevelVal = "No";
    switch (spiceLevel) {
      case 0:
        spiceLevelVal = "No";
        break;
      case 28:
        spiceLevelVal = "Low";
        break;
      case 66:
        spiceLevelVal = "Medium";
        break;
      case 100:
        spiceLevelVal = "High";
        break;
      default:
        spiceLevelVal = "No";
        break;
    }
    var modArr = [];
    modifiersSel.forEach((element) => {
      var eachElement = {};
      var isSelected = element.options.filter((x) => x.sel);
      if (isSelected.length > 0) {
        eachElement.modifierId = element.modifierId;
        eachElement.name = element.name;
        var opArr = [];
        isSelected.forEach((eachOption) => {
          opArr.push({
            name: eachOption.name,
            price: eachOption.price,
          });
        });
        eachElement.options = opArr;
        modArr.push(eachElement);
      }
    });
    var card_send = {
      userId: isAuthenticated()?.userId,
      itemId: modalItem.items
        ? null
        : modalItem._id
          ? modalItem._id
          : modalItem.itemId,
      comboId: modalItem.items ? modalItem._id : modalItem.comboId,
      modifiers: modArr,
      quantity: quantitySel,
      spiceLevel: spiceLevelVal,
    };
    if (isAuthenticated()?.userId) {
      setValues((x) => ({ ...x, cartLoading: true }));
      gtag_report_conversion(isAuthenticated()?.userId)
      POSTAPI_USER("/cart/addTo", card_send).then((res) => {
        if (res.success) {
          setValues((val) => ({
            ...val,
            quantitySel: 1,
            cartLoading: false,
          }));
          setFn((x) => ({
            ...x,
            itemModalVisible: false,
            modalItem: null,
            modifiersSel: [],
            errorPosition: "success",
            errorText: "Added to cart",
          }));
          setnumberOfItemsFn && setnumberOfItemsFn(res.numberOfItems);
        } else if (res.error) {
          setValues((x) => ({ ...x, cartLoading: false }));
          setFn((val) => ({
            ...val,
            cartLoading: false,
            itemModalVisible: false,
            modalItem: null,
            modifiersSel: [],
            errorPosition: "apiErr",
            errorText: res.error,
          }));
        }
      });
    } //ask to login
    else {
      setValues((val) => ({
        ...val,
        quantitySel: 1,
        cartLoading: false,
      }));
      setFn((x) => ({
        ...x,
        itemModalVisible: false,
        modalItem: null,
        modifiersSel: [],
        cartLoading: false,
      }));
      if(isFromSearch)
        return setFn((x) => ({
          ...x,
          loginModal: true,
          send_data_after_login: card_send,
          cartLoading: false,
        }));
      setLoginModalFn((x) => ({
        ...x,
        loginModal: true,
        send_data_after_login: card_send,
        cartLoading: false,
      }));
    }
  };
  return (
    <>
      {modalItem && (
        <Modal
          open={itemModalVisible}
          disableRestoreFocus
          disableScrollLock
          onClose={() => {
            setValues((val) => ({
              ...val,
              quantitySel: 1,
              send_data_after_login: {},
              cartLoading: false,
            }));
            setFn((x) => ({
              ...x,
              itemModalVisible: false,
              modalItem: null,
              modifiersSel: [],
            }));
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {modalBody(modalItem)}
            <div
              key="1"
              className="d-flex w-100 justify-content-between align-items-center cart_footer p-3"
            >
              {CartQuantityChange(quantitySel, "quantitySel", setValues)}
              {HalfRightBtnComp("Add to cart", addToCartFn, cartLoading)}
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export const TestModal = (itemModalVisible, setFn) => {
  return (
    <Modal
      open={itemModalVisible}
      onClose={() => {
        setFn((x) => ({
          ...x,
          itemModalVisibleTest: false,
        }));
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>this is a modal olat</Box>
    </Modal>
  );
};

export const OpenAddToCartModal = (e, card, setValues) => {
  var carde = { ...card };
  var modsArr = [];
  carde.modifiers &&
    carde.modifiers.forEach((element) => {
      var mod = { ...element };
      var options = [];
      mod.options.map((each) =>
        options.push({ name: each.name, price: each.price }),
      );
      mod.options = options;
      modsArr.push(mod);
    });
  carde.modifiers = modsArr;
  setValues((prev) => ({
    ...prev,
    modalItem: carde,
    modifiersSel: carde.modifiers ? carde.modifiers : [],
    itemModalVisible: true,
  }));
  // setValues(carde, carde.modifiers ? carde.modifiers : [], true)
};

const style = {
  position: "absolute",
  width: { xs: "95%", sm: "400px" },
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFFFFF",
  borderColor: "#FFF",
  boxShadow: 24,
  maxHeight: "95vh",
  overflow: "hidden",
};

const modalTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          width: "50px",
          height: "50px",
          backgroundColor: "transparent",
          backgroundImage: `url("https://nova-foods.s3.amazonaws.com/miscellaneous/emojione-fire.png")`,
          boxShadow: "none",
          transition: "none",
          border: "none",
          "::before": {
            boxShadow: "none",
            transition: "none",
          },
          ":hover": {
            boxShadow: "none",
            transition: "none",
          },
          "&.Mui-active": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

export const LoginModalView = ({
  OutletContext,
  loginModal,
  send_data_after_login,
  setFn,
  fromCart,
}) => {
  const google = window.google;
  const [values, setValues] = useState({
    step: 1,
    errorPosition: "",
    errorText: "",
    reg: false,
    phone: "",
    loginName: "",
    loginEmail: "",
    login_loading: false,
    send_data: send_data_after_login,
    facebook_logged: false,
    remainingSeconds: null,
  });
  const {
    step,
    errorPosition,
    errorText,
    loginName,
    loginEmail,
    phone,
    login_loading,
    remainingSeconds,
    //  facebook_logged,
  } = values;
  const [otp, setOTP] = useState("");
  var setUserIdFn = OutletContext ? OutletContext[0] : null;
  var setValuesFn = OutletContext ? OutletContext[5] : null;
  var setnumberOfItemsFn = OutletContext ? OutletContext[6] : null;
  const inputRefs = useRef([]);

  const handleCloseModal = useCallback(() => {
    // Function logic goes here
    setFn((val) => ({
      ...val,
      loginModal: false,
      step: 1,
    }));
    setValues((x) => ({
      ...x,
      step: 1,
      phone: "",
      loginEmail: "",
      loginName: "",
      login_loading,
    }));
    setOTP("");
  }, [login_loading, setFn]);

  useEffect(() => {
    // callback for google login
    const handleCallbackResponse = (response) => {
      var userObject = jwt_decode(response.credential);
      var socialsLoginInfo = {
        type: "google",
        sub: userObject.sub,
        aud: userObject.aud,
        email: userObject.email,
        name: userObject.name,
        picture: userObject.picture,
      };
      handleCloseModal();
      POSTAPI("/user/login", { socialsLoginInfo }).then((res) => {
        if (res.success) {
          AuthenticateNova(res.data);
          setUserIdFn(res.data.userId);
          setValuesFn((x) => ({ ...x, first: true }));
          if (!fromCart)
            return setValues((x) => ({
              ...x,
              errorPosition: "success",
              errorText: "Logged in",
            }));
          if (send_data_after_login)
            send_data_after_login.userId = res.data.userId;
          Fb_Con_data("Add to cart");
          gtag_report_conversion(isAuthenticated()?.userId)
          POSTAPI_USER_afterlogin(
            "/cart/addTo",
            { ...send_data_after_login },
            res.data.jwtToken,
          ).then((resP) => {
            if (resP.success) {
              setValues((val) => ({
                ...val,
                login_loading: false,
                loginEmail: "",
                loginPass: "",
                errorPosition: "success",
                errorText: "Added to cart",
              }));
              setFn((x) => ({ ...x, loginModal: false }));
              setUserIdFn(res.data.userId);
              setnumberOfItemsFn && setnumberOfItemsFn(resP.numberOfItems);
            } else if (resP.error)
              setValues((val) => ({
                ...val,
                errorPosition: "apiErr",
                errorText: resP.error,
                login_loading: false,
              }));
          });
        }
        if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            errorText: res.error,
            login_loading: false,
          }));
      });
    };
    if (google && !isAuthenticated()) {
      google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCallbackResponse,
      });
      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "outline",
        size: "meduim",
        width: "330px",
        text: "AAAAA",
      });

      google.accounts.id.prompt();
    }
  }, [
    setnumberOfItemsFn,
    google,
    loginModal,
    fromCart,
    setValuesFn,
    send_data_after_login,
    setFn,
    setUserIdFn,
    step,
    handleCloseModal,
  ]);
  useEffect(() => {
    inputRefs?.current[0]?.focus();
  }, [loginModal]);
  // for timer resend
  useEffect(() => {
    if (remainingSeconds > 0) {
      const timerId = setInterval(() => {
        setValues((x) => ({ ...x, remainingSeconds: x.remainingSeconds - 1 }));
        // setRemainingSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(timerId);
      };
    } else {
      handletimerEnd();
    }
  }, [remainingSeconds]);

  // enter on input1
  const handleKeyDown = (event, index) => {
    if (event.key === "Enter") {
      if (index === 0) {
        handleSubmit1();
        return;
      }
      if (index === 3) {
        handleSubmit2();
        return;
      } else if (index === 4) {
        handleSubmit3();
        return;
      }
      event.preventDefault();
      const nextIndex = index + 1;
      if (inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
  };
  const handleSubmit1 = async () => {
    if (isTextEmpty(phone))
      return setValues((x) => ({
        ...x,
        errorPosition: "phone",
        errorText: "Invalid phone",
      }));
    setValues((x) => ({ ...x, login_loading: true }));
    const res = await POSTAPI("/user/checkUser", {
      contact: phone,
    });
    if (res.error)
      return setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: res.error,
        login_loading: false,
      }));
    setValues((x) => ({
      ...x,
      step: res.isExistingUser ? 3 : 2,
      remainingSeconds: res.isExistingUser ? 60 : null,
      login_loading: false,
    }));
    inputRefs?.current[2]?.focus();
  };
  const handleSubmit2 = async () => {
    if (isTextEmpty(phone))
      return setValues((x) => ({
        ...x,
        errorPosition: "phone",
        errorText: "Invalid phone",
      }));
    else if (isTextEmpty(loginName))
      return setValues((x) => ({
        ...x,
        errorPosition: "loginName",
        errorText: "Invalid name",
      }));
    setValues((x) => ({ ...x, login_loading: true }));
    const res = await POSTAPI("/user/sendOTP", {
      contact: phone,
      name: loginName,
      email: loginEmail,
    });
    if (res.error)
      return setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: res.error,
        login_loading: false,
      }));
    setValues((x) => ({
      ...x,
      step: 3,
      remainingSeconds: 60,
      login_loading: false,
    }));
  };
  const handleSubmit3 = async () => {
    if (isTextEmpty(otp) || otp.length !== 4)
      return setValues((x) => ({
        ...x,
        errorPosition: "otp",
        errorText: "Invalid OTP",
      }));
    setValues((x) => ({ ...x, login_loading: true }));
    const res = await POSTAPI("/user/verifyOTPlogin", {
      name: loginName,
      contact: phone,
      email: loginEmail,
      otp: otp,
    });
    if (res.error)
      return setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: res.error,
        login_loading: false,
      }));
    AuthenticateNova(res.data);
    setUserIdFn(res.data.userId);
    setValuesFn((x) => ({ ...x, first: true }));
    if (!fromCart) {
      setValues((x) => ({
        ...x,
        errorPosition: "success",
        errorText: "Logged in",
        login_loading: false,
      }));
      handleCloseModal();
    }
    if (send_data_after_login) {
      send_data_after_login.userId = res.data.userId;
      Fb_Con_data("Add to cart");
      gtag_report_conversion(isAuthenticated()?.userId)
      POSTAPI_USER_afterlogin(
        "/cart/addTo",
        { ...send_data_after_login },
        res.data.jwtToken,
      ).then((resP) => {
        if (resP.success) {
          handleCloseModal();
          setValues((val) => ({
            ...val,
            errorPosition: "success",
            errorText: "Added to cart",
          }));
          setFn((x) => ({ ...x, loginModal: false }));
          setUserIdFn(res.data.userId);
          setnumberOfItemsFn && setnumberOfItemsFn(resP.numberOfItems);
        } else if (resP.error) {
          handleCloseModal();
          setValues((val) => ({
            ...val,
            errorPosition: "apiErr",
            errorText: resP.error,
          }));
        }
      });
    }
  };
  const handletimerEnd = () => {
    setValues((x) => ({ ...x, allowResend: true }));
  };
  const handleResendOTP = async () => {
    setValues((x) => ({ ...x, remainingSeconds: 60 }));
    const res = await POSTAPI("/user/resendOTP", {
      contact: phone,
    });
    if (res.error)
      return setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: res.error,
      }));
    setValues((x) => ({
      ...x,
      errorPosition: "success",
      errorText: "OTP resent",
    }));
  };
  return (
    <>
      {
        <Modal
          keepMounted
          open={loginModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="loginCartModal p-3 d-flex flex-column">
            {/* step 1 */}
            {step === 1 && (
              <div className={"d-flex flex-column"}>
                {/* top */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="ad_body_title">Login/Register</span>
                  <IconButton
                    onClick={() => {
                      setFn((val) => ({ ...val, loginModal: false }));
                      handleCloseModal();
                    }}
                  >
                    <X color="#000" />
                  </IconButton>
                </div>

                {/* body */}
                {LabelComp("Phone", "phone", errorPosition, errorText)}
                <div className="d-flex w-full" style={{ height: "45px" }}>
                  <div
                    className="d-flex w-auto px-2 align-items-center"
                    style={{
                      border: "1px solid #818090",
                      borderRadius: "12px 0 0 12px",
                    }}
                  >
                    <img
                      className="flag_img"
                      src={getS3Image("flag_of_nepal.png")}
                      alt=""
                    />
                    <span>+977</span>
                  </div>
                  <div className="d-flex w-100">
                    <input
                      type="text"
                      className="h-100 border-left-0 z-50 pl-2"
                      style={{
                        width: "100%",
                        outline: "none",
                        border: "1px solid rgba(129, 128, 144, 1)",
                        borderRadius: "0 12px 12px 0",
                      }}
                      autoFocus
                      ref={(ref) => (inputRefs.current[0] = ref)}
                      onKeyDown={(event) => handleKeyDown(event, 0)}
                      value={phone}
                      onChange={(e) =>
                        setValues((x) => ({
                          ...x,
                          phone: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                {FullBtnComp("Login with OTP", handleSubmit1, login_loading)}
              </div>
            )}

            {/* step 2 */}
            {step === 2 && (
              <div className={"d-flex flex-column"}>
                {/* top */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="ad_body_title">Register</span>
                  <IconButton
                    onClick={() => {
                      setFn((val) => ({ ...val, loginModal: false }));
                      handleCloseModal();
                    }}
                  >
                    <X color="#000" />
                  </IconButton>
                </div>

                {/* body */}
                {LabelComp("Phone", "phone", errorPosition, errorText)}
                <div className="d-flex" style={{ height: "45px" }}>
                  <div
                    className="d-flex w-auto px-2 align-items-center"
                    style={{
                      border: "1px solid #818090",
                      borderRadius: "12px 0 0 12px",
                    }}
                  >
                    <img
                      className="flag_img"
                      src={getS3Image("flag_of_nepal.png")}
                      alt=""
                    />
                    <span>+977</span>
                  </div>
                  <div className="d-flex w-100">
                    <input
                      type="text"
                      className="h-100 z-50 border-left-0 pl-2"
                      style={{
                        width: "100%",
                        outline: "none",
                        border: "1px solid #818090",
                        borderRadius: "0 12px 12px 0",
                      }}
                      ref={(ref) => (inputRefs.current[1] = ref)}
                      onKeyDown={(event) => handleKeyDown(event, 1)}
                      value={phone}
                      onChange={(e) =>
                        setValues((x) => ({
                          ...x,
                          phone: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                {/* name */}
                {LabelComp(
                  "Full Name *",
                  "loginName",
                  errorPosition,
                  errorText,
                  "mt-3",
                )}
                <input
                  type="text"
                  value={loginName}
                  style={{ marginBottom: "10px" }}
                  className={"admin_input"}
                  autoFocus
                  ref={(ref) => (inputRefs.current[2] = ref)}
                  onKeyDown={(event) => handleKeyDown(event, 2)}
                  onChange={(e) =>
                    setValues((prev) => ({
                      ...prev,
                      loginName: e.target.value,
                    }))
                  }
                />
                {LabelComp("Email", "loginEmail", errorPosition, errorText)}
                <input
                  type="text"
                  value={loginEmail}
                  style={{ marginBottom: "10px" }}
                  className={"admin_input"}
                  ref={(ref) => (inputRefs.current[3] = ref)}
                  onKeyDown={(event) => handleKeyDown(event, 3)}
                  onChange={(e) =>
                    setValues((prev) => ({
                      ...prev,
                      loginEmail: e.target.value,
                    }))
                  }
                />
                {FullBtnComp("Register", handleSubmit2, login_loading)}
              </div>
            )}

            {/* step 3 */}
            {step === 3 && (
              <div className={"d-flex flex-column"}>
                {/* top */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <span className="ad_body_title">OTP Verification</span>
                  <IconButton
                    onClick={() => {
                      setFn((val) => ({ ...val, loginModal: false }));
                      handleCloseModal();
                    }}
                  >
                    <X color="#000" />
                  </IconButton>
                </div>

                {/* body */}
                <div className="d-flex align-items-center justify-content-center gap-2 mb-3">
                  <div className="d-flex gap-1">
                    Enter the OTP sent to <span className="c1 w3">{phone}</span>
                  </div>
                </div>
                {/* otp */}
                <div
                  className="d-flex justify-content-center"
                  onKeyDown={(e) => handleKeyDown(e, 4)}
                >
                  <OTPInput
                    value={otp}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    inputClassName="otp_class"
                    inputStyles={{
                      width: "40px",
                      height: "60px",
                      outline: "none",
                      border: "1px solid #E9ECFF",
                      borderRadius: "12px",
                    }}
                    className="w-fit"
                  />
                </div>
                {FullBtnComp("Verify OTP", handleSubmit3, login_loading)}

                {remainingSeconds === 0 ? (
                  <div
                    className="d-flex gap-2 s-12 justify-content-center cursor_pointer"
                    onClick={handleResendOTP}
                  >
                    Didn’t receive OTP?
                    <span className="c9 w3">Resend</span>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center gap-2 s-12">
                    <div className="c9 w3">
                      <span>{remainingSeconds}s</span>
                    </div>
                    <span>until resend</span>
                  </div>
                )}
              </div>
            )}

            {step !== 3 && (
              <>
                <p className="ml-auto mr-auto">Or</p>
                <div id="signInDiv" className="ml-auto mr-auto" />
              </>
            )}
          </Box>
        </Modal>
      }
      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};
