import React from "react";
import PreviewImage from "../image-preview";
import UploadImage from "../upload-image";

const ImageUploadWithPreview = ({
  thumbModal,
  setThumbModal,
  required,
  previewImage,
  setPreviewImage,
  setFieldValue,
  label,
  setValues,
}) => {
  const handleChange = (file) => {
    setPreviewImage(file === null ? "" : file);
    // setFieldValue('image', file === null ? '' : file);
    setValues((x) => ({ ...x, thumbnailImage: file }));
  };
  return (
    <>
      {previewImage ? (
        <PreviewImage
          thumbModal={thumbModal}
          setThumbModal={setThumbModal}
          file={previewImage}
          label={label}
          onClick={() => {
            setPreviewImage("");
            setValues((x) => ({ ...x, thumbnailImage: null }));
            // setFieldValue('image', '');
          }}
        />
      ) : (
        <UploadImage
          name={"image"}
          onChange={handleChange}
          label={label}
          required={required}
        />
      )}
    </>
  );
};

export default React.memo(ImageUploadWithPreview);
