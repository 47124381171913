import { Avatar, IconButton, ThemeProvider } from "@mui/material";
// import MUIDataTable from 'mui-datatables'
import { PlusCircle, TrashSimple } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  DELETEAPIAdmin,
  GetAPIAdmin,
  POSTAPI_CreateInfoAdmin,
  POSTAPI_formAdmin,
  PUT_API_formAdmin,
} from "../../Auth/Index";
import {
  AlertError,
  DataGridNova,
  ErrorsComp,
  getMuiTheme,
  HalfRightBtnComp,
  InputComp,
  InputComp_Num,
  InputCompTextarea,
  LabelComp,
  LabelComp_Index,
  RedSwitch,
  SelectImage,
  TableEditDelete,
  TopAddComp,
  TopBackComp,
} from "../../Components/Common";
import { ValidateComboMeals } from "../../Components/Validate_Admin";
import "../../css/Admin/item.css";

export const ComboMeals = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    combos: [],
    first: true,
    loading: true,
    errorPosition: "",
    errorText: "",
    edAnchor: null,
    edClicked: null,
    pageSize: 10,
  });
  const {
    combos,
    first,
    loading,
    errorPosition,
    errorText,
    edAnchor,
    edClicked,
    pageSize,
  } = values;

  useEffect(() => {
    if (first) {
      POSTAPI_CreateInfoAdmin().then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            combos: res.combos,
            loading: false,
            first: false,
          }));
        } else if (res.error)
          setValues((prev) => ({
            ...prev,
            errorPosition: "apiErr",
            first: false,
            errorText: res.error,
            loading: false,
          }));
      });
    }
  }, [first]);

  // const columns = [
  //     {
  //         name: '_id',
  //         label: '',
  //         options: {
  //             display: false
  //         }
  //     },
  //     {
  //         name: 'image',
  //         label: 'Image',
  //         options: {
  //             display: false
  //         }
  //     },
  //     {
  //         name: "name",
  //         label: "Item",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 var index = tableMeta.rowIndex
  //                 return (
  //                     <div className="d-flex align-items-center">
  //                         <Avatar style={{ borderRadius: '12px' }}
  //                             src={tableMeta.currentTableData[index].data[1]} />
  //                         <span className="ml-3">{value}</span>
  //                     </div>
  //                 )
  //             }
  //         }
  //     },
  //     {
  //         name: "price",
  //         label: "Amount",
  //         options: {
  //             filter: true,
  //             sort: true
  //         }
  //     },
  //     {
  //         name: "items",
  //         label: "No. of items",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 // var index = tableMeta.rowIndex
  //                 return (
  //                     <span>{value.length ? value.length : ''}</span>
  //                 )
  //             }
  //         }
  //     },
  //     {
  //         name: "",
  //         label: "",
  //         options: {
  //             filter: true,
  //             sort: true,
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <TableEditDelete onClick={(e) => e.stopPropagation()} id={tableMeta.rowData[0]}
  //                         EditFn={EditFn} DeleteFn={DeleteFn}
  //                         edAnchor={edAnchor} setValues={setValues} edClicked={edClicked} />
  //                 )
  //             }
  //         }
  //     }
  // ];
  // const options = {
  //     filterType: "dropdown",
  //     selectableRows: 'none',
  //     onRowClick: handleRowClick,
  //     textLabels: {
  //         body: {
  //             noMatch: loading ?
  //                 <CircularProgressComp /> :
  //                 'No item yet.',
  //         },
  //     },
  //     ...tableDisabledOptions,
  // };

  const EditFn = () => {
    var item = combos.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      navigate("/items/combo/edit", { state: item });
    }
  };

  const DeleteFn = () => {
    var item = combos.filter((x) => x._id === edClicked);
    if (item.length === 1) {
      item = item[0];
      DELETEAPIAdmin("/combo/remove", { comboId: edClicked }).then((res) => {
        if (res.success) {
          setValues((x) => ({
            ...x,
            errorPosition: "success",
            errorText: `Combo Item ${item.name} deleted`,
            combos: res.combos,
          }));
        } else if (res.error)
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    }
  };

  const handleRowClick = (item) => {
    navigate("/items/combo/edit", { state: item });
  };

  const columnsData = [
    {
      field: "name",
      headerName: "Item",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center">
            <Avatar style={{ borderRadius: "12px" }} src={params.row.image} />
            <span className="ml-3">{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Amount",
      width: 120,
      renderCell: (params) => {
        return <span>Rs. {params.value}</span>;
      },
    },
    {
      field: "items",
      headerName: "No. of Items",
      width: 130,
      renderCell: (params) => {
        const value = params.value;
        return <span>{value.length ? value.length : ""}</span>;
      },
    },
    {
      field: "icon",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        return (
          <TableEditDelete
            onClick={(e) => e.stopPropagation()}
            id={params.row._id}
            EditFn={EditFn}
            DeleteFn={DeleteFn}
            edAnchor={edAnchor}
            setValues={setValues}
            edClicked={edClicked}
          />
        );
      },
    },
  ];

  return (
    <div className="category_body">
      {TopAddComp("Combo Meals", "/items/combo/add", "Add Combo")}

      <ThemeProvider theme={getMuiTheme()}>
        {/* <MUIDataTable
                    data={combos}
                    columns={columns}
                    options={options}
                /> */}
      </ThemeProvider>

      <div style={{ height: "65vh", width: "650px" }}>
        <DataGridNova
          loading={loading}
          getRowHeight={() => "auto"}
          headerHeight={70}
          rows={combos}
          columns={columnsData}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          onCellClick={(e) => handleRowClick(e.row)}
        />
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </div>
  );
};

export const AddComboMeals = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    image: null,
    price: "",
    estimatedTime: "",
    description: "",
    items: [
      {
        itemId: "",
        quantity: "",
      },
    ],
    errorPosition: "",
    errorText: "",
    error_itemIndex: "",
    loading: false,
    first: true,
    allItems: [],
    hasSpiceLevel: false,
  });
  const {
    name,
    image,
    description,
    price,
    estimatedTime,
    items,
    errorPosition,
    errorText,
    error_itemIndex,
    loading,
    first,
    allItems,
    hasSpiceLevel,
  } = values;

  useEffect(() => {
    if (first) {
      if (first) {
        GetAPIAdmin("/item/").then((res) => {
          if (res.success) {
            var allItemsArr = res.items;
            allItemsArr = allItemsArr.map((each) => ({
              label: each.name,
              value: each._id,
            }));
            setValues((prev) => ({
              ...prev,
              allItems: allItemsArr,
              first: false,
            }));
          }
        });
      }
    }
  }, [first]);

  const AddCombo = async () => {
    if (loading) return;
    var validated = await ValidateComboMeals(
      name,
      image,
      price,
      estimatedTime,
      items,
      setValues,
    );
    if (validated) {
      setValues((prev) => ({
        ...prev,
        loading: true,
        errorPosition: "",
        errorText: "",
      }));
      var formData = new FormData();
      formData.append("name", name);
      formData.append("image", image);
      formData.append("price", price);
      formData.append("estimatedTime", estimatedTime);
      formData.append("hasSpiceLevel", hasSpiceLevel);
      formData.append("items", JSON.stringify(items));
      POSTAPI_formAdmin("/combo/create", formData).then((res) => {
        if (res.success) navigate("/items/combo");
        else if (res.error)
          setValues((prev) => ({
            ...prev,
            first: false,
            loading: false,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    }
  };

  const handleDeleteOption = (ind) => {
    var nowItems = [...items];
    if (items.length === 1) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Atleast one item needed",
      }));
      return;
    }
    nowItems.splice(ind, 1);
    setValues((val) => ({ ...val, items: nowItems }));
  };

  const handleChangeOption = (e, ind) => {
    var nowItems = [...items];
    nowItems[ind][e.target.name] = e.target.value;
    setValues((val) => ({ ...val, items: nowItems }));
  };

  const addOption = (option_index) => {
    var nowItems = [...items];
    nowItems[option_index] = { itemId: "", quantity: "" };
    setValues((val) => ({ ...val, items: nowItems }));
  };

  return (
    <>
      {TopBackComp("Add Combo", "/items/combo")}

      <div className="add_single_item_body">
        <div className="d-flex justify-content-between">
          <div className="single_item_left">
            {LabelComp("Combo Name", "name", errorPosition, errorText)}
            {InputComp(name, "name", setValues, "add_category_input_full")}

            <div className="d-flex mb-1 align-items-center">
              <span className="mr-1 mb-1">
                Image
                <span className="s1"> (should be 600*400)</span>
              </span>
              {ErrorsComp("image", errorPosition, errorText)}
            </div>
            {SelectImage(image, "image", setValues)}

            {LabelComp("Price", "price", errorPosition, errorText)}
            {InputComp_Num(
              price,
              "price",
              setValues,
              "add_category_input_full",
            )}

            {LabelComp(
              "Preparation time (minutes)",
              "estimatedTime",
              errorPosition,
              errorText,
            )}
            {InputComp_Num(
              estimatedTime,
              "estimatedTime",
              setValues,
              "add_category_input_full",
            )}

            {LabelComp("Description", "description", errorPosition, errorText)}
            {InputCompTextarea(
              description,
              "description",
              setValues,
              "add_category_input_full",
            )}

            <div className="d-flex justify-content-between mt-3">
              <span>Spice Level</span>
              <RedSwitch
                checked={hasSpiceLevel}
                onChange={() =>
                  setValues((prev) => ({
                    ...prev,
                    hasSpiceLevel: !hasSpiceLevel,
                  }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>

          <div className="single_item_left">
            {items.map((eachItem, item_index) => {
              return (
                <div key={item_index}>
                  <>
                    <div className="each_option">
                      <div className="d-flex">
                        {LabelComp_Index(
                          "Item",
                          "item_name",
                          item_index,
                          errorPosition,
                          errorText,
                          error_itemIndex,
                        )}
                        <IconButton
                          className="d-flex ml-auto"
                          onClick={() => handleDeleteOption(item_index)}
                        >
                          <TrashSimple />
                        </IconButton>
                      </div>

                      <Select
                        maxMenuHeight={"200px"}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        options={allItems}
                        value={allItems.filter(
                          (option) => option.value === eachItem.itemId,
                        )}
                        name="name"
                        onChange={(e) => {
                          var nowItems = [...items];
                          nowItems[item_index]["itemId"] = e.value;
                          setValues((val) => ({ ...val, items: nowItems }));
                        }}
                      />

                      {LabelComp_Index(
                        "Quantity",
                        "item_quantity",
                        item_index,
                        errorPosition,
                        errorText,
                        error_itemIndex,
                      )}
                      <input
                        type="number"
                        name="quantity"
                        value={eachItem.quantity}
                        className="mod_input"
                        onChange={(e) => handleChangeOption(e, item_index)}
                      />
                    </div>
                  </>
                  {item_index === items.length - 1 && (
                    <PlusCircle
                      className="plusCircle_option"
                      onClick={() => addOption(item_index + 1)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {HalfRightBtnComp("Add Combo", AddCombo, loading)}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};

export const EditComboMeals = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (!location.state) navigate("/items/combo");

  const [values, setValues] = useState({
    name: location.state ? location.state.name : "",
    image: location.state ? location.state.image : null,
    description: location.state ? location.state.description : "",
    price: location.state ? location.state.price : "",
    estimatedTime: location.state ? location.state.estimatedTime : "",
    items: location.state
      ? location.state.items
      : [
          {
            itemId: "",
            quantity: "",
          },
        ],
    errorPosition: "",
    errorText: "",
    error_itemIndex: "",
    loading: false,
    first: true,
    allItems: [],
    hasSpiceLevel: location.state
      ? location.state.hasSpiceLevel
        ? true
        : false
      : false,
  });
  const {
    name,
    image,
    description,
    price,
    estimatedTime,
    items,
    errorPosition,
    errorText,
    error_itemIndex,
    loading,
    first,
    allItems,
    hasSpiceLevel,
  } = values;

  useEffect(() => {
    if (first) {
      if (first) {
        GetAPIAdmin("/item/").then((res) => {
          if (res.success) {
            var allItemsArr = res.items;
            allItemsArr = allItemsArr.map((each) => ({
              label: each.name,
              value: each._id,
            }));
            setValues((prev) => ({
              ...prev,
              allItems: allItemsArr,
              first: false,
            }));
          }
        });
      }
    }
  }, [first]);

  const UpdateCombo = async () => {
    if (loading) return;
    var validated = await ValidateComboMeals(
      name,
      image,
      price,
      estimatedTime,
      items,
      setValues,
    );
    if (validated) {
      setValues((prev) => ({
        ...prev,
        loading: true,
        errorPosition: "",
        errorText: "",
      }));
      var formData = new FormData();
      formData.append("comboId", location.state._id);
      formData.append("name", name);
      formData.append("image", image);
      formData.append("description", description);
      formData.append("price", price);
      formData.append("estimatedTime", estimatedTime);
      formData.append("hasSpiceLevel", hasSpiceLevel);
      formData.append("items", JSON.stringify(items));
      PUT_API_formAdmin("/combo/edit", formData).then((res) => {
        if (res.success) navigate("/items/combo");
        else if (res.error)
          setValues((prev) => ({
            ...prev,
            first: false,
            loading: false,
            errorPosition: "apiErr",
            errorText: res.error,
          }));
      });
    }
  };

  const handleDeleteOption = (ind) => {
    var nowItems = [...items];
    if (items.length === 1) {
      setValues((val) => ({
        ...val,
        errorPosition: "apiErr",
        errorText: "Atleast one item needed",
      }));
      return;
    }
    nowItems.splice(ind, 1);
    setValues((val) => ({ ...val, items: nowItems }));
  };

  const handleChangeOption = (e, ind) => {
    var nowItems = [...items];
    nowItems[ind][e.target.name] = e.target.value;
    setValues((val) => ({ ...val, items: nowItems }));
  };

  const addOption = (option_index) => {
    var nowItems = [...items];
    nowItems[option_index] = { itemId: "", quantity: "" };
    setValues((val) => ({ ...val, items: nowItems }));
  };

  return (
    <>
      {TopBackComp("Edit Combo", "/items/combo")}

      <div className="add_single_item_body">
        <div className="d-flex justify-content-between">
          <div className="single_item_left">
            {LabelComp("Combo Name", "name", errorPosition, errorText)}
            {InputComp(name, "name", setValues, "add_category_input_full")}

            <div className="d-flex mb-1 align-items-center">
              <span className="mr-1 mb-1">
                Image
                <span className="s1"> (should be 600*400)</span>
              </span>
              {ErrorsComp("image", errorPosition, errorText)}
            </div>
            {SelectImage(image, "image", setValues)}

            {LabelComp("Price", "price", errorPosition, errorText)}
            {InputComp_Num(
              price,
              "price",
              setValues,
              "add_category_input_full",
            )}

            {LabelComp(
              "Preparation time (minutes)",
              "estimatedTime",
              errorPosition,
              errorText,
            )}
            {InputComp_Num(
              estimatedTime,
              "estimatedTime",
              setValues,
              "add_category_input_full",
            )}

            {LabelComp("Description", "description", errorPosition, errorText)}
            {InputCompTextarea(
              description,
              "description",
              setValues,
              "add_category_input_full",
            )}

            <div className="d-flex justify-content-between mt-3">
              <span>Spice Level</span>
              <RedSwitch
                checked={hasSpiceLevel}
                onChange={() =>
                  setValues((prev) => ({
                    ...prev,
                    hasSpiceLevel: !hasSpiceLevel,
                  }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div>
          <div className="single_item_left">
            {items.map((eachItem, item_index) => {
              return (
                <div key={item_index}>
                  <>
                    <div className="each_option">
                      <div className="d-flex">
                        {LabelComp_Index(
                          "Item",
                          "item_name",
                          item_index,
                          errorPosition,
                          errorText,
                          error_itemIndex,
                        )}
                        <IconButton
                          className="d-flex ml-auto"
                          onClick={() => handleDeleteOption(item_index)}
                        >
                          <TrashSimple />
                        </IconButton>
                      </div>

                      <Select
                        maxMenuHeight={"200px"}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        options={allItems}
                        value={allItems.filter(
                          (option) => option.value === eachItem.itemId,
                        )}
                        name="name"
                        onChange={(e) => {
                          var nowItems = [...items];
                          nowItems[item_index]["itemId"] = e.value;
                          setValues((val) => ({ ...val, items: nowItems }));
                        }}
                      />

                      {LabelComp_Index(
                        "Quantity",
                        "item_quantity",
                        item_index,
                        errorPosition,
                        errorText,
                        error_itemIndex,
                      )}
                      <input
                        type="number"
                        name="quantity"
                        value={eachItem.quantity}
                        className="mod_input"
                        onChange={(e) => handleChangeOption(e, item_index)}
                      />
                    </div>
                  </>
                  {item_index === items.length - 1 && (
                    <PlusCircle
                      className="plusCircle_option"
                      onClick={() => addOption(item_index + 1)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {HalfRightBtnComp("Update Combo", UpdateCombo, loading)}
      </div>

      {AlertError(errorPosition, errorText, setValues)}
    </>
  );
};
