import {
  Avatar,
  Box,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Skeleton,
  styled,
} from "@mui/material";
import {
  ArrowUpLeft,
  CaretDown,
  CaretLeft,
  CaretRight,
  EnvelopeSimple,
  MagnifyingGlass,
  PhoneList,
  ShootingStar,
  Users,
  X,
} from "@phosphor-icons/react";
import { DatePicker, Dropdown, Space, Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGridNova } from "../../../Components/Common";
import { useGetDashboardCusQuery } from "../../../slices/apiSlice";
import { ad_dashFrom, ad_dashOrigin, ad_dashTo, setDashDate } from "../../../slices/novaSlice";
import { rangePresets, rangePresetsNew, renderIncDec } from "./AdminDashboard";
const { RangePicker } = DatePicker;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const headerIcons = [
  <Users color="#8943EF" size={24} />,
  <ShootingStar color="#8943EF" size={24} />,
  <ShootingStar color="#8943EF" size={24} />,
  <ArrowUpLeft color="#8943EF" size={24} />,
];

export const AdminDashboardCus = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    from: useSelector(ad_dashFrom),
    to: useSelector(ad_dashTo),
    searchText: "",
    tab: 0,
    searchedData: [],
    type: "total",
    pageSize: 10,
    cus: null,
    openCus: false,
    page : 1,   
    totalPages : null
  });
  const {
    from,
    to,
    searchText,
    tab,
    searchedData,
    type,
    pageSize,
    cus,
    openCus,
    page,
    totalPages
  } = values;
  const onChangeTab = (index) => {
    switch (index) {
      case 0:
        setValues((x) => ({ ...x, type: "total" }));
        break;
      case 1:
        setValues((x) => ({ ...x, type: "new" }));
        break;
      case 2:
        setValues((x) => ({ ...x, type: "new_order" }));
        break;
      case 3:
        setValues((x) => ({ ...x, type: "returning" }));
        break;
      default:
        break;
    }
    setValues((x) => ({ ...x, tab: index }));
  };
  const columnsData = [
    {
      field: "name",
      headerName: "Customer Name",
      description: "This column is sortable",
      sortable: false,
      width: 240,
    },
    {
      field: "phone",
      headerName: "Contact",
      description: "This column is sortable",
      sortable: false,
      width: 130,
    },
    {
      field: "email",
      headerName: "Email",
      description: "This column is sortable",
      sortable: false,
      width: 180,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <span style={{ width: 180, overflow: "hidden" }}>
              {params.value}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "totalCompleted",
      headerName: "Orders Completed",
      description: "This column is sortable",
      sortable: false,
      width: 160,
      renderCell: (params) => (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "totalFailed",
      headerName: "Times Failed",
      description: "This column is sortable",
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {params.value}
        </div>
      ),
    },
  ];
  const origin = useSelector(ad_dashOrigin)
  const handleRowClick = (item) => {
    setValues((x) => ({ ...x, cus: item, openCus: true }));
  };
  const query = `from=${new Date(from).toISOString()}&to=${new Date(to).toISOString()}&type=${type}&page=${page}&limit=${pageSize}&origin=${origin}`;
  const { data, isFetching: isLoading } = useGetDashboardCusQuery(query);
  useEffect(() => {
    setValues((x) => ({
      ...x,
      searchedData: data?.customersOrderedItemsInfo ?? [],
      totalPages : data?.totalPages
    }));
  }, [data]);
  useEffect(() => {}, [isLoading]);
  const dateFormat = "MMMM D, YYYY h:mm A";
  const dateNow = new Date().getHours();
  const onChangeFilter = (date) => {
    setValues((x) => ({
      ...x,
      from: date[0],
      to: date[1],
      first: true,
      loading: true,
      searchedData: [],
    }));
    dispatch(
      setDashDate({
        dashFrom: date[0],
        dashTo: date[1],
      }),
    );
  };
  const items = [
    {
      label: <span onClick={e=>handlePageSize(25)}>25</span>,
      key: '25',
    },
    {
      label: <span onClick={e=>handlePageSize(50)}>50</span>,
      key: '50',
    },
    {
      label: <span onClick={e=>handlePageSize(100)}>100</span>,
      key: '100',
    },
  ];
  const handlePageSize = (newSize) => {
    setValues(x=>({...x, pageSize : newSize}))
  }
  const handlePrevious = () => {
    if(page - 1 === 0) {
      return
    }
    else setValues(x=>({...x, page : page - 1}))
  }
  const handleNext = () => {
    if(page + 1 > totalPages) {
      return
    }
    else setValues(x=>({...x, page : page + 1}))
  }
  return (
    <div className="d-flex flex-column" style={{ gap: 16 }}>
    {!isLoading ?  <Box
        sx={{
          flexGrow: 1,
          display: isLoading ? "none" : "flex",
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          {data?.stats?.map((each, i) => (
            <Grid
              key={"ad_dash_header_" + i}
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
            >
              <Item
                className="d-flex p-3"
                onClick={(e) => onChangeTab(i)}
                style={{ border: tab === i ? "1px solid #8943EF" : "" }}
              >
                <IconButton className="ad_dash_top_av">
                  {headerIcons[i]}
                </IconButton>
                <div className="ad_dash_top_value_div">
                  <span className="s-10 c-secondary text-uppercase w3">
                    {each.title}
                  </span>
                  <span className="s3 c3">{each.amount}</span>
                  <div className="d-flex gap-2 align-items-center">
                    {renderIncDec(each.type)}
                    {each.percent} %
                  </div>
                </div>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
      :
      <div className="flex">
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Skeleton
              variant="rectangular"
              height={105}
              style={{ borderRadius: "12px" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Skeleton
              variant="rectangular"
              height={105}
              style={{ borderRadius: "12px" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Skeleton
              variant="rectangular"
              height={105}
              style={{ borderRadius: "12px" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Skeleton
              variant="rectangular"
              height={105}
              style={{ borderRadius: "12px" }}
            />
          </Grid>
        </Grid>
      </div>}
      <div className="d-flex flex-column" style={{ width: "657px" }}>
        <Box width={{md:'70vw', lg:'839px'}}
          className="d-flex justify-content-between gap-2"
        >
          {SearchDiv(searchText, data, setValues)}
          <Space direction="vertical" size={12} className="ml-auto">
            <RangePicker
              presets={dateNow >= 12 ? rangePresetsNew : rangePresets}
              showTime
              format={"YYYY-MM-DD HH:mm"}
              style={{ width: "330px" }}
              className="ml-auto s1"
              size="large"
              value={[dayjs(from, dateFormat), dayjs(to, dateFormat)]}
              onChange={onChangeFilter}
            />
          </Space>
        </Box>
        <Box width={{ md:'70vw', lg:'839px'}} 
        height={{md:'45vh', lg:'570px'}}>
          <DataGridNova
            getRowHeight={() => "auto"}
            headerHeight={70}
            rows={searchedData}
            columns={columnsData}
            loading={isLoading}
            pageSizeOptions={[10, 25, 50, 100]}
            getRowId={(row) => row._id ?? Math.random()}
            disableSelectionOnClick
            onPaginationModelChange={e=> {
              setValues((x) => ({ ...x, pageSize: e.pageSize, page : e.page + 1 }))
            }}
            onCellClick={(e) => handleRowClick(e.row)}
            hideFooter
          />
          <div className="d-flex bg-white align-items-center" style={{padding:'16px 24px'}}>
            <div className="d-flex">
              <IconButton className="bg-sec-text rounded-5" disabled={page - 1 === 0} onClick={handlePrevious}>
                <CaretLeft className="c4" size={12}/>
              </IconButton>
              <IconButton className="bg-sec-text rounded-5 ml-3" disabled={page + 1 > totalPages} onClick={handleNext}>
                <CaretRight className="c4" size={12}/>
              </IconButton>
            </div>
            <div className="d-flex ml-5" style={{gap:'10px'}}>
              <span>Page</span>
              <span>{page}</span>
              <span>of {totalPages}</span>
            </div>
            <div className="ml-auto">
              <Dropdown menu={{ items }} trigger={['click']}>
                  <Space>
                    {pageSize}
                    <CaretDown />
                    per page
                  </Space>
              </Dropdown>
            </div>
          </div>
        </Box>
      </div>

      {CusDetail(cus, openCus, setValues)}
    </div>
  );
};

const SearchDiv = (searchText, data, setValues) => {
  const handleChangeSearch = (e) => {
    const text = e?.target?.value;
    const filteredData = data?.customersOrderedItemsInfo?.filter((x) =>
      x.name?.toLowerCase()?.includes(text),
    );
    setValues((x) => ({ ...x, searchText: text, searchedData: filteredData }));
  };
  return (
    <Paper
      component="form"
      sx={{
        p: "0px 16px",
        display: "flex",
        border: "1px solid #E9ECFF",
        borderRadius: "25px",
        width: "400px",
        boxShadow: "none",
        mb: "16px",
        padding: "0 16px",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search by customer name"
        inputProps={{ "aria-label": "search" }}
        onChange={handleChangeSearch}
        value={searchText}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <MagnifyingGlass color="#8943EF" />
      </IconButton>
    </Paper>
  );
};

const CusDetail = (cus, openCus, setValues) => {
  const divRef = useRef(null);

  useEffect(() => {
    // Function to handle click event
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setValues((x) => ({ ...x, openCus: false }));
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Unbind the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef, setValues]);

  if (!openCus) return;
  return (
    <div
      ref={divRef}
      className="d-flex flex-column position-fixed bg-white"
      style={{
        overflowY: "auto",
        width: "376px",
        height: "calc(100vh - 90px)",
        right: 0,
        padding: "24px",
      }}
    >
      <div
        className="d-flex flex-column position-relative w-full"
        style={{ gap: "24px" }}
      >
        {/* header */}
        <div className="d-flex justify-content-between align-items-center">
          <span className="s5 w3">{cus?.name}</span>
          <IconButton
            onClick={(e) => setValues((x) => ({ ...x, openCus: false }))}
          >
            <X />
          </IconButton>
        </div>
        {/* body */}
        <div className="d-flex flex-column s1" style={{ gap: "12px" }}>
          {/* user info */}
          {cus.phone && (
            <div className="d-flex gap-3 align-items-center">
              <PhoneList size={20} />
              <span>{cus.phone}</span>
            </div>
          )}
          {cus.email && (
            <div className="d-flex gap-3 align-items-center">
              <EnvelopeSimple size={20} />
              <span>{cus.email}</span>
            </div>
          )}
          {/* items info */}
          <div className="d-flex flex-column s2 c3" style={{ gap: "8px" }}>
            <span className="s2 w3">Favourite Items</span>
            <div
              className="d-flex justify-content-between s-12 w3"
              style={{
                gap: "16px",
                padding: "8px 16px",
                backgroundColor: "#F2F2F2",
              }}
            >
              <div className="d-flex" style={{ gap: 16 }}>
                <span className="s1 c5"># 1</span>
                <span className="ml-1">Items</span>
              </div>
              <span>Qty Sold</span>
            </div>
            <div
              className="d-flex flex-column bg-white"
              style={{ minHeight: "296px" }}
            >
              {cus?.itemsMap?.map((each, i) => (
                <div
                  key={"top5Items_" + i}
                  className="d-flex justify-content-between"
                  style={{ gap: "16px", padding: "8px 16px" }}
                >
                  <div className="d-flex" style={{ gap: 16 }}>
                    <span style={{ minWidth: "fit-content" }}># {i + 1}</span>
                    <div className="d-flex" style={{ gap: "8px" }}>
                      <Avatar
                        src={each.image}
                        style={{ width: 32, height: 32 }}
                      />
                      <div className="d-flex flex-column">
                        <span className="s1 w3">{each.name}</span>
                        <span className="s-12 w1">Rs. {each.price}</span>
                      </div>
                    </div>
                  </div>
                  <span style={{ minWidth: "fit-content" }}>
                    {each.soldUnits} unit{each.soldUnits > 1 && "s"}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
